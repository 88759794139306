export const locale = {
    lang: 'en',
    data: {
        common : {
          "from": "from",
          "to": "to",
          "hours" : "hours",
          "hour": "hour",
          'last-15-minutes' : "last 15 minutes",
          'last-30-minutes':"last 30 minutes",
          'last-1-hour' : "last hour",
          'last-4-hours': "last 4 hours",
          'last-12-hours': "last 12 hours",
          'last-24-hours':"last 24 hours",
          'today' : "today",
          'this-week': "this week",
          'this-month': "this month",
          'this-year': "this year",
          'week-to-date': "week to date",
          'month-to-date': "month to date",
          'year-to-date': "year to date",
          'yesterday': "yesterday",
          'day-before-yesterday': "day before yesterday",
          'this-day-last-week': "today, last week",
          'previous-week': "previous week",
          'previous-month': "previous month",
          'previous-year': "previous year",
          'last-7-days': "last 7 days",
          'last-30-days': "last 30 days",
          'last-60-days': "last 60 days",
          'last-90-days': "last 90 days",
          'last-6-months': "last 6 months",
          'last-1-year': "last year",
          'last-2-years': "last 2 years",
          'last-5-years': "last 5 years",
          languages : {
            'it' : 'Italiano',
            'en' : 'English'
          },
          dowById : {
            '1' : "Monday",
            '2' : "Tuesday",
            '3' : "Wednesday",
            '4' : "Thursday",
            '5' : "Friday",
            '6' : "Saturday",
            '7' : "Sunday"
          },
          dowByName:{
            'monday' : "Monday",
            'tuesday' : "Tuesday",
            'wednesday' : "Wednesday",
            'thursday' : "Thursday",
            'friday' : "Friday",
            'saturday' : "Saturday",
            'sunday' : "Sunday"
          },
          dowByContractedName:{
            "mon" : "Mon",
            "tue": "Tue",
            "wed": "Wed",
            "thu": "Thu",
            "fri": "Fri",
            "sat": "Sat",
            "sun": "Sun"
          },
          graph:{
            yAxes:{
              title:{
                count: "Count"
              }
            }
          }
        }
    }
};
