import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit, OnDestroy{


  constructor(
  ) {
  }


  ngOnInit() {

  }

  ngOnDestroy(){

  }
}
