import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DashboardItemConfig, DashboardItemViewGeneric,
  DashboardItemViewMetricDelta, DashboardItemViewSerialChart,
  DataResultInterface, DataSource,
  DataSourceAssemblerFactory,
  DataSourceAssemblerInterface,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory, SerialChartConfig, ViewAssemblerFactory
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {
  AuthService,
  FuseTranslationLoaderService,
  FuseConfigService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  GridsterDashboardConfig
} from "core-fe-angular";
import {DecimalPipe} from "@angular/common";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import {
  Filter, FilterLocationReferences,
  FilterLocationReferencesStrategy,
  FilterVisitJunkData, FilterVisitNewret, FilterVisitStrategy,
  FilterVisitTimeFilterStrategies
} from "iottacle-ts-models";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import {FilterBuilderGenericNeo} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-neo";

@Component({
  selector: 'store-return-rate',
  templateUrl: './store-return-rate.component.html',
  styleUrls: ['./store-return-rate.component.scss']
})
export class StoreReturnRateComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;
  filterChangedSubscription:Subscription;


  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel());
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {

    let dashboardItems:DashboardItem[] = [];
    //RepositoryCatalog.init(this.http);

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let filterBuilderElastic:FilterBuilderInterface = new FilterBuilderGenericElastic();
    let filterBuilderNeo:FilterBuilderInterface = new FilterBuilderGenericNeo();


    //Recency & Frequency
    let recencyFrequencyDataSource:DataSourceConfigInterfaceInternal = {
      name: "recencyFrequencyDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"recency": {"gte": 0, "lt": 604800000}}}
              ]}},
          agg : {
            "2": {
              "histogram": {
                "field": "recency",
                "interval": 86400000,
                "min_doc_count": 0
              },
              "aggs": {
                "3": {
                  "histogram": {
                    "field": "frequency",
                    "interval": 1,
                    "min_doc_count": 0
                  },
                  "aggs": {
                    "1": {
                      "cardinality": {
                        "field": "ma_sha1"
                      }
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let recencyFrequencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "1"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Recency Vs Frequency" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText : "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        createChart : (chart)=> {
          chart.maskBullets = false;

          var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
          var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

          xAxis.dataFields.category = "recency";
          yAxis.dataFields.category = "frequency";

          xAxis.renderer.grid.template.disabled = true;
          xAxis.renderer.minGridDistance = 40;

          yAxis.renderer.grid.template.disabled = true;
          yAxis.renderer.inversed = true;
          yAxis.renderer.minGridDistance = 30;

          var series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "recency";
          series.dataFields.categoryY = "frequency";
          series.dataFields.value = "value";
          series.sequencedInterpolation = true;
          series.defaultState.transitionDuration = 3000;

          var bgColor = new am4core.InterfaceColorSet().getFor("background");

          var columnTemplate = series.columns.template;
          columnTemplate.strokeWidth = 1;
          columnTemplate.strokeOpacity = 0.2;
          columnTemplate.stroke = bgColor;
          columnTemplate.tooltipText = "{recency}, {frequency}: {value.workingValue.formatNumber('#.')}";
          columnTemplate.width = am4core.percent(100);
          columnTemplate.height = am4core.percent(100);

          series.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color(bgColor),
            max: chart.colors.getIndex(0)
          });

          // heat legend
          var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
          heatLegend.width = am4core.percent(100);
          heatLegend.series = series;
          heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
          heatLegend.valueAxis.renderer.minGridDistance = 30;

          // heat legend behavior
          series.columns.template.events.on("over", (event) => {
            handleHover(event.target);
          })

          series.columns.template.events.on("hit", (event) => {
            handleHover(event.target);
          })

          function handleHover(column) {
            if (!isNaN(column.dataItem.value)) {
              heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
              heatLegend.valueAxis.hideTooltip();
            }
          }

          series.columns.template.events.on("out", (event) => {
            heatLegend.valueAxis.hideTooltip();
          });

          return chart;

        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:0,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(recencyFrequencyDataSource)),
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          if (result){
            let chartData:any = [];
            for (let r in result["1"].aggregations["2"]["buckets"]){
              let recency = result["1"].aggregations["2"]["buckets"][r].key;
              for (let f in result["1"].aggregations["2"]["buckets"][r]["3"].buckets){
                let frequency = result["1"].aggregations["2"]["buckets"][r]["3"].buckets[f].key;
                let value = result["1"].aggregations["2"]["buckets"][r]["3"].buckets[f]["1"].value;
                chartData.push({
                  recency : Math.round(recency / 1000 / 60 / 60 / 24),
                  frequency : frequency,
                  value: Math.log(value)*100
                })
              }
            }
            let maxFreq = 0;
            let maxRec = 0;
            for (let c in chartData){
              maxFreq = chartData[c].frequency > maxFreq ? chartData[c].frequency : maxFreq;
              maxRec = chartData[c].recency > maxFreq ? chartData[c].recency : maxRec;
            }
            chartData.sort((a, b) => {
              //100:x = maxFreq:a.frequency
              let scaledFreqA =(maxFreq/a.frequency)*(1/100);
              let scaledRecA =(maxRec/a.recency)*(1/100);
              let scaledFreqB =(maxFreq/b.frequency)*(1/100);
              let scaledRecB =(maxRec/b.recency)*(1/100);

              return Math.min(scaledRecB , scaledFreqB) - Math.min(scaledFreqA , scaledRecA);
              //return (b.frequency + b.recency) - (a.recency - a.frequency);
            });
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(recencyFrequencyItem);

    //Median Frequency
    let medianFrequencyDataSource:DataSourceConfigInterfaceInternal = {
      name: "medianFrequencyDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "percentiles": {
                "field": "frequency",
                "percents": [
                  50
                ],
                "keyed": false
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let medianFrequencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id:"2",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Median Frequency" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: " times in a week"
      })),
      specificItemConfig : {
        showTopText : true,
        topTextMatIconColorClass: "blue-fg",
        topTextTitle: "Your customer returns about:",
        gridsterConfig : {
          x:0,
          y:0,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(medianFrequencyDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            toRet = {
              mainValue : result["1"].aggregations["1"].values[0].value
            };
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(medianFrequencyItem);

    //Median Recency
    let medianRecencyDataSource:DataSourceConfigInterfaceInternal = {
      name: "medianFrequencyDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "percentiles": {
                "field": "recency",
                "percents": [
                  50
                ],
                "keyed": false
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let medianRecencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Median Recency" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 1,
        maxFractionDigits : 1,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: " days"
      })),
      specificItemConfig : {
        showTopText : true,
        topTextMatIconColorClass: "blue-fg",
        topTextTitle: "Your customer generally come back after:",
        gridsterConfig : {
          x:0,
          y:6,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(medianRecencyDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            toRet = {
              mainValue : result["1"].aggregations["1"].values[0].value/1000/60/60/24
            };
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(medianRecencyItem);

    // New vs Ret distribution over weekdays
    let newretDistributionOverWeekdaysDataSource:DataSourceConfigInterfaceInternal = {
      name: "newretDistributionOverWeekdaysDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startDow",
                "interval": 1,
                "min_doc_count": 0
              },
              "aggs": {
                "3": {
                  "terms": {
                    "field": "newret",
                    "size": 5,
                    "order": {
                      "_count": "desc"
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let newretDistributionOverWeekdaysItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "4",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "New & Returning Distribution Over Week" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "dow"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "%",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              name : "New",
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "new",
                "categoryX": "dow"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0
                },
                "strokeOpacity": 0
              },
            },
            {
              "type": "ColumnSeries",
              stacked: true,
              name : "Returning",
              "dataFields": {
                "valueY": "returning",
                "categoryX": "dow"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:0,
          y:12,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(newretDistributionOverWeekdaysDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            let total = result[1].hits.total;
            for (let b in result["1"].aggregations["2"]["buckets"]){
              chartData.push({
                dow : result["1"].aggregations["2"]["buckets"][b].key,
                new : result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"].length > 0 &&
                result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].key == 1 ?
                  (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].doc_count / total * 100 ): 0,
                returning : result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"].length > 0 &&
                result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].key == 1 ?
                  (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1].doc_count / total * 100 ) : 0
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(newretDistributionOverWeekdaysItem);

    // New vs Ret distribution over hod
    let newretDistributionOverHodDataSource:DataSourceConfigInterfaceInternal = {
      name: "newretDistributionOverHodDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0
              },
              "aggs": {
                "3": {
                  "terms": {
                    "field": "newret",
                    "size": 5,
                    "order": {
                      "_count": "desc"
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let newretDistributionOverHodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "5",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "New & Returning Distribution Over Hour Of Day" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "hod"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "%",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              name : "New",
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "new",
                "categoryX": "hod"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0
                },
                "strokeOpacity": 0
              },
            },
            {
              "type": "ColumnSeries",
              stacked: true,
              name : "Returning",
              "dataFields": {
                "valueY": "returning",
                "categoryX": "hod"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:18,
          y:12,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(newretDistributionOverHodDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            let total = result[1].hits.total;
            for (let b in result["1"].aggregations["2"]["buckets"]){
              chartData.push({
                hod : result["1"].aggregations["2"]["buckets"][b].key/3600,
                new : result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"].length > 0 &&
                result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].key == 1 ?
                  result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0] ?
                    (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].doc_count / total * 100 ) : 0 : 0,
                returning : result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"].length > 0 &&
                result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].key == 1 ?
                  result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1] ?
                    (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1].doc_count / total * 100 ) : 0 : 0
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(newretDistributionOverHodItem);

    //Funnel - from morning to evening
    let funnelMorningCountDataSource:DataSourceConfigInterfaceInternal = {
      name: "funnelMorningCountDataSource",
      type: "neo",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleNeo4jGenericQuery"),
      filter : filterBuilderNeo.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          queryTemplate: "match (m:MacAddress)-[:HAS]->(:Visits)-[:HAS]->(v:Visit)<-[:HAS]-(:AdminVisits)-[:HAPPENED_IN]->(l:LocationDetails)<-[:LOCATION_DETAILS]-(:AdministrativeEntity) " +
            " where l.id in [__locationDetailsIds__] and v.start > __start_start__ and v.start < __start_end__ and v.startSod >= 28800 and v.startSod <= 39600 " +
            " return count(distinct m) as countMorning",
          params : {}
        }
      })),
      specificDataSourceConfig: {},
    };
    let funnelMorningToEveningDataSource:DataSourceConfigInterfaceInternal = {
      name: "funnelMorningToEveningDataSource",
      type: "neo",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleNeo4jGenericQuery"),
      filter : filterBuilderNeo.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          queryTemplate: "match (m:MacAddress)-[:HAS]->(:Visits)-[:HAS]->(v:Visit)<-[:HAS]-(:AdminVisits)-[:HAPPENED_IN]->(l:LocationDetails)<-[:LOCATION_DETAILS]-(:AdministrativeEntity) " +
            " where l.id in [__locationDetailsIds__] and v.start > __start_start__ and v.start < __start_end__ and v.startSod >= 28800 and v.startSod <= 39600\n" +
            "\n" +
            " match (m)-[:HAS]->(:Visits)-[:HAS]->(v1:Visit)<-[:HAS]-(:AdminVisits)-[:HAPPENED_IN]->(l1:LocationDetails)<-[:LOCATION_DETAILS]-(:AdministrativeEntity) " +
            " where l1.id in [__locationDetailsIds__] and v1.start > (v.start + (1000*60*60)) and v1.startSod >= 64800 and v1.startSod <= 82800 return count(distinct m) as countm",
          params : {}
        }
      })),
      specificDataSourceConfig: {},
    };
    let funnelMorningToEveningItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "6",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Conversion Morning-Evening" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 1,
        maxFractionDigits : 1,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: "%"
      })),
      specificItemConfig : {
        showTopText : true,
        topTextMatIconColorClass: "blue-fg",
        topTextTitle: "How many morning customer comes also on evening:",
        gridsterConfig : {
          x:0,
          y:0,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(funnelMorningCountDataSource)),
        "2" : DataSource.of(DataSourceConfig.of(funnelMorningToEveningDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          if (result){
            let toRet;
            let allMorningVisits = result[1][0]._fields[0].low;
            let afternoonVisits = result[2][0]._fields[0].low;
            toRet = {
              mainValue : afternoonVisits / allMorningVisits * 100
            };
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(funnelMorningToEveningItem);

    //Funnel - how many times outside bwfore getting in
    let outsideToInsideDataSource:DataSourceConfigInterfaceInternal = {
      name: "outsideToInsideDataSource",
      type: "neo",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleNeo4jGenericQuery"),
      filter : filterBuilderNeo.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          queryTemplate: "match (m:MacAddress)-[:HAS]->(:Visits)-[:HAS]->(v:Visit)<-[:HAS]-(:AdminVisits)-[:HAPPENED_IN]->(l:LocationDetails)<-[:LOCATION_DETAILS]-(:AdministrativeEntity) " +
            " where l.id in [__locationDetailsIds__] and v.start > __start_start__ and v.start < __start_end__ and v.avgSs < -82 " +
            " match (m)-[:HAS]->(:Visits)-[:HAS]->(v1:Visit)<-[:HAS]-(:AdminVisits)-[:HAPPENED_IN]->(l1:LocationDetails)<-[:LOCATION_DETAILS]-(:AdministrativeEntity) " +
            " where l1.id in [__locationDetailsIds__] and  v1.start > v.start and v1.avgSs >= -82 " +
            " with m as m, count(v1) as countV1 " +
            " return avg(countV1) as averageTimes ",
          params : {}
        }
      })),
      specificDataSourceConfig: {},
    };
    let outsidetoInsideItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "7",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "How many times outside before getting in" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: "times"
      })),
      specificItemConfig : {
        showTopText : true,
        topTextMatIconColorClass: "blue-fg",
        topTextTitle: "How many times pass outside before getting in:",
        gridsterConfig : {
          x:0,
          y:0,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(outsideToInsideDataSource)),
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          if (result){
            let toRet;
            let averageTimes = result[1][0]._fields[0];
            toRet = {
              mainValue : averageTimes
            };
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(outsidetoInsideItem);

    // churn rate over time
    let churnRateOverTime:DataSourceConfigInterfaceInternal = {
      name: "churnRateOverTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'ts',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_ADMINS
          },
          query: {},
          agg : {
            "2": {
              "date_histogram": {
                "field": "ts",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1": {
                  "avg": {
                    "field": "churnRate"
                  }
                }
              }
            }
          },
          index : "store_kpi",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let churnRateOverTimeItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "8",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Churn rate over time" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Count",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "strokeWidth": 3,
              "tooltip": {
                "pointerOrientation": "vertical"
              }
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:0,
          y:18,
          cols:36,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(churnRateOverTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            for (let b in result["1"].aggregations["2"]["buckets"]){
              chartData.push({
                time : result["1"].aggregations["2"]["buckets"][b].key,
                value : result["1"].aggregations["2"]["buckets"][b]["1"].value
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(churnRateOverTimeItem);

    //after when get back lost customer
    let getBackLostCustomersDataSource:DataSourceConfigInterfaceInternal = {
      name: "getBackLostCustomersDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.NEW,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [], "must_not": [
                {"match_phrase": {"recency": {"query": 0}}}
                ]}},
          agg : {
            "2": {
              "histogram": {
                "field": "recency",
                "interval": 86000000,
                "min_doc_count": 1
              },
              "aggs": {
                "3": {
                  "terms": {
                    "field": "newret",
                    "size": 5,
                    "order": {
                      "1": "desc"
                    }
                  },
                  "aggs": {
                    "1": {
                      "cardinality": {
                        "field": "ma_sha1"
                      }
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let getBackLostCustomersItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "9",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "After how many days lost customers come back" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "xAxesValue_01"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "%",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              name : "Distribution",
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "yAxesValue_01",
                "categoryX": "xAxesValue_01"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0
                },
                "strokeOpacity": 0
              },
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:0,
          y:12,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(getBackLostCustomersDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            //let timeToRemove = 30*24*60*60*1000; //30 days in ms
            let total = result["1"].hits.total;
            for (let b in result["1"].aggregations["2"]["buckets"]){
              let recency = Math.round(result["1"].aggregations["2"]["buckets"][b].key / 1000 / 60 / 60 / 24);// - timeToRemove;
              let distribution = result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"].length > 0 ?
                result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0][1].value : 0;
              distribution = distribution / total * 100;
              chartData.push({
                xAxesValue_01 : recency,
                yAxesValue_01 : distribution
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(getBackLostCustomersItem);

    //distribution over weekday & hour, when they come back
    let regainedHourlyPerDayHeatmapDataSource:DataSourceConfigInterfaceInternal = {
      name: "regainedHourlyPerDayHeatmapDataSource",
      type: "elastic",
      assembler : {
        name:"idempotentDataSourceAssembler",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          let data:{
            startHod:number,
            startDow:string,
            count:number
          }[] = [];

          let dowMapping = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
          for (let r in result.aggregations["2"].buckets){
            let sod = result.aggregations["2"].buckets[r].key;
            let hod = (sod / 3600);

            for (let b in result.aggregations["2"].buckets[r]["3"].buckets){
              let dow = result.aggregations["2"].buckets[r]["3"].buckets[b].key;
              let count = result.aggregations["2"].buckets[r]["3"].buckets[b].doc_count;
              data.push({
                startHod :hod,
                startDow : dowMapping[dow],
                count:count
              });
            }
          }

          return of(GenericDataResult.ofSuccess(thiz.getName(), data));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return DataSourceAssemblerFactory.idempotentDataSourceAssembler;
        },
        serialize : () => {
          return JSON.stringify({
            name : DataSourceAssemblerFactory.idempotentDataSourceAssembler.name
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            strategy : "same-period",
            timestampField:'start'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.NEW,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [],
              "must_not": [
                {"match_phrase": {"recency": {"query": 0}}}]}},
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0,
                "extended_bounds": {
                  "min": 0,
                  "max": 86400
                }
              },
              "aggs": {
                "3": {
                  "histogram": {
                    "field": "startDow",
                    "interval": 1,
                    "min_doc_count": 0,
                    "extended_bounds": {
                      "min": 1,
                      "max": 7
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let regainedHourlyPerDayHeatmapItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "10"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Heatmap Access Of Regained Customers" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText : "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        createChart : (chart)=> {
          chart.maskBullets = false;

          var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
          var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

          xAxis.dataFields.category = "startHod";
          yAxis.dataFields.category = "startDow";

          xAxis.renderer.grid.template.disabled = true;
          xAxis.renderer.minGridDistance = 40;

          yAxis.renderer.grid.template.disabled = true;
          yAxis.renderer.inversed = true;
          yAxis.renderer.minGridDistance = 30;

          var series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "startHod";
          series.dataFields.categoryY = "startDow";
          series.dataFields.value = "count";
          series.sequencedInterpolation = true;
          series.defaultState.transitionDuration = 3000;

          var bgColor = new am4core.InterfaceColorSet().getFor("background");

          var columnTemplate = series.columns.template;
          columnTemplate.strokeWidth = 1;
          columnTemplate.strokeOpacity = 0.2;
          columnTemplate.stroke = bgColor;
          columnTemplate.tooltipText = "{startHod}, {startDow}: {value.workingValue.formatNumber('#.')}";
          columnTemplate.width = am4core.percent(100);
          columnTemplate.height = am4core.percent(100);

          series.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color(bgColor),
            max: chart.colors.getIndex(0)
          });

// heat legend
          var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
          heatLegend.width = am4core.percent(100);
          heatLegend.series = series;
          heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
          heatLegend.valueAxis.renderer.minGridDistance = 30;

// heat legend behavior
          series.columns.template.events.on("over", (event) => {
            handleHover(event.target);
          })

          series.columns.template.events.on("hit", (event) => {
            handleHover(event.target);
          })

          function handleHover(column) {
            if (!isNaN(column.dataItem.value)) {
              heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
              heatLegend.valueAxis.hideTooltip();
            }
          }

          series.columns.template.events.on("out", (event) => {
            heatLegend.valueAxis.hideTooltip();
          });

          return chart;

        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:12,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(regainedHourlyPerDayHeatmapDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(regainedHourlyPerDayHeatmapItem);



    let dashboardCfg = DashboardConfig.of(
      {
        id: "StoreReturnRate1234",
        name: "Store Return Rate",
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        }
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);
    this.dashboard.setFilter(this.filter.filterValues);
    this.filterChangedSubscription = this.filter.filterChangedObservable()
      .subscribe((newFilterValue) => {
        this.dashboard.reloadData();
        this.showDashboard = true;
      });
  }


}


