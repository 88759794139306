import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {GridsterModule} from "angular-gridster2";
import {
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatOptionModule,
  MatListModule
} from '@angular/material';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { AgmCoreModule } from '@agm/core';
import {
		FuseSharedModule,
		FuseSidebarModule, 
		FuseWidgetModule,
		AuthGuardService,
		IottacleNavigationService
	   } from "core-fe-angular";
import {FilterModule} from "../../filter/filter.module";
import {AngularDashboardModule} from "dashboard-fe-angular";
import {PropertyOverviewComponent} from './overview/property-overview.component';
import {StoreDetailsComponent} from "../store/detail/store-details.component";
import {PropertyDetailsComponent} from './detail/property-details.component';
import {ClimateChangeDialog} from "./detail/climate-change-dialog";


const routes = [
  {
    path     : 'property',
    canActivate: [AuthGuardService],
    children : [
      {
        path     : 'overview',
        component: PropertyOverviewComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'detail',
        component: PropertyDetailsComponent,
        canActivate: [AuthGuardService]
      },
    ]
  }
];

@NgModule({
  providers : [
  ],
  imports     : [
    CommonModule,
    FuseSharedModule,
    FuseSidebarModule,
    RouterModule.forChild(routes),
    FilterModule,
    GridsterModule,
    AngularDashboardModule,

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    FuseWidgetModule,
    NgxChartsModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBvZWorK4nmcjUJ1lP6HooJyGsCjYYyqn0'
    })
  ],
  declarations: [
    PropertyOverviewComponent,
    PropertyDetailsComponent,
    ClimateChangeDialog
  ]
})
export class PropertyModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService
  ){

    //instruct navigation
    this._fuseNavigationService.addNavigationItem({
      'id'       : 'property',
      'title'    : 'Property',
      'translate': 'nav.property.group-name',
      'type'     : 'group',
      'children' : [
        {
          'id'       : 'property.overview',
          'title'    : 'Overview',
          'translate': 'nav.property.overview',
          'type'     : 'item',
          'icon'     : 'timeline',
          'url'  : '/property/overview'
        },
        {
          'id'       : 'property.detail',
          'title'    : 'Property Details',
          'translate': 'nav.property.detailGroup',
          'type'     : 'collapsable',
          'icon'     : 'location_on',
          'children' : [
            {
              'id'       : 'property.detail.detail',
              'title'    : 'Detail',
              'translate': 'nav.property.detail.detail',
              'type'     : 'item',
              'icon'     : 'location_on',
              'url'  : '/property/detail'
            }
            ]
        }
      ]
    }, "end");
  }
}
