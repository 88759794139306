import { forEach } from '@angular/router/src/utils/collection';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Observable, of, Subscription, throwError} from "rxjs";


import {
    Dashboard,
    DashboardConfig,
    DashboardItem,
    DashboardItemAssemblerFactory,
    DashboardItemConfig,
    DashboardItemViewGeneric,
    DataResultInterface,
    DataSource,
    DataSourceAssemblerFactory,
    DataSourceAssemblerInterface,
    DataSourceConfig,
    DataSourceConfigInterface,
    DataSourceInterface,
    FilterBuilderFactory,
    GenericDataResult,
    ObservedLevel
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {
    AdministrativeEntityLocation, Coordinate,
    Filter,
    FilterInterface,
    FilterStrategies,
    FilterStrategiesHelper,
    FilterVisitJunkData,
    FilterVisitNewret, FilterVisitRand,
    FilterVisitStrategy, FilterVisitTimeFilterStrategies,
    MyAdministrativeEntity
} from "iottacle-ts-models";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import deepmerge_ from 'deepmerge';
import moment_ from 'moment';
import lodash_ from "lodash";
import {
    am4themes_next14Theme,
    DashboardItemFactoryChart
} from "../next-one-sky-insights-00/utils";
import {
    EventDataSource
} from "../next-one-detail/event-data-source";
import {
    DashboardItemCatalog,
    DashboardItemFactory,
    DataSourceAssemblerChain,
    ElasticToIottacleDataSourceAssembler,
    InternalVisitsCountPerDayDataSourceForDailyCount,
    IottacleMultipleElasticGenericRepo,
    IottacleRepoDataPartition,
    MultipleFilterBuilderGenericElastic,
    Utils
} from "dashboard-items-catalog";
import {map} from "rxjs/operators";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
import {
  AuthService,
  DataSourceAssemblerCatalog, FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {DashboardConsolePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";

const deepmerge: any = deepmerge_;
const moment = moment_;
const _ = lodash_;

@Component({
    selector: 'next-one-areas',
    templateUrl: './next-one-areas.component.html',
    styleUrls: ['./next-one-areas.component.scss']
})
export class NextOneAreasComponent implements OnInit, OnDestroy {

  currentMap = 0
  showFloors = false;
    static mainAssembler = {
        name : "myAssDs",
        assemble : (result: {[dataSourceId: string]: any}, thiz: DashboardItem, secondVersion?: boolean): Observable<DataResultInterface<any>> => {
            const serieName: any = Object.keys(result.count.series)[0];
            const partitionName = Object.keys(result.count.series[serieName].partitions)[0];
            const metricKey = result.count.series[serieName].partitions[partitionName].metricKey;
            const countSeries = result.count.series[serieName].partitions[partitionName].data;
            const countSum: any = Object.values(countSeries).reduce((acc: any, el: any) => {
                acc = acc + el[metricKey];
                return acc;
            }, 0);
            const days = Object.keys(countSeries).length;

            let trafficSum = 0;
            const adminEntityId = secondVersion ? `${thiz.getLastFilterUsed().administrativeEntityFilter.selected.administrativeEntityDetails.id}_2` : thiz.getLastFilterUsed().administrativeEntityFilter.selected.administrativeEntityDetails.id; // 664;
            const geopoints = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].geopoints);
            const segments = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].segments);
            const graph = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].graph);
            const stores = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].stores);
            const maxTraffic = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].maxTraffic);
            const redThreshold = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].redThreshold);
            const yellowThreshold = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].yellowThreshold);
            const mapImage = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].floorMapImage);
            const center = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].center);
            const dDiff = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].dDiff);
            const heading = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].heading);
            const kDiff = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].kDiff);
            const rot = _.cloneDeep(thiz.getLastContextUsed().tempAdminEntityData[adminEntityId].rot);

            // fixing, filtering & reconstructing paths
            let reduced = result.path.hits.hits
                .map(el => {
                    let prevNode0 = Number(el._source.label[0]);
                    const newPath = [];
                    newPath.push(prevNode0);
                    for (let i = 1; i < el._source.label.length; i++) {
                        const nextNode0 = Number(el._source.label[i]);
                        if (graph[prevNode0] && graph[nextNode0]) {
                            const key = prevNode0 + "_" + nextNode0;
                            if (!segments[key]) {
                                const shortPath = NextOneAreasComponent.findShortestPath(graph, prevNode0, nextNode0);
                                if (shortPath && (shortPath.distance !== "Infinity") && (shortPath.path && shortPath.path.length >= 2) ) {
                                    for (let y = 1; y < shortPath.path.length; y++) {
                                        const step = shortPath.path[y];
                                        newPath.push(Number(step));
                                    }
                                }
                            } else {
                                newPath.push(nextNode0);
                            }
                        }
                        prevNode0 = nextNode0;
                    }

                    // check start/end node


                    if (newPath[0] !== newPath[newPath.length - 1] && (geopoints[newPath[0]] && geopoints[newPath[newPath.length - 1]] && (!geopoints[newPath[0]].isStartEndNode || !geopoints[newPath[newPath.length - 1]].isStartEndNode))) {
                        if (geopoints[newPath[0]].isStartEndNode) {
                            // first node is start
                            if (!geopoints[newPath[newPath.length - 1]].isStartEndNode) {
                                const shortPath1 = NextOneAreasComponent.findShortestPath(graph, newPath[newPath.length - 1], newPath[0]);
                                if (shortPath1.distance > 1) {
                                    for (let i = 1; i < shortPath1.path.length; i++) {
                                        newPath.push(Number(shortPath1.path[i]));
                                    }
                                } else {
                                    newPath.push(newPath[0]);
                                }
                            }
                        } else {
                            if (!geopoints[newPath[newPath.length - 1]].isStartEndNode) {
                                // first node is not start/end AND
                                // last node is not start/end
                                // rebuild start & end node, find closest startEnd node from
                                // newPath[0] and newPath[newPath.length - 1] and electt it as start/end node
                                let startEndNode;
                                let minDist = Number.MAX_VALUE;
                                for (const n of Object.values(geopoints).filter((el1: any) => el1.isStartEndNode)) {
                                    const shortPath = NextOneAreasComponent.findShortestPath(graph, newPath[0], (n as any).locationId);
                                    if (shortPath && shortPath.distance !== "Infinity" && shortPath.distance < minDist) {
                                        minDist = shortPath ? shortPath.distance : undefined;
                                        startEndNode = (n as any).locationId;
                                    }
                                }

                                if (minDist > 1) {
                                    const shortPath = NextOneAreasComponent.findShortestPath(graph, startEndNode, newPath[0]);
                                    for (let i = shortPath.path.length - 2; i >= 0; i--) {
                                        newPath.unshift(Number(shortPath.path[i]));
                                    }
                                } else {
                                    newPath.unshift(startEndNode);
                                }
                                const shortPath1 = NextOneAreasComponent.findShortestPath(graph, newPath[newPath.length - 1], startEndNode);
                                if (shortPath1.distance > 1) {
                                    for (let i = 1; i < shortPath1.path.length; i++) {
                                        newPath.push(Number(shortPath1.path[i]));
                                    }
                                } else {
                                    newPath.push(startEndNode);
                                }

                            } else {
                                // ONLY first node is not start/end
                                const shortPath = NextOneAreasComponent.findShortestPath(graph, newPath[newPath.length - 1], newPath[0]);
                                if (shortPath.distance > 1) {
                                    for (let i = shortPath.path.length - 2; i >= 0; i--) {
                                        newPath.unshift(Number(shortPath.path[i]));
                                    }
                                } else {
                                    newPath.unshift(newPath[newPath.length - 1]);
                                }
                            }
                        }
                    }

                    el.newPath = newPath;
                    el.segments = [];
                    el.newPathDetails = [geopoints[newPath[0]]];
                    let prevNode = newPath[0];
                    for (let e = 1; e < newPath.length; e++) {
                        const nextNode = newPath[e];
                        el.newPathDetails.push(geopoints[nextNode]);
                        el.segments.push(prevNode + "_" + nextNode);
                        prevNode = nextNode;
                    }
                    return el;
                })
                .map(el => {
                    // calc total traffic and segment traffic
                    for (const segment of el.segments){
                        if(segments[segment]) {
                            const segmentDetail = segments[segment];
                            const segmentCount = el._source && el._source.histograms[segment] ? (el._source.histograms[segment].lte + el._source.histograms[segment].gt) : 0;
                            segmentDetail.trafficCount = segmentDetail.trafficCount + segmentCount || 1;
                            trafficSum = trafficSum + segmentCount;
                        }
                    }
                    return el;
                })
                .filter(el => (geopoints[el.newPath[0]] && geopoints[el.newPath[0]].isStartEndNode) ||
                    (el.newPath.find(l => geopoints[l] && geopoints[l].isMiddleNode))
                );

            // normalize segment count
            const uiSegments = [];
            for (const segmentDetail of Object.values(segments) as any){
                // daily average traffic
                segmentDetail.trafficCount = ((countSum / trafficSum) * segmentDetail.trafficCount) / days;
                // if (segmentDetail.trafficCount > maxTraffic){
                //     maxTraffic = segmentDetail.trafficCount;
                //     console.log("MAX TRAFFIC: " + maxTraffic);
                // }
                const redThresholdValue = maxTraffic * redThreshold;
                const yellowThresholdValue = maxTraffic * yellowThreshold;
                segmentDetail.strokeColor = (segmentDetail.trafficCount > redThresholdValue) ? "#b5c8ab" : (segmentDetail.trafficCount > yellowThresholdValue) ? "#81a972" : "#377d22";
                segmentDetail.strokeOpacity =
                    (segmentDetail.trafficCount > redThresholdValue) ? segmentDetail.trafficCount / maxTraffic :
                        (segmentDetail.trafficCount > yellowThresholdValue) ? segmentDetail.trafficCount / redThresholdValue :
                            segmentDetail.trafficCount / yellowThresholdValue;
                segmentDetail.strokeOpacity = segmentDetail.strokeOpacity > 1 ? 1 : segmentDetail.strokeOpacity < 0.3 ? 0.3 : segmentDetail.strokeOpacity;
                const det = {
                    count: segmentDetail.trafficCount,
                    strokeColor: segmentDetail.strokeColor,
                    strokeOpacity: segmentDetail.strokeOpacity,
                    strokeWeight: 5,
                    name: segmentDetail.name,
                    coordinates: segmentDetail.path,
                    from: segmentDetail.from,
                    to: segmentDetail.to
                };
                uiSegments.push(det);
                // console.log(det);
            }

            reduced = reduced.map(el => {
                    // calc brand exposure
                    for (const segment of el.segments){
                        if(segments[segment]) {
                            const segmentDetail = segments[segment];
                            const trafficCount = segmentDetail.trafficCount;
                            for (const storeOrig of segmentDetail.stores){
                                const store = stores[storeOrig.id];
                                store.trafficCount = store.trafficCount + trafficCount || 1;
                                store.exposure = store.trafficCount * store.size;
                            }
                        }
                    }
                    return el;
                })
                .flatMap(el => {
                    // duplcate path in case it has a middleNode (needed just for UI purposes)
                    const toRet = [];
                    el.newPathStr = el.newPath.join("_");

                    let found = geopoints[el.newPath[0]];
                    if (found && found.isStartEndNode) {
                        // el.isStartEndNode = true;
                        el.locationId = el.newPath[0];
                        el.node = found;
                        toRet.push(el);
                    }
                    found = el.newPath.find(l => geopoints[l] && !geopoints[l].isStartEndNode);
                    if (found && !geopoints[found].isStartEndNode) {
                        const el1 = _.cloneDeep(el);
                        el1.locationId = geopoints[found].locationId;
                        el1.node = geopoints[found];
                        toRet.push(el1);
                    }
                    return toRet;
                })
                .filter((el: any) => {
                    // remove non-requested location from filter
                    const locationIds = (thiz.getDataSourceProxies().path.getDataSource()as any).computedLocationIds;
                    return !!locationIds.find(e => e === el.locationId);
                })
                .reduce((acc: any, el) => {
                    let found;
                    if (!acc.nodes[el.locationId]) {
                        acc.nodes[el.locationId] = [];
                    } else {
                        found = acc.nodes[el.locationId].find(el0 => el0.newPathStr === el.newPathStr);
                    }
                    if (found) {
                        // merging elements
                        found._source.count = found._source.count + el._source.count;
                        for (const h of Object.keys(el._source.histograms)) {
                            const bucket = el._source.histograms[h];
                            if (found._source.histograms[h]) {
                                found._source.histograms[h].lte = found._source.histograms[h].lte + el._source.histograms[h].lte;
                                found._source.histograms[h].gt = found._source.histograms[h].gt + el._source.histograms[h].gt;
                                found._source.histograms[h].count++;
                            } else {
                                found._source.histograms[h] = bucket;
                                found._source.histograms[h].count = 1;
                            }
                        }
                    } else {
                        acc.nodes[el.locationId].push(el);
                    }
                    return acc;
                }, {
                    nodes: {}
                } as {
                    nodes: {
                        [nodeLocationId: string]: []
                    }
                });

            for (const i of Object.keys(reduced.nodes)) {
                reduced.nodes[i] = reduced.nodes[i].filter(el => el.newPath.length > 3).sort((a, b) => b._source.count - a._source.count).slice(0, 3);
            }
            reduced.stores = Object.values(Object.values(stores).reduce((acc, el: any) => {
                if (!acc[el.category]){
                    acc[el.category] = {
                        name: el.category,
                        children: []
                    };
                }
                acc[el.category].children.push({
                    name: el.name,
                    value: el.exposure
                });
                return acc;
            }, {})) ;
            reduced.segments = segments;
            reduced.mapItems = {
                srcImage: mapImage,
                rot: rot,
                dDiff: dDiff,
                kDiff: kDiff,
                paths: uiSegments,
                markers: Object.values(geopoints),
                heading: heading,
                center: center,
                id: thiz.getLastFilterUsed().administrativeEntityFilter.selected.administrativeEntityDetails.id
            };
            result.path = reduced;
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), result.path));
        },
        serialize : () => {
            return JSON.stringify({
                name : "myAssDs"
            });
        },
        rehydrate : (serializedAssembler: string) => {
            return DashboardItemAssemblerFactory.getAssembler("idempotentDashboardItemAssembler");
        }
    };

    filter: Filter;
    dashboard: Dashboard;
    showDashboard = false;

    filterChangedSubscription: Subscription;

    constructor(
        private fuseConfig: FuseConfigService,
        private authService: AuthService,
        private decimalPipe: DecimalPipe,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        private http: HttpClient,
        protected injector: Injector
    ) {
        this.fuseConfig.config = {
          layout: {
            navbar   : {
              hidden: true
            },
            toolbar  : {
              hidden: true
            },
            footer   : {
              hidden: true
            },
            sidepanel: {
              hidden: true
            }
          }
        };
        // qrnerTranslationService.setTranslation(english, italian);
        this.translationLoader.loadTranslations(english, italian);
        this.filter = new Filter(authService.getUnderlyingLoginModel(), {}, this.translateService);
    }

    static findShortestPath(graph, startNode, endNode): {
        distance: any,
        path: any[]
    }
    {
        // establish object for recording distances from the start node
        let distances = {};
        distances[endNode] = "Infinity";
        distances = Object.assign(distances, graph[startNode]);

        // track paths
        const parents = {endNode: null};
        // tslint:disable-next-line:forin
        for (const child in graph[startNode]) {
            parents[child] = startNode;
        }

        // track nodes that have already been visited
        const visited = [];

        // find the nearest node
        let node = this.shortestDistanceNode(distances, visited);

        // for that node
        while (node) {
            // find its distance from the start node & its child nodes
            const distance = distances[node];
            const children = graph[node];
            // for each of those child nodes
            for (const child in children) {
                // make sure each child node is not the start node
                if (String(child) === String(startNode)) {
                    // console.log("don't return to the start node! 🙅");
                    continue;
                } else {
                    // console.log("startNode: " + startNode);
                    // console.log("distance from node " + parents[node] + " to node " + node + ")");
                    // console.log("previous distance: " + distances[node]);
                    // save the distance from the start node to the child node
                    const newdistance = distance + children[child];
                    // console.log("new distance: " + newdistance);
                    // if there's no recorded distance from the start node to the child node in the distances object
                    // or if the recorded distance is shorter than the previously stored distance from the start node to the child node
                    // save the distance to the object
                    // record the path
                    if (!distances[child] || distances[child] > newdistance) {
                        distances[child] = newdistance;
                        parents[child] = node;
                        // console.log("distance + parents updated");
                    } else {
                        // console.log("not updating, because a shorter path already exists!");
                    }
                }
            }
            // move the node to the visited set
            visited.push(node);
            // move to the nearest neighbor node
            node = this.shortestDistanceNode(distances, visited);
        }

        // using the stored paths from start node to end node
        // record the shortest path
        const shortestPath = [endNode];
        let parent = parents[endNode];
        while (parent) {
            shortestPath.push(parent);
            parent = parents[parent];
        }
        shortestPath.reverse();

        // return the shortest path from start node to end node & its distance
        const results = {
            distance: distances[endNode],
            path: shortestPath,
        };

        return results;
    }

    static shortestDistanceNode(distances, visited): any {
        let shortest = null;

        // tslint:disable-next-line:forin
        for (const node in distances) {
            const currentIsShortest =
                shortest === null || distances[node] < distances[shortest];
            if (currentIsShortest && !visited.includes(node)) {
                shortest = node;
            }
        }
        return shortest;
    }

    changeMap(value:any) : void {
        this.currentMap = value.value
        this.loadData()
    }


    ngOnDestroy(): void {
        this.filterChangedSubscription.unsubscribe();
        this.dashboard.destroy();
    }

    ngOnInit(): void {
      this.loadData()
    }

    createMapDashboardItem(config: any, translateService: any, secondVersion?: boolean): DashboardItem {
      console.log(config);

        const id = config.id || Utils.generateGUID();
        const readOnly = config.readOnly !== undefined ? config.readOnly : true;
        const viewConfig = DashboardItemViewGeneric.empty();

        const finalConfig = {itemType: "map-with-polygons"};
        _.defaultsDeep(finalConfig, config, {
            id: id,
            observedLevel: ObservedLevel.ITEM_VIEW,
            name: translateService.instant('widgets.map.' + id + '.name'),
            showHeader: false,
            readOnly: readOnly,
            showName: false,
            autoUpdateMs: 0,
            subtitle: translateService.instant('widgets.metricDelta.' + id + '.subtitle'),
            infoButtonText: translateService.instant('widgets.map.' + id + '.infoButtonText'),
            itemType: "map-with-polygons",
            style: {
                background: "white",
                'border-color': "#D0D6D9",
                'border-radius': "2px",
                headerFxLayoutAlign: "start center"
            },
            viewConfig: viewConfig,
            specificItemConfig: {
                height: '400px',
                heatmapRadius: 40,
                gMapOptions: {
                    mapTypeId: 'satellite',
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false
                },
                gridsterConfig: {
                    x: 13,
                    y: 2,
                    cols: 23,
                    rows: 12,
                    dragEnabled: !readOnly,
                    resizeEnabled: !readOnly
                }
            },
            dataSources: {
                "count": DataSource.of(DataSourceConfig.of(new InternalVisitsCountPerDayDataSourceForDailyCount(this.http))),
                "path": DataSource.of(DataSourceConfig.of(new InStorePathDataSource(this.http, undefined)))
            },
            assembler: {
                name : "myAssDs",
                assemble : (result: {[dataSourceId: string]: any}, thiz: DashboardItem): Observable<DataResultInterface<any>> => {
                    return NextOneAreasComponent.mainAssembler.assemble(result, thiz, secondVersion)
                        .pipe(
                            map(res => {
                              if(res.result.mapItems.id === 766) this.showFloors = true
                              return GenericDataResult.ofSuccess(res.itemId, res.getResult().mapItems)})
                        );
                },
                serialize : () => {
                    return JSON.stringify({
                        name : "myAssDs"
                    });
                },
                rehydrate : (serializedAssembler: string) => {
                    return DashboardItemAssemblerFactory.getAssembler("idempotentDashboardItemAssembler");
                }
            }
        });

        const item: DashboardItem = DashboardItem.of(DashboardItemConfig.of(finalConfig));
        return item;
    }

    createTopPathDashboardItem(config: any, translateService: any): DashboardItem {
        const id = Utils.generateGUID();
        const readOnly = config.readOnly;
        const finalConfig = {};

        _.defaultsDeep(finalConfig, config, {
            id: id,
            observedLevel: ObservedLevel.ITEM_VIEW,
            name: translateService.instant('widgets.storeAreasTopPath.' + id + '.name'),
            showHeader: false,
            readOnly: true,
            showName: false,
            autoUpdateMs: 0,
            subtitle: translateService.instant('widgets.storeAreasTopPath.' + id + '.subtitle'),
            infoButtonText: translateService.instant('widgets.storeAreasTopPath.' + id + '.infoButtonText'),
            itemType: "store-areas-top-path",
            style: {
                background: "white",
                'border-color': "#D0D6D9",
                'border-radius': "2px",
                headerFxLayoutAlign: "start center"
            },
            viewConfig: DashboardItemViewGeneric.empty(),
            specificItemConfig: {
                height: '400px',
                heatmapRadius: 40,
                gMapOptions: {
                    mapTypeId: 'satellite',
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false
                },
                gridsterConfig: {
                    x: 13,
                    y: 2,
                    cols: 23,
                    rows: 12,
                    dragEnabled: !readOnly,
                    resizeEnabled: !readOnly
                }
            },
            dataSources: {
                "count": DataSource.of(DataSourceConfig.of(new InternalVisitsCountPerDayDataSourceForDailyCount(this.http))),
                "path": DataSource.of(DataSourceConfig.of(new InStorePathDataSource(this.http, (el: any) => el.isStartEndNode || el.isMiddleNode)))
            },
            assembler: NextOneAreasComponent.mainAssembler
        });

        const item: DashboardItem = DashboardItem.of(DashboardItemConfig.of(finalConfig));
        return item;
    }

    createCountPasserByPerLocation(config: any, translateService: any): DashboardItem {
        const item = DashboardItemFactory.createXYChartItem(
            _.defaultsDeep(config, {
                substituteDataSources: true,
                dataSources: {
                    "traffic": DataSource.of(DataSourceConfig.of(new InStoreTrafficDataSource(this.http)))
                },
                assembler: DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler"),
                viewConfig: DashboardItemFactoryChart.createXYChartItem({
                    legend: {
                        showLegendIntoAccordion: false,
                    },
                    periodicVerticalLine: {
                        period: "auto"
                    },
                    export: {
                        excel: true,
                        csv: true,
                        image: true
                    }
                })
            }),
            this.translateService);
        return item;
    }

    createBrandTreemap(config: any, translateService: any): DashboardItem {
        const item = DashboardItemFactory.createXYChartItem(
            _.defaultsDeep(config, {
                substituteDataSources: true,
                dataSources: {
                    "count": DataSource.of(DataSourceConfig.of(new InternalVisitsCountPerDayDataSourceForDailyCount(this.http))),
                    "path": DataSource.of(DataSourceConfig.of(new InStorePathDataSource(this.http, undefined)))
                },
                assembler: NextOneAreasComponent.mainAssembler,
                viewConfig: DashboardItemFactoryChart.createXYChartItem({
                    createNewChart: (id: any, data: DataResultInterface<any>, dashboardItem: DashboardItem) => {
                        const chart1: any = am4core.create(id, am4charts["TreeMap"]);
                        return chart1;
                    },
                    dataChangedHook: (chart1: any, id: any, data: DataResultInterface<any>, dashboardItem: DashboardItem): any => {
                        am4core.useTheme(am4themes_animated);
                        am4core.useTheme(am4themes_next14Theme);
                        const chart: any = am4core.create(id, am4charts["TreeMap"]);
                        chart.language.locale = am4lang_it_IT;
                        chart.colors.step = 2;

                        // define data fields
                        chart.dataFields.value = "value";
                        chart.dataFields.name = "name";
                        chart.dataFields.children = "children";

                        chart.zoomable = false;
                        const bgColor = new am4core.InterfaceColorSet().getFor("background");

                        // level 0 series template
                        const level0SeriesTemplate = chart.seriesTemplates.create("0");
                        const level0ColumnTemplate = level0SeriesTemplate.columns.template;

                        level0ColumnTemplate.column.cornerRadius(5, 5, 5, 5);
                        level0ColumnTemplate.fillOpacity = 0;
                        level0ColumnTemplate.strokeWidth = 2;
                        level0ColumnTemplate.strokeOpacity = 0;



                        // level 1 series template
                        const level1SeriesTemplate = chart.seriesTemplates.create("1");
                        const level1ColumnTemplate = level1SeriesTemplate.columns.template;

                        level1SeriesTemplate.tooltip.animationDuration = 0;
                        level1SeriesTemplate.strokeOpacity = 1;

                        level1ColumnTemplate.column.cornerRadius(5, 5, 5, 5);
                        level1ColumnTemplate.fillOpacity = 1;
                        level1ColumnTemplate.strokeWidth = 2;
                        // level1ColumnTemplate.stroke = bgColor;

                        const bullet1 = level1SeriesTemplate.bullets.push(new am4charts.LabelBullet());
                        bullet1.locationY = 0.5;
                        bullet1.locationX = 0.5;
                        // bullet1.label.text = "{name}";
                        // bullet1.label.fill = am4core.color("#ffffff");
                        level1ColumnTemplate.tooltipText = "{parentName} {name}"

                        chart.maxLevels = 2;

                            // add logo
                        var image = level1SeriesTemplate.columns.template.createChild(
                        am4core.Image
                        );
                        // image.opacity = 0.15;
                        image.align = "center";
                        image.valign = "middle";
                        image.width = am4core.percent(80);
                        image.height = am4core.percent(80);

                        // add adapter for href to load correct image
                        image.adapter.add("href", (href, target) => {
                        var dataItem = target.parent.dataItem;
                        if (dataItem) {
                            return (
                            "https://iottacle-main-web-storage.s3.eu-west-1.amazonaws.com/logo/logo_" +
                            dataItem.treeMapDataItem.name.replace(/\s/g, "").toLowerCase() +
                            ".png"
                            );
                        }
                        });

                        data.result.stores.forEach((s) => {
                            s.children = s.children.filter((c) => c.value)
                        })
                        chart.data = data.result.stores
                        chart.validateData();
                        return chart;
                    },
                    legend: {
                        showLegendIntoAccordion: false,
                    },
                    periodicVerticalLine: {
                        period: "auto"
                    },
                    export: {
                        excel: true,
                        csv: true,
                        image: true
                    }
                })
            }),
            this.translateService);
        return item;
    }

    loadData(): void {
      const dashboardItems: DashboardItem[] = [];
      const user = this.authService.getCurrentLoggedUser();

      DataSourceAssemblerCatalog.init();
      FilterBuilderCatalog.init();
      const readOnly = true;

      const storeMapItemConfig = {
          id: "97bb90c9-f62b-4b56-8579-22651cc0d3ef",
          readOnly: readOnly,
          specificItemConfig: {
              height: '650px',
              fitBounds: {
                  markers: true
              },
              gMapOptions: {
                  mapTypeId: 'roadmap',
                  draggable: true,
                  zoomControl: true,
                  scrollwheel: true,
                  disableDoubleClickZoom: true
              },
              gridsterConfig: {x: 5, y: 0, cols: 26, rows: 15},
              callbacks: {
                  onClick: (elementType: string, elementValue: any, item: any): void => {
                      // traffic
                      let ds = item.getDataSourceProxies().path.getDataSource();
                      let f = _.cloneDeep(item.getLastFilterUsed());
                      if (elementType === "marker") {
                          ds.locationIds = [elementValue.locationId];
                      }else{
                          delete ds.locationIds;
                      }
                      ds.reloadData(f, item.getLastContextUsed());

                      // top path
                      ds = topPathItem.getDataSourceProxies().path.getDataSource();
                      f = _.cloneDeep(item.getLastFilterUsed());
                      if (elementType === "marker") {
                          ds.locationIds = [elementValue.locationId];
                      }else{
                          delete ds.locationIds;
                      }
                      ds.reloadData(f, item.getLastContextUsed());

                      // count bar chart
                      ds = countPasserByPerLocation.getDataSourceProxies().traffic.getDataSource();
                      f = _.cloneDeep(item.getLastFilterUsed());
                      if (elementType === "path") {
                          ds.locationIdFrom = elementValue.from;
                          ds.locationIdTo = elementValue.to;
                      }else{
                          delete ds.locationIdFrom;
                          delete ds.locationIdTo;
                      }
                      ds.reloadData(f, item.getLastContextUsed());
                  }
              }
          }
      };
      const storeMapItemConfig2 = {
          id: "97bb90c9-f62b-4b56-8579-22651cc0d3ef",
          readOnly: readOnly,
          specificItemConfig: {
              height: '650px',
              fitBounds: {
                  markers: true
              },
              gMapOptions: {
                  mapTypeId: 'roadmap',
                  draggable: true,
                  zoomControl: true,
                  scrollwheel: true,
                  disableDoubleClickZoom: true
              },
              gridsterConfig: {x: 5, y: 0, cols: 26, rows: 15},
              callbacks: {
                  onClick: (elementType: string, elementValue: any, item: any): void => {
                      // traffic
                      let ds = item.getDataSourceProxies().path.getDataSource();
                      let f = _.cloneDeep(item.getLastFilterUsed());
                      if (elementType === "marker") {
                          ds.locationIds = [elementValue.locationId];
                      }else{
                          delete ds.locationIds;
                      }
                      ds.reloadData(f, item.getLastContextUsed());

                      // top path
                      ds = topPathItem.getDataSourceProxies().path.getDataSource();
                      f = _.cloneDeep(item.getLastFilterUsed());
                      if (elementType === "marker") {
                          ds.locationIds = [elementValue.locationId];
                      }else{
                          delete ds.locationIds;
                      }
                      ds.reloadData(f, item.getLastContextUsed());

                      // count bar chart
                      ds = countPasserByPerLocation.getDataSourceProxies().traffic.getDataSource();
                      f = _.cloneDeep(item.getLastFilterUsed());
                      if (elementType === "path") {
                          ds.locationIdFrom = elementValue.from;
                          ds.locationIdTo = elementValue.to;
                      }else{
                          delete ds.locationIdFrom;
                          delete ds.locationIdTo;
                      }
                      ds.reloadData(f, item.getLastContextUsed());
                  }
              }
          }
      };
      const storeMapItem = this.createMapDashboardItem(storeMapItemConfig, this.translateService);
      const storeMapItem2 = this.createMapDashboardItem(storeMapItemConfig2, this.translateService, true);
      if(this.currentMap === 0) {
        dashboardItems.push(storeMapItem);
      } else{
        dashboardItems.push(storeMapItem2);
      }

      const topPathItemConfig = {
          id: "00c02957-e142-44ff-be23-8c1d468f60ec",
          specificItemConfig: {
              gridsterConfig: {x: 16, y: 0, cols: 36, rows: 13},
          }
      };
      const topPathItem = this.createTopPathDashboardItem(topPathItemConfig, this.translateService);
      dashboardItems.push(topPathItem);

      const countPasserByConfig = {
          id: "3e1c6e14-8ea8-45ec-8879-913ce3645677",
          specificItemConfig: {
              gridsterConfig: {x: 30, y: 0, cols: 36, rows: 12}
          }
      };
      const countPasserByPerLocation = this.createCountPasserByPerLocation(countPasserByConfig, this.translateService);
      // dashboardItems.push(countPasserByPerLocation);


      const brandExposureConfig = {
          id: "3c077094-6641-41fe-a039-bb489420f9a9",
          specificItemConfig: {
              gridsterConfig: {x: 53, y: 0, cols: 36, rows: 18}
          }
      };
      const brandExposureItem = this.createBrandTreemap(brandExposureConfig, this.translateService);
      //dashboardItems.push(brandExposureItem);
      // @ts-ignore
      const adminEntities: MyAdministrativeEntity[] = this.authService.getCurrentLoggedUser().getMyOrganizations().flatMap(org => org.getMyAdministrativeEntities());

      const allStores_SvicomBariblu = {
          Adidas: {
              id: "Adidas",
              name: "Adidas",
              category: "Fashion",
              size: 1
          },
          Alcott: {
              id: "Alcott",
              name: "Alcott",
              category: "Fashion",
              size: 2
          },
          AwLab: {
              id: "AwLab",
              name: "AwLab",
              category: "Fashion",
              size: 1
          },
          Azzaro: {
              id: "Azzaro",
              name: "Azzaro",
              category: "Fashion",
              size: 4
          },
          BlackSheep: {
              id: "BlackSheep",
              name: "Black Sheep",
              category: "Fashion",
              size: 1
          },
          CottonAndSilk: {
              id: "CottonAndSilk",
              name: "Cotton&Silk",
              category: "Fashion",
              size: 2
          },
          GiorgiaAndJohns: {
              id: "GiorgiaAndJohns",
              name: "Giorgia & Johns",
              category: "Fashion",
              size: 2.5
          },
          HM: {
              id: "HM",
              name: "H&M",
              category: "Fasion",
              size: 4
          },
          Indi: {
              id: "Indi",
              name: "Indi",
              category: "Fashion",
              size: 3
          },
          Live978: {
              id: "Live978",
              name: "Live978",
              category: "Fashion",
              size: 1
          },

          NewYorker: {
              id: "NewYorker",
              name: "New Yorker",
              category: "Fashion",
              size: 2
          },
          RevolutionKids: {
              id: "RevolutionKids",
              name: "Revolution Kids",
              category: "Fashion",
              size: 6
          },
          SensoUnico: {
              id: "SensoUnico",
              name: "Senso Unico",
              category: "Fashion",
              size: 3
          },
          Valerio1966: {
              id: "Valerio1966",
              name: "Valerio 1966",
              category: "Fashion",
              size: 1
          },
          Sorbino: {
              id: "Sorbino",
              name: "Sorbino",
              category: "Fashion",
              size: 3
          },
          Expert: {
              id: "Expert",
              name: "Expert",
              category: "Home Goods",
              size: 8
          },
          Primadonna: {
              id: "Primadonna",
              name: "Primadonna",
              category: "Fashion",
              size: 4
          },
          Douglas: {
              id: "Douglas",
              name: "Douglas",
              category: "Personal Goods",
              size: 1.5
          },
          Equivalenza: {
              id: "Equivalenza",
              name: "Equivalenza",
              category: "Personal Goods",
              size: 2.5
          },
          GrandVision: {
              id: "GrandVision",
              name: "Grand Vision",
              category: "Personal Goods",
              size: 2
          },
          FrancoGioielli: {
              id: "FrancoGioielli",
              name: "Franco Gioielli",
              category: "Personal Goods",
              size: 1.5
          },
          OpticalBariblu: {
              id: "OpticalBariblu",
              name: "Optical Bariblu",
              category: "Personal Goods",
              size: 1
          },
          MariannaBattista: {
              id: "MariannaBattista",
              name: "Marianna Battista",
              category: "Personal Goods",
              size: 1.5
          },

          SarniOro: {
              id: "SarniOro",
              name: "Sarni Oro",
              category: "Personal Goods",
              size: 3
          },
          SangioCaffe: {
              id: "SangioCaffe",
              name: "Sangio Caffè",
              category: "Catering",
              size: 2
          },
          CasolareDiPuglia: {
              id: "CasolareDiPuglia",
              name: "Casolare Di Puglia",
              category: "Catering",
              size: 2
          },
          DaGiovanni: {
              id: "DaGiovanni",
              name: "DaGiovanni",
              category: "Catering",
              size: 2
          },
          McDonalds: {
              id: "McDonalds",
              name: "Mc Donalds",
              category: "Catering",
              size: 3
          },
          Yoyogurt: {
              id: "Yoyogurt",
              name: "Yoyogurt",
              category: "Catering",
              size: 1.5
          },
          SangioBistrot: {
              id: "SangioBistrot",
              name: "Sangio Bistrot",
              category: "Catering",
              size: 1
          },
          MondoCamerette: {
              id: "MondoCamerette",
              name: "Mondo Camerette",
              category: "Home Goods",
              size: 2
          },
          HappyCasaStore: {
              id: "HappyCasaStore",
              name: "Happy Casa Store",
              category: "Home Goods",
              size: 8
          },

          Thun: {
              id: "Thun",
              name: "Thun",
              category: "Home Goods",
              size: 2.5
          },
          Tiger: {
              id: "Tiger",
              name: "Tiger",
              category: "Home Goods",
              size: 4
          },
          YankeeCandle: {
              id: "YankeeCandle",
              name: "Yankee Candle",
              category: "Home Goods",
              size: 3
          },
          //BancomatPP: {
          //    id: "BancomatPP",
          //    name: "Bancomat PP",
          //    category: "Service",
          //    size: 0.2
          //},
          Biosan: {
              id: "Biosan",
              name: "Biosan",
              category: "Service",
              size: 1
          },
          CigaretteExpress: {
              id: "CigaretteExpress",
              name: "CigaretteExpress",
              category: "Service",
              size: 2
          },
          DentalPro: {
              id: "DentalPro",
              name: "Dental Pro",
              category: "Service",
              size: 3
          },

          //Fototessera: {
          //    id: "Fototessera",
          //    name: "Fototessera",
          //    category: "Service",
          //    size: 0.2
          //},
          JeanLouisDavid: {
              id: "JeanLouisDavid",
              name: "Jean Louis David",
              category: "Service",
              size: 1
          },
          //Nursery: {
          //    id: "Nursery",
          //    name: "Nursery",
          //    category: "Service",
          //    size: 0.2
          //},
          SartoriePlanet: {
              id: "SartoriePlanet",
              name: "Sartorie Planet",
              category: "Service",
              size: 1
          },
          //Tabaccheria: {
          //    id: "Tabaccheria",
          //    name: "Tabaccheria",
          //    category: "Service",
          //    size: 0.2
          //},
          GameStop: {
              id: "GameStop",
              name: "GameStop",
              category: "Home Goods",
              size: 2.5
          },
          GiuntiAlPunto: {
              id: "GiuntiAlPunto",
              name: "Giunti Al Punto",
              category: "Home Goods",
              size: 1
          },

          LaCasaDeLasCarcas: {
              id: "LaCasaDeLasCarcas",
              name: "La Casa De Las Carcas",
              category: "Home Goods",
              size: 2
          },
          Carrefour: {
              id: "Carrefour",
              name: "Carrefour",
              category: "Grocery",
              size: 12
          },
          Calzedonia: {
              id: "Calzedonia",
              name: "Calzedonia",
              category: "Fashion",
              size: 3
          },
          Chicco: {
              id: "Chicco",
              name: "Chicco",
              category: "Fashion",
              size: 1
          },
          Cisalfa: {
              id: "Cisalfa",
              name: "Cisalfa",
              category: "Fashion",
              size: 6
          },
          FiorellaRubino: {
              id: "FiorellaRubino",
              name: "Fiorella Rubino",
              category: "Fashion",
              size: 1
          },
          GoldenPoint: {
              id: "GoldenPoint",
              name: "Golden Point",
              category: "Fashion",
              size: 2
          },
          Intimissimi: {
              id: "Intimissimi",
              name: "Intimissimi",
              category: "Fashion",
              size: 2
          },

          LoByLovable: {
              id: "LoByLovable",
              name: "Lo By Lovable",
              category: "Fashion",
              size: 2
          },
          Motivi: {
              id: "Motivi",
              name: "Motivi",
              category: "Fashion",
              size: 2.5
          },
          NunaLie: {
              id: "NunaLie",
              name: "Nuna Lie",
              category: "Fashion",
              size: 3.5
          },
          ObbiettiviModa: {
              id: "ObbiettiviModa",
              name: "Obbiettivi Moda",
              category: "Fashion",
              size: 3
          },
          Oltre: {
              id: "Oltre",
              name: "Oltre",
              category: "Fashion",
              size: 1
          },
          OriginalMarines: {
              id: "OriginalMarines",
              name: "Original Marines",
              category: "Fashion",
              size: 1
          },
          OVS: {
              id: "OVS",
              name: "OVS",
              category: "Fashion",
              size: 8
          },

          Revolution: {
              id: "Revolution",
              name: "Revolution",
              category: "Fashion",
              size: 2
          },
          LowDesign: {
              id: "LowDesign",
              name: "Low Design",
              category: "Fashion",
              size: 1
          },
          PullAndBear: {
              id: "PullAndBear",
              name: "Pull&Bear",
              category: "Fashion",
              size: 2
          },
          Stradivarius: {
              id: "Stradivarius",
              name: "Stradivarius",
              category: "Fashion",
              size: 3.5
          },
          Tezenis: {
              id: "Tezenis",
              name: "Tezenis",
              category: "Fashion",
              size: 1
          },
          Zara: {
              id: "Zara",
              name: "Zara",
              category: "Fashion",
              size: 7
          },
          Eminflex: {
              id: "Eminflex",
              name: "Eminflex",
              category: "Home Goods",
              size: 1
          },
          LaMaisonSofa: {
              id: "LaMaisonSofa",
              name: "LaMaisonSofa",
              category: "Home Goods",
              size: 1
          },

          Pagi: {
              id: "Pagi",
              name: "Pagi",
              category: "Home Goods",
              size: 1
          },

          Satur: {
              id: "Satur",
              name: "Satur",
              category: "Home Goods",
              size: 4
          },

          Wind: {
              id: "Wind",
              name: "Wind",
              category: "Personal Goods",
              size: 3
          },

          Kiko: {
              id: "Kiko",
              name: "Kiko",
              category: "Personal Goods",
              size: 1
          },

          Stroili: {
              id: "Stroili",
              name: "Stroili",
              category: "Personal Goods",
              size: 1
          },

          IdeaBellezza: {
              id: "IdeaBellezza",
              name: "Idea Bellezza",
              category: "Personal Goods",
              size: 3.5
          },

          Bata: {
              id: "Bata",
              name: "Bata",
              category: "Fashion",
              size: 1.5
          },

          Deichmann: {
              id: "Deichmann",
              name: "Deichmann",
              category: "Fashion",
              size: 6
          },

          JDSport: {
              id: "JDSport",
              name: "JD Sport",
              category: "Fashion",
              size: 5
          },

          Tata: {
              id: "Tata",
              name: "Tata",
              category: "Fashion",
              size: 2
          },

          KiaCafe: {
              id: "KiaCafe",
              name: "Kia Cafè",
              category: "Caterign",
              size: 1
          },

          //BancomatPT: {
          //    id: "BancomatPT",
          //    name: "Bancomat PT",
          //    category: "Service",
          //    size: 0.2
          //},

          Findomestic: {
              id: "Findomestic",
              name: "Findomestic",
              category: "Service",
              size: 1
          },

          //Infermeria: {
          //    id: "Infermeria",
          //    name: "Infermeria",
          //    category: "Service",
          //    size: 0.2
          //},

          MusicSchool: {
              id: "MusicSchool",
              name: "MusicSchool",
              category: "Service",
              size: 1
          },

          // Nursery: {
          //     id: "Nursery",
          //     name: "Nursery",
          //     category: "Service",
          //     size: 0.2
          // }
      };

      const allStores_SvicomPavia = {
          OldWildWest: {
              id: "OldWildWest",
              name: "Old Wild West",
              category: "Catering",
              size: 3
          },
          DemaLavanderie: {
              id: "DemaLavanderie",
              name: "Dema Lavanderie",
              category: "Services",
              size: 1
          },
          WinCafeBar: {
              id: "WinCafeBar",
              name: "WinCafè Bar",
              category: "Catering",
              size: 1
          },
          AlicePizza: {
              id: "AlicePizza",
              name: "Alice Pizza",
              category: "Catering",
              size: 3
          },
          ILovePoke: {
              id: "ILovePoke",
              name: "I Love Poke",
              category: "Catering",
              size: 1
          },
          LaPiadineria: {
              id: "LaPiadineria",
              name: "La Piadineria",
              category: "Catering",
              size: 0.5
          },
          YounGo: {
              id: "YounGo",
              name: "YounGo",
              category: "Services",
              size: 0.2
          },
          PolloAndFriends: {
              id: "PolloAndFriends",
              name: "Pollo And Friends",
              category: "Catering",
              size: 2
          },
          Clayton: {
              id: "Clayton",
              name: "Clayton",
              category: "Fashion",
              size: 2
          },
          IlTuoTelefonino: {
              id: "IlTuoTelefonino",
              name: "Il Tuo Telefonino",
              category: "Services",
              size: 1
          },

          Hdental: {
              id: "Hdental",
              name: "Hdental",
              category: "Services",
              size: 2
          },
          Nau: {
              id: "Nau",
              name: "Nau!",
              category: "Personal Goods",
              size: 1
          },
          RealEstate09: {
              id: "RealEstate09",
              name: "Real Estate 09",
              category: "none",
              size: 1
          },
          TopCleanLavanderia: {
              id: "TopCleanLavanderia",
              name: "Top Clean Lavanderia",
              category: "Services",
              size: 1
          },
          LaPlayaSunBeauty: {
              id: "LaPlayaSunBeauty",
              name: "La Playa Sun Beauty",
              category: "Services",
              size: 1
          },
          Bata: {
              id: "Bata",
              name: "Bata",
              category: "Fashion",
              size: 2
          },
          Xo: {
              id: "Xo",
              name: "Xò Officina Tessile",
              category: "Fashion",
              size: 2
          },
          BlueSpirit: {
              id: "BlueSpirit",
              name: "Blue Spirit",
              category: "Personal Goods",
              size: 3
          },
          CasaMia: {
              id: "CasaMia",
              name: "Casa Mia",
              category: "Home Goods",
              size: 1
          },
          Alexim: {
              id: "Alexim",
              name: "Alexim",
              category: "Services",
              size: 1
          },
          Lovable: {
              id: "Lovable",
              name: "Lovable",
              category: "Fashion",
              size: 1
          },
          WindTre: {
              id: "WindTre",
              name: "WindTre",
              category: "Home Goods",
              size: 1
          },
          PhoneMedic: {
              id: "PhoneMedic",
              name: "Phone Medic",
              category: "Services",
              size: 1
          },

          PiuMedicalParafarmacia: {
              id: "PiuMedicalParafarmacia",
              name: "Più Medical Parafarmacia",
              category: "Services",
              size: 3
          },
          Carrefour: {
              id: "Carrefour",
              name: "Carrefour",
              category: "Grocery",
              size: 14
          },
          CasaPesci: {
              id: "CasaPesci",
              name: "Casa Pesci",
              category: "Home Goods",
              size: 3
          },
          Thun: {
              id: "Thun",
              name: "Thun",
              category: "Home Goods",
              size: 1.5
          },
          Kisene: {
              id: "Kisene",
              name: "Kisenè",
              category: "Home Goods",
              size: 2.5
          },
          GameStop: {
              id: "GameStop",
              name: "GameStop",
              category: "Home Goods",
              size: 1
          },
          GoldenPoint: {
              id: "GoldenPoint",
              name: "Golden Point",
              category: "Fashion",
              size: 1
          },
          Yamamay: {
              id: "Yamamay",
              name: "Yamamay",
              category: "Fashion",
              size: 1
          },
          Tim: {
              id: "Tim",
              name: "Tim",
              category: "Home Goods",
              size: 1
          },

          RealEstate29: {
              id: "RealEstate29",
              name: "Real Estate 29",
              category: "none",
              size: 2
          },
          Bancomat: {
              id: "Bancomat",
              name: "Bancomat",
              category: "Services",
              size: 0.1
          },
          BarZagara: {
              id: "BarZagara",
              name: "Bar Zagarà",
              category: "Catering",
              size: 4
          },
          Sorbino: {
              id: "Sorbino",
              name: "Sorbino",
              category: "Fashion",
              size: 5
          },
          BeautyCo: {
              id: "BeautyCo",
              name: "Beauty & Co",
              category: "Personal Goods",
              size: 2
          },
          MondoGelato: {
              id: "MondoGelato",
              name: "MondoGelato",
              category: "Catering",
              size: 4
          },
          NunaLie: {
              id: "NunaLie",
              name: "Nuna Lie",
              category: "Fashion",
              size: 4
          },

          Motivi: {
              id: "Motivi",
              name: "Motivi",
              category: "Fashion",
              size: 2
          },
          Gate21: {
              id: "Gate21",
              name: "Gate 21",
              category: "Fashion",
              size: 1
          },
          AwLab: {
              id: "AwLab",
              name: "AwLab",
              category: "Fashion",
              size: 2
          },
          Geox: {
              id: "Geox",
              name: "Geox",
              category: "Fashion",
              size: 2
          },
          Caporiccio: {
              id: "Caporiccio",
              name: "Caporiccio",
              category: "Fashion",
              size: 2
          },
          Melluso: {
              id: "Melluso",
              name: "Melluso",
              category: "Fashion",
              size: 2
          },
          MumbleMumble: {
              id: "MumbleMumble",
              name: "MumbleMumble",
              category: "Home Goods",
              size: 1
          },
          FiorellaRubino: {
              id: "FiorellaRubino",
              name: "Fiorella Rubino",
              category: "Fashion",
              size: 2
          },

          GiuntiAlPunto: {
              id: "GiuntiAlPunto",
              name: "Giunti al Punto",
              category: "Home Goods",
              size: 2
          },
          OriginalMarines: {
              id: "OriginalMarines",
              name: "OriginalMarines",
              category: "Fashion",
              size: 2
          },
          RealEstate46: {
              id: "RealEstate46",
              name: "Real Estate 46",
              category: "none",
              size: 2
          },
          ToccoDiClasse: {
              id: "ToccoDiClasse",
              name: "Tocco Di Classe",
              category: "Fashion",
              size: 1.5
          },
          SalmoiraghiVigano: {
              id: "SalmoiraghiVigano",
              name: "Salmoiraghi & Viganò",
              category: "Personal Goods",
              size: 2
          },
          Euronics: {
              id: "Euronics",
              name: "Euronics",
              category: "Home Goods",
              size: 2
          },
          BluVacanze: {
              id: "BluVacanze",
              name: "Blu Vacanze",
              category: "Services",
              size: 1
          },
          ImagePlanet: {
              id: "ImagePlanet",
              name: "Image Planet",
              category: "Services",
              size: 1
          },

          Arcaplanet: {
              id: "Arcaplanet",
              name: "Arcaplanet",
              category: "Animal Goods",
              size: 2
          },
          BijouBrigitte: {
              id: "BijouBrigitte",
              name: "Bijou Brigitte",
              category: "Personal Goods",
              size: 1
          },
          Douglas: {
              id: "Douglas",
              name: "Douglas",
              category: "Personal Goods",
              size: 2
          },
          Yoyogurt: {
              id: "Yoyogurt",
              name: "Yoyogurt",
              category: "Catering",
              size: 1
          },
          Erbolario: {
              id: "Erbolario",
              name: "L'Erbolario",
              category: "Personal Goods",
              size: 2
          },
          Vodafone: {
              id: "Vodafone",
              name: "Vodafone",
              category: "Home Goods",
              size: 1
          },
          WyconCostetics: {
              id: "WyconCostetics",
              name: "Wycon Cosmetics",
              category: "Home Goods",
              size: 1
          },

          RealEstate57B: {
              id: "RealEstate57B",
              name: "Real Estate 57B",
              category: "none",
              size: 1
          },
          Rinascimento: {
              id: "Rinascimento",
              name: "Rinascimento",
              category: "Fashion",
              size: 3
          },
          Stroili: {
              id: "Stroili",
              name: "Stroili",
              category: "Personal Goods",
              size: 2
          },
          Oltre: {
              id: "Oltre",
              name: "Oltre",
              category: "Fashion",
              size: 2
          },
          Pandora: {
              id: "Pandora",
              name: "Pandora",
              category: "Personal Goods",
              size: 1.5
          },
          Celio: {
              id: "Celio",
              name: "Celio",
              category: "Fashion",
              size: 2
          },
          Zuiki: {
              id: "Zuiki",
              name: "Zuiki",
              category: "Fashion",
              size: 2
          },
          Cycleband: {
              id: "Cycleband",
              name: "CycleBand",
              category: "Fashion",
              size: 2
          }
      };

      const allStores_Iper_Rozzano= {
          Giocattoli: {
              id: "Giocattoli",
              name: "Giocattoli",
              category: "Catering",
              size: 3
          },
          EventiPromozionaliStagionali: {
              id: "EventiPromozionaliStagionali",
              name: "EventiPromozionaliStagionali",
              category: "Services",
              size: 1
          },
          Cartoleria: {
              id: "Cartoleria",
              name: "Cartoleria",
              category: "Catering",
              size: 1
          },
          MondoBaby: {
              id: "MondoBaby",
              name: "MondoBaby",
              category: "Catering",
              size: 3
          },
          Abbigliamento: {
              id: "Abbigliamento",
              name: "Abbigliamento",
              category: "Catering",
              size: 1
          },
          Profumeria: {
              id: "Profumeria",
              name: "Profumeria",
              category: "Catering",
              size: 0.5
          },
          MondoCasa: {
              id: "MondoCasa",
              name: "YounGo",
              category: "Services",
              size: 0.2
          },
          Cantinetta: {
              id: "Cantinetta",
              name: "Cantinetta",
              category: "Catering",
              size: 2
          },
          FruttaVerdura: {
              id: "FruttaVerdura",
              name: "FruttaVerdura",
              category: "Catering",
              size: 2
          },
          Gastronomia: {
              id: "Gastronomia",
              name: "Gastronomia",
              category: "Services",
              size: 1
          },

          SalumiFormaggi: {
              id: "SalumiFormaggi",
              name: "SalumiFormaggi",
              category: "Services",
              size: 2
          },
          FreschiSurgelati: {
              id: "FreschiSurgelati",
              name: "FreschiSurgelati",
              category: "Personal Goods",
              size: 1
          },
          DispensaDolce: {
              id: "DispensaDolce",
              name: "DispensaDolce",
              category: "none",
              size: 1
          },
          DispensaSalata: {
              id: "DispensaSalata",
              name: "DispensaSalata",
              category: "Services",
              size: 1
          },
          DetergenzaCasa: {
              id: "DetergenzaCasa",
              name: "DetergenzaCasa",
              category: "Services",
              size: 1
          },
          Panetteria:{
              id: "Panetteria",
              name: "Panetteria",
              category: "Services",
              size: 1
          }
      };
      const context = {
          adminEntities: adminEntities,
          fieldValueLookup: {
              adminEntityId: {
                  lookup: (value: any) => {
                      const adminEntity = adminEntities.find((el) => el.administrativeEntityDetails.id === value);
                      return adminEntity ? adminEntity.administrativeEntityDetails.name : value;
                  }
              },
              newret: Utils.fieldValueLookup.newret,
              startDow: Utils.fieldValueLookup.startDow(this.translateService),
              startSod: Utils.fieldValueLookup.startSod,
          },
          translateService: this.translateService,
          tempAdminEntityData: {
              726: {
                  geopoints: {
                      736: {
                          name: "Sezione Promozioni",
                          lat: 45.39121,
                          lon: 9.17853,
                          locationId: 736,
                          isStartEndNode: true
                      },
                      734: { // Sezione Biscotti
                          name: "Sezione Biscotti",
                          lat: 45.39122,
                          lon: 9.17848,
                          locationId: 734,
                          isStartEndNode: true
                      },
                      733: { // SezioneFreschi
                          name: "Sezione Freschi",
                          lat: 45.39122,
                          lon: 9.17843,
                          locationId: 733,
                          isStartEndNode: true
                      },
                      732: { // Ingresso Lato Promozioni
                          name: "Ingresso Lato Promozioni",
                          lat: 45.39117,
                          lon: 9.17851,
                          locationId: 732
                      },
                      731: { // Ingresso Lato Pizzeria
                          name: "Ingresso Lato Pizzeria",
                          lat: 45.39119,
                          lon: 9.1784,
                          locationId: 731
                      }
                  },
                  stores: allStores_Iper_Rozzano,
                  segments: {
                      "732_736": {
                          name: "Ingresso Lato Promozioni - Sezione Promozioni",
                          path: [{lon: 9.17851, lat: 45.39117}, {lon: 9.17853, lat: 45.39121}],
                          count: 0,
                          stores: [
                              allStores_Iper_Rozzano.Giocattoli,
                              allStores_Iper_Rozzano.EventiPromozionaliStagionali,
                              allStores_Iper_Rozzano.Cartoleria
                          ]
                      },
                      "736_732": {
                          name: "Sezione Promozioni - Ingresso Lato Promozioni",
                          path: [{lon: 9.17853, lat: 45.39121}, {lon: 9.17851, lat: 45.39117}],
                          count: 0,
                          stores: [
                              allStores_Iper_Rozzano.Cartoleria,
                              allStores_Iper_Rozzano.EventiPromozionaliStagionali,
                              allStores_Iper_Rozzano.Giocattoli
                          ]
                      },
                      "732_731": {
                          name: "Ingresso Lato Promozioni - Ingresso Lato Pizzeria",
                          path: [{lon: 9.17851, lat: 45.39117}, {lon:9.1784,lat:45.39119}],
                          count: 0,
                          stores: [
                              allStores_Iper_Rozzano.Giocattoli,
                              allStores_Iper_Rozzano.Cartoleria,
                              allStores_Iper_Rozzano.MondoBaby,
                              allStores_Iper_Rozzano.Abbigliamento,
                              allStores_Iper_Rozzano.Profumeria,
                              allStores_Iper_Rozzano.MondoCasa,
                              allStores_Iper_Rozzano.Cantinetta,
                              allStores_Iper_Rozzano.FruttaVerdura,
                              allStores_Iper_Rozzano.Gastronomia                ]
                      },
                      "731_732": {
                          name: "Ingresso Lato Pizzeria - Ingresso Lato Promozioni",
                          path: [{lon:9.1784,lat:45.39119}, {lon: 9.17851, lat: 45.39117}],
                          count: 0,
                          stores: [
                              allStores_Iper_Rozzano.Gastronomia,
                              allStores_Iper_Rozzano.FruttaVerdura,
                              allStores_Iper_Rozzano.Cantinetta,
                              allStores_Iper_Rozzano.MondoCasa,
                              allStores_Iper_Rozzano.Profumeria,
                              allStores_Iper_Rozzano.Abbigliamento,
                              allStores_Iper_Rozzano.MondoBaby,
                              allStores_Iper_Rozzano.Cartoleria,
                              allStores_Iper_Rozzano.Giocattoli
                          ]
                      },
                      "731_733": {
                          name: "Ingresso Lato Pizzeria - Sezione Freschi ",
                          path: [{lon:9.1784,lat:45.39119}, {lon:9.17843, lat:45.39122}],
                          count: 0,
                          stores: [
                              allStores_Iper_Rozzano.Panetteria,
                              allStores_Iper_Rozzano.FruttaVerdura,
                              allStores_Iper_Rozzano.SalumiFormaggi,
                              allStores_Iper_Rozzano.FreschiSurgelati
                          ]

                      },
                      "733_731": {
                          name: "Sezione Freschi - Ingresso Lato Pizzeria",
                          path: [{lon:9.17843, lat:45.39122},{lon:9.1784,lat:45.39119}],
                          count: 0,
                          stores: [
                              allStores_Iper_Rozzano.FreschiSurgelati,
                              allStores_Iper_Rozzano.SalumiFormaggi,
                              allStores_Iper_Rozzano.FruttaVerdura,
                              allStores_Iper_Rozzano.Panetteria
                          ]

                      },
                      "733_734": {
                          name: "Sezione Freschi - Sezione Biscotti",
                          path: [{lon:9.17843, lat:45.39122},  {lon: 9.17848,lat:45.39122}],
                          count: 0,
                          stores: [
                              allStores_Iper_Rozzano.FreschiSurgelati,
                              allStores_Iper_Rozzano.DispensaDolce
                          ]
                      },
                      "734_733": {
                          name: "Sezione Biscotti - Sezione Freschi",
                          path: [  {lon: 9.17848,lat:45.39122},{lon:9.17843, lat:45.39122}],
                          count: 0,
                          stores: [
                              allStores_Iper_Rozzano.DispensaDolce,
                              allStores_Iper_Rozzano.FreschiSurgelati
                          ]
                      },
                      "734_736": {
                          name: "Sezione Biscotti - Sezione Promozioni",
                          path: [{lon: 9.17848,lat:45.39122}, {lon: 9.17853, lat: 45.39121}],
                          count: 0,
                          stores: [
                              allStores_Iper_Rozzano.DispensaSalata,
                              allStores_Iper_Rozzano.DetergenzaCasa
                          ]
                      },
                      "736_734": {
                          name: "Sezione Promozioni - Sezione Biscotti",
                          path: [{lon: 9.17853, lat: 45.39121}, {lon: 9.17848,lat:45.39122}],
                          count: 0,
                          stores: [
                              allStores_Iper_Rozzano.DetergenzaCasa,
                              allStores_Iper_Rozzano.DispensaSalata
                          ]
                      }
                  },
                  graph: {
                      732: {736: 1,731:1},
                      731: {732: 1, 733: 1},
                      733: {731: 1, 734: 1},
                      734: {733: 1, 736: 1},
                      736: {732: 1, 734: 1}
                  },
                  maxTraffic: 1000,
                  redThreshold: 0.8,
                  yellowThreshold: 0.3,
                  floorMapImage: "https://iottacle-main-web-storage.s3.eu-west-1.amazonaws.com/foo/pavia.png",
                  rot: -1.0692295135814294, // la rotazione dell'immagine
                  dDiff: -0.0015515067670204274, // riproporzione mappa
                  kDiff: -0.00040033774903491803, // riproporzione mappa
                  heading: 197, // grandezza mappa
                  center: {lat: 45.3912, lon: 9.17846}
              },
              664: {
                  geopoints: {
                      658: {
                          name: "Ingresso 1",
                          showLabel: true,
                          lat: 45.2050319,
                          lon: 9.1722559,
                          locationId: 658,
                          isStartEndNode: true
                      },
                      659: { // ingresso 2
                          name: "Ingresso 2",
                          showLabel: true,
                          lat: 45.205341,
                          lon: 9.1727711,
                          locationId: 659,
                          isStartEndNode: true
                      },
                      660: { // ingresso 3
                          name: "Ingresso 3",
                          showLabel: true,
                          lat: 45.2050575,
                          lon: 9.1741998,
                          locationId: 660,
                          isStartEndNode: true
                      },
                      664: { // euronics
                          name: "Euronics",
                          showLabel: true,
                          lat: 45.2047421,
                          lon: 9.1740679,
                          locationId: 664
                      },
                      661: { // a2a
                          name: "a2a",
                          showLabel: true,
                          lat: 45.2051399,
                          lon: 9.1734952,
                          locationId: 661
                      },
                      662: { // carrefour
                          name: "Carrefour",
                          showLabel: true,
                          lat: 45.2047819,
                          lon: 9.173396,
                          locationId: 662
                      },
                      663: { // middle
                          name: "Food",
                          showLabel: true,
                          lat: 45.2049629,
                          lon: 9.1725992,
                          locationId: 663,
                          isMiddleNode: true
                      }
                  },
                  stores: allStores_SvicomPavia,
                  segments: {
                      "658_663": {
                          from: 658,
                          to: 663,
                          name: "Ingresso 1 - Food",
                          path: [{lon: 9.1726166, lat: 45.2049412}, {lon: 9.1722465, lat: 45.2050149}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.OldWildWest,
                              allStores_SvicomPavia.DemaLavanderie,
                              allStores_SvicomPavia.WinCafeBar,
                              allStores_SvicomPavia.AlicePizza,
                              allStores_SvicomPavia.ILovePoke,
                              allStores_SvicomPavia.LaPiadineria,
                              allStores_SvicomPavia.YounGo,
                              allStores_SvicomPavia.PolloAndFriends,
                              allStores_SvicomPavia.Clayton,
                              allStores_SvicomPavia.IlTuoTelefonino
                          ]
                      },
                      "663_658": {
                          from: 663,
                          to: 658,
                          name: "Food - Ingresso1",
                          path: [{lon: 9.1722559, lat: 45.2050319}, {lon: 9.1725992, lat: 45.2049629}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.OldWildWest,
                              allStores_SvicomPavia.DemaLavanderie,
                              allStores_SvicomPavia.WinCafeBar,
                              allStores_SvicomPavia.AlicePizza,
                              allStores_SvicomPavia.ILovePoke,
                              allStores_SvicomPavia.LaPiadineria,
                              allStores_SvicomPavia.YounGo,
                              allStores_SvicomPavia.PolloAndFriends,
                              allStores_SvicomPavia.Clayton,
                              allStores_SvicomPavia.IlTuoTelefonino
                          ]
                      },
                      "663_659": {
                          from: 663,
                          to: 659,
                          name: "Food - Ingresso 2",
                          path: [{lon: 9.1726166, lat: 45.2049412}, {lon: 9.1727979, lat: 45.2053268}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.RealEstate09,
                              allStores_SvicomPavia.AlicePizza,
                              allStores_SvicomPavia.PolloAndFriends,
                              allStores_SvicomPavia.Clayton,
                              allStores_SvicomPavia.IlTuoTelefonino,
                              allStores_SvicomPavia.Hdental,
                              allStores_SvicomPavia.Nau,
                              allStores_SvicomPavia.TopCleanLavanderia,
                              allStores_SvicomPavia.LaPlayaSunBeauty,
                              allStores_SvicomPavia.Bata,
                              allStores_SvicomPavia.Xo,
                              allStores_SvicomPavia.BlueSpirit,
                              allStores_SvicomPavia.CasaMia,
                              allStores_SvicomPavia.Alexim,
                              allStores_SvicomPavia.Lovable,
                              allStores_SvicomPavia.WindTre,
                              allStores_SvicomPavia.PhoneMedic
                          ]

                      },
                      "659_663": {
                          from: 659,
                          to: 663,
                          name: "Ingresso2 - Food",
                          path: [{lon: 9.1727804, lat: 45.2053656}, {lon: 9.1725992, lat: 45.2049629}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.RealEstate09,
                              allStores_SvicomPavia.AlicePizza,
                              allStores_SvicomPavia.PolloAndFriends,
                              allStores_SvicomPavia.Clayton,
                              allStores_SvicomPavia.IlTuoTelefonino,
                              allStores_SvicomPavia.Hdental,
                              allStores_SvicomPavia.Nau,
                              allStores_SvicomPavia.TopCleanLavanderia,
                              allStores_SvicomPavia.LaPlayaSunBeauty,
                              allStores_SvicomPavia.Bata,
                              allStores_SvicomPavia.Xo,
                              allStores_SvicomPavia.BlueSpirit,
                              allStores_SvicomPavia.CasaMia,
                              allStores_SvicomPavia.Alexim,
                              allStores_SvicomPavia.Lovable,
                              allStores_SvicomPavia.WindTre,
                              allStores_SvicomPavia.PhoneMedic
                          ]
                      },
                      "663_662": {
                          from: 663,
                          to: 662,
                          name: "Food - Zona Carrefour",
                          path: [{lon: 9.1726166, lat: 45.2049412}, {lon: 9.1734309, lat: 45.2047724}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.PolloAndFriends,
                              allStores_SvicomPavia.AlicePizza,
                              allStores_SvicomPavia.Clayton,
                              allStores_SvicomPavia.IlTuoTelefonino,
                              allStores_SvicomPavia.Tim,
                              allStores_SvicomPavia.Yamamay,
                              allStores_SvicomPavia.GoldenPoint,
                              allStores_SvicomPavia.GameStop,
                              allStores_SvicomPavia.Kisene,
                              allStores_SvicomPavia.Thun,
                              allStores_SvicomPavia.CasaPesci,
                              allStores_SvicomPavia.Carrefour,
                              allStores_SvicomPavia.PiuMedicalParafarmacia
                          ]
                      },
                      "662_663": {
                          from: 662,
                          to: 663,
                          name: "Zona Carrefour - Food",
                          path: [{lon: 9.1733987, lat: 45.2048017}, {lon: 9.1725992, lat: 45.2049629}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.PolloAndFriends,
                              allStores_SvicomPavia.AlicePizza,
                              allStores_SvicomPavia.Clayton,
                              allStores_SvicomPavia.IlTuoTelefonino,
                              allStores_SvicomPavia.Tim,
                              allStores_SvicomPavia.Yamamay,
                              allStores_SvicomPavia.GoldenPoint,
                              allStores_SvicomPavia.GameStop,
                              allStores_SvicomPavia.Kisene,
                              allStores_SvicomPavia.Thun,
                              allStores_SvicomPavia.CasaPesci,
                              allStores_SvicomPavia.Carrefour,
                              allStores_SvicomPavia.PiuMedicalParafarmacia
                          ]
                      },
                      "662_661": {
                          from: 662,
                          to: 661,
                          name: "Zona Carrefour - Zona a2a",
                          path: [{lon: 9.1734309, lat: 45.2047724}, {lon: 9.1735113, lat: 45.2051371}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.CasaPesci,
                              allStores_SvicomPavia.RealEstate29,
                              allStores_SvicomPavia.Bancomat,
                              allStores_SvicomPavia.BarZagara,
                              allStores_SvicomPavia.Sorbino,
                              allStores_SvicomPavia.BeautyCo,
                              allStores_SvicomPavia.MondoGelato,
                              allStores_SvicomPavia.NunaLie,
                              allStores_SvicomPavia.Carrefour
                          ]
                      },
                      "661_662": {
                          from: 661,
                          to: 662,
                          name: "Zona a2a - Zona carrefour",
                          path: [{lon: 9.1734751, lat: 45.2051456}, {lon: 9.173396, lat: 45.2047819}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.CasaPesci,
                              allStores_SvicomPavia.RealEstate29,
                              allStores_SvicomPavia.Bancomat,
                              allStores_SvicomPavia.BarZagara,
                              allStores_SvicomPavia.Sorbino,
                              allStores_SvicomPavia.BeautyCo,
                              allStores_SvicomPavia.MondoGelato,
                              allStores_SvicomPavia.NunaLie,
                              allStores_SvicomPavia.Carrefour
                          ]
                      },
                      "661_659": {
                          from: 661,
                          to: 659,
                          name: "Zona a2a - Ingresso 2",
                          path: [{lon: 9.1734952, lat: 45.2051399}, {lon: 9.1733237, lat: 45.2051766}, {
                              lon: 9.172939,
                              lat: 45.2052908
                          }, {lon: 9.1727804, lat: 45.2053656}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.MondoGelato,
                              allStores_SvicomPavia.BarZagara,
                              allStores_SvicomPavia.Motivi,
                              allStores_SvicomPavia.Gate21,
                              allStores_SvicomPavia.AwLab,
                              allStores_SvicomPavia.Geox,
                              allStores_SvicomPavia.BlueSpirit,
                              allStores_SvicomPavia.Bata,
                              allStores_SvicomPavia.Xo,
                              allStores_SvicomPavia.Caporiccio,
                              allStores_SvicomPavia.Melluso,
                              allStores_SvicomPavia.MumbleMumble,
                              allStores_SvicomPavia.FiorellaRubino,
                              allStores_SvicomPavia.Sorbino,
                              allStores_SvicomPavia.BeautyCo
                          ]
                      },
                      "659_661": {
                          from: 659,
                          to: 661,
                          name: "Ingresso 2 - Zona a2a",
                          path: [{lon: 9.1727711, lat: 45.205341}, {lon: 9.1729211, lat: 45.2052745}, {
                              lon: 9.1733112,
                              lat: 45.205157
                          }, {lon: 9.1734858, lat: 45.2051182}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.MondoGelato,
                              allStores_SvicomPavia.BarZagara,
                              allStores_SvicomPavia.Motivi,
                              allStores_SvicomPavia.Gate21,
                              allStores_SvicomPavia.AwLab,
                              allStores_SvicomPavia.Geox,
                              allStores_SvicomPavia.BlueSpirit,
                              allStores_SvicomPavia.Bata,
                              allStores_SvicomPavia.Xo,
                              allStores_SvicomPavia.Caporiccio,
                              allStores_SvicomPavia.Melluso,
                              allStores_SvicomPavia.MumbleMumble,
                              allStores_SvicomPavia.FiorellaRubino,
                              allStores_SvicomPavia.Sorbino,
                              allStores_SvicomPavia.BeautyCo
                          ]
                      },
                      "662_664": {
                          from: 662,
                          to: 664,
                          name: "Zona Carrefour - Zona Euronics",
                          path: [{lon: 9.1734309, lat: 45.2047724}, {lon: 9.173909, lat: 45.204693}, {
                              lon: 9.1740679,
                              lat: 45.2047421
                          }],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.Carrefour,
                              allStores_SvicomPavia.ImagePlanet,
                              allStores_SvicomPavia.BluVacanze,
                              allStores_SvicomPavia.Euronics,
                              allStores_SvicomPavia.SalmoiraghiVigano,
                              allStores_SvicomPavia.ToccoDiClasse,
                              allStores_SvicomPavia.RealEstate46,
                              allStores_SvicomPavia.OriginalMarines,
                              allStores_SvicomPavia.GiuntiAlPunto,
                              allStores_SvicomPavia.NunaLie
                          ]
                      },
                      "664_662": {
                          from: 664,
                          to: 662,
                          name: "Zona Euronics - Zona Carrefour",
                          path: [{lon: 9.1740438, lat: 45.2047648}, {lon: 9.1739036, lat: 45.204718}, {
                              lon: 9.1733987,
                              lat: 45.2048017
                          }],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.Carrefour,
                              allStores_SvicomPavia.ImagePlanet,
                              allStores_SvicomPavia.BluVacanze,
                              allStores_SvicomPavia.Euronics,
                              allStores_SvicomPavia.SalmoiraghiVigano,
                              allStores_SvicomPavia.ToccoDiClasse,
                              allStores_SvicomPavia.RealEstate46,
                              allStores_SvicomPavia.OriginalMarines,
                              allStores_SvicomPavia.GiuntiAlPunto,
                              allStores_SvicomPavia.NunaLie
                          ]
                      },
                      "664_660": {
                          from: 664,
                          to: 660,
                          name: "Zona Euronics - Ingresso 3",
                          path: [{lon: 9.1740679, lat: 45.2047421}, {lon: 9.174135, lat: 45.2048418}, {
                              lon: 9.1742262,
                              lat: 45.2050794
                          }],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.Euronics,
                              allStores_SvicomPavia.Arcaplanet,
                              allStores_SvicomPavia.BijouBrigitte,
                              allStores_SvicomPavia.Douglas,
                              allStores_SvicomPavia.Yoyogurt,
                              allStores_SvicomPavia.Erbolario,
                              allStores_SvicomPavia.Vodafone,
                              allStores_SvicomPavia.WyconCostetics,
                              allStores_SvicomPavia.SalmoiraghiVigano
                          ]
                      },
                      "660_664": {
                          from: 660,
                          to: 664,
                          name: "Ingresso 3 - Zona Euronics",
                          path: [{lon: 9.1741873, lat: 45.2050795}, {
                              lon: 9.1740988,
                              lat: 45.2048593
                          }, {lon: 9.1740438, lat: 45.2047648}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.Euronics,
                              allStores_SvicomPavia.Arcaplanet,
                              allStores_SvicomPavia.BijouBrigitte,
                              allStores_SvicomPavia.Douglas,
                              allStores_SvicomPavia.Yoyogurt,
                              allStores_SvicomPavia.Erbolario,
                              allStores_SvicomPavia.Vodafone,
                              allStores_SvicomPavia.WyconCostetics,
                              allStores_SvicomPavia.SalmoiraghiVigano
                          ]
                      },
                      "660_661": {
                          from: 660,
                          to: 661,
                          name: "Ingresso3 - Zona a2a",
                          path: [{lon: 9.1742262, lat: 45.2050794}, {
                              lon: 9.1740646,
                              lat: 45.2050695
                          }, {lon: 9.1736699, lat: 45.2051076}, {lon: 9.1734952, lat: 45.2051399}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.Yoyogurt,
                              allStores_SvicomPavia.RealEstate57B,
                              allStores_SvicomPavia.Rinascimento,
                              allStores_SvicomPavia.Stroili,
                              allStores_SvicomPavia.Oltre,
                              allStores_SvicomPavia.Pandora,
                              allStores_SvicomPavia.BeautyCo,
                              allStores_SvicomPavia.Sorbino,
                              allStores_SvicomPavia.BarZagara,
                              allStores_SvicomPavia.MondoGelato,
                              allStores_SvicomPavia.Celio,
                              allStores_SvicomPavia.Zuiki,
                              allStores_SvicomPavia.Cycleband,
                              allStores_SvicomPavia.Erbolario,
                              allStores_SvicomPavia.Douglas,
                              allStores_SvicomPavia.BijouBrigitte
                          ]
                      },
                      "661_660": {
                          from: 661,
                          to: 660,
                          name: "Zona a2a - Ingresso3",
                          path: [{lon: 9.1734858, lat: 45.2051182}, {
                              lon: 9.1736562,
                              lat: 45.2050839
                          }, {lon: 9.1740491, lat: 45.205044}, {lon: 9.1742195, lat: 45.2050521}],
                          count: 0,
                          stores: [
                              allStores_SvicomPavia.Yoyogurt,
                              allStores_SvicomPavia.RealEstate57B,
                              allStores_SvicomPavia.Rinascimento,
                              allStores_SvicomPavia.Stroili,
                              allStores_SvicomPavia.Oltre,
                              allStores_SvicomPavia.Pandora,
                              allStores_SvicomPavia.BeautyCo,
                              allStores_SvicomPavia.Sorbino,
                              allStores_SvicomPavia.BarZagara,
                              allStores_SvicomPavia.MondoGelato,
                              allStores_SvicomPavia.Celio,
                              allStores_SvicomPavia.Zuiki,
                              allStores_SvicomPavia.Cycleband,
                              allStores_SvicomPavia.Erbolario,
                              allStores_SvicomPavia.Douglas,
                              allStores_SvicomPavia.BijouBrigitte
                          ]
                      }
                  },
                  graph: {
                      658: {663: 1},
                      663: {658: 1, 659: 1, 662: 1},
                      659: {663: 1, 661: 1},
                      662: {663: 1, 661: 1, 664: 1},
                      661: {659: 1, 662: 1, 660: 1},
                      664: {662: 1, 660: 1},
                      660: {664: 1, 661: 1}
                  },
                  maxTraffic: 2000,
                  redThreshold: 0.9,
                  yellowThreshold: 0.5,
                  floorMapImage: "https://iottacle-main-web-storage.s3.eu-west-1.amazonaws.com/foo/pavia.png",
                  rot: -1.0692295135814294,
                  dDiff: -0.0015515067670204274,
                  kDiff: -0.00040033774903491803,
                  heading: 197,
                  center: {lat: 45.20474840766004, lon: 9.173244235311877}
              }, 766: {
                  geopoints: {
                      828: { // OVS
                          name: "OVS",
                          showLabel: true,
                          lat: 41.083374996265675,
                          lon: 16.947491436782407,
                          locationId: 828
                      },
                      829: { // PullAndBear
                          name: "PullAndBear",
                          showLabel: true,
                          lat: 41.08287605819852,
                          lon: 16.947336276240595,
                          locationId: 829
                      },
                      830: { // Zara
                          name: "Zara",
                          showLabel: true,
                          lat: 41.08246111120295,
                          lon: 16.94694256866967,
                          locationId: 830,
                          isStartEndNode: true
                      },
                      831: { // Cisalfa
                          name: "Cisalfa",
                          showLabel: true,
                          lat: 41.082658262231995,
                          lon: 16.94641148326842,
                          locationId: 831,
                          isStartEndNode: true
                      },
                      832: { // Stradivarius
                          name: "Stradivarius",
                          showLabel: true,
                          lat: 41.083122286107226,
                          lon: 16.9466124344984,
                          locationId: 832
                      },
                      833: { // Deichmann
                          name: "Deichmann",
                          showLabel: true,
                          lat: 41.08351778573588,
                          lon: 16.94694097380642,
                          locationId: 833,
                          isStartEndNode: true
                      },
                      /* 834: { // Azzaro
                          name: "Azzaro",
                          showLabel: true,
                          lat: 41.083374996265675,
                          lon: 16.951491436782407,
                          locationId: 834
                      }, */
                      /* 835: { // Expert
                          name: "Expert",
                          showLabel: true,
                          lat: 41.08298829601343,
                          lon: 16.95107475564057,
                          locationId: 835
                      }, */
                      /* 836: { // NewYorker
                          name: "NewYorker",
                          showLabel: true,
                          lat: 41.08246111120295,
                          lon: 16.95094256866967,
                          locationId: 835
                      }, */
                     /*  837: { // McDonalds
                          name: "McDonalds",
                          showLabel: true,
                          lat: 41.082658262231995,
                          lon: 16.95041148326842,
                          locationId: 837,
                          isStartEndNode: true
                      }, */
                      /* 838: { // AwLab
                          name: "AwLab",
                          showLabel: true,
                          lat: 41.083122286107226,
                          lon: 16.9506124344984,
                          locationId: 838
                      }, */
                     /*  839: { // HM
                          name: "HM",
                          showLabel: true,
                          lat: 41.08351778573588,
                          lon: 16.95094097380642,
                          locationId: 839,
                          isMiddleNode: true,
                          isStartEndNode: true
                      } */
                  },
                  stores: allStores_SvicomBariblu,
                  segments: {
                      ///
                      ///
                      ///
                      ///
                      ///
                      ///
                      //PT renamed PT Ingresso 1 to OVS and PT Ingresso 2 to Zara
                      ///
                      ///
                      ///
                      ///
                      ///
                      ///
                      /* "832_838": {
                          from: 832,
                          to: 838,
                          name: "Stradivarius - AwLab",
                          path: [{lon: 16.946661874877243, lat: 41.082978030325705}, {lon: 16.9506124344984, lat: 41.083122286107226}],
                          count: 0,
                          stores: [ ]
                      },
                      "838_832": {
                          from: 838,
                          to: 832,
                          name: "AwLab - Stradivarius",
                          path: [{lon: 16.9506124344984, lat: 41.083122286107226}, {lon: 16.946661874877243, lat: 41.082978030325705}],
                          count: 0,
                          stores: [ ]
                      }, */

                      "828_829": {
                          from: 828,
                          to: 829,
                          name: "OVS - PullAndBear",
                          path: [{lon: 16.947491436782407, lat: 41.083374996265675}, {lon: 16.947336276240595, lat: 41.08287605819852}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.OVS,
                              allStores_SvicomBariblu.Chicco,
                              allStores_SvicomBariblu.LaMaisonSofa,
                              allStores_SvicomBariblu.Satur
                          ]
                      },
                      "829_828": {
                          from: 829,
                          to: 828,
                          name: "PullAndBear - OVS",
                          path: [{lon: 16.947315763807964, lat: 41.08291431726423}, {lon: 16.94745132614282, lat: 41.083343477682824}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.OVS,
                              allStores_SvicomBariblu.Chicco,
                              allStores_SvicomBariblu.LaMaisonSofa,
                              allStores_SvicomBariblu.Satur
                          ]
                      },
                      "829_830": {
                          from: 829,
                          to: 830,
                          name: "PullAndBear - Zara",
                          path: [{lon: 16.947336276240595, lat: 41.08287605819852}, {lon: 16.94694256866967, lat: 41.08246111120295}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.PullAndBear,
                              allStores_SvicomBariblu.Eminflex,
                              allStores_SvicomBariblu.Zara,
                              allStores_SvicomBariblu.KiaCafe,
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.Kiko
                          ]
                      },
                      "830_829": {
                          from: 830,
                          to: 829,
                          name: "Zara - PullAndBear",
                          path: [{lon: 16.946883559175273, lat: 41.082514005437794}, {lon: 16.947315763807964, lat: 41.08291431726423}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.PullAndBear,
                              allStores_SvicomBariblu.Eminflex,
                              allStores_SvicomBariblu.Zara,
                              allStores_SvicomBariblu.KiaCafe,
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.Kiko
                          ]
                      },
                      "830_831": {
                          from: 830,
                          to: 831,
                          name: "Zara - Cisalfa",
                          path: [{lon: 16.94694256866967, lat: 41.08246111120295}, {lon: 16.94641148326842, lat: 41.082658262231995}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.NunaLie,
                              allStores_SvicomBariblu.IdeaBellezza,
                              allStores_SvicomBariblu.Findomestic,
                              allStores_SvicomBariblu.Cisalfa,
                              allStores_SvicomBariblu.LoByLovable,
                              allStores_SvicomBariblu.MusicSchool
                          ]
                      },
                      "831_830": {
                          from: 831,
                          to: 830,
                          name: "Cisalfa - Zara",
                          path: [{lon: 16.946502389777415, lat: 41.08269432638005}, {lon: 16.946883559175273, lat: 41.082514005437794}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.NunaLie,
                              allStores_SvicomBariblu.IdeaBellezza,
                              allStores_SvicomBariblu.Findomestic,
                              allStores_SvicomBariblu.Cisalfa,
                              allStores_SvicomBariblu.LoByLovable,
                              allStores_SvicomBariblu.MusicSchool
                          ]
                      },
                      "831_832": {
                          from: 831,
                          to: 832,
                          name: "Cisalfa - Stradivarius",
                          path: [{lon: 16.94641148326842, lat: 41.082658262231995}, {lon: 16.9466124344984,lat: 41.083122286107226}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.LoByLovable,
                              allStores_SvicomBariblu.FiorellaRubino,
                              allStores_SvicomBariblu.LowDesign,
                              allStores_SvicomBariblu.Tata,
                              allStores_SvicomBariblu.Oltre,
                              allStores_SvicomBariblu.NunaLie,
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.Intimissimi,
                              allStores_SvicomBariblu.Calzedonia,
                              allStores_SvicomBariblu.Stradivarius
                          ]
                      },
                      "832_831": {
                          from: 832,
                          to: 831,
                          name: "Stradivarius - Cisalfa",
                          path: [{lon: 16.946661874877243, lat: 41.082978030325705}, {lon: 16.946502389777415, lat: 41.08269432638005}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.LoByLovable,
                              allStores_SvicomBariblu.FiorellaRubino,
                              allStores_SvicomBariblu.LowDesign,
                              allStores_SvicomBariblu.Tata,
                              allStores_SvicomBariblu.Oltre,
                              allStores_SvicomBariblu.NunaLie,
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.Intimissimi,
                              allStores_SvicomBariblu.Calzedonia,
                              allStores_SvicomBariblu.Stradivarius
                          ]
                      },
                      "832_833": {
                          from: 832,
                          to: 833,
                          name: "Stradivarius - Deichmann",
                          path: [{lon: 16.9466124344984, lat: 41.083122286107226}, {lon: 16.94694097380642, lat: 41.08351778573588}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Stradivarius,
                              allStores_SvicomBariblu.Motivi,
                              allStores_SvicomBariblu.Wind,
                              allStores_SvicomBariblu.ObbiettiviModa,
                              allStores_SvicomBariblu.GoldenPoint,
                              allStores_SvicomBariblu.Stroili,
                              allStores_SvicomBariblu.Tezenis,
                              allStores_SvicomBariblu.OriginalMarines,
                              allStores_SvicomBariblu.Bata
                          ]
                      },
                      "833_832": {
                          from: 833,
                          to: 832,
                          name: "Deichmann - Stradivarius",
                          path: [{lon: 16.946972870830944, lat: 41.08347931778968}, {lon: 16.946661874877243, lat: 41.082978030325705}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Stradivarius,
                              allStores_SvicomBariblu.Motivi,
                              allStores_SvicomBariblu.Wind,
                              allStores_SvicomBariblu.ObbiettiviModa,
                              allStores_SvicomBariblu.GoldenPoint,
                              allStores_SvicomBariblu.Stroili,
                              allStores_SvicomBariblu.Tezenis,
                              allStores_SvicomBariblu.OriginalMarines,
                              allStores_SvicomBariblu.Bata
                          ]
                      },
                      "833_828": {
                          from: 833,
                          to: 828,
                          name: "Deichmann - OVS",
                          path: [{lon: 16.94694097380642, lat: 41.08351778573588}, {lon: 16.947491436782407, lat: 41.083374996265675}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Pagi,
                              allStores_SvicomBariblu.GoldenPoint,
                              allStores_SvicomBariblu.Deichmann,
                              allStores_SvicomBariblu.JDSport,
                              allStores_SvicomBariblu.ObbiettiviModa,
                              allStores_SvicomBariblu.OVS
                          ]
                      },
                      "828_833": {
                          from: 828,
                          to: 833,
                          name: "OVS - Deichmann",
                          path: [{lon: 16.947491436782407, lat: 41.083374996265675}, {lon: 16.946972870830944, lat: 41.08347931778968}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Pagi,
                              allStores_SvicomBariblu.GoldenPoint,
                              allStores_SvicomBariblu.Deichmann,
                              allStores_SvicomBariblu.JDSport,
                              allStores_SvicomBariblu.ObbiettiviModa,
                              allStores_SvicomBariblu.OVS
                          ]
                      },
                      ///
                      ///
                      ///
                      ///
                      ///
                      ///
                      /// PP
                      ///
                      ///
                      ///
                      ///
                      ///
                      ///
                     /*  "834_835": {
                          from: 834,
                          to: 835,
                          name: "Azzaro - Expert",
                          path: [{lon: 16.951491436782407, lat: 41.083374996265675}, {lon: 16.95107475564057, lat: 41.08298829601343}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Azzaro,
                              allStores_SvicomBariblu.DentalPro,
                              allStores_SvicomBariblu.MondoCamerette,
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Expert,
                              allStores_SvicomBariblu.SarniOro,
                              allStores_SvicomBariblu.Biosan,
                              allStores_SvicomBariblu.GiorgiaAndJohns,
                              allStores_SvicomBariblu.Equivalenza,
                              allStores_SvicomBariblu.SartoriePlanet,
                              allStores_SvicomBariblu.OpticalBariblu
                          ]
                      },
                      "835_834": {
                          from: 835,
                          to: 834,
                          name: "Expert - Azzaro",
                          path: [{lon: 16.95102390607295, lat: 41.08298043459841}, {lon: 16.95145132614282, lat: 41.083343477682824}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Azzaro,
                              allStores_SvicomBariblu.DentalPro,
                              allStores_SvicomBariblu.MondoCamerette,
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Expert,
                              allStores_SvicomBariblu.SarniOro,
                              allStores_SvicomBariblu.Biosan,
                              allStores_SvicomBariblu.GiorgiaAndJohns,
                              allStores_SvicomBariblu.Equivalenza,
                              allStores_SvicomBariblu.SartoriePlanet,
                              allStores_SvicomBariblu.OpticalBariblu
                          ]
                      }, */
                      /* "835_836": {
                          from: 835,
                          to: 836,
                          name: "Expert - NewYorker",
                          path: [{lon: 16.95107475564057, lat: 41.08298829601343}, {lon: 16.95094256866967, lat: 41.08246111120295}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Expert,
                              allStores_SvicomBariblu.HappyCasaStore,
                              allStores_SvicomBariblu.NewYorker,
                              allStores_SvicomBariblu.MariannaBattista,
                              allStores_SvicomBariblu.GameStop,
                              allStores_SvicomBariblu.CottonAndSilk,
                              allStores_SvicomBariblu.JeanLouisDavid,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CigaretteExpress
                          ]
                      }, */
                      /* "836_835": {
                          from: 836,
                          to: 835,
                          name: "NewYorker - Expert",
                          path: [{lon: 16.950883559175273, lat: 41.082514005437794}, {lon: 16.95102390607295, lat: 41.08298043459841}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Expert,
                              allStores_SvicomBariblu.HappyCasaStore,
                              allStores_SvicomBariblu.NewYorker,
                              allStores_SvicomBariblu.MariannaBattista,
                              allStores_SvicomBariblu.GameStop,
                              allStores_SvicomBariblu.CottonAndSilk,
                              allStores_SvicomBariblu.JeanLouisDavid,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CigaretteExpress
                          ]
                      }, */
                     /*  "836_837": {
                          from: 836,
                          to: 837,
                          name: "NewYorker - McDonalds",
                          path: [{lon: 16.95094256866967, lat: 41.08246111120295}, {lon: 16.95041148326842, lat: 41.082658262231995}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.HappyCasaStore,
                              allStores_SvicomBariblu.NewYorker,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CigaretteExpress,
                              allStores_SvicomBariblu.GrandVision,
                              allStores_SvicomBariblu.Yoyogurt,
                              allStores_SvicomBariblu.Primadonna,
                              allStores_SvicomBariblu.SangioCaffe,
                              allStores_SvicomBariblu.DaGiovanni
                          ]
                      },
                      "837_836": {
                          from: 837,
                          to: 836,
                          name: "McDonalds - NewYorker",
                          path: [{lon: 16.950502389777415, lat: 41.08269432638005}, {lon: 16.950883559175273, lat: 41.082514005437794}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.HappyCasaStore,
                              allStores_SvicomBariblu.NewYorker,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CigaretteExpress,
                              allStores_SvicomBariblu.GrandVision,
                              allStores_SvicomBariblu.Yoyogurt,
                              allStores_SvicomBariblu.Primadonna,
                              allStores_SvicomBariblu.SangioCaffe,
                              allStores_SvicomBariblu.DaGiovanni
                          ]
                      }, */
                      /* "837_838": {
                          from: 837,
                          to: 838,
                          name: "McDonalds - AwLab",
                          path: [{lon: 16.95041148326842, lat: 41.082658262231995}, {lon: 16.9506124344984,lat: 41.083122286107226}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.McDonalds,
                              allStores_SvicomBariblu.SangioBistrot,
                              allStores_SvicomBariblu.Yoyogurt,
                              allStores_SvicomBariblu.CasolareDiPuglia,
                              allStores_SvicomBariblu.SangioCaffe,
                              allStores_SvicomBariblu.DaGiovanni,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CottonAndSilk,
                              allStores_SvicomBariblu.FrancoGioielli,
                              allStores_SvicomBariblu.Indi,
                              allStores_SvicomBariblu.AwLab,
                              allStores_SvicomBariblu.Primadonna,
                              allStores_SvicomBariblu.Adidas,
                              allStores_SvicomBariblu.BlackSheep,
                              allStores_SvicomBariblu.Valerio1966,
                              allStores_SvicomBariblu.Live978,
                              allStores_SvicomBariblu.GiuntiAlPunto
                          ]
                      }, */
                      /* "838_837": {
                          from: 838,
                          to: 837,
                          name: "AwLab - McDonalds",
                          path: [{lon: 16.950661874877243, lat: 41.082978030325705}, {lon: 16.950502389777415, lat: 41.08269432638005}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.McDonalds,
                              allStores_SvicomBariblu.SangioBistrot,
                              allStores_SvicomBariblu.Yoyogurt,
                              allStores_SvicomBariblu.CasolareDiPuglia,
                              allStores_SvicomBariblu.SangioCaffe,
                              allStores_SvicomBariblu.DaGiovanni,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CottonAndSilk,
                              allStores_SvicomBariblu.FrancoGioielli,
                              allStores_SvicomBariblu.Indi,
                              allStores_SvicomBariblu.AwLab,
                              allStores_SvicomBariblu.Primadonna,
                              allStores_SvicomBariblu.Adidas,
                              allStores_SvicomBariblu.BlackSheep,
                              allStores_SvicomBariblu.Valerio1966,
                              allStores_SvicomBariblu.Live978,
                              allStores_SvicomBariblu.GiuntiAlPunto
                          ]
                      }, */
                      /* "838_839": {
                          from: 838,
                          to: 839,
                          name: "AwLab - HM",
                          path: [{lon: 16.9506124344984, lat: 41.083122286107226}, {lon: 16.95094097380642, lat: 41.08351778573588}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.SensoUnico,
                              allStores_SvicomBariblu.YankeeCandle,
                              allStores_SvicomBariblu.Thun,
                              allStores_SvicomBariblu.Sorbino,
                              allStores_SvicomBariblu.LaCasaDeLasCarcas,
                              allStores_SvicomBariblu.HM,
                              allStores_SvicomBariblu.Tiger,
                              allStores_SvicomBariblu.Alcott,
                              allStores_SvicomBariblu.GiuntiAlPunto
                          ]
                      }, */
                      /* "839_838": {
                          from: 839,
                          to: 838,
                          name: "HM - AwLab",
                          path: [{lon: 16.950972870830944, lat: 41.08347931778968}, {lon: 16.950661874877243, lat: 41.082978030325705}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.SensoUnico,
                              allStores_SvicomBariblu.YankeeCandle,
                              allStores_SvicomBariblu.Thun,
                              allStores_SvicomBariblu.Sorbino,
                              allStores_SvicomBariblu.LaCasaDeLasCarcas,
                              allStores_SvicomBariblu.HM,
                              allStores_SvicomBariblu.Tiger,
                              allStores_SvicomBariblu.Alcott,
                              allStores_SvicomBariblu.GiuntiAlPunto
                          ]
                      }, */
                      /* "839_834": {
                          from: 839,
                          to: 834,
                          name: "HM - Azzaro",
                          path: [{lon: 16.95094097380642, lat: 41.08351778573588}, {lon: 16.951491436782407, lat: 41.083374996265675}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Tiger,
                              allStores_SvicomBariblu.HM,
                              allStores_SvicomBariblu.LaCasaDeLasCarcas,
                              allStores_SvicomBariblu.Sorbino,
                              allStores_SvicomBariblu.SarniOro,
                              allStores_SvicomBariblu.Douglas,
                              allStores_SvicomBariblu.MondoCamerette,
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Azzaro
                          ]
                      },
                      "834_839": {
                          from: 834,
                          to: 839,
                          name: "Azzaro - HM",
                          path: [{lon: 16.951491436782407, lat: 41.083374996265675}, {lon: 16.950972870830944, lat: 41.08347931778968}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Tiger,
                              allStores_SvicomBariblu.HM,
                              allStores_SvicomBariblu.LaCasaDeLasCarcas,
                              allStores_SvicomBariblu.Sorbino,
                              allStores_SvicomBariblu.SarniOro,
                              allStores_SvicomBariblu.Douglas,
                              allStores_SvicomBariblu.MondoCamerette,
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Azzaro
                          ]
                      }, */
                  },
                  graph: {
                      828: {829: 1, 833: 1},
                      829: {828: 1, 830: 1},
                      830: {829: 1, 831: 1},
                      831: {830: 1, 832: 1},
                      832: {831: 1, 833: 1},
                      833: {832: 1, 828: 1},
                      //834: {839: 1, 835: 1},
                      //835: {834: 1, 836: 1},
                     // 836: {835: 1, 837: 1},
                      //837: {836: 1, 838: 1},
                      //838: {837: 1, 839: 1},
                      //839: {838: 1, 834: 1}
                  },
                  maxTraffic: 2000,
                  redThreshold: 0.9,
                  yellowThreshold: 0.5,
                  floorMapImage: "/assets/images/backgrounds/bariblu1.png",
                  rot: -84.0202295135814294,
                  dDiff: -0.0009355067670204274, // riproporzione mappa allarga l'immagine
                  kDiff: -0.00033433774903491803, // riproporzione mappa alza l'immagine
                  heading: 197, // grandezza mappa
                  center: {lat: 41.08304534973345, lon: 16.946901102546082}
              },
              '766_2': {
                  geopoints: {
                      /* 828: { // OVS
                          name: "OVS",
                          showLabel: true,
                          lat: 41.083374996265675,
                          lon: 16.947491436782407,
                          locationId: 828
                      },
                      829: { // PullAndBear
                          name: "PullAndBear",
                          showLabel: true,
                          lat: 41.08287605819852,
                          lon: 16.947336276240595,
                          locationId: 829
                      },
                      830: { // Zara
                          name: "Zara",
                          showLabel: true,
                          lat: 41.08246111120295,
                          lon: 16.94694256866967,
                          locationId: 830
                      },
                      831: { // Cisalfa
                          name: "Cisalfa",
                          showLabel: true,
                          lat: 41.082658262231995,
                          lon: 16.94641148326842,
                          locationId: 831,
                          isStartEndNode: true
                      },
                      832: { // Stradivarius
                          name: "Stradivarius",
                          showLabel: true,
                          lat: 41.083122286107226,
                          lon: 16.9466124344984,
                          locationId: 832
                      },
                      833: { // Deichmann
                          name: "Deichmann",
                          showLabel: true,
                          lat: 41.08351778573588,
                          lon: 16.94694097380642,
                          locationId: 833,
                          isStartEndNode: true
                      }, */
                      834: { // Azzaro
                          name: "Azzaro",
                          showLabel: true,
                          lat: 41.083374996265675,
                          lon: 16.947491436782407,
                          locationId: 834
                      },
                      835: { // Expert
                          name: "Expert",
                          showLabel: true,
                          lat: 41.08281812601343,
                          lon: 16.947106276240595,
                          locationId: 835
                      },
                      836: { // NewYorker
                          name: "NewYorker",
                          showLabel: true,
                          lat: 41.08246111120295,
                          lon: 16.94694256866967,
                          locationId: 835
                      },
                      837: { // McDonalds
                          name: "McDonalds",
                          showLabel: true,
                          lat: 41.082658262231995,
                          lon: 16.94641148326842,
                          locationId: 837,
                          isStartEndNode: true
                      },
                      838: { // AwLab
                          name: "AwLab",
                          showLabel: true,
                          lat: 41.083122286107226,
                          lon: 16.9466124344984,
                          locationId: 838
                      },
                      839: { // HM
                          name: "HM",
                          showLabel: true,
                          lat: 41.08351778573588,
                          lon: 16.94694097380642,
                          locationId: 839,
                          isMiddleNode: true,
                          isStartEndNode: true
                      }
                  },
                  stores: allStores_SvicomBariblu,
                  segments: {
                      ///
                      ///
                      ///
                      ///
                      ///
                      ///
                      //PT renamed PT Ingresso 1 to OVS and PT Ingresso 2 to Zara
                      ///
                      ///
                      ///
                      ///
                      ///
                      ///
                      /* "832_838": {
                          from: 832,
                          to: 838,
                          name: "Stradivarius - AwLab",
                          path: [{lon: 16.946661874877243, lat: 41.082978030325705}, {lon: 16.9506124344984, lat: 41.083122286107226}],
                          count: 0,
                          stores: [ ]
                      },
                      "838_832": {
                          from: 838,
                          to: 832,
                          name: "AwLab - Stradivarius",
                          path: [{lon: 16.9506124344984, lat: 41.083122286107226}, {lon: 16.946661874877243, lat: 41.082978030325705}],
                          count: 0,
                          stores: [ ]
                      },

                      "828_829": {
                          from: 828,
                          to: 829,
                          name: "OVS - PullAndBear",
                          path: [{lon: 16.947491436782407, lat: 41.083374996265675}, {lon: 16.947336276240595, lat: 41.08287605819852}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.OVS,
                              allStores_SvicomBariblu.Chicco,
                              allStores_SvicomBariblu.LaMaisonSofa,
                              allStores_SvicomBariblu.Satur
                          ]
                      },
                      "829_828": {
                          from: 829,
                          to: 828,
                          name: "PullAndBear - OVS",
                          path: [{lon: 16.947315763807964, lat: 41.08291431726423}, {lon: 16.94745132614282, lat: 41.083343477682824}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.OVS,
                              allStores_SvicomBariblu.Chicco,
                              allStores_SvicomBariblu.LaMaisonSofa,
                              allStores_SvicomBariblu.Satur
                          ]
                      },
                      "829_830": {
                          from: 829,
                          to: 830,
                          name: "PullAndBear - Zara",
                          path: [{lon: 16.947336276240595, lat: 41.08287605819852}, {lon: 16.94694256866967, lat: 41.08246111120295}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.PullAndBear,
                              allStores_SvicomBariblu.Eminflex,
                              allStores_SvicomBariblu.Zara,
                              allStores_SvicomBariblu.KiaCafe,
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.Kiko
                          ]
                      },
                      "830_829": {
                          from: 830,
                          to: 829,
                          name: "Zara - PullAndBear",
                          path: [{lon: 16.946883559175273, lat: 41.082514005437794}, {lon: 16.947315763807964, lat: 41.08291431726423}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.PullAndBear,
                              allStores_SvicomBariblu.Eminflex,
                              allStores_SvicomBariblu.Zara,
                              allStores_SvicomBariblu.KiaCafe,
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.Kiko
                          ]
                      },
                      "830_831": {
                          from: 830,
                          to: 831,
                          name: "Zara - Cisalfa",
                          path: [{lon: 16.94694256866967, lat: 41.08246111120295}, {lon: 16.94641148326842, lat: 41.082658262231995}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.NunaLie,
                              allStores_SvicomBariblu.IdeaBellezza,
                              allStores_SvicomBariblu.Findomestic,
                              allStores_SvicomBariblu.Cisalfa,
                              allStores_SvicomBariblu.LoByLovable,
                              allStores_SvicomBariblu.MusicSchool
                          ]
                      },
                      "831_830": {
                          from: 831,
                          to: 830,
                          name: "Cisalfa - Zara",
                          path: [{lon: 16.946502389777415, lat: 41.08269432638005}, {lon: 16.946883559175273, lat: 41.082514005437794}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.NunaLie,
                              allStores_SvicomBariblu.IdeaBellezza,
                              allStores_SvicomBariblu.Findomestic,
                              allStores_SvicomBariblu.Cisalfa,
                              allStores_SvicomBariblu.LoByLovable,
                              allStores_SvicomBariblu.MusicSchool
                          ]
                      },
                      "831_832": {
                          from: 831,
                          to: 832,
                          name: "Cisalfa - Stradivarius",
                          path: [{lon: 16.94641148326842, lat: 41.082658262231995}, {lon: 16.9466124344984,lat: 41.083122286107226}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.LoByLovable,
                              allStores_SvicomBariblu.FiorellaRubino,
                              allStores_SvicomBariblu.LowDesign,
                              allStores_SvicomBariblu.Tata,
                              allStores_SvicomBariblu.Oltre,
                              allStores_SvicomBariblu.NunaLie,
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.Intimissimi,
                              allStores_SvicomBariblu.Calzedonia,
                              allStores_SvicomBariblu.Stradivarius
                          ]
                      },
                      "832_831": {
                          from: 832,
                          to: 831,
                          name: "Stradivarius - Cisalfa",
                          path: [{lon: 16.946661874877243, lat: 41.082978030325705}, {lon: 16.946502389777415, lat: 41.08269432638005}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.LoByLovable,
                              allStores_SvicomBariblu.FiorellaRubino,
                              allStores_SvicomBariblu.LowDesign,
                              allStores_SvicomBariblu.Tata,
                              allStores_SvicomBariblu.Oltre,
                              allStores_SvicomBariblu.NunaLie,
                              allStores_SvicomBariblu.Revolution,
                              allStores_SvicomBariblu.Intimissimi,
                              allStores_SvicomBariblu.Calzedonia,
                              allStores_SvicomBariblu.Stradivarius
                          ]
                      },
                      "832_833": {
                          from: 832,
                          to: 833,
                          name: "Stradivarius - Deichmann",
                          path: [{lon: 16.9466124344984, lat: 41.083122286107226}, {lon: 16.94694097380642, lat: 41.08351778573588}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Stradivarius,
                              allStores_SvicomBariblu.Motivi,
                              allStores_SvicomBariblu.Wind,
                              allStores_SvicomBariblu.ObbiettiviModa,
                              allStores_SvicomBariblu.GoldenPoint,
                              allStores_SvicomBariblu.Stroili,
                              allStores_SvicomBariblu.Tezenis,
                              allStores_SvicomBariblu.OriginalMarines,
                              allStores_SvicomBariblu.Bata
                          ]
                      },
                      "833_832": {
                          from: 833,
                          to: 832,
                          name: "Deichmann - Stradivarius",
                          path: [{lon: 16.946972870830944, lat: 41.08347931778968}, {lon: 16.946661874877243, lat: 41.082978030325705}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Stradivarius,
                              allStores_SvicomBariblu.Motivi,
                              allStores_SvicomBariblu.Wind,
                              allStores_SvicomBariblu.ObbiettiviModa,
                              allStores_SvicomBariblu.GoldenPoint,
                              allStores_SvicomBariblu.Stroili,
                              allStores_SvicomBariblu.Tezenis,
                              allStores_SvicomBariblu.OriginalMarines,
                              allStores_SvicomBariblu.Bata
                          ]
                      },
                      "833_828": {
                          from: 833,
                          to: 828,
                          name: "Deichmann - OVS",
                          path: [{lon: 16.94694097380642, lat: 41.08351778573588}, {lon: 16.947491436782407, lat: 41.083374996265675}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Pagi,
                              allStores_SvicomBariblu.GoldenPoint,
                              allStores_SvicomBariblu.Deichmann,
                              allStores_SvicomBariblu.JDSport,
                              allStores_SvicomBariblu.ObbiettiviModa,
                              allStores_SvicomBariblu.OVS
                          ]
                      },
                      "828_833": {
                          from: 828,
                          to: 833,
                          name: "OVS - Deichmann",
                          path: [{lon: 16.947491436782407, lat: 41.083374996265675}, {lon: 16.946972870830944, lat: 41.08347931778968}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Pagi,
                              allStores_SvicomBariblu.GoldenPoint,
                              allStores_SvicomBariblu.Deichmann,
                              allStores_SvicomBariblu.JDSport,
                              allStores_SvicomBariblu.ObbiettiviModa,
                              allStores_SvicomBariblu.OVS
                          ]
                      }, */
                      ///
                      ///
                      ///
                      ///
                      ///
                      ///
                      /// PP
                      ///
                      ///
                      ///
                      ///
                      ///
                      ///
                      "834_835": {
                          from: 834,
                          to: 835,
                          name: "Azzaro - Expert",
                          path: [{lon: 16.947491436782407, lat: 41.083374996265675}, {lon: 16.947106276240595, lat: 41.08281812601343}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Azzaro,
                              allStores_SvicomBariblu.DentalPro,
                              allStores_SvicomBariblu.MondoCamerette,
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Expert,
                              allStores_SvicomBariblu.SarniOro,
                              allStores_SvicomBariblu.Biosan,
                              allStores_SvicomBariblu.GiorgiaAndJohns,
                              allStores_SvicomBariblu.Equivalenza,
                              allStores_SvicomBariblu.SartoriePlanet,
                              allStores_SvicomBariblu.OpticalBariblu
                          ]
                      },
                      "835_834": {
                          from: 835,
                          to: 834,
                          name: "Expert - Azzaro",
                          path: [{lon: 16.947138276240595, lat: 41.08296029601343}, {lon: 16.947491436782407, lat: 41.083374996265675}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Azzaro,
                              allStores_SvicomBariblu.DentalPro,
                              allStores_SvicomBariblu.MondoCamerette,
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Expert,
                              allStores_SvicomBariblu.SarniOro,
                              allStores_SvicomBariblu.Biosan,
                              allStores_SvicomBariblu.GiorgiaAndJohns,
                              allStores_SvicomBariblu.Equivalenza,
                              allStores_SvicomBariblu.SartoriePlanet,
                              allStores_SvicomBariblu.OpticalBariblu
                          ]
                      },
                      "835_836": {
                          from: 835,
                          to: 836,
                          name: "Expert - NewYorker",
                          path: [{lon: 16.947106276240595, lat: 41.08281812601343}, {lon: 16.94694256866967, lat: 41.08246111120295}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Expert,
                              allStores_SvicomBariblu.HappyCasaStore,
                              allStores_SvicomBariblu.NewYorker,
                              allStores_SvicomBariblu.MariannaBattista,
                              allStores_SvicomBariblu.GameStop,
                              allStores_SvicomBariblu.CottonAndSilk,
                              allStores_SvicomBariblu.JeanLouisDavid,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CigaretteExpress
                          ]
                      },
                      "836_835": {
                          from: 836,
                          to: 835,
                          name: "NewYorker - Expert",
                          path: [{lon: 16.94692056866967, lat: 41.08254111120295}, {lon: 16.947138276240595, lat: 41.08296029601343}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Expert,
                              allStores_SvicomBariblu.HappyCasaStore,
                              allStores_SvicomBariblu.NewYorker,
                              allStores_SvicomBariblu.MariannaBattista,
                              allStores_SvicomBariblu.GameStop,
                              allStores_SvicomBariblu.CottonAndSilk,
                              allStores_SvicomBariblu.JeanLouisDavid,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CigaretteExpress
                          ]
                      },
                      "836_837": {
                          from: 836,
                          to: 837,
                          name: "NewYorker - McDonalds",
                          path: [{lon: 16.94694256866967, lat: 41.08246111120295}, {lon: 16.94641148326842, lat: 41.082658262231995}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.HappyCasaStore,
                              allStores_SvicomBariblu.NewYorker,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CigaretteExpress,
                              allStores_SvicomBariblu.GrandVision,
                              allStores_SvicomBariblu.Yoyogurt,
                              allStores_SvicomBariblu.Primadonna,
                              allStores_SvicomBariblu.SangioCaffe,
                              allStores_SvicomBariblu.DaGiovanni
                          ]
                      },
                      "837_836": {
                          from: 837,
                          to: 836,
                          name: "McDonalds - NewYorker",
                          path: [{lon: 16.94653148326842, lat: 41.082681462231995}, {lon: 16.94692056866967, lat: 41.08254111120295}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.HappyCasaStore,
                              allStores_SvicomBariblu.NewYorker,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CigaretteExpress,
                              allStores_SvicomBariblu.GrandVision,
                              allStores_SvicomBariblu.Yoyogurt,
                              allStores_SvicomBariblu.Primadonna,
                              allStores_SvicomBariblu.SangioCaffe,
                              allStores_SvicomBariblu.DaGiovanni
                          ]
                      },
                      "837_838": {
                          from: 837,
                          to: 838,
                          name: "McDonalds - AwLab",
                          path: [{lon: 16.94641148326842, lat: 41.082658262231995}, {lon: 16.9466124344984,lat: 41.083122286107226}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.McDonalds,
                              allStores_SvicomBariblu.SangioBistrot,
                              allStores_SvicomBariblu.Yoyogurt,
                              allStores_SvicomBariblu.CasolareDiPuglia,
                              allStores_SvicomBariblu.SangioCaffe,
                              allStores_SvicomBariblu.DaGiovanni,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CottonAndSilk,
                              allStores_SvicomBariblu.FrancoGioielli,
                              allStores_SvicomBariblu.Indi,
                              allStores_SvicomBariblu.AwLab,
                              allStores_SvicomBariblu.Primadonna,
                              allStores_SvicomBariblu.Adidas,
                              allStores_SvicomBariblu.BlackSheep,
                              allStores_SvicomBariblu.Valerio1966,
                              allStores_SvicomBariblu.Live978,
                              allStores_SvicomBariblu.GiuntiAlPunto
                          ]
                      },
                      "838_837": {
                          from: 838,
                          to: 837,
                          name: "AwLab - McDonalds",
                          path: [{lon: 16.9468124344984, lat: 41.083122286107226}, {lon: 16.94653148326842, lat: 41.082681462231995}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.McDonalds,
                              allStores_SvicomBariblu.SangioBistrot,
                              allStores_SvicomBariblu.Yoyogurt,
                              allStores_SvicomBariblu.CasolareDiPuglia,
                              allStores_SvicomBariblu.SangioCaffe,
                              allStores_SvicomBariblu.DaGiovanni,
                              allStores_SvicomBariblu.RevolutionKids,
                              allStores_SvicomBariblu.CottonAndSilk,
                              allStores_SvicomBariblu.FrancoGioielli,
                              allStores_SvicomBariblu.Indi,
                              allStores_SvicomBariblu.AwLab,
                              allStores_SvicomBariblu.Primadonna,
                              allStores_SvicomBariblu.Adidas,
                              allStores_SvicomBariblu.BlackSheep,
                              allStores_SvicomBariblu.Valerio1966,
                              allStores_SvicomBariblu.Live978,
                              allStores_SvicomBariblu.GiuntiAlPunto
                          ]
                      },
                      "838_839": {
                          from: 838,
                          to: 839,
                          name: "AwLab - HM",
                          path: [{lon: 16.9466124344984, lat: 41.083122286107226}, {lon: 16.94694097380642, lat: 41.08351778573588}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.SensoUnico,
                              allStores_SvicomBariblu.YankeeCandle,
                              allStores_SvicomBariblu.Thun,
                              allStores_SvicomBariblu.Sorbino,
                              allStores_SvicomBariblu.LaCasaDeLasCarcas,
                              allStores_SvicomBariblu.HM,
                              allStores_SvicomBariblu.Tiger,
                              allStores_SvicomBariblu.Alcott,
                              allStores_SvicomBariblu.GiuntiAlPunto
                          ]
                      },
                      "839_838": {
                          from: 839,
                          to: 838,
                          name: "HM - AwLab",
                          path: [{lon: 16.94698097380642, lat: 41.08338778573588}, {lon: 16.9468124344984, lat: 41.083122286107226}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.SensoUnico,
                              allStores_SvicomBariblu.YankeeCandle,
                              allStores_SvicomBariblu.Thun,
                              allStores_SvicomBariblu.Sorbino,
                              allStores_SvicomBariblu.LaCasaDeLasCarcas,
                              allStores_SvicomBariblu.HM,
                              allStores_SvicomBariblu.Tiger,
                              allStores_SvicomBariblu.Alcott,
                              allStores_SvicomBariblu.GiuntiAlPunto
                          ]
                      },
                      "839_834": {
                          from: 839,
                          to: 834,
                          name: "HM - Azzaro",
                          path: [{lon: 16.94694097380642, lat: 41.08351778573588}, {lon: 16.947491436782407, lat: 41.083374996265675}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Tiger,
                              allStores_SvicomBariblu.HM,
                              allStores_SvicomBariblu.LaCasaDeLasCarcas,
                              allStores_SvicomBariblu.Sorbino,
                              allStores_SvicomBariblu.SarniOro,
                              allStores_SvicomBariblu.Douglas,
                              allStores_SvicomBariblu.MondoCamerette,
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Azzaro
                          ]
                      },
                      "834_839": {
                          from: 834,
                          to: 839,
                          name: "Azzaro - HM",
                          path: [{lon: 16.947491436782407, lat: 41.083374996265675}, {lon: 16.94698097380642, lat: 41.08338778573588}],
                          count: 0,
                          stores: [
                              allStores_SvicomBariblu.Tiger,
                              allStores_SvicomBariblu.HM,
                              allStores_SvicomBariblu.LaCasaDeLasCarcas,
                              allStores_SvicomBariblu.Sorbino,
                              allStores_SvicomBariblu.SarniOro,
                              allStores_SvicomBariblu.Douglas,
                              allStores_SvicomBariblu.MondoCamerette,
                              allStores_SvicomBariblu.Carrefour,
                              allStores_SvicomBariblu.Azzaro
                          ]
                      },
                  },
                  graph: {
                      /* 828: {829: 1, 833: 1},
                      829: {828: 1, 830: 1},
                      830: {829: 1, 831: 1},
                      831: {830: 1, 832: 1},
                      832: {831: 1, 833: 1},
                      833: {832: 1, 828: 1}, */
                      834: {839: 1, 835: 1},
                      835: {834: 1, 836: 1},
                      836: {835: 1, 837: 1},
                      837: {836: 1, 838: 1},
                      838: {837: 1, 839: 1},
                      839: {838: 1, 834: 1}
                  },
                  maxTraffic: 2000,
                  redThreshold: 0.9,
                  yellowThreshold: 0.5,
                  floorMapImage: "/assets/images/backgrounds/bariblu2.png",
                  rot: -84.0202295135814294,
                  dDiff: -0.0009355067670204274, // riproporzione mappa allarga l'immagine
                  kDiff: -0.00033433774903491803, // riproporzione mappa alza l'immagine
                  heading: 197, // grandezza mappa
                  center: {lat: 41.08304534973345, lon: 16.946901102546082}
              },
          },


          filter: this.filter
      };

      const dashboardCfg = DashboardConfig.of(
          {
              id: "NextOneAccessBreakdown",
              name: "Next One",
              dashboardPersistency: DashboardConsolePersistency.of(),
              specificDashboardConfig: {
                  gridsterConfig: new GridsterDashboardConfig()
              },
              initFilter: this.filter.filterValues
          });

      this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

      this.dashboard.load()
          .pipe(map((hasLoaded) => {
              if (hasLoaded) {
                  this.dashboard.reloadData(this.filter.filterValues, context);
                  this.showDashboard = true;
                  console.log(this);

                  this.filterChangedSubscription = this.filter.filterChangedObservable()
                      .subscribe((newFilterValue) => {
                          this.dashboard.reloadData(newFilterValue, context);
                          this.showDashboard = true;
                      });
              }
          }))
          .subscribe();
    }

}

export class InStorePathDataSource implements DataSourceConfigInterface {
    public static _name = "InStorePathDataSource";

    constructor(
        public http: HttpClient,
        private defaultLocationFilter: (el: any) => boolean) {
    }

    name = InStorePathDataSource._name;
    type = "elastic";
    assembler = DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler");
    repo = {
        getName: () => "InStorePathDataSource",
        getChannelType: () => ChannelType.CLOSEABLE,
        invoke: (thiz: DataSourceInterface, filter0: FilterInterface, context: any): Observable<any> => {
            const filter = _.cloneDeep(filter0);
            const adminEntityId = context.filter.filterValues.administrativeEntityFilter.selected.administrativeEntityDetails.id; // 664;
            const geopoints = _.cloneDeep(context.tempAdminEntityData[adminEntityId].geopoints);

            const repo = new IottacleMultipleElasticGenericRepo(this.http);
            filter.queries[0].query.bool.must[0].match_phrase.adminEntityId = adminEntityId;
            let locationIds;
            if ((thiz as any).locationIds) {
                locationIds = (thiz as any).locationIds;
            }else if (this.defaultLocationFilter){
                locationIds = Object.values(geopoints).filter(this.defaultLocationFilter).map((el: any) => el.locationId);
            }else{
                locationIds = Object.values(geopoints).map((el: any) => el.locationId);
            }
            (thiz as any).computedLocationIds = locationIds;

            const should = [];
            for (const location of locationIds) {
                should.push({
                    match_phrase: {
                        label: location
                    }
                });
            }
            filter.queries[0].query.bool.must.push({
                bool: {
                    should: should
                }
            });

            return repo.invoke(thiz, filter);

        },
        rehydrate: (serializedAssembler: string): CloseableRepositoryInterface => { // before CloseableRepositoryInterface
            return this.repo;
        },
        serialize: (): string => {
            return JSON.stringify({
                name: "InStorePathRepo"
            });
        }
    };
    filter = new MultipleFilterBuilderGenericElastic().rehydrate(JSON.stringify({
        customData: {
            timeStrategy: {
                timestampField: 'tm',
                strategy: FilterVisitTimeFilterStrategies.SAME_PERIOD
            },
            locationReference: Utils.locationReference_fromFilterAdmins,
            // visitStrategyFilter : Utils.visitStrategyFilter_InternalVisits,
            // xAxis: {
            //     "timeserie": {
            //         "date_histogram": {
            //             "field": "start",
            //             "interval": "1d",
            //             "time_zone": "Europe/Rome",
            //             "min_doc_count": 0
            //         }
            //     }
            // },
            // splitSeries:[
            //     {
            //         "by-store": {
            //             "terms":{
            //                 "field" : "adminEntityId",
            //                 "size": 10
            //             }
            //         }
            //     }
            // ],
            // metrics:[
            //     {
            //         "count": {
            //             "count": {
            //                 "field": "foo"
            //             }
            //         }
            //     }
            // ],
            query: {
                bool: {
                    must: [
                        {
                            match_phrase: {
                                phenomenon: "in-store-areas-paths"
                            }
                        },
                        {
                            match_phrase: {
                                "timeframe": "daily"
                            }
                        }
                    ]
                }
            },
            size: 10000,
            index: "all-data",
            returnComplete: true
        }
    }));

    specificDataSourceConfig = {};

    serialize(): string {
        return JSON.stringify({
            name: InStorePathDataSource._name
        });
    }

    public rehydrate(cfg: any): DataSourceConfigInterface {
        return new InStorePathDataSource(this.http, this.defaultLocationFilter);
    }

}


export class InStoreTrafficDataSource implements DataSourceConfigInterface {
    public static _name = "InStoreTrafficDataSource";

    constructor(public http: HttpClient) {
    }

    name = InStoreTrafficDataSource._name;
    type = "elastic";
    assembler: DataSourceAssemblerInterface = new DataSourceAssemblerChain([
        new ElasticToIottacleDataSourceAssembler()
    ]);

    repo = {
        getName: () => "InStoreTrafficDataSource",
        getChannelType: () => ChannelType.CLOSEABLE,
        invoke: (thiz: DataSourceInterface, filter0: FilterInterface, context: any): Observable<any> => {
            const filter = _.cloneDeep(filter0);
            const adminEntityId = context.filter.filterValues.administrativeEntityFilter.selected.administrativeEntityDetails.id; // 664;
            filter.queries[0].query.bool.must[0].match_phrase.adminEntityId = adminEntityId;

            const from = (thiz as any).locationIdFrom;
            const to = (thiz as any).locationIdTo;

            if (from && to){
                filter.queries[0].query.bool.must.push(
                    {
                        bool: {
                            should: [
                                {
                                    bool: {
                                        must: [
                                            {
                                                match_phrase: {
                                                    from: from
                                                }
                                            },
                                            {
                                                match_phrase: {
                                                    to: to
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    bool: {
                                        must: [
                                            {
                                                match_phrase: {
                                                    from: to
                                                }
                                            },
                                            {
                                                match_phrase: {
                                                    to: from
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    });
            }

            const r = new IottacleMultipleElasticGenericRepo(this.http);
            return r.invoke(thiz, filter);
        },
        rehydrate: (serializedAssembler: string): CloseableRepositoryInterface => { // before CloseableRepositoryInterface
            return this.repo;
        },
        serialize: (): string => {
            return JSON.stringify({
                name: "foo"
            });
        }
    };
    filter = new MultipleFilterBuilderGenericElastic().rehydrate(JSON.stringify({
        customData: {
            timeStrategy: {
                timestampField: 'tm',
                strategy: FilterVisitTimeFilterStrategies.SAME_PERIOD
            },
            locationReference: Utils.locationReference_fromFilterAdmins,
            // visitStrategyFilter : Utils.visitStrategyFilter_InternalVisits,
            xAxis: {
                "timeserie": {
                    "date_histogram": {
                        "field": "tm",
                        "interval": "1d",
                        "time_zone": "Europe/Rome",
                        "min_doc_count": 0
                    }
                }
            },
            // splitSeries:[
            //     {
            //         "by-store": {
            //             "terms":{
            //                 "field" : "adminEntityId",
            //                 "size": 10
            //             }
            //         }
            //     }
            // ],
            metrics: [
                {
                    "sum": {
                        "sum": {
                            "field": "count"
                        }
                    }
                }
            ],
            query: {
                bool: {
                    must: [
                        {
                            match_phrase: {
                                phenomenon: "in-store-areas-transition"
                            }
                        },
                        {
                            match_phrase: {
                                "timeframe": "daily"
                            }
                        }
                    ]
                }
            },
            size: 10000,
            index: "all-data",
            returnComplete: true
        }
    }));

    specificDataSourceConfig = {};

    serialize(): string {
        return JSON.stringify({
            name: InStoreTrafficDataSource._name
        });
    }

    public rehydrate(cfg: any): DataSourceConfigInterface {
        return new InStoreTrafficDataSource(this.http);
    }

    findShortestPath(graph, startNode, endNode): {
        distance: any,
        path: any[]
    } {
        // establish object for recording distances from the start node
        let distances = {};
        distances[endNode] = "Infinity";
        distances = Object.assign(distances, graph[startNode]);

        // track paths
        const parents = {endNode: null};
        // tslint:disable-next-line:forin
        for (const child in graph[startNode]) {
            parents[child] = startNode;
        }

        // track nodes that have already been visited
        const visited = [];

        // find the nearest node
        let node = this.shortestDistanceNode(distances, visited);

        // for that node
        while (node) {
            // find its distance from the start node & its child nodes
            const distance = distances[node];
            const children = graph[node];
            // for each of those child nodes
            for (const child in children) {
                // make sure each child node is not the start node
                if (String(child) === String(startNode)) {
                    // console.log("don't return to the start node! 🙅");
                    continue;
                } else {
                    // console.log("startNode: " + startNode);
                    // console.log("distance from node " + parents[node] + " to node " + node + ")");
                    // console.log("previous distance: " + distances[node]);
                    // save the distance from the start node to the child node
                    const newdistance = distance + children[child];
                    // console.log("new distance: " + newdistance);
                    // if there's no recorded distance from the start node to the child node in the distances object
                    // or if the recorded distance is shorter than the previously stored distance from the start node to the child node
                    // save the distance to the object
                    // record the path
                    if (!distances[child] || distances[child] > newdistance) {
                        distances[child] = newdistance;
                        parents[child] = node;
                        // console.log("distance + parents updated");
                    } else {
                        // console.log("not updating, because a shorter path already exists!");
                    }
                }
            }
            // move the node to the visited set
            visited.push(node);
            // move to the nearest neighbor node
            node = this.shortestDistanceNode(distances, visited);
        }

        // using the stored paths from start node to end node
        // record the shortest path
        const shortestPath = [endNode];
        let parent = parents[endNode];
        while (parent) {
            shortestPath.push(parent);
            parent = parents[parent];
        }
        shortestPath.reverse();

        // return the shortest path from start node to end node & its distance
        const results = {
            distance: distances[endNode],
            path: shortestPath,
        };

        return results;
    }

    shortestDistanceNode(distances, visited): any {
        let shortest = null;

        // tslint:disable-next-line:forin
        for (const node in distances) {
            const currentIsShortest =
                shortest === null || distances[node] < distances[shortest];
            if (currentIsShortest && !visited.includes(node)) {
                shortest = node;
            }
        }
        return shortest;
    }


}


