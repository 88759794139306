import {Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription} from "rxjs";

import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DataSource,
  DataSourceConfig
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardConsolePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import {
  AuthService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {
  Filter, MyAdministrativeEntity
} from "iottacle-ts-models";
import {
  DashboardItemFactoryChart
} from "../next-one-sky-insights-00/utils";
import {
  EventDataSource
} from "../next-one-detail/event-data-source";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {
  DashboardItemFactory,
  DashboardItemCatalog,
  Utils
}  from "dashboard-items-catalog";

@Component({
  selector: 'next-one-opportunity',
  templateUrl: './next-one-opportunity.component.html',
  styleUrls: ['./next-one-opportunity.component.scss']
})
export class NextOneOpportunityComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private translateService:TranslateService,
    private http:HttpClient
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {}, this.translateService);
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {
    let dashboardItems:DashboardItem[] = [];
    //RepositoryCatalog.init(this.http);

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let eventDataSource = new EventDataSource(this.http, this.authService);


    //OPPORTUNITY
    let opportunityTitleId = "acd623f7-9ce8-495f-8c28-c5aa5de006d3";
    let opportunityTitleItem =  DashboardItemFactory.createTitleItem({
      id : opportunityTitleId,
      _repoText : this.translateService.instant('widgets.htmlText.' + opportunityTitleId + '.repoText'), //# Opportunity
      specificItemConfig : {
        gridsterConfig : {x:0, y:0, cols:36, rows:3}
      }
    }, this.translateService);
    dashboardItems.push(opportunityTitleItem);

    let readOnly = true;

    let opportunityRateOverTimeItem = DashboardItemCatalog.lineChart_opportunityRateOverTimeItem(readOnly,this.translateService, this.http, {x:0, y:2, cols:36, rows:12}, DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService))), DashboardItemFactoryChart.createXYChartItem({xSort:'asc',series : {ratio : {type: "line"}},
    export:{
      excel:true,
      csv:true,
      image:true
  }}));
    dashboardItems.push(opportunityRateOverTimeItem);

    let opportunityRateOverDowItem = DashboardItemCatalog.barChart_opportunityRateOverDowItem(readOnly, this.translateService, this.http, {x:0, y:(46-38), cols:18, rows:13}, DashboardItemFactoryChart.createXYChartItem({
      legend:{
        showLegendIntoAccordion:true,
      },
      export:{
        excel:true,
        csv:true,
        image:true
    }
    }))
    dashboardItems.push(opportunityRateOverDowItem);

    // Opportunity rate over hod
    let gridster_opportunityRateOverHodItem = {x:18, y:(46-38), cols:18, rows:13};
    let opportunityRateOverHodItem = DashboardItemCatalog.barChart_opportunityRateOverHodItem(readOnly, this.translateService, this.http, gridster_opportunityRateOverHodItem, DashboardItemFactoryChart.createXYChartItem({
      legend:{
        showLegendIntoAccordion:true,
      },
      periodicVerticalLine:{
        period: "auto"
      },
      export:{
      excel:true,
      csv:true,
      image:true
  }}))
    dashboardItems.push(opportunityRateOverHodItem);

    let externalCountOverTimeSamePeriodItem = DashboardItemCatalog.barChart_externalCountOverTimeSamePeriodItem(readOnly, this.translateService, this.http, {x:0, y:18, cols:36, rows:12}, DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService))), DashboardItemFactoryChart.createXYChartItem({export:{
      excel:true,
      csv:true,
      image:true
  }}))
    dashboardItems.push(externalCountOverTimeSamePeriodItem);

    // @ts-ignore
    let adminEntities:MyAdministrativeEntity[] = this.authService.getCurrentLoggedUser().getMyOrganizations().flatMap(org => org.getMyAdministrativeEntities());

    let context = {
      adminEntities: adminEntities,
      fieldValueLookup: {
        adminEntityId : {
          lookup:(value:any) => {
            let adminEntity = adminEntities.find((el) => el.administrativeEntityDetails.id == value);
            return adminEntity ? adminEntity.administrativeEntityDetails.name : value;
          }
        },
        newret:Utils.fieldValueLookup.newret,
        startDow:Utils.fieldValueLookup.startDow(this.translateService),
        startSod:Utils.fieldValueLookup.startSod,
      },
      translateService: this.translateService
    };

    let dashboardCfg = DashboardConfig.of(
      {
        id: "NextOneOpportunity",
        name: "Next One",
        dashboardPersistency : DashboardConsolePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        },
        initFilter : this.filter.filterValues
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData(this.filter.filterValues, context);
          this.showDashboard = true;
          this.filterChangedSubscription = this.filter.filterChangedObservable()
            .subscribe((newFilterValue) => {
              this.dashboard.reloadData(newFilterValue, context);
              this.showDashboard = true;
            });
        }
      });

  }


}


