import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {GridsterModule} from "angular-gridster2";

import {
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatOptionModule,
  MatListModule,
  MatProgressBarModule,
  MatPaginatorModule,
  MatCardModule,
  MatDialogModule,
  MatInputModule,
  MatSlideToggleModule
} from '@angular/material';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { AgmCoreModule } from '@agm/core';
import {DeviceBeaconListComponent} from "./list/device-beacon-list.component";
import {FilterModule} from "../../../filter/filter.module";
import {AngularDashboardModule} from "dashboard-fe-angular";
import {
  AuthGuardService,
  FuseSharedModule,
  FuseSidebarModule,
  FuseWidgetModule,
  IottacleNavigationService
} from "core-fe-angular";




const routes = [
  {
    path     : 'device',
    canActivate: [AuthGuardService],
    children : [
      {
        path     : 'beacon',
        canActivate: [AuthGuardService],
        children : [
          {
            path     : 'list',
            component: DeviceBeaconListComponent,
            canActivate: [AuthGuardService]
          }
        ]
      }
    ]
  }
];

@NgModule({
  providers : [

  ],
  imports     : [
    CommonModule,
    FuseSharedModule,
    FuseSidebarModule,
    RouterModule.forChild(routes),
    FilterModule,
    GridsterModule,
    AngularDashboardModule,

    MatProgressBarModule,
    MatPaginatorModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatSlideToggleModule,

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    FuseWidgetModule,
    NgxChartsModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBvZWorK4nmcjUJ1lP6HooJyGsCjYYyqn0'
    })
  ],
  declarations: [
    DeviceBeaconListComponent
  ]
})
export class DeviceBeaconModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService
  ){

    //instruct navigation
    this._fuseNavigationService.addNavigationItem({
      'id'       : 'device',
      'title'    : 'Device',
      'translate': 'nav.device.group-name',
      'type'     : 'group',
      'children' : [
        {
          'id'       : 'device.beacon',
          'title'    : 'Beacon',
          'translate': 'nav.device.beacon.group-name',
          'type'     : 'collapsable',
          'icon'     : 'store',
          'children' : [
            {
              'id'       : 'device.beacon.list',
              'title'    : 'List',
              'translate': 'nav.device.beacon.list',
              'type'     : 'item',
              'icon'     : 'location_on',
              'url'  : 'entity/Device/Beacon/list'
            }
            ]
        }
      ]
    }, "end");
  }
}
