import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";

import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DashboardItemConfig, DashboardItemViewGeneric,
  DashboardItemViewMetricDelta, DashboardItemViewSerialChart,
  DataResultInterface, DataSource,
  DataSourceAssemblerFactory,
  DataSourceAssemblerInterface,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory, SerialChartConfig, ViewAssemblerFactory
} from "iottacle-dashboard";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardConsolePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import {
  AuthService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {TranslateService} from "@ngx-translate/core";
import {
  Filter,
  FilterInterface,
  FilterLocationReferencesStrategy,
  FilterQuickValues, FilterVisitJunkData, FilterVisitNewret, FilterVisitStrategy,
  FilterVisitTimeFilterStrategies, MyAdministrativeEntity
} from "iottacle-ts-models";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import {FilterBuilderGenericNeo} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-neo";
import * as am4core from "@amcharts/amcharts4/core";
import _ from "lodash";
import _moment from 'moment';
import {StoreUtils} from "../store-utils";
// import {
//   FilterLocationReferences,
//   RemoveFilterElements
// } from "../next-one-detail/multiple-filter-builder-generic-elastic";
// import {
//   // ActualOccupancyDataSource,
//   //DashboardItemFactory,
//   // HourlyPerDayHeatmapDataSource, HourlyPerDayHeatmapDataSourceToday
// } from "../next-one-detail/utils";
import {HttpClient} from "@angular/common/http";

import {
  DashboardItemFactory,
  DashboardItemCatalog,
  IottacleMultipleElasticGenericRepo,
  HourlyPerDayHeatmapDataSource, HourlyPerDayHeatmapDataSourceToday,
  MultipleFilterBuilderGenericElastic,
  FilterLocationReferences,
  RemoveFilterElements,
  InternalCountPerDayFilter
} from "dashboard-items-catalog";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
import {am4themes_next14Theme} from "../next-one-sky-insights-00/utils";
import {GenericDataSource} from "../next-one-detail/iottacle-generic-data-source";
import {NextOneOverviewOccupancyAssembler} from "./next-one-overview-occupany-assembler";

const moment = _moment;

@Component({
  selector: 'next-one-overview',
  templateUrl: './next-one-overview.component.html',
  styleUrls: ['./next-one-overview.component.scss']
})
export class NextOneOverviewComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private translateService:TranslateService,
    private http:HttpClient
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    // @ts-ignore
    let adminEntities:MyAdministrativeEntity[] = this.authService.getCurrentLoggedUser().getMyOrganizations().flatMap(org => org.getMyAdministrativeEntities());

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {
      defaultFilterQuickValue : 'last-7-days',
      selectedAdminEntities: adminEntities
    }, this.translateService);
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {
    let user = this.authService.getCurrentLoggedUser();

    let dashboardItems:DashboardItem[] = [];

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();

    let readOnly = true;

    let styleWhite = StoreUtils.getWhiteBadgeStyle();

    //Stores Map
    // let storesMapItem = DashboardItemFactory.createMap({
    //   id : "eebad74a-d3e3-4278-bdda-df9f575eb96e",
    //   readOnly: readOnly,
    //   specificItemConfig : {
    //     height: '550px',
    //     fitBounds:{
    //       markers:true
    //     },
    //     gMapOptions:{
    //       mapTypeId: 'roadmap'
    //     },
    //     gridsterConfig : {x:0, y:0, cols:22, rows:12}
    //   },
    // }, this.translateService);
    let specificItemConfig = {
      height: '550px',
      fitBounds:{
        markers:true
      },
      gMapOptions:{
        mapTypeId: 'roadmap'
      },
      gridsterConfig : {x:0, y:0, cols:22, rows:12}
    }
    let storesMapItem = DashboardItemCatalog.mapChart_forOverviewPage(readOnly,this.translateService,specificItemConfig)
    dashboardItems.push(storesMapItem);

    //total Count over time
    //let countSamePeriodItem = DashboardItemCatalog.metricDelta_overallVisitCountForOverviewPage_v2({
    //  readOnly: readOnly,
    //  specificItemConfig:{
    //    gridsterConfig:{x:22, y:0, cols:14, rows:5}
    //  }
    //}, this.translateService, this.decimalPipe, this.http);
    let countSamePeriodItem = NextOneOverviewComponent.metricDelta_overallVisitCountForOverviewPage_TEMP(readOnly,this.translateService, this.decimalPipe, this.http,
      {x:22, y:0, cols:14, rows:5}, user);
    dashboardItems.push(countSamePeriodItem);

    //avg Duration over time
    //let durationSamePeriodItem = DashboardItemCatalog.metricDelta_averageRangeVisitDurationForOverviewPage_v2({
    //  readOnly: readOnly,
    //  specificItemConfig:{
    //    gridsterConfig:{x:22, y:5, cols:14, rows:5}
    //  }
    //}, this.translateService, this.http)
    let durationSamePeriodItem = NextOneOverviewComponent.metricDelta_averageRangeVisitDurationForOverviewPage_TEMP(readOnly,this.translateService, this.http,
      {x:22, y:5, cols:14, rows:5}, user);
    dashboardItems.push(durationSamePeriodItem);

  let currentDow = moment().day();
    currentDow = currentDow == 0 ? 7 : currentDow;

    //occupancy over HOD and DOW and actual occupancy
    /**new impl */
    let occupancyOverHodAndDowItem = NextOneOverviewComponent.barChart_occupancyOverHodAndDow_TEMP(
      readOnly, this.translateService, this.http,
      {x:0,y:12,cols:22,rows:7,dragEnabled : !readOnly,resizeEnabled: !readOnly},
      DashboardItemViewSerialChart.of(SerialChartConfig.of({
        createNewChart: (id:any, data:DataResultInterface<any>, dashboardItem:DashboardItem) => {
          let chart1:any = am4core.create(id, am4charts["XYChart"]);
          return chart1;
        },
        dataChangedHook : (chart:any, id:any, data:any, dashboardItem:DashboardItem) => {
          let res = data.getResult();
          am4core.useTheme(am4themes_animated);
          am4core.useTheme(am4themes_next14Theme);
          let chart2:any = am4core.create(id, am4charts["XYChart"]);
          chart2.language.locale = am4lang_it_IT;

          chart2.colors.list = [
            am4core.color('#b4b4b4'), //grey
            am4core.color('#67a964'), //green
            am4core.color('#EAEDF2'), //white
            am4core.color('#EAEDF2')
          ];

          let categoryAxis = chart2.xAxes.push(new am4charts.CategoryAxis());
          categoryAxis.dataFields.category = "hod";

          if (res.message) {
            let range = categoryAxis.axisRanges.create();
            range.category = res.currentHour;
            range.grid.strokeOpacity = 1;
            range.grid.stroke = res.messageColor;
            range.grid.strokeWidth = 2;
            range.grid.strokeOpacity = 1;
            range.label.inside = true;
            range.label.text = res.message;
            range.label.fill = res.messageColor;
            range.label.valign = "top";
            range.label.adapter.add("horizontalCenter", function () {
              if (res.currentHour < 15) {
                return "left";
              } else if (res.currentHour == 15) {
                return "middle";
              } else {
                return "right";
              }
            });
          }

          let valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
          valueAxis.hidden = true;
          valueAxis.title.text = this.translateService.instant('nav.store.one.overview.dashboardItems.occupancyOverHodAndDow.chart.yAxes.0.title.text');

          let averageCountSeries = chart2.series.push(new am4charts.ColumnSeries());
          averageCountSeries.dataFields.valueY = this.translateService.instant('common.dowById.' + currentDow);
          averageCountSeries.dataFields.categoryX = "hod";
          averageCountSeries.clustered = false;
          averageCountSeries.name = this.translateService.instant('common.dowById.' + currentDow);
          averageCountSeries.columns.template.column.cornerRadiusTopLeft = 5;
          averageCountSeries.columns.template.column.cornerRadiusTopRight = 5;


          let currentCountSeries = chart2.series.push(new am4charts.ColumnSeries());
          currentCountSeries.dataFields.valueY = "current";
          currentCountSeries.dataFields.categoryX = "hod";
          currentCountSeries.clustered = false;
          currentCountSeries.name = "Count";
          currentCountSeries.columns.template.column.cornerRadiusTopLeft = 3;
          currentCountSeries.columns.template.column.cornerRadiusTopRight = 3;
          currentCountSeries.columns.template.width = am4core.percent(50);
          currentCountSeries.columns.template.propertyFields.fill = "color";
          currentCountSeries.columns.template.propertyFields.stroke = "color";

          chart2.data = res.data;

          chart2.validateData();
          return chart2;
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")), user);
    dashboardItems.push(occupancyOverHodAndDowItem);


    // @ts-ignore
    let adminEntities:MyAdministrativeEntity[] = this.authService.getCurrentLoggedUser().getMyOrganizations().flatMap(org => org.getMyAdministrativeEntities());

    let context = {
      adminEntities: adminEntities,
      fieldValueLookup: {},
      translateService: this.translateService
    };

    let dashboardCfg = DashboardConfig.of(
      {
        id: "NextOneOverview",
        name: "Next One",
        dashboardPersistency : DashboardConsolePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        },
        initFilter : this.filter.filterValues
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData(this.filter.filterValues, context);
          this.showDashboard = true;
        }
      });

    this.filterChangedSubscription = this.filter.filterChangedObservable()
      .subscribe((newFilterValue) => {
        this.dashboard.reloadData(newFilterValue, context);
        this.showDashboard = true;
      });

  }

  public static metricDelta_overallVisitCountForOverviewPage_TEMP(readOnly:boolean, translateService:any, decimalPipe:any, http:any,
    gridsterConfig:any, user:any){

      let item = DashboardItemFactory.createMetricDeltaItem({
        id : "21ca3123-9097-401a-8e15-760e6b41a0c2",
        readOnly: readOnly,
        specificItemConfig : {"matIcon": "directions_walk", gridsterConfig : gridsterConfig},
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(http,"metricDelta_overallVisitCountForOverviewPage", user )))
         // "current" : DataSource.of(DataSourceConfig.of(new GenericDataSource(http,"metricDelta_overallVisitCountForOverviewPage", new InternalCountPerDayFilter(FilterBuilderFactory.idempotentFilterBuilder)) )),
          //"previous" : DataSource.of(DataSourceConfig.of(new GenericDataSource(http,"metricDelta_overallVisitCountForOverviewPage", new InternalCountPerDayFilterPreviousPeriod(FilterBuilderFactory.idempotentFilterBuilder))))
        },
        assembler:  DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
      }, translateService, decimalPipe);
      return item;
  }

  public static metricDelta_averageRangeVisitDurationForOverviewPage_TEMP(readOnly:boolean, translateService:any, http:any,
    gridsterConfig:any, user:any){

      let item = DashboardItemFactory.createTextMetricDeltaItem({
        id : "1b585688-8f7b-46a2-9fe0-7521b8e1f2d4",
        readOnly: readOnly,
        specificItemConfig : {"matIcon": "schedule", gridsterConfig : gridsterConfig},
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(http,"metricDelta_averageRangeVisitDurationForOverviewPage", user )))
        },
        assembler:  DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
      }, translateService);
      return item;
  }

  public static barChart_occupancyOverHodAndDow_TEMP(readOnly:boolean, translateService:any, http:any, gridsterConfig:any, chartConfig:any, user:any){
    let visitMultiplier = 1.3;
    let currentDow = moment().day();
    currentDow = currentDow == 0 ? 7 : currentDow;
    let currentDowString = translateService.instant('common.dowById.' + currentDow);

    let item = DashboardItemFactory.createXYChartItemOverview({
      id: "1722a1b9-b97b-435b-a224-3e80aba6d58d",
      readOnly: readOnly,
      specificItemConfig : {
        gridsterConfig : gridsterConfig //{x:0, y:18, cols:36, rows:10}
      },
      viewConfig: chartConfig,
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(http,"overview_occupancyOverHodAndDow", user )))
      },
      assembler:  DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
    }, translateService)
    return item;
  }
}
