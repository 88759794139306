import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {GridsterModule} from "angular-gridster2";
import {
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatOptionModule,
  MatListModule,
  MatExpansionModule
} from '@angular/material';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { AgmCoreModule } from '@agm/core';
import {FuseSharedModule,FuseSidebarModule, FuseWidgetModule,AuthGuardService,IottacleNavigationService, RepositoryCatalog} from "core-fe-angular";
import {StoreOverviewComponent} from "./overview/store-overview.component";
import {FilterModule} from "../../filter/filter.module";
import {AngularDashboardModule} from "dashboard-fe-angular";
import {StoreDetailsComponent} from "./detail/store-details.component";
import {StoreReturnRateComponent} from "./return-rate/store-return-rate.component";
import {StoreOpportunityComponent} from "./opportunity/store-opportunity.component";
import {StoreAccessesBreakdownComponent} from "./accesses-breakdown/store-accesses-breakdown.component";
import {StoreLiveComponent} from "./live/store-live.component";
import {NextOneComponent} from "./next-one/next-one.component";
import {NextOneAccessBreakdownComponent} from "./next-one-access-breakdown/next-one-access-breakdown.component";
import {NextOneDetailComponent} from "./next-one-detail/next-one-detail.component";
import {NextOneLoyaltyComponent} from "./next-one-loyalty/next-one-loyalty.component";
import {NextOneOpportunityComponent} from "./next-one-opportunity/next-one-opportunity.component";
import {NextOneOverviewComponent} from "./next-one-overview/next-one-overview.component";
import {NextOneCalendarComponent} from "./next-one-calendar/next-one-calendar.component";
import {NextOneProximityComponent} from "./next-one-proximity/next-one-proximity.component";


import { IottacleCalendarModule } from "calendar-fe-angular";
import {NextOneDurationComponent} from "./next-one-duration/next-one-duration.component";
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import {NextOneSkyInsights01Component} from "./next-one-sky-insights-01/next-one-sky-insights-01.component";
import { NextOneSkyInsights00Component } from './next-one-sky-insights-00/next-one-sky-insights-00.component';
import {MatInputModule} from "@angular/material/input";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NextOneAreasComponent} from "./next-one-areas/next-one-areas.component";


const routes = [
  {
    path     : 'store',
    canActivate: [AuthGuardService],
    children : [
      {
        path     : 'overview',
        component: StoreOverviewComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'live',
        component: StoreLiveComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'detail',
        component: StoreDetailsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'return-rate',
        component: StoreReturnRateComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'opportunity',
        component: StoreOpportunityComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'accesses-breakdown',
        component: StoreAccessesBreakdownComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one',
        component: NextOneComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one-access-breakdown',
        component: NextOneAccessBreakdownComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one-detail',
        component: NextOneDetailComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one-duration',
        component: NextOneDurationComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one-loyalty',
        component: NextOneLoyaltyComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one-opportunity',
        component: NextOneOpportunityComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one-overview',
        component: NextOneOverviewComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one-calendar',
        component: NextOneCalendarComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one-proximity',
        component: NextOneProximityComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one-sky-insights-00',
        component: NextOneSkyInsights00Component,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'next-one-areas',
        component: NextOneAreasComponent,
        canActivate: [AuthGuardService]
      },
    ]
  }
];

@NgModule({
  providers : [
  ],
  imports     : [
    CommonModule,
    FuseSharedModule,
    FuseSidebarModule,
    RouterModule.forChild(routes),
    FilterModule,
    GridsterModule,
    AngularDashboardModule,
    MatSelectModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    FlexLayoutModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    FuseWidgetModule,
    NgxChartsModule,
    MatExpansionModule,

    IottacleCalendarModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBvZWorK4nmcjUJ1lP6HooJyGsCjYYyqn0'
    })
  ],
  declarations: [
    StoreOverviewComponent,
    StoreDetailsComponent,
    StoreReturnRateComponent,
    StoreOpportunityComponent,
    StoreAccessesBreakdownComponent,
    StoreLiveComponent,
    NextOneComponent,
    NextOneAccessBreakdownComponent,
    NextOneDetailComponent,
    NextOneLoyaltyComponent,
    NextOneOpportunityComponent,
    NextOneOverviewComponent,
    NextOneCalendarComponent,
    NextOneDurationComponent,
    NextOneProximityComponent,
    NextOneSkyInsights00Component,
    NextOneSkyInsights01Component,
    NextOneAreasComponent
  ]
})
export class StoreModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService, private http:HttpClient
  ){

    RepositoryCatalog.init(this.http);

    //instruct navigation
    this._fuseNavigationService.addNavigationItem({
      'id'       : 'store',
      'title'    : 'Store',
      'translate': 'nav.store.group-name',
      'type'     : 'group',
      'children' : [
        {
          'id'       : 'store.overview',
          'title'    : 'Overview',
          'translate': 'nav.store.overview',
          'type'     : 'item',
          'icon'     : 'store',
          'url'  : '/store/overview'
        },
        {
          'id'       : 'store.detail',
          'title'    : 'Store Details',
          'translate': 'nav.store.detailGroup',
          'type'     : 'collapsable',
          'icon'     : 'location_on',
          'children' : [
            {
              'id'       : 'store.detail.live',
              'title'    : 'Detail',
              'translate': 'nav.store.detail.live',
              'type'     : 'item',
              'icon'     : 'location_on',
              'url'  : '/store/live'
            },
            {
              'id'       : 'store.detail.detail',
              'title'    : 'Detail',
              'translate': 'nav.store.detail.detail',
              'type'     : 'item',
              'icon'     : 'location_on',
              'url'  : '/store/detail'
            },
            {
              'id'       : 'store.detail.duration',
              'title'    : 'Duration',
              'translate': 'nav.store.detail.duration',
              'type'     : 'item',
              'icon'     : 'location_on',
              'url'  : '/store/duration'
            },
            {
              'id'       : 'store.detail.return-rate',
              'title'    : 'Loyalty',
              'translate': 'nav.store.detail.return-rate',
              'type'     : 'item',
              'icon'     : 'timeline',
              'url'  : '/store/return-rate'
            },
            {
              'id'       : 'store.detail.opportunity',
              'title'    : 'Opportunity',
              'translate': 'nav.store.detail.opportunity',
              'type'     : 'item',
              'icon'     : 'timeline',
              'url'  : '/store/opportunity'
            },
            {
              'id'       : 'store.detail.accesses',
              'title'    : 'Accesses Breakdown',
              'translate': 'nav.store.detail.accesses',
              'type'     : 'item',
              'icon'     : 'timeline',
              'url'  : '/store/accesses-breakdown'
            },
          ]
        }
      ]
    }, "end");
  }
}
