import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DashboardItemConfig, DashboardItemViewGeneric,
  DashboardItemViewMetricDelta, DashboardItemViewSerialChart,
  DataResultInterface, DataSource,
  DataSourceAssemblerFactory,
  DataSourceAssemblerInterface,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory, SerialChartConfig, ViewAssemblerFactory
} from "iottacle-dashboard";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {
  AuthService,
  FuseTranslationLoaderService,
  FuseConfigService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  GridsterDashboardConfig
} from "core-fe-angular";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import {
  AdministrativeEntityType,
  Filter,
  FilterInterface,
  FilterLocationReferences,
  FilterLocationReferencesStrategy,
  FilterVisitJunkData,
  FilterVisitNewret,
  FilterVisitStrategy,
  FilterVisitTimeFilterStrategies
} from "iottacle-ts-models";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";


@Component({
  selector: 'store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.scss']
})
export class StoreDetailsComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {allowedAdminEntityType:[AdministrativeEntityType.STORE]});
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {

    let dashboardItems:DashboardItem[] = [];
    //RepositoryCatalog.init(this.http);

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let filterBuilder:FilterBuilderInterface = new FilterBuilderGenericElastic();

    let customRepoDataSource:DataSourceConfigInterfaceInternal = {
      name: "totalCountCurrentPeriod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          return of({
            center : {
              lat : 45.54512532000085,
              lon : 9.329699374973757
            },
            kDiff : 0.0003838819983883468,
            dDiff : 0.0013203716277985222,
            rot : -4.52805957639483,
            imgUrl : 'https://www.pointinside.com/wp-content/uploads/2017/06/Visual_Analytics@2x.jpg.jpg',
            //zoom : 21
            polygons : [
              {
                name : "Main - hypermarket",
                coordinates : [
                  {
                    lat:45.5452528,
                    lon:9.3284653,
                    z:0
                  },
                  {
                    lat:45.5447437,
                    lon:9.3285297,
                    z:0
                  },
                  {
                    lat:45.5449034,
                    lon:9.3309196,
                    z:0
                  },
                  {
                    lat:45.5451852,
                    lon:9.330874,
                    z:0
                  },
                  {
                    lat:45.5452209,
                    lon:9.330772,
                    z:0
                  },
                  {
                    lat:45.5453035,
                    lon:9.3307613,
                    z:0
                  },
                  {
                    lat:45.5453223,
                    lon:9.3308069,
                    z:0
                  },
                  {
                    lat:45.5454237,
                    lon:9.3307935,
                    z:0
                  },
                  {
                    lat:45.5454181,
                    lon:9.3306594,
                    z:0
                  },
                  {
                    lat:45.5455327,
                    lon:9.3306487,
                    z:0
                  },
                  {
                    lat:45.545435,
                    lon:9.3289615,
                    z:0
                  },
                  {
                    lat:45.5452904,
                    lon:9.3289857,
                    z:0
                  },
                  {
                    lat:45.5452528,
                    lon:9.3284653,
                    z:0
                  }],
                color : "#ffffff",
                alpha : 0.5
              },
              {
                name : "Service Counter",
                coordinates : [
                  {
                    lat:45.5453768,
                    lon:9.3293746,
                    z:0
                  },
                  {
                    lat:45.5454407,
                    lon:9.3303295,
                    z:0
                  },
                  {
                    lat:45.545512,
                    lon:9.3303187,
                    z:0
                  },
                  {
                    lat:45.5454576,
                    lon:9.3293585,
                    z:0
                  },
                  {
                    lat:45.5453768,
                    lon:9.3293746,
                    z:0
                  }
                  ],
                color : "#ffffff",
                alpha : 0.5
              },
              {
                name : "Tech",
                coordinates : [
                  {
                    lat:45.5448057,
                    lon:9.3288677,
                    z:0
                  },
                  {
                    lat:45.54511,
                    lon:9.3288221,
                    z:0
                  },
                  {
                    lat:45.5450894,
                    lon:9.3287041,
                    z:0
                  },
                  {
                    lat:45.5452434,
                    lon: 9.328688,
                    z:0
                  },
                  {
                    lat:45.5452303,
                    lon:9.3284948,
                    z:0
                  },
                  {
                    lat:45.5447757,
                    lon:9.3285646,
                    z:0
                  },
                  {
                    lat:45.5448057,
                    lon:9.3288677,
                    z:0
                  }
                ],
                color : "#ffffff",
                alpha : 0.5
              },
              {
                name : "Daily Offers",
                coordinates : [
                  {
                    lat:45.5448057,
                    lon:9.3288677,
                    z:0
                  },
                  {
                    lat:45.5448226,
                    lon:9.3291842,
                    z:0
                  },
                  {
                    lat:45.5453392,
                    lon:9.3291144,
                    z:0
                  },
                  {
                    lat:45.5453279,
                    lon: 9.3289803,
                    z:0
                  },
                  {
                    lat:45.5452904,
                    lon:9.3289857,
                    z:0
                  },
                  {
                    lat:45.5452829,
                    lon:9.3289213,
                    z:0
                  },
                  {
                    lat:45.5451006,
                    lon:9.3289401,
                    z:0
                  },
                  {
                    lat:45.54511,
                    lon:9.3288221,
                    z:0
                  },
                  {
                    lat:45.5448057,
                    lon:9.3288677,
                    z:0
                  }
                ],
                color : "#ffffff",
                alpha : 0.5
              }
            ]
          });
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let interiorMap:DashboardItem = DashboardItem.of(DashboardItemConfig.of({
      id: "1"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Interior Map" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "interiorMap",
      infoButtonText:"",
      itemType : "map-with-polygons",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        showInteriorMap : true
      },
      dataSources : {
        "customRepoDataSource" : DataSource.of(DataSourceConfig.of(customRepoDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentDashboardItemAssembler")
    }));

    let openingHoursDataSource:DataSourceConfigInterfaceInternal = {
      name: "openingHoursDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          return of({
            weekdays : [
               {
                 name: "Lunedì",
                 hours : [
                   {
                     from : "09:00",
                     to : "21:00"
                   }
                 ]
               },
              {
                name: "Martedì",
                hours : [
                  {
                    from : "09:00",
                    to : "21:00"
                  }
                ]
              },
              {
                name: "Mercoledì",
                hours : [
                  {
                    from : "09:00",
                    to : "21:00"
                  }
                ]
              },
              {
                name: "Giovedì",
                hours : [
                  {
                    from : "09:00",
                    to : "21:00"
                  }
                ]
              },
              {
                name: "Venerdì",
                hours : [
                  {
                    from : "09:00",
                    to : "21:00"
                  }
                ]
              },
              {
                name: "Sabato",
                hours : [
                  {
                    from : "09:00",
                    to : "21:00"
                  }
                ]
              },
              {
                name: "Domenica",
                hours : [
                  {
                    from : "09:00",
                    to : "21:00"
                  }
                ]
              }
            ]
          });
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let openingHours:DashboardItem = DashboardItem.of(DashboardItemConfig.of({
      id: "2"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Opening hours" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "opening hours",
      infoButtonText:"",
      itemType : "opening-hours",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {},
      dataSources : {
        "openingHoursDataSource" : DataSource.of(DataSourceConfig.of(openingHoursDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));


    /**
     *
     * 1)
     *
     *
     *
     *
     *
     *
     *
     * REPO
     *
     */

    //Store Count
    let countLast10daysAndTrend:DataSourceConfigInterfaceInternal = {
      name: "maxDurationLast10daysAndTrend",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:10, multiplier:'days'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let currentCount:DataSourceConfigInterfaceInternal = {
      name: "currentDuration",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: 'time-ago', duration: 20, multiplier: 'minutes'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let countItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Live now - Visits' count" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: " people",
        miniChartConfig: {
          chartType : "XYChart",
          chart :{
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            cursor : {
              lineY : {
                disabled : true
              }
            },
            xAxes: [{
              type: "DateAxis",
              dataFields: {
                category: "name"
              },
              renderer: {
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            yAxes: [{
              type: "ValueAxis",
              renderer: {
                baseGrid: {
                  disabled: true
                },
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            series: [
              {
                type: "LineSeries",
                dataFields: {
                  valueY: "value",
                  dateX: "key"
                },
                tooltip: {
                  pointerOrientation: "vertical"
                },
                tensionX : 0.8,
                strokeWidth:2
              }
            ]
          }
        }
      })),
      specificItemConfig : {
        showTopText : true,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "Live now - Visits' count",
        topTextMatIcon: "people",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:0,
          y:0,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(countLast10daysAndTrend)),
        "2" : DataSource.of(DataSourceConfig.of(currentCount))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let percDuration = result["2"].aggregations["1"].value;
            let miniChartData:any = [];
            for (let b= 0; b< result["1"].aggregations["1-bucket"]["buckets"].length && b<10; b++){
              miniChartData.push({
                key : result["1"].aggregations["1-bucket"]["buckets"][b].key,
                value : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value
              })
            }
            toRet = {
              mainValue : percDuration,
              miniChartData : miniChartData
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(countItem);

    //Median Frequency
    let medianFrequencyDataSource:DataSourceConfigInterfaceInternal = {
      name: "medianFrequencyDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "percentiles": {
                "field": "frequency",
                "percents": [
                  50
                ],
                "keyed": false
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let medianFrequencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "4",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Your customer returns about:" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "times in a week",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "blue-fg",
        topTextTitle: "",
        gridsterConfig : {
          x:18,
          y:0,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(medianFrequencyDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            toRet = {
              mainValue : result["1"].aggregations["1"].values[0].value
            };
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(medianFrequencyItem);

    //Median Recency
    let medianRecencyDataSource:DataSourceConfigInterfaceInternal = {
      name: "medianFrequencyDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "percentiles": {
                "field": "recency",
                "percents": [
                  50
                ],
                "keyed": false
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let medianRecencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "5",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Your customer generally come back after:" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "days",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 1,
        maxFractionDigits : 1,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "blue-fg",
        topTextTitle: "",
        gridsterConfig : {
          x:18,
          y:6,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(medianRecencyDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            toRet = {
              mainValue : result["1"].aggregations["1"].values[0].value/1000/60/60/24
            };
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(medianRecencyItem);

    //store access Heatmap
    let hourlyPerDayHeatmapDataSource:DataSourceConfigInterfaceInternal = {
      name: "hourlyPerDayHeatmapDataSource",
      type: "elastic",
      assembler : {
        name:"idempotentDataSourceAssembler",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          let data:{
            startHod:number,
            startDow:string,
            count:number
          }[] = [];

          let dowMapping = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
          for (let r in result.aggregations["2"].buckets){
            let sod = result.aggregations["2"].buckets[r].key;
            let hod = (sod / 3600);

            for (let b in result.aggregations["2"].buckets[r]["3"].buckets){
              let dow = result.aggregations["2"].buckets[r]["3"].buckets[b].key;
              let count = result.aggregations["2"].buckets[r]["3"].buckets[b].doc_count;
              data.push({
                startHod :hod,
                startDow : dowMapping[dow],
                count:count
              });
            }
          }

          return of(GenericDataResult.ofSuccess(thiz.getName(), data));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return DataSourceAssemblerFactory.idempotentDataSourceAssembler;
        },
        serialize : () => {
          return JSON.stringify({
            name : DataSourceAssemblerFactory.idempotentDataSourceAssembler.name
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            strategy : "same-period",
            timestampField:'start'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0,
                "extended_bounds": {
                  "min": 0,
                  "max": 86400
                }
              },
              "aggs": {
                "3": {
                  "histogram": {
                    "field": "startDow",
                    "interval": 1,
                    "min_doc_count": 0,
                    "extended_bounds": {
                      "min": 1,
                      "max": 7
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let hourlyPerDayHeatmapItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "6" ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Heatmap Access" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText : "This heatmap displays the visits count over time per each day of the week",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        createChart : (chart)=> {
          chart.maskBullets = false;

          var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
          var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

          xAxis.dataFields.category = "startHod";
          yAxis.dataFields.category = "startDow";

          xAxis.renderer.grid.template.disabled = true;
          xAxis.renderer.minGridDistance = 40;

          yAxis.renderer.grid.template.disabled = true;
          yAxis.renderer.inversed = true;
          yAxis.renderer.minGridDistance = 30;

          var series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "startHod";
          series.dataFields.categoryY = "startDow";
          series.dataFields.value = "count";
          series.sequencedInterpolation = true;
          series.defaultState.transitionDuration = 3000;

          var bgColor = new am4core.InterfaceColorSet().getFor("background");

          var columnTemplate = series.columns.template;
          columnTemplate.strokeWidth = 1;
          columnTemplate.strokeOpacity = 0.2;
          columnTemplate.stroke = bgColor;
          columnTemplate.tooltipText = "{startHod}, {startDow}: {value.workingValue.formatNumber('#.')}";
          columnTemplate.width = am4core.percent(100);
          columnTemplate.height = am4core.percent(100);

          series.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color(bgColor),
            max: chart.colors.getIndex(0)
          });

// heat legend
          var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
          heatLegend.width = am4core.percent(100);
          heatLegend.series = series;
          heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
          heatLegend.valueAxis.renderer.minGridDistance = 30;

// heat legend behavior
          series.columns.template.events.on("over", (event) => {
            handleHover(event.target);
          })

          series.columns.template.events.on("hit", (event) => {
            handleHover(event.target);
          })

          function handleHover(column) {
            if (!isNaN(column.dataItem.value)) {
              heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
              heatLegend.valueAxis.hideTooltip();
            }
          }

          series.columns.template.events.on("out", (event) => {
            heatLegend.valueAxis.hideTooltip();
          });

          return chart;

        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:12,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "hourlyPerDayHeatmapDataSource" : DataSource.of(DataSourceConfig.of(hourlyPerDayHeatmapDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(hourlyPerDayHeatmapItem);

    //Duration METER
    let maxDurationLast10daysAndTrend:DataSourceConfigInterfaceInternal = {
      name: "maxDurationLast10daysAndTrend",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:10, multiplier:'days'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "avg": {
                    "field": "visitDuration"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let currentDuration:DataSourceConfigInterfaceInternal = {
      name: "currentDuration",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'timeToConsider',
            strategy: 'time-ago', duration: 1, multiplier: 'days'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "avg": {
                "field": "visitDuration"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let durationItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "7",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "NOW - Average Duration" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 24 hours",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: "min",
        miniChartConfig: {
          chartType : "XYChart",
          chart :{
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            cursor : {
              lineY : {
                disabled : true
              }
            },
            xAxes: [{
              type: "DateAxis",
              dataFields: {
                category: "name"
              },
              renderer: {
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            yAxes: [{
              type: "ValueAxis",
              renderer: {
                baseGrid: {
                  disabled: true
                },
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            series: [
              {
                type: "LineSeries",
                dataFields: {
                  valueY: "value",
                  dateX: "key"
                },
                tooltip: {
                  pointerOrientation: "vertical"
                },
                tensionX : 0.8,
                strokeWidth:2
              }
            ]
          }
        }
      })),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:18,
          y:12,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "maxDurationLast10daysAndTrend" : DataSource.of(DataSourceConfig.of(maxDurationLast10daysAndTrend)),
        "currentDuration" : DataSource.of(DataSourceConfig.of(currentDuration))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let maxDuration = result.maxDurationLast10daysAndTrend.aggregations["1"].value;
            let percDuration = result.currentDuration.aggregations["1"].value /1000/60;
            let miniChartData:any = [];
            for (let b= 0; b< result.maxDurationLast10daysAndTrend.aggregations["1-bucket"]["buckets"].length && b<10; b++){
              miniChartData.push({
                key : result.maxDurationLast10daysAndTrend.aggregations["1-bucket"]["buckets"][b].key,
                value : result.maxDurationLast10daysAndTrend.aggregations["1-bucket"]["buckets"][b]["1-metric"].value/1000/60
              })
            }
            toRet = {
              mainValue : percDuration,
              miniChartData : miniChartData
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(durationItem);

    //New vs Ret
    let newVsRetFilterTime:DataSourceConfigInterfaceInternal = {
      name: "newVsRetFilterTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "terms": {
                "field": "newret",
                "size": 2,
                "order": {
                  "1": "desc"
                }
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let newVsRetSamePeriodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "8",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Accesses over time" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 24 hours",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "PieChart",
        chart :{
          "series": [
            {
              "type": "PieSeries",
              "dataFields": {
                "value": "value",
                "category": "newret"
              }
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:18,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(newVsRetFilterTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            if (result["1"].aggregations["2"].buckets[0].key === 1){
              chartData.push({
                newret : "New",
                value : result["1"].aggregations["2"].buckets[0]["1"].value
              });
              chartData.push({
                newret : "Returning",
                value : result["1"].aggregations["2"].buckets[1]["1"].value
              })
            }else{
              chartData.push({
                newret : "New",
                value : result["1"].aggregations["2"].buckets[1]["1"].value
              });
              chartData.push({
                newret : "Returning",
                value : result["1"].aggregations["2"].buckets[0]["1"].value
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(newVsRetSamePeriodItem);

    //Count over time
    let countFilterTime:DataSourceConfigInterfaceInternal = {
      name: "countFilterTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let countSamePeriodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "9",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Accesses over time" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 24 hours",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Count",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {dateX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
              "bullets": [{
                "type": "Bullet",
                "children": [{
                  "type": "Image",
                  "propertyFields": {
                    "href": "weatherIconUrl"
                  },
                  "width": 30,
                  "height": 30,
                  "horizontalCenter": "middle",
                  "verticalCenter": "middle"
                }]
              }]
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:18,
          y:0,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(countFilterTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let miniChartData:any = [];
          if (result){
            for (let b= 0; b< result["1"].aggregations["1-bucket"]["buckets"].length; b++){
              miniChartData.push({
                time : result["1"].aggregations["1-bucket"]["buckets"][b].key,
                value : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value
              })
            }
          }
          if (miniChartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), miniChartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(countSamePeriodItem);


    // churn rate over time
    let churnRateOverTime:DataSourceConfigInterfaceInternal = {
      name: "churnRateOverTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'ts',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            strategy : FilterLocationReferences.FROM_FILTER_ADMINS
          },
          query: {},
          agg : {
            "2": {
              "date_histogram": {
                "field": "ts",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1": {
                  "avg": {
                    "field": "churnRate"
                  }
                }
              }
            }
          },
          index : "store_kpi",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let churnRateOverTimeItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "10",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Churn rate over time" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Count",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "strokeWidth": 3,
              "tooltip": {
                "pointerOrientation": "vertical"
              }
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:18,
          y:18,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(churnRateOverTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            for (let b= 0; b< result["1"].aggregations["2"]["buckets"].length; b++){
              chartData.push({
                time : result["1"].aggregations["2"]["buckets"][b].key,
                value : result["1"].aggregations["2"]["buckets"][b]["1"].value
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(churnRateOverTimeItem);




    let dashboardCfg = DashboardConfig.of(
      {
        id: "StoreDetails1234",
        name: "Store Details",
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        }
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);
    this.dashboard.setFilter(this.filter.filterValues);
    this.filterChangedSubscription = this.filter.filterChangedObservable()
      .subscribe((newFilterValue) => {
        this.dashboard.reloadData();
        this.showDashboard = true;
      });

  }


}


