import { NgModule } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Beacon} from "../context/device/beacon/beacon";
import {EntityModelCatalog} from "dashboard-fe-angular";
import {Device} from "../context/device/device/device";
import {ServiceDispatcher, SyncSettingsStorage} from "iottacle-ts-models";

@NgModule({
  declarations: [],
  imports     : [],
  providers   : [],
  bootstrap   : []
})
export class AppBootstrapModule {


  constructor(
    private httpClient:HttpClient
  ){
    let userLocalStorage:SyncSettingsStorage = new SyncSettingsStorage("userLocalStorage");
    ServiceDispatcher.registerService("SyncSettingsStorage", userLocalStorage);

    let beacon:Beacon = new Beacon(httpClient);
    EntityModelCatalog.registerEntityModel(beacon);

    let device:Device = new Device(httpClient);
    EntityModelCatalog.registerEntityModel(device);
  }



}
