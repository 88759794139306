import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GridsterModule} from "angular-gridster2";
import {AngularDashboardModule, EntityModelCatalog} from "dashboard-fe-angular";

import {
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatOptionModule,
  MatListModule
} from '@angular/material';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { AgmCoreModule } from '@agm/core';
import {
  AuthGuardService, AuthService,
  FuseSharedModule,
  FuseSidebarModule,
  FuseWidgetModule,
  IottacleNavigationService
} from "core-fe-angular";
import {ReccoVehicleModule} from "./vehicle/recco-vehicle.module";
import {ReccoOverviewModule} from "./recco/recco-overview.module";
import {FilterModule} from "../../filter/filter.module";
import {Vehicle} from "../../../../context/recco/vehicle/vehicle";
import {HttpClient} from "@angular/common/http";
import {Driver} from "../../../../context/recco/driver/driver";
import {Job} from "../../../../context/recco/job/job";

@NgModule({
  providers : [
    ReccoOverviewModule,
    ReccoVehicleModule
  ],
  imports     : [
    CommonModule,
    FuseSharedModule,
    FuseSidebarModule,
    FilterModule,
    GridsterModule,
    AngularDashboardModule,

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    FuseWidgetModule,
    NgxChartsModule,


    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBvZWorK4nmcjUJ1lP6HooJyGsCjYYyqn0'
    }),

    ReccoOverviewModule,
    ReccoVehicleModule
  ],
  declarations: [
  ]
})
export class ReccoModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService,
    private httpClient: HttpClient,
    private auth:AuthService
  ){

    EntityModelCatalog.registerEntityModel(new Vehicle(httpClient, auth.loginService));

    let driver:Driver = new Driver(httpClient);
    EntityModelCatalog.registerEntityModel(driver);

    let job:Job = new Job(httpClient, auth.loginService);
    EntityModelCatalog.registerEntityModel(job);
  }
}
