export const locale = {
    lang: 'it',
    data: {
        common : {
          from: "da",
          to: "a",
          hours : "ore",
          hour: "ora",
          'last-15-minutes' : "ultimi 15 minuti",
          'last-30-minutes':"ultimi 30 minuti",
          'last-1-hour' : "ultima ora",
          'last-4-hours': "ultime 4 ore",
          'last-12-hours': "ultime 12 ore",
          'last-24-hours':"ultime 24 ore",
          'today' : "oggi",
          'this-week': "questa settimana",
          'this-month': "questo mese",
          'this-year': "questo anno",
          'week-to-date': "da inizio settimana",
          'month-to-date': "da inizio mese",
          'year-to-date': "da inizio anno",
          'yesterday': "ieri",
          'day-before-yesterday': "l'altro ieri",
          'this-day-last-week': "oggi, una settimana fa",
          'previous-week': "settimna scorsa",
          'previous-month': "mese scorso",
          'previous-year': "anno scorso",
          'last-7-days': "ultimi 7 giorni",
          'last-30-days': "ultimi 30 giorni",
          'last-60-days': "ultimi 60 giorni",
          'last-90-days': "ultimi 90 giorni",
          'last-6-months': "ultimi 6 mesi",
          'last-1-year': "ultimo anno",
          'last-2-years': "ultimi 2 anni",
          'last-5-years': "ultimi 5 anni",
          languages : {
            'it' : 'Italiano',
            'en' : 'Inglese'
          },
          dowById : {
            '1' : "Lunedì",
            '2' : "Martedì",
            '3' : "Mercoledì",
            '4' : "Giovedì",
            '5' : "Venerdì",
            '6' : "Sabato",
            '7' : "Domenica",
          },
          dowByName:{
            'monday' : "Lunedì",
            'tuesday' : "Martedì",
            'wednesday' : "Mercoledì",
            'thursday' : "Giovedì",
            'friday' : "Venerdì",
            'saturday' : "Sabato",
            'sunday' : "Domenica",
          },
          dowByContractedName:{
            "mon" : "Lun",
            "tue": "Mar",
            "wed": "Mer",
            "thu": "Gio",
            "fri": "Ven",
            "sat": "Sab",
            "sun": "Dom"
          },
          graph:{
            yAxes:{
              title:{
                count: "Conteggio"
              }
            }
          }
        }
    }
};

