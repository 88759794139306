import {
  DataResultInterface,
  DataSource,
  DataSourceAssemblerInterface,
  DataSourceConfig, DataSourceConfigInterface,
  DataSourceInterface,
  GenericDataResult
} from "iottacle-dashboard";
import {Observable, of} from "rxjs";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import _lodash from "lodash";
const _ = _lodash;
import _moment from "moment";
const moment = _moment;
moment.locale('it');

export class QuarterTimeBucketDataSourceAssembler{
  name = "QuarterTimeBucketDataSourceAssembler";
  assemble (result:any, thiz:DataSourceInterface, context:any):Observable<DataResultInterface<any>> {
    try {
      let toRet = _.cloneDeep(result);
      if (result && result.series) {
        let cfg: DataSourceConfigInterface = (<DataSource>thiz).getConfig();
        let filter: FilterBuilderGenericElastic = <FilterBuilderGenericElastic>cfg.filter;
        for (let s in result.series) {
          let serie = result.series[s];
          if (serie) {
            for (let p in serie.partitions) {
              let partition = serie.partitions[p];
              toRet.series[s].partitions[p].data = [];
              //summing all
              let xAxisFieldName = filter.customData.xAxis.timeserie.date_histogram.field;
              let key = partition.metricKey;
              for (let d in partition.data) {
                let newTime = moment(partition.data[d][xAxisFieldName]).startOf("quarter");
                let weeklyElement = toRet.series[s].partitions[p].data.find((el: any) => el[xAxisFieldName] == newTime);
                if (!weeklyElement) {
                  let newElement: any = {};
                  newElement[xAxisFieldName] = newTime.valueOf();
                  newElement[key] = partition.data[d][key];
                  newElement._count = 1;
                  toRet.series[s].partitions[p].data.push(newElement);
                } else {
                  weeklyElement[key] = weeklyElement[key] + partition.data[d][key];
                  weeklyElement._count = weeklyElement._count + 1;
                }
              }
              for (let d in toRet.series[s].partitions[p].data) {
                toRet.series[s].partitions[p].data[d][key] = toRet.series[s].partitions[p].data[d][key] / toRet.series[s].partitions[p].data[d]._count;
                delete toRet.series[s].partitions[p].data[d]._count;
              }
            }
          }
        }
        return of(GenericDataResult.ofSuccess(thiz.getName(), toRet));
      }
      return of(GenericDataResult.ofSuccess(thiz.getName(), {}));
    }catch(e){
      console.error(e);
      return of(GenericDataResult.ofError("").internalFeError({
        errorDescription:"error on WeeklyTimeBucketDataSourceAssembler",
        errorCode: "1234",
        errorDetails: e
      }));
    }
  }
  rehydrate(serializedAssembler:string):DataSourceAssemblerInterface{
    return new QuarterTimeBucketDataSourceAssembler();
  }
  serialize(){
    return JSON.stringify({
      name : "QuarterTimeBucketDataSourceAssembler"
    });
  }
}
