import {
  DashboardItem,
  DashboardItemViewSerialChart,
  DataResultInterface,
  DataSource,
  DataSourceConfig, DataSourceConfigInterface,
  SerialChartConfig, ViewAssemblerFactory
} from "iottacle-dashboard";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import deepmerge_ from 'deepmerge';
const deepmerge:any = deepmerge_;
import _lodash from "lodash";
const _ = _lodash;
import {
  Utils as UtilsCatalog,
  IottacleRepoDataPartition
}  from "dashboard-items-catalog";
import moment from "moment";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
import {ColumnSeries, DateAxis, LineSeries} from "@amcharts/amcharts4/charts";

export function am4themes_next14Theme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color("#3674b1"),
      am4core.color("#EFB000"),
      am4core.color("#FF8489"),
      am4core.color("#5BC2E7"),
      am4core.color("#56AFB8"),
      am4core.color("#9037AA"),
      am4core.color("#404285"),
      am4core.color("#14243C"),
      am4core.color("#006298")
    ]
  }
}

export class Utils{

  public static getRangeOfDates (startDate,endDate, partitionData, xAxis, metricKey, advanceAmount:number = 1, advanceUnit = 'days'){
    var dateArray = [];
    var fromDate = startDate.startOf("day");//.set({hour:0,minute:0,second:0,millisecond:0});
    var toDate = endDate.startOf("day");//.set({hour:0,minute:0,second:0,millisecond:0});
    let minDate = toDate;
    for (let d of partitionData){
      if (minDate.isAfter(moment(d[xAxis]))){
        minDate = moment(d[xAxis]);
      }
    }
    if (fromDate.isAfter(minDate)){
      fromDate = minDate;
    }

    let cursorDate = minDate;
    while (fromDate.isSameOrBefore(cursorDate)){
      dateArray.push( { [xAxis]:cursorDate.valueOf(), [metricKey]:0 } );
      // @ts-ignore
      cursorDate = moment(cursorDate).subtract(advanceAmount, advanceUnit);
    }
    cursorDate = minDate;
    while (toDate.isSameOrAfter(cursorDate)) {
      dateArray.push( { [xAxis]:cursorDate.valueOf(), [metricKey]:0 } );
      // @ts-ignore
      cursorDate = moment(cursorDate).add(advanceAmount, advanceUnit);
    }
    return dateArray;
  }

  public static createPeriodicVerticalLineOnTimeserie (period, timeserieData, xAxisFieldName,  chart) {
    let xAxis:any = chart.xAxes.getIndex(0);
    let periods:{[date:number]:any} = {};
    if (!period || period == "auto"){
      let start = timeserieData[0][xAxisFieldName];
      let end = timeserieData[timeserieData.length - 1][xAxisFieldName];
      if ((end - start) > ( 90 * 24 * 60 * 60 * 1000)){
        //if range is greater than 3 months
        period = "quarter";
      }else{
        period = "week";
      }
    }
    for (let d of timeserieData) {
      let startOfPeriod = moment(d[xAxisFieldName]).startOf(period);
      periods[startOfPeriod.valueOf()] = startOfPeriod;
    }
    for (let p of Object.values(periods)) {
      let range = xAxis.axisRanges.create();
      range.date =  new Date(p.valueOf());
      range.grid.stroke = am4core.color("grey");
      range.grid.strokeWidth = 2;
      range.grid.strokeOpacity = 1;

      let format = "[w]w";
      switch (period){
        case "week": format = "[w]w"; break;
        case "Week": format = "[w]w"; break;
        case "w": format = "[w]w"; break;
        case "quarter": format = "[Q]Q"; break;
        case "Quarter": format = "[Q]Q"; break;
        case "Q": format = "[Q]Q"; break;
        default: format = "[w]w"; break;
      }

      range.label.inside = true;
      range.label.text = p.format(format);
      range.label.fill = range.grid.stroke;
      range.label.valign = "top";
      range.label.adapter.add("horizontalCenter", function() {
        return "left";
      });
    }
  }

  public static createEventRangeFunction (elm, chart) {
    let xAxis:any = chart.xAxes.getIndex(0);
    xAxis.axisRanges.clear();
    for (let i = 0; i < elm.length; i++) {
      let e = elm[i];
      let range = xAxis.axisRanges.create();
      range.date =  new Date(e.start);

      if((e.end-e.start) > 86400000) range.endDate= new Date(e.end);

      range.axisFill.fill =  e.color ? e.color : "#A96478";//e.category ? e.category.color ? e.category.color : "#A96478" : "#A96478";
      range.axisFill.fillOpacity =  0.3;
      range.label.inside = true;
      range.label.location = 0.5;

      let htmlTags = "";
      if (e.tags && e.tags.length > 0) {
        for (let tag in e.tags) {
          if(htmlTags!='')htmlTags = htmlTags + ", " + e.tags[tag] + " ";
          else htmlTags =  e.tags[tag] + " ";
        }
      }
      let htmlStores = "";
      if (e.adminEntityDetails && e.adminEntityDetails.length > 0) {
        let i = 0;
        for (let adminEntityDetails in e.adminEntityDetails) {
          if(i==0){
            htmlStores = htmlStores + " " + e.adminEntityDetails[adminEntityDetails].name + " ";
          }
          i++;
        }
        if(htmlStores.length > 20) htmlStores = htmlStores.substr(0, 20)+'...';
        if(i>1)htmlStores = htmlStores + " (+ " + (i-1) + " more)";
      }


      var ename = e.name[0].toUpperCase() + e.name.substr(1).toLowerCase();
      var startDate = new Date(e.start).getDate() +"-"+ new Date(e.start).getMonth()+"-"+new Date(e.start).getFullYear();
      var endDate = new Date(e.end).getDate() +"-"+ new Date(e.end).getMonth()+"-"+new Date(e.end).getFullYear();


      range.axisFill.tooltip = new am4core.Tooltip();
      range.axisFill.tooltipText = "[bold] Event: "+ename+" ("+ (e.category? e.category.name : '') +")\n Tags: "+htmlTags+ " \n Store: " +htmlStores + "\n From: " +startDate+" To: [bold]"+endDate+"[/]";
      range.axisFill.interactionsEnabled = true;
      range.axisFill.isMeasured = true;

      if((e.end-e.start) < 86400000){
        let bullet = new am4charts.CircleBullet();
        bullet.fill = e.color;
        range.bullet = bullet;
        range.bullet.tooltipText = range.axisFill.tooltipText;
      }
    }
  }

  private static processOver(hoveredSeries, chart) {
    let serieTypeCode = "columns";
    if (hoveredSeries instanceof LineSeries){
      serieTypeCode = "segments";
    }else if (hoveredSeries instanceof ColumnSeries){
      serieTypeCode = "columns";
    }
    hoveredSeries.toFront();
    hoveredSeries[serieTypeCode].each(function(segment) {
      segment.setState("hover");
    })
    chart.series.each(function(series) {
      if (series != hoveredSeries) {
        series[serieTypeCode].each(function(segment) {
          segment.setState("dimmed");
        })
        series.bulletsContainer.setState("dimmed");
      }
    });
  }

  private static processOut(hoveredSeries, chart) {
    let serieTypeCode = "columns";
    if (hoveredSeries instanceof LineSeries){
      serieTypeCode = "segments";
    }else if (hoveredSeries instanceof ColumnSeries){
      serieTypeCode = "columns";
    }

    chart.series.each(function(series) {
      series[serieTypeCode].each(function(segment) {
        segment.setState("default");
      })
      series.bulletsContainer.setState("default");
    });
  }

  private static createLineSerie(chart2, xAxisChartName, valueYPropertyName){
    let series = chart2.series.push(new am4charts.LineSeries());
    series.strokeWidth = 3;
    series.xAxis.renderer.grid.template.disabled = true;
    series.connect = false;
    //let bullet = series.bullets.push(new am4charts.CircleBullet());

    //allow to highlight hovered series
    var segment = series.segments.template;
    segment.interactionsEnabled = true;

    var hoverState = segment.states.create("hover");
    hoverState.properties.strokeWidth = 3;

    var dimmed = segment.states.create("dimmed");
    dimmed.properties.stroke = am4core.color("#dadada");

    segment.events.on("over", function(event) {
      Utils.processOver(event.target.parent.parent.parent, chart2);
    });

    segment.events.on("out", function(event) {
      Utils.processOut(event.target.parent.parent.parent, chart2);
    });

    //Tooltip
    series.tooltipText = "Serie: {name} - {"+xAxisChartName+"}: [bold]{"+valueYPropertyName+"}[/]";
    series.adapter.add("tooltipText", function (ev) {
      var text = "[bold]{"+xAxisChartName+"}[/]\n"
      chart2.series.each(function(item){
        text += "[" + item.stroke.hex + "]●[/] " + item.name + ": {" + item.name + "}\n";
      });
      return text;
    });

    series.tooltip.pointerOrientation = "horizontal";
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#fff");
    series.tooltip.label.fill = am4core.color("#00");

    // Prevent cross-fading of tooltips
    series.tooltip.defaultState.transitionDuration = 0;
    series.tooltip.hiddenState.transitionDuration = 0;


    return series;
  }

  private static createColumnSerie(chart2, xAxisChartName, valueYPropertyName){
    let series = chart2.series.push(new am4charts.ColumnSeries());
    series.columns.template.fillOpacity = .8;
    series.xAxis.renderer.grid.template.disabled = true;
    //series.connect = false;
    let columnTemplate1 = series.columns.template;
    columnTemplate1.strokeWidth = 1;
    columnTemplate1.strokeOpacity = 1;

    //allow to highlight hovered series
    var columns = series.columns.template;
    columns.interactionsEnabled = true;

    var hoverState = columns.states.create("hover");
    hoverState.properties.strokeWidth = 3;

    var dimmed = columns.states.create("dimmed");
    dimmed.properties.stroke = am4core.color("#dadada");
    dimmed.properties.fill = am4core.color("#dadada");

    // columns.events.on("over", function(event) {
    //   Utils.processOver(event.target.parent.parent.parent, chart2);
    // });
    //
    // columns.events.on("out", function(event) {
    //   Utils.processOut(event.target.parent.parent.parent, chart2);
    // });

    //Tooltip
    series.tooltipText = "Serie: {name} - {"+xAxisChartName+"}: [bold]{"+valueYPropertyName+"}[/]";
    series.adapter.add("tooltipText", function (ev) {
      var text = "[bold]{"+xAxisChartName+"}[/]\n"
      chart2.series.each(function(item){
        text += "[" + item.stroke.hex + "]●[/] " + item.name + ": {" + item.name + "}\n";
      });
      return text;
    });

    series.tooltip.pointerOrientation = "horizontal";
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#fff");
    series.tooltip.label.fill = am4core.color("#00");

    // Prevent cross-fading of tooltips
    series.tooltip.defaultState.transitionDuration = 0;
    series.tooltip.hiddenState.transitionDuration = 0;

    return series;
  }

  private static createLegend(chart2,id, dashboardItem){
    /* Create legend */
    chart2.legend = new am4charts.Legend();

    if (dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.legend &&
      dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.legend.position){
      chart2.legend.position = dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.legend.position;
    }


    if (dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.legend &&
      dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.legend.showLegendIntoAccordion) {
      /* Create a separate container to put legend in */
      var legendContainer = am4core.create('legend'+id, am4core.Container);
      legendContainer.width = am4core.percent(100);
      legendContainer.height = am4core.percent(100);
      chart2.legend.parent = legendContainer;
    }

    chart2.legend.scrollable = true;
    chart2.legend.itemContainers.template.events.on("over", function(event) {
      Utils.processOver(event.target.dataItem.dataContext, chart2);
    })

    chart2.legend.itemContainers.template.events.on("out", function(event) {
      Utils.processOut(event.target.dataItem.dataContext, chart2);
    })
  }

  private static createXYCursor (chart, xAxis, swappedAxes, cursorSyncManager?){
    /* Create a cursor */
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = -1;
    /* Configure cursor lines */
    if (!swappedAxes) {
      chart.cursor.xAxis = xAxis;
      chart.cursor.fullWidthLineX = true;
      chart.cursor.lineX.strokeWidth = 0;
      chart.cursor.lineX.fill = am4core.color("#8F3985");
      chart.cursor.lineX.fillOpacity = 0.1;
      chart.cursor.lineY.disabled = true;
    }else{
      chart.cursor.yAxis = xAxis;
      chart.cursor.fullWidthLineY = true;
      chart.cursor.lineY.strokeWidth = 0;
      chart.cursor.lineY.fill = am4core.color("#8F3985");
      chart.cursor.lineY.fillOpacity = 0.1;
      chart.cursor.lineX.disabled = true;
    }
    if (cursorSyncManager){
      let preventBroadcasting = false;
      let id = cursorSyncManager.on("cursorpositionchanged", (msg) => {
        let point = xAxis.dateToPoint(msg.xAxisValue);
        preventBroadcasting = true;
        chart.cursor.triggerMove(point,"soft", false);
      });
      chart.cursor.events.on("cursorpositionchanged", (ev)=> {
        if (!preventBroadcasting) {
          let xAxis = ev.target.chart.xAxes.getIndex(0);
          let xAxisValue
          if (xAxis instanceof DateAxis) {
            xAxisValue = xAxis.positionToDate(xAxis.toAxisPosition(ev.target.xPosition));
          }else{
            xAxisValue = xAxis.positionToCategory(xAxis.toAxisPosition(ev.target.xPosition));
          }
          let msg = {xAxisValue: xAxisValue};
          cursorSyncManager.broadcastEvent(id, "cursorpositionchanged", msg);
        }
        preventBroadcasting = false;
      });

    }

  }

  public static xyChartWidgetDataChangedHook (chart:any, id:any, data:DataResultInterface<any>, dashboardItem:DashboardItem){
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_next14Theme);
    let chart2:any = am4core.create(id, am4charts["XYChart"]);
    chart2.language.locale = am4lang_it_IT;
    let res = data.getResult();
    if (res && res.series) {

      let filterUsed =  dashboardItem.getLastFilterUsed();

      //create xAxis data
      //get first data source with "elastic" type
      let ds:DataSource;
      let dataSources = dashboardItem.getDataSources();
      for (let d in dataSources){
        let cfg:DataSourceConfigInterface = (<DataSource>dataSources[d]).getConfig();
        if (cfg.type == "elastic"){
          ds = <DataSource>dataSources[d];
          break;
        }
      }
      if (ds){
        //exists an Elastic dataSource, proceed in creating xAxis data
        //get xAxis field name to look for into data
        let filter:FilterBuilderGenericElastic = <FilterBuilderGenericElastic>ds.getConfig().filter;
        let xAxisFieldName = "_all";
        let fieldElasticType;
        if (filter.customData.xAxis && Object.keys(filter.customData.xAxis).length) {
          xAxisFieldName = UtilsCatalog.getAggregationFieldName(filter.customData.xAxis);
          fieldElasticType = UtilsCatalog.getElasticAggregationType(filter.customData.xAxis);
        }
        let availableSeries1:any = {};
        let dataForXaxis = {};
        let dataForYaxis = [];
        let wholeData = [];
        let valueAxis = [];
        for (let s in res.series){
          let serie = res.series[s];
          let stacked = false;
          for (let p in serie.partitions){
            let partition:IottacleRepoDataPartition = serie.partitions[p];
            let fullPartitionChainName = "";
            for (let p1 in partition.partitionChain){
              fullPartitionChainName = fullPartitionChainName + partition.partitionChain[p1].fieldValueLookup + " - ";
            }
            fullPartitionChainName = fullPartitionChainName + partition.leafMetricKey;
            var dateRange = [];

            if(fieldElasticType === "date_histogram"){
              let starttime = filterUsed.timeFilter.outcome.timeRangeBoundaries.from;
              let endtime = filterUsed.timeFilter.outcome.timeRangeBoundaries.to;
              let advanceByAmount = dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.advanceByAmount || 1;
              let advanceByUnit = dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.advanceByUnit || 'day';
              dateRange = Utils.getRangeOfDates(starttime,endtime, partition.data,xAxisFieldName,   partition.metricKey, advanceByAmount, advanceByUnit);
              let result =  _.unionBy(partition.data,dateRange,xAxisFieldName);
              partition.data = result;

              partition.data.sort(function(x, y){
                return x[xAxisFieldName] - y[xAxisFieldName];
              })

            }

            valueAxis.push(partition.metricKey);
            availableSeries1[partition.name] = {
              name: partition.metricKey,//fullPartitionChainName,
              key : partition.name,
              metricName : partition.metricName,
              metricKey: partition.metricKey,
              leafMetricKey: partition.leafMetricKey,
              stacked : stacked,
              data: partition.data
            };

            stacked = true;
            for (let d in partition.data){
              dataForXaxis[partition.data[d][xAxisFieldName]] = partition.data[d][xAxisFieldName];
              let objKeys = Object.keys(partition.data[d]);
              //if(objKeys[0].indexOf(partition.metricName) >=0 ){
                //partition.data[d][fullPartitionChainName] = partition.data[d][partition.metricKey]
                wholeData.push(partition.data[d]);
              // } else {
              //   wholeData.push(partition.data[d]);
              // }

              //dataExample = partition.data[d][partition.metricKey];
            }
          }
        }
        let dataArrayForXaxis = [];
        for (let d in dataForXaxis){
          let data1 = {};
          data1[xAxisFieldName] = dataForXaxis[d];
          dataArrayForXaxis.push(data1);
        }
        //order xAxis values
        if (dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.xSort){
          switch (dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.xSort) {
            case "asc":
              dataArrayForXaxis =_.orderBy(dataArrayForXaxis, [xAxisFieldName], ["asc"]);
              break;
            case "desc":
              dataArrayForXaxis = _.orderBy(dataArrayForXaxis, [xAxisFieldName], ["desc"]);
              break;
          }

          let orderedData = _.groupBy(wholeData, function(el) {
            return el[xAxisFieldName];
          });
          let dataArrayToExport = [];
          for(let i in orderedData){
            let mergedObj = {};
            if(orderedData[i].length > 1){
              mergedObj = orderedData[i].reduce(((r, c) => Object.assign(r, c)), {});
            } else {
              mergedObj = orderedData[i][0];
            }
            dataArrayToExport.push(mergedObj);
          }
          chart2.data = dataArrayToExport;
         //chart2.data = dataArrayForXaxis;
        }else{

          let orderedData = _.groupBy(wholeData, function(el) {
            return el[xAxisFieldName];
          });
          let dataArrayToExport = [];
          for(let i in orderedData){
            let mergedObj = {};
            if(orderedData[i].length > 1){
              mergedObj = orderedData[i].reduce(((r, c) => Object.assign(r, c)), {});
            } else {
              mergedObj = orderedData[i][0];
            }
            dataArrayToExport.push(mergedObj);
          }
          //ugly hack, TODO find a way to check if field is timestamp
          // if(xAxisFieldName === 'start'){
          //   dataArrayToExport.forEach((el)=>{
          //     el[xAxisFieldName] = moment(el[xAxisFieldName]).format('DD/MM/YYYY');
          //   });
          // }
          chart2.data = dataArrayToExport;
        }

        //xAxis Creation
        let swappedAxes = dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.swappedAxes;
        let xAxesPropertyName = swappedAxes ? 'yAxes' : 'xAxes';
        let yAxesPropertyName = swappedAxes ? 'xAxes' : 'yAxes';

        let xAxis;
        let xAxisChartName;
        switch(fieldElasticType){
          case "date_histogram":
            xAxis = chart2[xAxesPropertyName].push(new am4charts.DateAxis());
            xAxisChartName = swappedAxes ? "dateY" : "dateX";
            chart2.dateFormatter.intlLocales = "it-IT";
            if (dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.dateFormat ==  "weeklyView") {
              xAxis.baseInterval = { timeUnit: "week", count: 1 };
              xAxis.minGridDistance = 1;
              xAxis.gridIntervals.setAll([
                { timeUnit: "month", count: 1 },
                { timeUnit: "week", count: 1 }
              ]);
              xAxis.renderer.labels.template.location = 0.5;
              xAxis.dateFormatter.dateFormat = "'week' w d/M/yyyy";
              xAxis.dateFormatter.intlLocales = "it-IT";
              xAxis.dateFormats.setKey("month", "w");
              xAxis.dateFormats.setKey("week", "w");
              xAxis.periodChangeDateFormats.setKey("week", "[bold]MMM[/]");
              xAxis.renderer.labels.template.fontSize = 11;
            }
            break;
          default:
            //if (dataExample && (Number(dataExample) != Number.NaN)) {
            //  xAxis = chart2.xAxes.push(new am4charts.ValueAxis());
            //  xAxisChartName = "valueX";
            //}else{
            xAxis = chart2[xAxesPropertyName].push(new am4charts.CategoryAxis());
            xAxisChartName = swappedAxes ? 'categoryY' : "categoryX";
            xAxis.dataFields.category = xAxisFieldName;
            xAxis.renderer.minGridDistance = 30;
            //}
            break;
        }
        xAxis.renderer.grid.disabled = true;
        xAxis.renderer.inversed = swappedAxes ? true : false;
        //yAxes Creation
        let yAxis = chart2[yAxesPropertyName].push(new am4charts.ValueAxis());
        //yAxis.dataFields.category = "startDow";
        let context = dashboardItem.getLastContextUsed();
        yAxis.title.text = "widgets.chart." + dashboardItem.getId() + '.chart.yAxes.0.title.text';
        if (context && context.translateService){
          let itemId = dashboardItem.getId();
          yAxis.title.text = context.translateService.instant("widgets.chart." + itemId + '.chart.yAxes.0.title.text');
        }

        yAxis.cursorTooltipEnabled = false;
        yAxis.min = 0;
        yAxis.renderer.grid.strokeOpacity = 0.07;
        yAxis.renderer.baseGrid.disabled = true;
        let valueYPropertyName = swappedAxes ? 'valueX' : 'valueY';
        //fillup series data to all the series, this is needed for tooltip to work
        for (let s in availableSeries1) {
          for (let s1 in availableSeries1) {
            if (s1!=s){
              for (let d in availableSeries1[s].data){
                let element = availableSeries1[s1].data.find(el => el[xAxisFieldName] == availableSeries1[s].data[d][xAxisFieldName]);
                if (element){
                  element[availableSeries1[s].metricKey] = availableSeries1[s].data[d][availableSeries1[s].metricKey];
                }
              }
            }
          }
        }

        //series creation
        chart2.series.clear();
        for (let s in availableSeries1) {
          let chartType = dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.series ? dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.series[availableSeries1[s].metricName] ? dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.series[availableSeries1[s].metricName].type : "bar" : "bar";
          let series;
          switch(chartType){
            case 'line':
              series = Utils.createLineSerie(chart2, xAxisChartName, valueYPropertyName);
              break;
            case 'bar':
              series = Utils.createColumnSerie(chart2,xAxisChartName, valueYPropertyName);
              break;
            default:
              series = Utils.createColumnSerie(chart2,xAxisChartName, valueYPropertyName);
              break;
          }

          series.dataFields[valueYPropertyName] = availableSeries1[s].metricKey;
          series.dataFields[xAxisChartName] = xAxisFieldName;
          series.name = availableSeries1[s].name;
          //order xAxis values
        if (dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.xSort){
          switch (dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.xSort) {
            case "asc":
              availableSeries1[s].data =_.orderBy(availableSeries1[s].data, [xAxisFieldName], ["asc"]);
              break;
            case "desc":
              availableSeries1[s].data = _.orderBy(availableSeries1[s].data, [xAxisFieldName], ["desc"]);
              break;
          }
           series.data = availableSeries1[s].data;
        }else{
          series.data = availableSeries1[s].data;
        }

          series.stacked = availableSeries1[s].stacked;
          series.tooltip.pointerOrientation = swappedAxes ? "horizontal" : "vertical";
        }

        //legend
        if (Object.keys(availableSeries1).length > 0  && !chart2.legend){
          Utils.createLegend(chart2,id, dashboardItem);
        }

        //cursor
        Utils.createXYCursor(chart2, xAxis, swappedAxes, dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.cursorSyncManager);

        //Period line on timeserie

        if (dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.periodicVerticalLine){
          let period = dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.periodicVerticalLine.period || "week";
          Utils.createPeriodicVerticalLineOnTimeserie(period, wholeData, xAxisFieldName,chart2);
        }

        //EXport functions
        if(dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export){
          // let keys = chart2.data[];
          // chart2.exporting.dataFields = {"adminEntityId": "adminEntityId", "percentage": "percentage"};
         //this actually take start field which is a timestamp and convert it into date format DD MM YYYY
          chart2.exporting.dateFormat = "EEE, MMM dd";
          chart2.exporting.dateFields.push("start");
          chart2.exporting.filePrefix = 'NextOne Platform -' + dashboardItem.dashboardItemConfig.name;

          chart2.exporting.menu = new am4core.ExportMenu();
          let basicStructure = {
              "label": "...",
              "menu": []
          };

          if(dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export.image){
            basicStructure["menu"].push({
              "label": "Image",
              "menu": [
                { "type": "png", "label": "PNG" },
                { "type": "jpg", "label": "JPG" },
                { "type": "pdf", "label": "PDF" }
              ]
            })
          }
          if(dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export.csv || dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export.excel ){
            let basicStructureData = {
                      "label": "Data",
                      "menu": []
            };

            if(dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export.csv){
              basicStructureData["menu"].push({ "type": "csv", "label": "CSV" });
            }
            if(dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export.excel){
              basicStructureData["menu"].push({ "type": "xlsx", "label": "XLSX" });
            }

            basicStructure["menu"].push(basicStructureData);
          }

          chart2.exporting.menu.items = [basicStructure];

        }

      }

    }
    chart2.hiddenState.properties.opacity = 0;

    //EVENTS
    if (res && res.events && res.events.length > 0) {
      Utils.createEventRangeFunction(res.events, chart2);
    }

    chart2.validateData();
    return chart2;
  };

  public static heatmapChartWidgetDataChangedHook(chart2:any, id:any, data:DataResultInterface<any>, dashboardItem:DashboardItem){

    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_next14Theme);
    let chart:any = am4core.create(id, am4charts["XYChart"]);

    let res = data.getResult();
    if (res) {
      chart.maskBullets = false;

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

      xAxis.dataFields.category = "startHod";
      yAxis.dataFields.category = "startDow";

      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 40;

      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.inversed = true;
      yAxis.renderer.minGridDistance = 30;

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "startHod";
      series.dataFields.categoryY = "startDow";
      series.dataFields.value = "count";
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      var bgColor = new am4core.InterfaceColorSet().getFor("background");

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 0.2;
      columnTemplate.stroke = bgColor;
      let context = dashboardItem.getLastContextUsed();
      if (context && context.translateService){
        columnTemplate.tooltipText = "" + context.translateService.instant('widgets.map.hours') + " {startHod}, {startDow}: {value.workingValue.formatNumber('#.')} %";
      } else {
        columnTemplate.tooltipText = "hours {startHod}, {startDow}: {value.workingValue.formatNumber('#.')} %";
      }
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = am4core.percent(100);

      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        min: am4core.color(bgColor),
        max: chart.colors.getIndex(0)
      });

      // heat legend
      var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
      heatLegend.width = am4core.percent(100);
      heatLegend.series = series;
      heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
      heatLegend.valueAxis.renderer.minGridDistance = 30;

      // heat legend behavior
      series.columns.template.events.on("over", (event) => {
        handleHover(event.target);
      });

      series.columns.template.events.on("hit", (event) => {
        handleHover(event.target);
      });

      let handleHover = (column) => {
        if (!isNaN(column.dataItem.value)) {
          heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
        } else {
          heatLegend.valueAxis.hideTooltip();
        }
      };

      series.columns.template.events.on("out", (event) => {
        heatLegend.valueAxis.hideTooltip();
      });
      chart.data = res;
      chart.validateData();
    }

    return chart;


  }

  public static pieChartWidgetDataChangedHook (chart:any, id:any, data:DataResultInterface<any>, dashboardItem:DashboardItem) {

    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_next14Theme);
    let chart2:any = am4core.create(id, am4charts["PieChart"]);
    chart2.innerRadius = am4core.percent(30);
    let res = data.getResult();
    if (res && res.series) {
      //create xAxis data
      //get first data source with "elastic" type
      let ds:DataSource;
      let dataSources = dashboardItem.getDataSources();
      for (let d in dataSources){
        let cfg:DataSourceConfigInterface = (<DataSource>dataSources[d]).getConfig();
        if (cfg.type == "elastic"){
          ds = <DataSource>dataSources[d];
          break;
        }
      }
      if (ds){
        //exists an Elastic dataSource, proceed in creating xAxis data
        //get xAxis field name to look for into data
        let filter:FilterBuilderGenericElastic = <FilterBuilderGenericElastic>ds.getConfig().filter;
        let xAxisFieldName;
        let fieldElasticType;
        if (filter.customData.xAxis && Object.keys(filter.customData.xAxis).length) {
          xAxisFieldName = UtilsCatalog.getAggregationFieldName(filter.customData.xAxis);
          fieldElasticType = UtilsCatalog.getElasticAggregationType(filter.customData.xAxis);
        }else if (filter.customData.splitSeries && filter.customData.splitSeries.length > 0){
          xAxisFieldName = UtilsCatalog.getAggregationFieldName(filter.customData.splitSeries[0]);
          fieldElasticType = UtilsCatalog.getElasticAggregationType(filter.customData.splitSeries[0]);
        }else{
          xAxisFieldName  = "_all";
          fieldElasticType = "_all";
        }
        let availableSeries1:any = {};
        // let dataExample:any;
        // let dataForXaxis = {};
        for (let s in res.series){
          let serie = res.series[s];
          availableSeries1[serie.name] = {
            name: serie.name,
            value: "value",
            data: []
          };

          for (let p in serie.partitions){
            let partition = serie.partitions[p];
            for (let d in partition.data){
              let fullPartitionChainName = "";
              for (let p in partition.partitionChain) {
                fullPartitionChainName = fullPartitionChainName + partition.partitionChain[p].fieldValueLookup + " - ";
              }
              fullPartitionChainName = fullPartitionChainName + partition.leafMetricKey;
              for (let f in partition.data[d]){
                if (f != xAxisFieldName){
                  let el:any = {};
                  el[xAxisFieldName] = fullPartitionChainName;
                  el.value = partition.data[d][f];
                  availableSeries1[serie.name].data.push(el);
                }
              }
            }
          }
        }

        //series creation
        let wholeData = [];
        chart2.series.clear();
        for (let s in availableSeries1) {
          let series = chart2.series.push(new am4charts.PieSeries());
          series.dataFields.value = "value";
          series.dataFields.category = xAxisFieldName;
          series.slices.template.stroke = am4core.color("#fff");
          series.labels.template.maxWidth = 300;
          series.alignLabels = true;
          series.labels.template.wrap = true;
          series.slices.template.strokeWidth = 2;
          series.slices.template.strokeOpacity = 1;
          series.slices.template.tooltipText = "{category}";
          series.data = availableSeries1[s].data;
          series.name = availableSeries1[s].name;
          series.stacked = availableSeries1[s].stacked;
          let arrayToCalc = availableSeries1[s].data
          for(let i in arrayToCalc){
            arrayToCalc[0] = arrayToCalc[0] || {};
            arrayToCalc[1] = arrayToCalc[1] || {};
            arrayToCalc[0].percentage = (arrayToCalc[0].value * 100 / (arrayToCalc[0].value + arrayToCalc[1].value)).toFixed(2);
            arrayToCalc[1].percentage = (arrayToCalc[1].value * 100 / (arrayToCalc[1].value + arrayToCalc[0].value)).toFixed(2);
          }
          wholeData.push(...arrayToCalc);
          chart2.data = wholeData;
        }

        if(dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export){

          chart2.exporting.filePrefix = 'NextOne Platform -' + dashboardItem.dashboardItemConfig.name;
          chart2.exporting.dataFields = {"adminEntityId": "adminEntityId", "percentage": "percentage"};

           chart2.exporting.menu = new am4core.ExportMenu();
           let basicStructure = {
               "label": "...",
               "menu": []
           };

           if(dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export.image){
             basicStructure["menu"].push({
               "label": "Image",
               "menu": [
                 { "type": "png", "label": "PNG" },
                 { "type": "jpg", "label": "JPG" },
                 { "type": "pdf", "label": "PDF" }
               ]
             })
           }
           if(dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export.csv || dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export.excel ){
             let basicStructureData = {
                       "label": "Data",
                       "menu": []
             };

             if(dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export.csv){
               basicStructureData["menu"].push({ "type": "csv", "label": "CSV" });
             }
             if(dashboardItem.dashboardItemConfig.viewConfig.config.chartConfig.export.excel){
               basicStructureData["menu"].push({ "type": "xlsx", "label": "XLSX" });
             }

             basicStructure["menu"].push(basicStructureData);
           }

           chart2.exporting.menu.items = [basicStructure];

         }

        //legend
        //if (Object.keys(availableSeries1).length > 1 && !chart2.legend){
        //  chart2.legend = new am4charts.Legend();
        //}
      }

    }
    //chart2.hiddenState.properties.opacity = 0;
    chart2.validateData();
    return chart2;
  }

}

export class DashboardItemFactoryChart {

  public static createXYChartItem(_viewConfigConfig?:any):any{
    _viewConfigConfig = _viewConfigConfig || {};
    let viewConfigConfig = {};
    _.defaultsDeep(viewConfigConfig, _viewConfigConfig || {},{
    createNewChart: (id:any, data:DataResultInterface<any>, dashboardItem:DashboardItem) => {
    let chart1:any = am4core.create(id, am4charts["XYChart"]);
    return chart1;
    },
    dataChangedHook: Utils.xyChartWidgetDataChangedHook,
    });
    let viewConfig = DashboardItemViewSerialChart.of(
    SerialChartConfig.of(viewConfigConfig),
    ViewAssemblerFactory.getAssembler("idempotentViewAssembler")
    );

    return viewConfig;
  }

  public static createPieChartItem(_viewConfigConfig?:any):any{
    _viewConfigConfig = _viewConfigConfig || {};
    let viewConfigConfig = {};
    _.defaultsDeep(viewConfigConfig, _viewConfigConfig || {},{
    createNewChart: (id:any, data:DataResultInterface<any>, dashboardItem:DashboardItem) => {
    let chart1:any = am4core.create(id, am4charts["PieChart"]);
    return chart1;
    },
    dataChangedHook: Utils.pieChartWidgetDataChangedHook,
      legend: {
        showLegendIntoAccordion: false
      }
    });
    let viewConfig = DashboardItemViewSerialChart.of(
    SerialChartConfig.of(viewConfigConfig),
    ViewAssemblerFactory.getAssembler("idempotentViewAssembler")
    );


    return viewConfig;

  }

  public static createHeatmapItem(_viewConfigConfig?:any):any{
    _viewConfigConfig = _viewConfigConfig || {};
    let viewConfigConfig = {};
    _.defaultsDeep(viewConfigConfig, _viewConfigConfig || {},{
      createNewChart: (id:any, data:DataResultInterface<any>, dashboardItem:DashboardItem) => {
        let chart1:any = am4core.create(id, am4charts["XYChart"]);
        return chart1;
      },
      dataChangedHook: Utils.heatmapChartWidgetDataChangedHook,
      legend: {
        showLegendIntoAccordion: false
      }
    });
    let viewConfig = DashboardItemViewSerialChart.of(
    SerialChartConfig.of(viewConfigConfig),
    ViewAssemblerFactory.getAssembler("idempotentViewAssembler")
    );

    return viewConfig;

  }
}
