import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FilterModule} from "../../../filter/filter.module";
import {RouterModule} from "@angular/router";
import {GridsterModule} from "angular-gridster2";
import {AngularDashboardModule} from "dashboard-fe-angular";
import {ReccoOverviewComponent} from "./overview/recco-overview.component";

import {
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatOptionModule,
  MatListModule
} from '@angular/material';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { AgmCoreModule } from '@agm/core';
import {
  AuthGuardService,
  FuseSharedModule,
  FuseSidebarModule,
  FuseWidgetModule,
  IottacleNavigationService
} from "core-fe-angular";


const routes = [
  {
    path     : 'recco',
    canActivate: [AuthGuardService],
    children : [
      {
        path     : 'overview',
        component: ReccoOverviewComponent,
        canActivate: [AuthGuardService]
      }
    ]
  }
];

@NgModule({
  providers : [
  ],
  imports     : [
    CommonModule,
    FuseSharedModule,
    FuseSidebarModule,
    RouterModule.forChild(routes),
    FilterModule,
    GridsterModule,
    AngularDashboardModule,

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    FuseWidgetModule,
    NgxChartsModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBvZWorK4nmcjUJ1lP6HooJyGsCjYYyqn0'
    })
  ],
  declarations: [
    ReccoOverviewComponent
  ]
})
export class ReccoOverviewModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService
  ){

    //instruct navigation
    this._fuseNavigationService.addNavigationItem({
      'id'       : 'recco',
      'title'    : 'Recco',
      'translate': 'nav.recco.group-name',
      'type'     : 'group',
      'children' : [
        {
          'id'       : 'recco.overview',
          'title'    : 'Overview',
          'translate': 'nav.recco.overview',
          'type'     : 'item',
          'icon'     : 'timeline',
          'url'  : '/recco/overview'
        }
      ]
    }, "end");
  }
}
