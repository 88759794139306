export const locale = {
    lang: 'it',
    data: {
        'nav': {
            'smart-city': {
              'group-name' : 'Smart City',
              overview : "Overview",
              "park-usage" : "Uso Aree Verdi",
              "supermarket-usage" : "Supermarket"
            }

        }
    }
};
