import {Coordinate} from "iottacle-ts-models";


export class Circle{

  constructor(
    public strokeColor?:string,
    public strokeOpacity?:number,
    public strokeWeight?:number,
    public fillColor?:string,
    public fillOpacity?:number,
    public center?:Coordinate,
    public radius?:number
  ){

    }


    public static of(config:{
      strokeColor?:string,
      strokeOpacity?:number,
      strokeWeight?:number,
      fillColor?:string,
      fillOpacity?:number,
      center?:Coordinate,
      radius?:number
    }):Circle{
    return new Circle(
      config.strokeColor,
      config.strokeOpacity,
      config.strokeWeight,
      config.fillColor,
      config.fillOpacity,
      config.center,
      config.radius
    )
    }


}
