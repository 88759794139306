import {
  CloseableRepositoryInterface,
  RepositoryInterface
} from "iottacle-dashboard/dist/repositories/repository-interface";
import {Observable, of} from "rxjs";
import {DataSourceInterface} from "iottacle-dashboard";
import {
  AvgMetricAggregate, CardinalityMetricAggregate,
  ElasticAggregateQuerySyntax, ElasticAggregateQuerySyntaxImpl,
  ElasticBucketAggregateAbstract, ElasticBucketAggregateFactory,
  ElasticMetricAggregateAbstract, ElasticMetricAggregateFactory, HistogramBucketAggregate
} from "./elastic-aggregate-part";
import {
  ElasticStepQueryFactory,
  Funnel, FunnelImpl,
  FunnelReturnClause,
  FunnelReturnClauseImpl,
  StepQuery
} from "./iottacle-funnel-query";
import {
  BoolStepQueryElement, ElasticStepQueryElementFactory,
  IottacleStepTimeLevelStepQueryElement,
  MatchPhraseQueryElement,
  RangeLevelStepQueryElement
} from "./elastic-query-part";


export enum TimeDirection {
  before="BEFORE",
  after="AFTER"
}



export class Neo4jFunnelDashboardItemRepository extends CloseableRepositoryInterface{


  public constructor() {
    super();

    //AGGREGATE ELEMENTS
    ElasticMetricAggregateFactory.elasticMetricAggregates.avg = AvgMetricAggregate.of;
    ElasticMetricAggregateFactory.elasticMetricAggregates.cardinality = CardinalityMetricAggregate.of;
    ElasticBucketAggregateFactory.elasticBucketAggregates.histogram = HistogramBucketAggregate.of;

    //QUERY ELEMENTS
    ElasticStepQueryElementFactory.elasticQueryElements.range = RangeLevelStepQueryElement.of;
    ElasticStepQueryElementFactory.elasticQueryElements.match_phrase = MatchPhraseQueryElement.of;
    ElasticStepQueryElementFactory.elasticQueryElements.bool = BoolStepQueryElement.of;
    ElasticStepQueryElementFactory.elasticQueryElements.iottacle_step_time = IottacleStepTimeLevelStepQueryElement.of;



  }

  /**
   * aggregate is valid when has 0 or 1 bucket aggregations AND other metric aggregations are compatible with the one
   * that this class can deal with
   */
  static validBucketAggregates = ["terms", "histogram"];
  static validMetricAggregates = ["max", "min", "avg", "count", "cardinality"];


  public static isValidBucketAggregate(aggregate:ElasticAggregateQuerySyntax){
    let keys = Object.keys(aggregate);
    if (keys.indexOf("aggs") < 0){
      return false;
    }

    let valid = false;
    for (let b in this.validBucketAggregates){
      if (keys.indexOf(this.validBucketAggregates[b]) >= 0){
        valid = true;
      }
    }
    return valid;
  }

  public static isValidMetricAggregate(aggregate:ElasticAggregateQuerySyntax){
    let keys = Object.keys(aggregate);
    let valid = false;
    for (let b in this.validMetricAggregates){
      if (keys.indexOf(this.validMetricAggregates[b]) >= 0){
        valid = true;
      }
    }
    return valid;
  }

  public static aggregateIsValid(aggregate:ElasticAggregateQuerySyntax):boolean{

    let keys = Object.keys(aggregate);
    if (keys.length == 1) {
      for (let aggKey in aggregate[keys[0]]){
        if (aggKey != "aggs" && (this.validBucketAggregates.indexOf(aggKey) >= 0 || this.validMetricAggregates.indexOf(aggKey) >= 0)){
          return true;
        }
      }
      return false;
    } else {
      for (let k in keys) {
        for (let aggKey in aggregate[keys[k]]){
          if (aggregate[keys[k]].hasOwnProperty(aggKey)) {
            if (aggKey!=="key" && this.validMetricAggregates.indexOf(aggKey) < 0) {
              return false;
            }
          }
        }
      }
      return true;
    }
  }

  private static getStepQueryTemplate(step:number){
    let macAddressLabel = step==0? ":MacAddress" : "";
    return ` match (macAddress_0${macAddressLabel})-[:HAS]->(visits_${step}:Visits)-[:HAS]->(visit_${step}:Visit)<-[:HAS]-(adminVisits_${step}:AdminVisits)-[:HAPPENED_IN]->(locationDetails_${step}:LocationDetails)<-[:LOCATION_DETAILS]-(administrativeEntity_${step}:AdministrativeEntity) `;
  }

  private static buildWhereClauseForStep(step:number, stepDetails:StepQuery){
    let q = "WHERE true ";

    for (let s in stepDetails){
      q = q + " AND " + stepDetails[s].buildNeo4jQuery(step);
    }
    return q;
  }

  private static buildWithClauseForStep(step:number){
    let q = " WITH ";
    if (step === 0) {
      q = q + ` distinct macAddress_${step} as macAddress_${step}, {visit:visit_${step}, locationDetails:locationDetails_${step}, administrativeEntity:administrativeEntity_${step}} as step_${step} `;
    }else{
      for (let s = step-1; s >= 0 ; s--){
        q = q + ` step_${s} as step_${s}, `;
      }
      q = q + ` {visit:visit_${step}, locationDetails:locationDetails_${step}, administrativeEntity:administrativeEntity_${step}} as step_${step} `;
    }
    return q;
  }

  private static buildFinalWithClauseForStep(relativeToStep:number){
    return ` WITH step_${relativeToStep} as step `;
  }

  private static buildReturnClause(returnClause:FunnelReturnClause){
    let q = "";
    if (Neo4jFunnelDashboardItemRepository.aggregateIsValid(returnClause.aggs)){
      let before = [];
      let innerObject = {};
      for (let clause in returnClause.aggs){
        let rc:any = returnClause.aggs[clause];
        let beforeReturnQuery = rc.buildNeoQuery(innerObject);
        if (beforeReturnQuery && beforeReturnQuery.length > 0){
          before = before.concat(beforeReturnQuery);
        }
      }

      if (before.length > 0) {
        for (let a = 0; a < before.length; a++) {
          q = q + " WITH " + JSON.stringify(before[a]).replace(/"/g,"") + " AS step order by step.key asc ";
        }
        q = q + " WITH " + JSON.stringify(innerObject).replace(/"/g,"") + " AS step order by step.key asc RETURN {aggregations : collect(step)[0]} as step";
      }else{
        q = q + " WITH " + JSON.stringify(innerObject).replace(/"/g,"") + " AS step order by step.key asc RETURN {aggregations : collect(step)[0]} as step";
      }
    }
    return q;
  }

  getName(): string {
    return "Neo4jFunnelDashboardItemRepository";
  }

  invoke(dataSource: DataSourceInterface, funnel:FunnelImpl): Observable<any> {
    let q = "";
    for (let step in funnel.steps){
      let s:number = Number(step);
      q = q + Neo4jFunnelDashboardItemRepository.getStepQueryTemplate(s) +
        Neo4jFunnelDashboardItemRepository.buildWhereClauseForStep(s, funnel.steps[step]) +
        Neo4jFunnelDashboardItemRepository.buildWithClauseForStep(s);
    }
    q = q + Neo4jFunnelDashboardItemRepository.buildFinalWithClauseForStep(funnel.aggs.relativeToStep);

    let query = q + Neo4jFunnelDashboardItemRepository.buildReturnClause(funnel.aggs);

    return of(query);
  }

  rehydrate(serializedRepo: string): RepositoryInterface {
    return new Neo4jFunnelDashboardItemRepository();
  }

  serialize(): string {
    return JSON.stringify({
      name : "Neo4jFunnelDashboardItemRepository"
    });
  }
}
