import {DataSourceInterface} from "iottacle-dashboard";
import {Observable,Subject, throwError} from "rxjs";
import {map, retry} from "rxjs/operators";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {FilterInterface} from "iottacle-ts-models";
import _lodash from "lodash";
const _ = _lodash;
import deepmerge_ from 'deepmerge';
const deepmerge:any = deepmerge_;

export class GenericRepo implements CloseableRepositoryInterface{

    private currentPendingCalls:{
      [key:string] : Subject<any>
    } = {};

    constructor(private http:any, private urlPath:string) {}

    getName (){return "genericQueryRepo";}
    getChannelType () { return ChannelType.CLOSEABLE;}
    invoke (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> {
      try {
        let key = JSON.stringify(filter);
        if (this.currentPendingCalls[key]) {
          return this.currentPendingCalls[key].asObservable();
        } else {
          let s: Subject<any> = new Subject();
          this.currentPendingCalls[key] = s;

          const url = "quickanalyticsapi/executor/query";
          let body = {
            contextName : "Analytics",
            domainName : "DashboardItemAnalyticsQueries",
            queryName : this.urlPath,
            where: filter
          };
          this.http.post(url, body)
            .pipe(
              retry(3),
              map((res: any) => res.result)
            )
            .subscribe(
              (res:any) => s.next(res),
              (err:any) => {
                s.error(err);
                delete this.currentPendingCalls[key];
              },
              () => {
                s.complete();
                delete this.currentPendingCalls[key];
              });
          return s.asObservable();
        }
      }catch(e){
        return throwError(e);
      }
    }

    rehydrate(serializedAssembler:string):CloseableRepositoryInterface {
      return new GenericRepo(this.http, this.urlPath);
    }

    serialize():string {
      return JSON.stringify({
        name : "GenericRepo"
      })
    }
}
