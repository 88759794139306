import {FieldsName} from "../client-administrative-entity.module";

export const locale = {
    lang: 'en',
    data: {
      Client: {
        AdministrativeEntity: {
          title: "Administrative Entity",
          fields : {
            name: {
              _name: "Name"
            },
            active : {
              _name: "Active"
            },
            analyzeKpi: {
              _name: "Analyze KPI"
            },
            analyzeVisits: {
              _name: "Analyze Visits"
            },
            description: {
              _name: "Description"
            },
            address:{
              _name: "Address"
            },
            type: {
              _name: "Type"
            },
            tags: {
              _name: "Tags"
            },
            windowInactivityGapMs: {
              _name: "Window Inactivity Gap (ms)",
              _tooltipDescriptionInForm: "It is the milliseconds of tolerance (to receive a new probe for the visit) after which a new visit will be created"
            },
            id:{
              _name: "id"
            },
            administrativeEntityId:{
              _name: "Admin entity Id"
            },
            deltaTimeFrequencyMs:{
              _name: "Delta Time Frequency (ms)"
            },
            deltaTimeForFrequencyMs:{
              _name: "Delta Time For Frequency (ms)"
            },
            deltaTimeConsideringNewVisit:{
              _name: "Delta Time Considering New Visit (ms)"
            },
            externalVisitsQuery:{
              _name: "External Visits Query"
            },
            internalVisitsQuery:{
              _name: "Internal Visits Query"
            },
            dataCleanupConfig:{
              _name: "Data Cleanup Configurations",
              "start&end":{
                _name: "Duration",
                start:{
                  _name: "From"
                },
                end:{
                  _name: "To"
                }
              },
              weeklyMaxMissingDataTolerance:{
                _name: "Weekly max missing data tolerance (0..1)"
              }
            },
            deviceSetups:{
              _name: "Device Setups",
              id :{
                _name:"id"
              },
              "start&end":{
                _name: "Duration",
                start:{
                  _name: "From"
                },
                end:{
                  _name: "To"
                }
              },
              coordinate:{
                _name: "Coordinates"
              },
              maxPossibleDistanceMt:{
                _name: "Maximum Possible Distance (mt)"
              },
              gpsSupport:{
                _name: "GPS Support"
              },
              deviceId:{
                _name: "Device"
              }
            },
            organization:{
              _name: "Organization"
            },
            openingHours:{
              _name: "Opening Hours"
            },
            locationDetails:{
              _name: "Location Details",
              id:{
                _name: "Id"
              },
              name:{
                _name: "Name"
              },
              "start&end":{
                _name: "Duration",
                start:{
                  _name: "From"
                },
                end:{
                  _name: "To"
                }
              },
              asInside:{
                _name: "Consider As Internal Area"
              },
              polygon:{
                _name: "Polygon"
              },
              tags:{
                _name: "Tags"
              }
            },
            correctionFactors:{
              _name: "Correction Factors",
              "start&end":{
                _name: "Durata",
                start:{
                  _name: "From"
                },
                end:{
                  _name: "To"
                }
              },
              id:{
                _name: "Id"
              },
              dailyCorrectionFactor:{
                _name: "Daily Correction Factor"
              },
              hourlyCorrectionFactor:{
                _name: "Hourly Correction Factor"
              },
              passersByCorrectionFactor:{
                _name: "Passers-by Correction Factor"
              }
            },
            globalCorrectionFactors:{
              _name: "Global Correction Factors",
              "start&end":{
                _name: "Duration",
                start:{
                  _name: "From"
                },
                end:{
                  _name: "To"
                }
              },
              id:{
                _name: "Id"
              },
              factor:{
                _name: "Factor"
              }
            },
            internalVisitPartitioning:{
              _name: "Internal Visit Partitioning",
              id:{
                _name: "Id"
              },
              name:{
                _name: "Name"
              },
              filter:{
                _name: "Filter"
              }
            },
            antiCorruptionLayers:{
              _name: "Anti-corruption Layers",
              id:{
                _name: "Id"
              },
              externalSystemName:{
                _name: "External System Name"
              },
              fieldName:{
                _name: "Field Name"
              },
              fieldValue:{
                _name: "Field Value"
              }
            },
          }
        }
      }
    }
};
