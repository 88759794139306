import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DataSource,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {
  AuthService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {
  Filter,
  FilterQuickValues,
  MyAdministrativeEntity
} from "iottacle-ts-models";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import deepmerge_ from 'deepmerge';
const deepmerge:any = deepmerge_;
import _moment from 'moment';
import {
  EventDataSource
} from "../next-one-detail/event-data-source";
const moment = _moment;
import {
  DashboardItemFactory,
  DataSourceAssemblerChain,
  ElasticToIottacleDataSourceAssembler, ExternalCountPerDayFilter,
  InternalCountPerDayFilter,
  IottacleMultipleElasticGenericRepo,
  GroupByTagDataSourceAssembler,
  Utils, VisitExternalPasserByCorrectionFactorDataSourceAssembler,
  VisitGlobalCorrectionFactorDataSourceAssembler, WeeklyTimeBucketDataSourceAssembler
} from "dashboard-items-catalog";
import {DashboardConsolePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import {DashboardItemFactoryChart} from "../next-one-sky-insights-00/utils";

@Component({
  selector: 'next-one-sky-insights-01',
  templateUrl: './next-one-sky-insights-01.component.html',
  styleUrls: ['./next-one-sky-insights-01.component.scss']
})
export class NextOneSkyInsights01Component implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private translateService:TranslateService,
    private http:HttpClient
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {
      defaultFilterQuickValue : FilterQuickValues.THIS_YEAR
    }, this.translateService);
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {
    let dashboardItems:DashboardItem[] = [];

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let readOnly = true;

    //Visits over time
    let countOverTimeSamePeriodItem =  NextOneSkyInsights01Component.barChart_visitsAlongDaysWithEvents_TEMP_WEEKLY(readOnly, this.translateService, this.authService, this.http,
      {x:0, y:0, cols:36, rows:10}, DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService))), DashboardItemFactoryChart.createXYChartItem({
        series : {
          count : {
            type: "line"
          }
        },
        dateFormat: "weeklyView",
        advanceByAmount: 1,
        advanceByUnit: 'week',
        export:{
          excel:true,
          csv:true,
          image:true
        }
      }) );
    dashboardItems.push(countOverTimeSamePeriodItem);

    //external count over time
    let externalCountOverTimeSamePeriodItem = NextOneSkyInsights01Component.barChart_externalCountOverTimeSamePeriodItem_TEMP_WEEKLY(readOnly, this.translateService, this.http, {x:0, y:10, cols:36, rows:10}, DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService))), DashboardItemFactoryChart.createXYChartItem({
      series : {
        countExternal : {
          type: "line"
        }
      },
      dateFormat: "weeklyView",
      advanceByAmount: 1,
      advanceByUnit: 'week',
      export:{
        excel:true,
        csv:true,
        image:true
      }
    }));
    dashboardItems.push(externalCountOverTimeSamePeriodItem);


    // @ts-ignore
    let adminEntities:MyAdministrativeEntity[] = this.authService.getCurrentLoggedUser().getMyOrganizations().flatMap(org => org.getMyAdministrativeEntities());

    let context = {
      adminEntities: adminEntities,
      fieldValueLookup: {
        adminEntityId : {
          lookup:(value:any) => {
            let adminEntity = adminEntities.find((el) => el.administrativeEntityDetails.id == value);
            return adminEntity ? adminEntity.administrativeEntityDetails.name : value;
          }
        },
        newret:Utils.fieldValueLookup.newret,
        startDow:Utils.fieldValueLookup.startDow(this.translateService),
        startSod:Utils.fieldValueLookup.startSod,
      },
      translateService: this.translateService
    };

    let dashboardCfg = DashboardConfig.of(
      {
        id: "NextOneAccessBreakdown",
        name: "Next One",
        dashboardPersistency : DashboardConsolePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        },
        initFilter : this.filter.filterValues
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData(this.filter.filterValues, context);
          this.showDashboard = true;
          this.filterChangedSubscription = this.filter.filterChangedObservable()
            .subscribe((newFilterValue) => {
              this.dashboard.reloadData(newFilterValue, context);
              this.showDashboard = true;
            });
        }
      });

  }


  public static barChart_visitsAlongDaysWithEvents_TEMP_WEEKLY(readOnly:boolean, translateService:any, authService:any,
                                                   http:any, gridsterConfig:any, eventDataSource:DataSourceInterface, chartConfig:any){

    let item =  DashboardItemFactory.createXYChartItem({
      id: "0a05efc5-28d6-4143-a5f6-77e449653f18",
      readOnly: readOnly,
      specificItemConfig : {
        gridsterConfig : gridsterConfig
      },
      viewConfig: chartConfig,
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(new InternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY(http))),
        "2" : eventDataSource
      },
    }, translateService);

    return item;

  }

  public static barChart_externalCountOverTimeSamePeriodItem_TEMP_WEEKLY(readOnly:boolean, translateService:any, http:any, gridsterConfig:any, eventDataSource:DataSourceInterface ,chartConfig:any) {
    let item = DashboardItemFactory.createXYChartItem({
      id: "8afc6fb0-536f-4b86-a1ab-dcc0a1040d6e",
      readOnly: readOnly,
      specificItemConfig : {
        gridsterConfig : gridsterConfig //{x:0, y:18, cols:36, rows:10}
      },
      viewConfig: chartConfig,
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(new ExternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY(http))),
        "2" : eventDataSource
      },
    }, translateService);

    return item;
  }


}

export class InternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY implements DataSourceConfigInterfaceInternal{
  constructor(private http:any) {
  }
  name= "InternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY";
  type= "elastic";
  assembler = new DataSourceAssemblerChain([
    new ElasticToIottacleDataSourceAssembler(),
    new VisitGlobalCorrectionFactorDataSourceAssembler(),
    new GroupByTagDataSourceAssembler(),
    //new SeriesGroupDataSourceAssembler(),
    new WeeklyTimeBucketDataSourceAssembler()
  ]);
  repo:any = new IottacleMultipleElasticGenericRepo(this.http);
  filter = new InternalCountPerDayFilter().getFilter();
  specificDataSourceConfig = {};
}


export class ExternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY implements DataSourceConfigInterfaceInternal{
  constructor(private http:any) {
  }
  name= "ExternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY";
  type= "elastic";
  assembler = new DataSourceAssemblerChain([
    new ElasticToIottacleDataSourceAssembler(),
    new VisitExternalPasserByCorrectionFactorDataSourceAssembler(),
    new GroupByTagDataSourceAssembler(),
    //new SeriesGroupDataSourceAssembler(),
    new WeeklyTimeBucketDataSourceAssembler()
    //new VisitGlobalCorrectionFactorDataSourceAssembler()
  ]);
  repo = new IottacleMultipleElasticGenericRepo(this.http);
  filter = new ExternalCountPerDayFilter().getFilter();
  specificDataSourceConfig = {};
}
