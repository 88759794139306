export const locale = {
    lang: 'it',
    data: {
        labels: {
            partition: "Partiziona Visite",
            filter: "Filtro",
            store: "Punto Vendita",
            stores: "Punti Vendita",
            tags: "Tags",
            group: "Raggruppa vista",
            quick: "Rapido",
            relative: "Relativo",
            absolute: "Assoluto",
            newFilter: "Nuovo Filtro",
            from: "Da",
            to: "A",
            cancel: "Annulla",
            apply: "Applica Filtro",
            now: "ADESSO",
            when: "Quando",
            from2: "Dal",
            to2: "Al",
            noFilter: "Nessun Filtro",
            selectAll: "Seleziona tutto",
            weekdaysFilter: "Filtra Giorni Settimanali",
            weeklyAggregation: "Aggrega per settimana",
            weekdays: "giorni infrasettimanali",
            weekend: "weekend",
            mon: "Lun",
            tue: "Mar",
            wed: "Mer",
            thu: "Gio",
            fri: "Ven",
            sat: "Sab",
            sun: "Dom"
        },
        sentences: {
            availableAreasAndValidity: "Aree disponibili e periodo di validità",
            searchStores: "Cerca Punti Vendita...",
            searchTags: "Cerca Tag...",
            groupVisits: "Selezionando questa casella tutte le selezioni scelte (punti vendita e tag) verranno raggruppate in una unica serie",
            partitioning: "Selezionando questa casella, ogni serie verrà partizionata secondo i criteri definiti in fase di configurazione (es. criteri spaziali, di durata o entrambi)"
        },
        timeFilters: {
            thisWeek: "Questa settimana",
            thisMonth: "Questo mese",
            thisYear: "Questo anno",
            fromFirstDOW: "Da inizio settimana",
            fromFirstDOM: "Da inizio mese",
            fromFirstDOY: "Da inizio anno",
            lastWeek: "Settimana scorsa",
            lastMonth: "Mese scorso",
            lastYear: "Anno scorso",
            last7Days: "Ultimi 7 giorni",
            last30Days: "Ultimi 30 giorni",
            last60Days: "Ultimi 60 giorni",
            last90Days: "Ultimi 90 giorni",
            last6Months: "Ultimi 6 mesi",
            lastYearCurrent: "Ultimo anno",
            last2Years: "Ultimi 2 anni",
            daysAgo: "Giorni fa",
            weeksAgo: "Settimane fa",
            monthsAgo: "Mesi fa",
            yearsAgo: "Anni fa"
        },
        tags: {
            Region: "Regioni",
            Province: "Province",
            Nielsen: "Aree Nielsen",
            GenericGroup: "Cat. Generica"
        },
        floor: {
          floor_0: "Primo Piano",
          floor_1: "Secondo Piano",
        }
    }
};

