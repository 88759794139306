import {Utils} from "iottacle-dashboard/dist/utils/Utils";

export class CursorSyncManager{

  private registeredListeners:{[eventName:string]:{id:string, callback:((msg)=>any)}[]} = {};

  public on(eventName:string, callback:(msg)=>any){
    let id = Utils.guid();
    this.registeredListeners[eventName] = this.registeredListeners[eventName] || [];
    this.registeredListeners[eventName].push({id:id, callback:callback});
    return id;
  }

  public broadcastEvent(id:string, eventName:string, msg:any){
    if (this.registeredListeners[eventName]){
      for (let listener of this.registeredListeners[eventName]){
        if (listener.id != id) {
          setTimeout(listener.callback(msg));
        }
      }
    }
  }
}
