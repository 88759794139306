export const locale = {
    lang: 'en',
    data: {
      'nav': {
        'smart-city': {
          'group-name' : 'Smart City',
          overview : "Overview",
          "park-usage" : "Park Usage",
          "supermarket-usage" : "Supermarket Usage"
        }

      }
    }
};
