import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";

import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DashboardItemConfig, DashboardItemViewGeneric,
  DashboardItemViewMetricDelta, DashboardItemViewSerialChart,
  DataResultInterface, DataSource,
  DataSourceAssemblerFactory,
  DataSourceAssemblerInterface,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory, SerialChartConfig, ViewAssemblerFactory
} from "iottacle-dashboard";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardConsolePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import {
  AuthService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {TranslateService} from "@ngx-translate/core";
import {
  Filter, FilterInterface,
  FilterLocationReferences,
  FilterLocationReferencesStrategy,
  FilterQuickValues, FilterVisitJunkData, FilterVisitNewret, FilterVisitStrategy,
  FilterVisitTimeFilterStrategies
} from "iottacle-ts-models";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import {FilterBuilderGenericNeo} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-neo";
import * as am4core from "@amcharts/amcharts4/core";
import _ from "lodash";
import _moment from 'moment';
import {StoreUtils} from "../store-utils";



const moment = _moment;

@Component({
  selector: 'next-one-calendar',
  templateUrl: './next-one-calendar.component.html',
  styleUrls: ['./next-one-calendar.component.scss']
})
export class NextOneCalendarComponent implements OnInit, OnDestroy{

  organizationData:any;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private translateService:TranslateService
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    console.log("here");
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.organizationData = this.authService.getCurrentLoggedUser().getSelectedOrganization();
  }
}


