import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Observable, of, Subscription, zip} from "rxjs";
import {
  Dashboard,
  DashboardConfig,
  DashboardItem, DashboardItemConfig, DashboardItemViewGeneric, DashboardItemViewMetricDelta, DataResultInterface,
  DataSource,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface, GenericDataResult, MetricDeltaConfig, ObservedLevel
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {
  AuthService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {
  Filter,
  FilterQuickValues, FilterVisitTimeFilterStrategies,
  MyAdministrativeEntity
} from "iottacle-ts-models";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import deepmerge_ from 'deepmerge';
import _moment from 'moment';
import {
   DashboardItemFactoryChart
} from "./utils";
import {
  DashboardItemFactory,
  ExternalCountPerDayFilter, ExternalVisitsCountPerDayDataSourceForDailyCount,
  InternalCountPerDayFilter, InternalVisitsCountPerDayDataSourceForDailyCount,
  MultipleFilterBuilderGenericElastic,
  GroupByTagDataSourceAssembler,
  Utils, WeeklyTimeBucketDataSourceAssembler, DataSourceAssemblerChain
} from "dashboard-items-catalog";
import _lodash from "lodash";
import {QuarterTimeBucketDataSourceAssembler} from "./quarter-time-bucket-data-source-assembler";
import {YearTimeBucketDataSourceAssembler} from "./year-time-bucket-data-source-assembler";
import {map} from "rxjs/operators";
import { DashboardConsolePersistency } from 'context/ext/dashboard-persistency/dashboard-console-persistency';
import {CursorSyncManager} from "./cursor-sync-manager";
import {MediumVisitsCountPerDayDataSourceForDailyCount} from "dashboard-items-catalog/dist/src/data-sources/medium-visits-count-per-day-data-source-for-daily-count";
import {MediumCountPerDayFilter} from "dashboard-items-catalog/dist/src/filters/medium-count-per-day-filter";
const _ = _lodash;
const deepmerge:any = deepmerge_;
const moment = _moment;

@Component({
  selector: 'next-one-sky-insights-00',
  templateUrl: './next-one-sky-insights-00.component.html',
  styleUrls: ['./next-one-sky-insights-00.component.scss']
})
export class NextOneSkyInsights00Component implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private translateService:TranslateService,
    private http:HttpClient
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {
      defaultFilterQuickValue : FilterQuickValues.THIS_YEAR
    }, this.translateService);
    moment.locale(this.authService.getCurrentLoggedUser().localeLanguage || 'it');
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {
    let dashboardItems:DashboardItem[] = [];

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let readOnly = true;

    let cursorSyncManager = new CursorSyncManager();
    //Visits over time
    let countOverTimeSamePeriodItem =  NextOneSkyInsights00Component.barChart_visitsAlongDaysWithEvents_TEMP_WEEKLY(readOnly, this.translateService, this.authService, this.http,
      {x:0, y:0, cols:36, rows:11},
        //DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService))),
        DashboardItemFactoryChart.createXYChartItem({
          legend:{
            showLegendIntoAccordion:false,
          },
          periodicVerticalLine:{
            period: "quarter"
          },
          cursorSyncManager: cursorSyncManager,
          series : {
            count : {
              type: "line"
            }
          },
          dateFormat: "weeklyView",
          advanceByAmount: 1,
          advanceByUnit: 'week',
          export:{
            excel:true,
            csv:true,
            image:true
          }
        }) );
    dashboardItems.push(countOverTimeSamePeriodItem);

    //external count over time
    let externalCountOverTimeSamePeriodItem = NextOneSkyInsights00Component.barChart_externalCountOverTimeSamePeriodItem_TEMP_WEEKLY(
        readOnly, this.translateService, this.http, {x:0, y:10, cols:36, rows:11},
         //DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService))),
        DashboardItemFactoryChart.createXYChartItem({
          legend:{
            //position : "right",
            showLegendIntoAccordion:false,
          },
          periodicVerticalLine:{
            period: "quarter"
          },
          cursorSyncManager: cursorSyncManager,
          series : {
            countExternal : {
              type: "line"
            }
          },
          dateFormat: "weeklyView",
          advanceByAmount: 1,
          advanceByUnit: 'week',
          export:{
            excel:true,
            csv:true,
            image:true
          }
        }));
    dashboardItems.push(externalCountOverTimeSamePeriodItem);


    let skyTable = NextOneSkyInsights00Component.skyTable(readOnly, this.translateService, this.http, {x:0, y:22, cols:36, rows:15});
    dashboardItems.push(skyTable);


    // @ts-ignore
    let adminEntities:MyAdministrativeEntity[] = this.authService.getCurrentLoggedUser().getMyOrganizations().flatMap(org => org.getMyAdministrativeEntities());

    let context = {
      adminEntities: adminEntities,
      fieldValueLookup: {
        adminEntityId : {
          lookup:(value:any) => {
            let adminEntity = adminEntities.find((el) => el.administrativeEntityDetails.id == value);
            return adminEntity ? adminEntity.administrativeEntityDetails.name : value;
          }
        },
        newret:Utils.fieldValueLookup.newret,
        startDow:Utils.fieldValueLookup.startDow(this.translateService),
        startSod:Utils.fieldValueLookup.startSod,
      },
      translateService: this.translateService
    };

    let dashboardCfg = DashboardConfig.of(
      {
        id: "NextOneAccessBreakdown",
        name: "Next One",
        dashboardPersistency : DashboardConsolePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        },
        initFilter : this.filter.filterValues
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.filter.filterValues.selectedYear = Number(moment().subtract(1, "year").format("YYYY"));
          this.dashboard.reloadData(this.filter.filterValues, context);
          this.showDashboard = true;
          this.filterChangedSubscription = this.filter.filterChangedObservable()
            .subscribe((newFilterValue) => {
              this.dashboard.reloadData(newFilterValue, context);
              this.showDashboard = true;
            });
        }
      });

  }


  public static barChart_visitsAlongDaysWithEvents_TEMP_WEEKLY(readOnly:boolean, translateService:any, authService:any,
                                                   http:any, gridsterConfig:any, chartConfig:any){

    let item =  DashboardItemFactory.createXYChartItem({
      id: "73dc1edc-3a2c-4625-8bd3-c7385e07d677",
      readOnly: readOnly,
      specificItemConfig : {
        gridsterConfig : gridsterConfig
      },
      viewConfig: chartConfig,
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(new InternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY(http))),
      },
    }, translateService);

    return item;
  }

  public static barChart_externalCountOverTimeSamePeriodItem_TEMP_WEEKLY(readOnly:boolean, translateService:any, http:any, gridsterConfig:any,chartConfig:any) {
    let item = DashboardItemFactory.createXYChartItem({
      id: "eb3cb899-530d-4aef-bd7c-fbc18bc39d75",
      readOnly: readOnly,
      specificItemConfig : {
        gridsterConfig : gridsterConfig //{x:0, y:18, cols:36, rows:10}
      },
      viewConfig: chartConfig,
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(new ExternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY(http)))
      },
    }, translateService);

    return item;
  }


  public static skyTable(readOnly:boolean, translateService:any, http:any, gridsterConfig:any) {
    let item = NextOneSkyInsights00Component.createSkyTableDashboardItem({
      id: "5ac01bec-98b5-4ba4-9061-8ae4231e0183",
      readOnly: readOnly,
      specificItemConfig : {
        gridsterConfig : gridsterConfig //{x:0, y:18, cols:36, rows:10}
      },
      dataSources : {
        //"externalSelectedWeek" : DataSource.of(DataSourceConfig.of(new ExternalVisitsCountPerDayDataSourceForDailyCountSelectedWeek(http))),
        //"externalSelectedQuarter" : DataSource.of(DataSourceConfig.of(new ExternalVisitsCountPerDayDataSourceForDailyCount(http))),
        "externalThisYear" : DataSource.of(DataSourceConfig.of(new ExternalVisitsCountPerDayDataSourceForDailyCountThisYear(http))),
        "externalSelectedYear" : DataSource.of(DataSourceConfig.of(new ExternalVisitsCountPerDayDataSourceForDailyCountSelectedYear(http))),
        //"potentialSelectedWeek" : DataSource.of(DataSourceConfig.of(new MediumVisitsCountPerDayDataSourceForDailyCountSelectedWeek(http))),
        //"potentialSelectedQuarter" : DataSource.of(DataSourceConfig.of(new MediumVisitsCountPerDayDataSourceForDailyCount(http))),
        "potentialThisYear" : DataSource.of(DataSourceConfig.of(new MediumVisitsCountPerDayDataSourceForDailyCountThisYear(http))),
        "potentialSelectedYear" : DataSource.of(DataSourceConfig.of(new MediumVisitsCountPerDayDataSourceForDailyCountSelectedYear(http))),
        //"internalSelectedWeek" : DataSource.of(DataSourceConfig.of(new InternalVisitsCountPerDayDataSourceForDailyCountSelectedWeek(http))),
        //"internalSelectedQuarter" : DataSource.of(DataSourceConfig.of(new InternalVisitsCountPerDayDataSourceForDailyCount(http))),
        "internalThisYear" : DataSource.of(DataSourceConfig.of(new InternalVisitsCountPerDayDataSourceForDailyCountThisYear(http))),
        "internalSelectedYear" : DataSource.of(DataSourceConfig.of(new InternalVisitsCountPerDayDataSourceForDailyCountSelectedYear(http))),
      },
    }, translateService);
    return item;
  }
  public static createSkyTableDashboardItem(config:any, translateService:any):DashboardItem{
    let id = config.id || Utils.generateGUID();
    let readOnly = config.readOnly != undefined? config.readOnly : true;

    let viewConfigConfig = {};
    _.defaultsDeep(viewConfigConfig, config._viewConfigConfig || {});

    let finalConfig = {itemType : "sky-weekly-table-item"};
    _.defaultsDeep(finalConfig, config, {
      id: id,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : translateService.instant('widgets.skyTable.'+id+'.name'),
      showHeader : true,
      readOnly: readOnly,
      showName: true,
      autoUpdateMs : 0,
      subtitle: translateService.instant('widgets.skyTable.'+id+'.subtitle'),
      infoButtonText: translateService.instant('widgets.skyTable.'+id+'.infoButtonText'),
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:0,
          cols:15,
          rows:5,
          dragEnabled: !readOnly,
          resizeEnabled: ! readOnly
        }
      },
      dataSources : {},
      assembler : new DashboardItemAssemblerSkyTable()
    });

    let item =  DashboardItem.of(DashboardItemConfig.of(finalConfig));
    return item;
  }
}

export class DashboardItemAssemblerSkyTable{
  name =  "DashboardItemAssemblerSkyTable";
  assemble (result: { [dataSourceId: string]: any }, thiz: DashboardItem, context: any, userFilter: any):Observable<DataResultInterface<any>>{
    try {
      if (!result) {
        return of(GenericDataResult.ofSuccess(thiz.getId().toString(), {}));
      }
      let newResult:any = {series:{}};

      let observables = [];
      //external
      if (result.externalThisYear){
        newResult.series.externalThisYear = result.externalThisYear;
        let clonedExternalYear = _.cloneDeep(result.externalThisYear);
        observables.push( new WeeklyTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().externalThisYear.getDataSource(), {}, userFilter)
          .pipe(
            map(res => {
              newResult.series.externalThisYearWeekly = res.result;
              return res;
            })
          ));
        observables.push( new QuarterTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().externalThisYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.externalThisQuarter = res.result;
                  return res;
                })
            ));
        observables.push(new YearTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().externalThisYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.externalThisYearAvg =  res.result;
                  return res;
                })
            ));
      }
      if (result.externalSelectedYear){
        newResult.series.externalSelectedYear = result.externalSelectedYear;
        let clonedExternalYear = _.cloneDeep(result.externalSelectedYear);
        observables.push(new WeeklyTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().externalSelectedYear.getDataSource(), {}, userFilter)
          .pipe(
            map(res => {
              newResult.series.externalSelectedYearWeekly =  res.result;
              return res;
            })
          ));

        observables.push(new QuarterTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().externalSelectedYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.externalSelectedQuarter =  res.result;
                  return res;
                })
            ));
        observables.push(new YearTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().externalSelectedYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.externalSelectedYearAvg =  res.result;
                  return res;
                })
            ));
      }

      //potential
      if (result.potentialThisYear){
        newResult.series.potentialThisYear = result.potentialThisYear;
        let clonedExternalYear = _.cloneDeep(result.potentialThisYear);
        observables.push(new WeeklyTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().potentialThisYear.getDataSource(), {}, userFilter)
          .pipe(
            map(res => {
              newResult.series.potentialThisYearWeekly = res.result;
              return res;
            })
          ));
        observables.push(new QuarterTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().potentialThisYear.getDataSource(), {})
            .pipe(
              map(res => {
                newResult.series.potentialThisQuarter = res.result;
                return res;
              })
            ));
        observables.push( new YearTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().potentialThisYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.potentialThisYearAvg = res.result;
                  return res;
                })
            ));
      }
      if (result.potentialSelectedYear){
        newResult.series.potentialSelectedYear = result.potentialSelectedYear;
        let clonedExternalYear = _.cloneDeep(result.potentialSelectedYear);
        observables.push(new WeeklyTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().potentialSelectedYear.getDataSource(), {}, userFilter)
          .pipe(
            map(res => {
              newResult.series.potentialSelectedYearWeekly = res.result;
              return res;
            })
          ));
        observables.push(new QuarterTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().potentialSelectedYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.potentialSelectedQuarter = res.result;
                  return res;
                })
            ));
        observables.push( new YearTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().potentialSelectedYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.potentialSelectedYearAvg = res.result;
                  return res;
                })
            ));
      }

      //internal
      if (result.internalThisYear){
        newResult.series.internalThisYear = result.internalThisYear;
        let clonedExternalYear = _.cloneDeep(result.internalThisYear);
        observables.push( new WeeklyTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().internalThisYear.getDataSource(), {}, userFilter)
          .pipe(
            map(res => {
              newResult.series.internalThisYearWeekly = res.result;
              return res;
            })
          ));
        observables.push( new QuarterTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().internalThisYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.internalThisQuarter = res.result;
                  return res;
                })
            ));
        observables.push( new YearTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().internalThisYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.internalThisYearAvg = res.result;
                  return res;
                })
            ));
      }
      if (result.internalSelectedYear){
        newResult.series.internalSelectedYear = result.internalSelectedYear;
        let clonedExternalYear = _.cloneDeep(result.internalSelectedYear);
        observables.push(  new WeeklyTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().internalSelectedYear.getDataSource(), {}, userFilter)
          .pipe(
            map(res => {
              newResult.series.internalSelectedYearWeekly = res.result;
              return res;
            })
          ));
        observables.push(  new QuarterTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().internalSelectedYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.internalSelectedQuarter = res.result;
                  return res;
                })
            ));
        observables.push( new YearTimeBucketDataSourceAssembler().assemble(clonedExternalYear, thiz.getDataSourceProxies().internalSelectedYear.getDataSource(), {})
            .pipe(
                map(res => {
                  newResult.series.internalSelectedYearAvg = res.result;
                  return res;
                })
            ));
      }

      return forkJoin(observables, (res) => GenericDataResult.ofSuccess(thiz.getId().toString(), newResult));
    }catch (e){
      return of(GenericDataResult
          .ofError(thiz.getId().toString())
          .internalFeError({
            errorDescription: "error for itemId: " + thiz.getId().toString() + " name: " + thiz.getName(),
            errorDetails: e
          }));
    }
  }

  serialize () {
    return JSON.stringify({
      name : "DashboardItemAssemblerSkyTable"
    });
  }

  rehydrate(serializedAssembler:string){
    return new DashboardItemAssemblerSkyTable();
  }

}

export class InternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY extends InternalVisitsCountPerDayDataSourceForDailyCount{
  constructor(http:any) {
    super(http);
    let temp = new InternalVisitsCountPerDayDataSourceForDailyCount(http);
    (temp.assembler as DataSourceAssemblerChain).assemblers = (temp.assembler as DataSourceAssemblerChain).assemblers.map(a => {
      if (a instanceof GroupByTagDataSourceAssembler){
        return new GroupByTagDataSourceAssembler('avg');
      // }else if (a instanceof SeriesGroupDataSourceAssembler){
      //   return new SeriesGroupDataSourceAssembler('avg');
      }else{
        return a;
      }
    });

    (temp.assembler as DataSourceAssemblerChain).pushAssemblerIntoChain(new WeeklyTimeBucketDataSourceAssembler());
    this.assembler = temp.assembler;
  }
  name= "InternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY";
}

export class ExternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY extends ExternalVisitsCountPerDayDataSourceForDailyCount{
  constructor(http:any) {
    super (http);
    let temp = new ExternalVisitsCountPerDayDataSourceForDailyCount(http);
    temp.assembler.assemblers = temp.assembler.assemblers.map(a => {
      if (a instanceof GroupByTagDataSourceAssembler){
        return new GroupByTagDataSourceAssembler('avg');
      // }else if (a instanceof SeriesGroupDataSourceAssembler){
      //   return new SeriesGroupDataSourceAssembler('avg');
      }else{
        return a;
      }
    });
    temp.assembler.pushAssemblerIntoChain(new WeeklyTimeBucketDataSourceAssembler());
    this.assembler = temp.assembler;
  }
  name= "ExternalVisitsCountPerDayDataSourceForDailyCount_TEMP_WEEKLY";
}

export class ExternalVisitsCountPerDayDataSourceForDailyCountSelectedYear extends ExternalVisitsCountPerDayDataSourceForDailyCount{

  constructor(http:any) {
    super(http);
    let temp = new ExternalVisitsCountPerDayDataSourceForDailyCount(http);
    temp.assembler.assemblers = temp.assembler.assemblers.map(a => {
      if (a instanceof GroupByTagDataSourceAssembler){
        return new GroupByTagDataSourceAssembler('avg');
      // }else if (a instanceof SeriesGroupDataSourceAssembler){
      //   return new SeriesGroupDataSourceAssembler('avg');
      }else{
        return a;
      }
    });
    this.assembler = temp.assembler;

    let newFilter = new ExternalCountPerDayFilter(new MultipleFilterBuilderGenericElastic({considerVisitPartitioning:false, allowGrouping:true})).getFilter();
    newFilter.customData.timeStrategy = {
      timestampField: 'start',
      strategy: FilterVisitTimeFilterStrategies.QUICK,
      quickValue: FilterQuickValues.PREVIOUS_YEAR_UP_TO_THIS_WEEK
    };
    this.filter = newFilter;
  }
  name= "ExternalVisitsCountPerDayDataSourceForDailyCountSelectedYear";
}

export class ExternalVisitsCountPerDayDataSourceForDailyCountThisYear extends ExternalVisitsCountPerDayDataSourceForDailyCount{

  constructor(http:any) {
    super(http);
    let temp = new ExternalVisitsCountPerDayDataSourceForDailyCount(http);
    temp.assembler.assemblers = temp.assembler.assemblers.map(a => {
      if (a instanceof GroupByTagDataSourceAssembler){
        return new GroupByTagDataSourceAssembler('avg');
      // }else if (a instanceof SeriesGroupDataSourceAssembler){
      //   return new SeriesGroupDataSourceAssembler('avg');
      }else{
        return a;
      }
    });
    this.assembler = temp.assembler;


    let newFilter = new ExternalCountPerDayFilter(new MultipleFilterBuilderGenericElastic({considerVisitPartitioning:false, allowGrouping:true})).getFilter();
    newFilter.customData.timeStrategy = {
      timestampField: 'start',
      strategy: FilterVisitTimeFilterStrategies.QUICK,
      quickValue: FilterQuickValues.THIS_YEAR
    };
    this.filter = newFilter;
  }
  name= "ExternalVisitsCountPerDayDataSourceForDailyCountThisYear";
}

export class MediumVisitsCountPerDayDataSourceForDailyCountSelectedYear extends MediumVisitsCountPerDayDataSourceForDailyCount{

  constructor(http:any) {
    super(http);
    let temp = new MediumVisitsCountPerDayDataSourceForDailyCount(http);
    temp.assembler.assemblers = temp.assembler.assemblers.map(a => {
      if (a instanceof GroupByTagDataSourceAssembler){
        return new GroupByTagDataSourceAssembler('avg');
      // }else if (a instanceof SeriesGroupDataSourceAssembler){
      //   return new SeriesGroupDataSourceAssembler('avg');
      }else{
        return a;
      }
    });
    //temp.assembler.pushAssemblerIntoChain(new WeeklyTimeBucketDataSourceAssembler());
    this.assembler = temp.assembler;

    let newFilter = new MediumCountPerDayFilter(new MultipleFilterBuilderGenericElastic({considerVisitPartitioning:false, allowGrouping:true})).getFilter();
    newFilter.customData.timeStrategy = {
      timestampField: 'start',
      strategy: FilterVisitTimeFilterStrategies.QUICK,
      quickValue: FilterQuickValues.PREVIOUS_YEAR_UP_TO_THIS_WEEK
    };
    this.filter = newFilter;
  }
  name= "MediumVisitsCountPerDayDataSourceForDailyCountSelectedYear";
}

export class MediumVisitsCountPerDayDataSourceForDailyCountThisYear extends MediumVisitsCountPerDayDataSourceForDailyCount{

  constructor(http:any) {
    super(http);
    let newFilter = new MediumCountPerDayFilter(new MultipleFilterBuilderGenericElastic({considerVisitPartitioning:false, allowGrouping:true})).getFilter();
    newFilter.customData.timeStrategy = {
      timestampField: 'start',
      strategy: FilterVisitTimeFilterStrategies.QUICK,
      quickValue: FilterQuickValues.THIS_YEAR
    };
    this.filter = newFilter;

    let temp = new MediumVisitsCountPerDayDataSourceForDailyCount(http);
    temp.assembler.assemblers = temp.assembler.assemblers.map(a => {
      if (a instanceof GroupByTagDataSourceAssembler){
        return new GroupByTagDataSourceAssembler('avg');
      // }else if (a instanceof SeriesGroupDataSourceAssembler){
      //   return new SeriesGroupDataSourceAssembler('avg');
      }else{
        return a;
      }
    });
    //temp.assembler.pushAssemblerIntoChain(new WeeklyTimeBucketDataSourceAssembler());
    this.assembler = temp.assembler;
  }
  name= "MediumVisitsCountPerDayDataSourceForDailyCountThisYear";
}

export class InternalVisitsCountPerDayDataSourceForDailyCountSelectedYear extends InternalVisitsCountPerDayDataSourceForDailyCount{

  constructor(http:any) {
    super(http);
    let temp = new InternalVisitsCountPerDayDataSourceForDailyCount(http);
    (temp.assembler as DataSourceAssemblerChain).assemblers = (temp.assembler as DataSourceAssemblerChain).assemblers.map(a => {
      if (a instanceof GroupByTagDataSourceAssembler){
        return new GroupByTagDataSourceAssembler('avg');
      }else{
        return a;
      }
    });

    this.assembler = temp.assembler;


    let newFilter = new InternalCountPerDayFilter(new MultipleFilterBuilderGenericElastic({overrideVisitPartitioning:true, considerVisitPartitioning:true, allowGrouping:true})).getFilter();
    newFilter.customData.timeStrategy = {
      timestampField: 'start',
      strategy: FilterVisitTimeFilterStrategies.QUICK,
      quickValue: FilterQuickValues.PREVIOUS_YEAR_UP_TO_THIS_WEEK
    };
    this.filter = newFilter;
  }
  name= "InternalVisitsCountPerDayDataSourceForDailyCountSelectedYear";

}

export class InternalVisitsCountPerDayDataSourceForDailyCountThisYear extends InternalVisitsCountPerDayDataSourceForDailyCount{

  constructor(http:any) {

    super(http);

    let temp = new InternalVisitsCountPerDayDataSourceForDailyCount(http);
    (<DataSourceAssemblerChain>temp.assembler).assemblers = (<DataSourceAssemblerChain>temp.assembler).assemblers.map(a => {
      if (a instanceof GroupByTagDataSourceAssembler){
        return new GroupByTagDataSourceAssembler('avg');
      // }else if (a instanceof SeriesGroupDataSourceAssembler){
      //   return new SeriesGroupDataSourceAssembler('avg');
      }else{
        return a;
      }
    });
    this.assembler = temp.assembler;


    let newFilter = new InternalCountPerDayFilter(new MultipleFilterBuilderGenericElastic({overrideVisitPartitioning:true, considerVisitPartitioning:true, allowGrouping:true})).getFilter();
    newFilter.customData.timeStrategy = {
      timestampField: 'start',
      strategy: FilterVisitTimeFilterStrategies.QUICK,
      quickValue: FilterQuickValues.THIS_YEAR
    };
    this.filter = newFilter;
  }
  name= "InternalVisitsCountPerDayDataSourceForDailyCountThisYear";

}
