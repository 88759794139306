export const locale = {
    lang: 'it',
    data: {
        Device:{
          Device:{
            title: "Device",
            fields:{
              id:{
                _name: "Id"
              },
              deviceId:{
                _name:"deviceId"
              },
              acl:{
                _name: "Acl",
                externalSystemName: {
                  _name: "External System Name"
                },
                externalFieldName: {
                  _name: "External Field Name"
                },
                externalFieldValue: {
                  _name: "External Field Value"
                },
                mDashToken: {
                  _name: "mDashToken"
                }
              },
              description:{
                _name: "description"
              },
              status: {
                _name: "status"
              },
              liveValues:{
                _name: "liveValues",
                phenomenon:{
                  _name: "phenomenon"
                },
                value1d:{
                  _name: "value1d"
                },
                value2d:{
                  _name: "value2d"
                },
                value3d:{
                  _name: "value3d"
                },
                value4d:{
                  _name: "value4d"
                },
                value5d:{
                  _name: "value5d"
                },
                value1s:{
                  _name: "value1s"
                },
                value2s:{
                  _name: "value2s"
                },
                value3s:{
                  _name: "value3s"
                },
                value4s:{
                  _name: "value4s"
                },
                value5s:{
                  _name: "value5s"
                },
                template:{
                  _name:"template"
                },
                envVariables:{
                  _name: "envVariables"
                }
              },
              template:{
                _name:"template"
              }
            }
          }
        },
        widgets:{
          chart:{
            "eb58e4a5-18ab-46a9-8284-6379a6dc462c":{
              infoButtonText:"Questo grafico mostra i dati ricevuti dal device in tempo reale",
              name: "Ricezione dati real-time",
              chart:{
                yAxes:{
                  "0": {
                    title: {
                      text: "Quantità dati ricevuti"
                    }
                  }
                }
              }
            }
          }
        }
    }
};
