export const locale = {
  lang: 'it',
  data: {
    'nav': {
      'recco': {
        'group-name' : 'Recco',
        'overview' : "Overview",
        'vehicle':{
			       'group-name' : 'Vehicles',
				   "list":'List'
			    }        
      }
    }
  }
};
