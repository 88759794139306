import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from "rxjs";

import {
  Dashboard,
  DashboardConfig,
  DashboardItem, DashboardItemAssemblerFactory,
  DashboardItemConfig, DashboardItemViewGeneric,
  DashboardItemViewMetricDelta,
  DataResultInterface, DataSource,
  DataSourceAssemblerFactory,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal, DataSourceInterface, FilterBuilderFactory,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory,
} from "iottacle-dashboard";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {locale as english} from "../../i18n/en";
import {locale as italian} from "../../i18n/it";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material";
import {Beacon} from "../../../../../../context/device/beacon/beacon";
import {
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  fuseAnimations
} from "core-fe-angular";
import {AuthService, FuseConfigService, FuseTranslationLoaderService} from "core-fe-angular";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Entity, SchemaFieldDefinitionToColumnsForDataTableDashboardItem} from "dashboard-fe-angular";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import {FilterInterface} from "iottacle-ts-models";


@Component({
  selector: 'device-beacon-list',
  templateUrl: './device-beacon-list.component.html',
  styleUrls: ['./device-beacon-list.component.scss'],
  animations : fuseAnimations
})
export class DeviceBeaconListComponent implements OnInit, OnDestroy{

  itemsList:DashboardItem;
  beaconEntity:Entity;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private httpClient:HttpClient
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let filterBuilder:FilterBuilderInterface = new FilterBuilderGenericElastic();
    this.beaconEntity = new Beacon(this.httpClient);

    let beaconDataSource:DataSourceConfigInterfaceInternal = {
      name: "beaconDataSource",
      type: "elastic",
      assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "custom",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          return this.beaconEntity.getAllEntities();
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return undefined;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    this.itemsList =  DashboardItem.of(DashboardItemConfig.of({
      id: "2"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Beacon List" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "data-table",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        addItem:{
          entityModel : this.beaconEntity,
          //fieldsToUse: []
        },
        showTopText : false,
        topTextMatIconColorClass: "orange-fg",
        topTextTitle: "",
        topTextMatIcon: "warning",
        topTextSubtitle: "",
        columns: SchemaFieldDefinitionToColumnsForDataTableDashboardItem.translate(this.beaconEntity, undefined),
        gridsterConfig : {
          x:19,
          y:0,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(beaconDataSource))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    this.itemsList.reloadData();




  }


}


