export const locale = {
    lang: 'en',
    data: {
      nav: {
          store: {
            'group-name' : "Store",
            overview : "Overview",
            detailGroup : 'Store details',
            detail : {
              detail : 'Details',
              'return-rate' : "Return Rate",
              opportunity : "Opportunity",
              accesses : "Accesses"
            },
            one:{
              overview:{
                dashboardItems: {
                  occupancyOverHodAndDow: {
                    name: "Today visits against average",
                    infoButtonText: "This graph shows the visits' average distribution of the current day and the occupancy right now. The average distribution is evaluated over a period of 30 days",
                    chart: {
                      yAxes: {
                        0: {
                          title: {
                            text: "Average Occupancy"
                          }
                        }
                      },
                      series: {
                        0: {
                          columns: {
                            tooltipText: "Average hourly count\nHour: {categoryX}\nAverage count: {valueY}"
                          }
                        }
                      },
                      occupancyMessages:{
                        low: "Less crowded than usual",
                        med: "Crowded as usual",
                        high: "More crowded than usual",
                      }
                    }
                  }
                }
              }
            }
          }
      },
    widgets: {
        metricDelta:{
          "378debcb-65b1-44a0-a3a2-888860045f08":{
            name: "Visits",
            subtitle:"Difference against reference period",
            infoButtonText:"Total number of visits over the selected periond. The 'reference period' refers to the previous period of the selected period. For example: if the selected filter is 'last 30 days' than the reference period will be 'from 60 to 30 days before'. ",
            viewConfig:{
              "postfix":"visits",
              "variationSign": "%"
            }
          },
          "378debcb-65b1-44a0-a3a2-888860045f08P":{
            name: "Potential",
            subtitle:"Difference against reference period",
            infoButtonText:"Total number of potential over the selected periond. The 'reference period' refers to the previous period of the selected period. For example: if the selected filter is 'last 30 days' than the reference period will be 'from 60 to 30 days before'. ",
            viewConfig:{
              "postfix":"potential",
              "variationSign": "%"
            }
          },
          "21ca3123-9097-401a-8e15-760e6b41a0c2":{//per overview page
            name: "Last week's visits",
            subtitle:"Difference against reference period",
            infoButtonText:"Total number of visits over the selected period. The 'reference period' refers to the previous period of the selected period. For example: if the selected filter is 'last 30 days' than the reference period will be 'from 60 to 30 days before'. ",
            viewConfig:{
              "postfix":"visits",
              "variationSign": "%"
            }
          },
          "b62d7ba0-f183-4296-abb2-33569b5ed634":{
            name: "Visitors",
            subtitle:"Difference against reference period",
            infoButtonText:"Number of unique visitors detected. The 'reference period' refers to the previous period of the selected period. For example: if the selected filter is 'last 30 days' than the reference period will be 'from 60 to 30 days before'. ",
            viewConfig:{
              "postfix":"visitors",
              "variationSign": ""
            }
          },
          "c4831b24-95f9-483c-b924-eb4aacd6660d":{
            name: "Days since last visit",
            subtitle:"Difference against reference period",
            infoButtonText:"(Average) number of days passed since the last visit, based on external visitors",
            viewConfig:{
              "postfix":"days",
              "variationSign": ""
            }
          },
          "8b880152-6185-4629-b6f7-90ab09d0653b":{
            name: "Average Weekly Visits",
            subtitle:"Difference against reference period",
            infoButtonText:"Average number of weekly visits. This value is calculated over all visitors that made at least 2 visits",
            viewConfig:{
              "postfix":"times",
              "variationSign": ""
            }
          }
        },
        textMetric:{
          "9e60366d-786e-4773-8c27-ebcb317fc873":{
            name: "Visit duration",
            subtitle:"Difference against reference period",
            infoButtonText:"This value shows the duration's visits range of the POI. The 'reference period' refers to the previous period of the selected period. For example: if the selected filter is 'last 30 days' than the reference period will be 'from 60 to 30 days before'. ",
            viewConfig:{
              "postfix":"min"
            }
          },
          "1b585688-8f7b-46a2-9fe0-7521b8e1f2d4":{
            name: "Last week's visit duration",
            subtitle:"Previous week",
            infoButtonText:"This value shows the duration's visits range of the POI. The 'reference period' refers to the previous period of the selected period. For example: if the selected filter is 'last 30 days' than the reference period will be 'from 60 to 30 days before'. ",
            viewConfig:{
              "postfix":"min"
            }
          }
        },
        htmlText: {
          "e5cded76-ab98-4c9e-9614-fca074c0fc86": {
            repoText: "# Loyalty \n In this section you can evaluate the performances of your stores under customer's loyalty perspective (retention, churn rate, new/return...)"
          },
          "6f608737-3e9b-41b5-bd98-2e1a0547d84d":{
            repoText:"# Re-gained Customers \n In this section you can find 're-gained' analysis of your customers"
          },
          "59959e40-ce48-411d-8db5-f86a617b1975":{
            repoText:"# Frequency \n In this section you can find frequency analysis of your customers"
          },
          //Opportunity
          "acd623f7-9ce8-495f-8c28-c5aa5de006d3":{
            repoText:"# Opportunity \n In this section you can find all the KPI related with external passages related with internal visits"
          }
        },
        chart : {
          //details
          "0a05efc5-28d6-4143-a5f6-77e449653f18":{
            name : "Visits",
            infoButtonText: "This graph shows the daily count of the visits in the selected period of time",
            chart:{
              yAxes:{
                "0":{
                  title:{
                    text: "#Visits"
                  }
                }
              }
            }
          },
          "b33616fa-cbe7-4251-afed-632bcaf593fc":{
            name : "Visits distribution by day of week",
            infoButtonText: "This graph shows visits distribution over days of week. Values as evaluated in the selected time period",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "% of visits over total"
                  }
                }
              }
            }
          },
          "da9e3eef-9965-4488-8510-4f59ebd6a4d5":{
            name : "Visits distribution by day of week and hour of day",
            infoButtonText: "This map shows visits distribution for every hour of every day of week. Values as evaluated in the selected time period",
          },
          "ecff0235-ac1b-495b-82c4-4b169c52b1c0":{
            name : "Visits distribution by hour of day",
            infoButtonText: "This graph shows visits distribution over hours of day. Values as evaluated in the selected time period",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "% of visits over total"
                  }
                }
              }
            }
          },
          //duration
          "0cd8b36d-5eff-4d64-91b6-c6d86bc743eb":{
            name : "Visits' duration distribution",
            infoButtonText: "This chart shows  visits distribution in 10 minutes range up to 1 hour. Visits greater than 1 hour are aggregated in the last slot",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text:"% of visits over total"
                  }
                }
              }
            }
          },
          "3c077094-6641-41fe-a039-bb489420f9a9":{
            name : "Brand Exposure",
            /* infoButtonText: "Il grafico mostra la distribuzione delle visite in range di 10 minuti, fino ad un massimo di 1 ora, le visite maggiori di 1 ora sono aggregate nell'ultimo slot",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "% visite sul totale"
                  }
                }
              }
            } */
          },
          "99501a85-4196-4f36-a498-a230906a4218":{
            name : "Visits' duration over time",
            infoButtonText: "This chart shows the average duration range (min-max) of visits over time.",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Duration (min)"
                  }
                }
              }
            }
          },
          "a7e86485-9227-4bfb-86c3-25ecd32e21b7":{
            name : "Visits' duration over day of week",
            infoButtonText: "This chart shows the average duration range (min-max) of visits over days of week",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Duration (min)"
                  }
                }
              }
            }
          },
          "2d53afe1-d17d-44d4-8493-d0d3e87cddf1":{
            name : "Visits' duration over hour of day",
            infoButtonText: "This chart shows the average duration range (min-max) of visits over hours of day",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Duration (min)"
                  }
                }
              }
            }
          },
          //loyalty
          "51fcd450-aeeb-41f3-8aab-04c230692abe" : {
            name : "Churn Rate",
            infoButtonText: "Churn rate considers the number of daily churns, evaluating a 'churn' as a customer that is not visiting the store since {{churnPeriod}} days",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Churn Rate"
                  }
                }
              }
            }
          },
          "ff689e3a-6015-4a67-af40-c31852f02acf": {
            name : "Returns (% over total)",
            infoButtonText: "This chart shows weekly distribution of visitors that visited the place more than one time",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Returns (% over total)"
                  }
                }
              }
            }
          },
          "132d5d0d-4d1e-4705-b61f-40643e286d5c":{
            name : "Returns (% over total)",
            infoButtonText: "This chart shows daily distribution of visitors that visited the place more than one time",
            chart:{
              yAxes:{
                "0": {
                  title:{
                    text: "Returns (% over total)"
                  }
                }
              }
            }
          },
          "7d54cb44-81e9-4976-a239-f00718505b41":{
            name : "Returns (% over total)",
            infoButtonText: "This chart shows the distribution over time of visitors that visited the place more than one time",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Returns (% over total)"
                  }
                }
              }
            }
          },
          "e9d66492-7e70-4f17-b508-a74f207bf527":{
            name : "Returning Visitors Distribution Rate",
            infoButtonText: "This chart shows after how many days visitors come back again",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "# of visitors (%)"
                  }
                }
              }
            }
          },
          "606d38b5-30d3-4833-bb7b-76ff6ed98ea3":{
            name: " Frequency Distribution over day of week",
            infoButtonText: "This chart shows frequency's average weekly distribution of visitors",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Frequency (avg)"
                  }
                }
              }
            }
          },
          "fc46c930-912f-4fd6-bba5-ba28a47e83ba":{
            name: "Frequency Distribution over hour of day",
            infoButtonText: "This chart shows frequency's average daily distribution of visitors",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Frequency (avg)"
                  }
                }
              }
            }
          },
          "bec411b6-ed76-4142-af72-69b584dc6303":{
            name: "Visitors (new/returning) distribution",
            infoButtonText: "Distribution of visitors (returning and new) that comes into the store"
          },
          "90c55c68-0bd4-4fe5-96e1-31e8ef01b93b":{
            name: "Returning Visitors",
            infoButtonText: "Returning visitors distribution. The chart is calculated as: ( ret / (new+ret) )",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Ratio (%)"
                  }
                }
              }
            }
          },
          "c1653188-e43b-4389-bbb7-9618de2c5e7f":{
            name: "Re-gained visitors (heatmap day/hour)",
            infoButtonText: "This chart shows the distribution of re-gained visits over weekdays and hour of day. A re-gained visitor is someone it has NOT come back within the pre-defined setting of this specific POI (eg: 30 days)",
          },
          "f377352a-2d23-4c74-a327-e0f63b2cd315": {
            name: "Average Frequency",
            infoButtonText: "This chart show the average frequency of all the visits happened in between of the specified date filter",
            chart: {
              yAxes: {
                "0": {
                  title: {
                    text: "Average Frequency"
                  }
                }
              }
            }
          },
          "89e569a7-3e34-4808-a2bb-cc8c80c0b820": {
            name: "Average Recency",
            infoButtonText: "This chart show the average recency of all the visits happened in between of the specified date filter",
            chart: {
              yAxes: {
                "0": {
                  title: {
                    text: "Average Recency"
                  }
                }
              }
            }
          },
        //Opportunity
        "29f6da3f-368c-40d1-8bc2-8e8abff2ff4c":{
          name: "Potential public",
          infoButtonText: "This chart shows the ratio between internal visits and external passers-by. It represents the concept of 'Opportunity'. an higher values means an higher ability to 'attract' passers-by inside the store",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "Public acquired (%)"
                }
              }
            }
          }
        },
        "7376b762-390a-4d4c-a357-22649c367ac2":{
          name: "Potential public over days of week",
          infoButtonText: "This chart shows the ratio between internal visits and external passers-by over days of week. It represents the concept of 'Opportunity'. an higher values means an higher ability to 'attract' passers-by inside the store",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "Public acquired (%)"
                }
              }
            }
          }
        },
        "6161e72e-0a09-4865-8ad3-ab780f654245":{
          name: "Potential public over days of week",
          infoButtonText: "This chart shows the ratio between internal visits and external passers-by over hours of day. It represents the concept of 'Opportunity'. an higher values means an higher ability to 'attract' passers-by inside the store",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "Public acquired (%)"
                }
              }
            }
          }
        },
        "8afc6fb0-536f-4b86-a1ab-dcc0a1040d6e":{
          name: "Potential Public (external passers-by)",
          infoButtonText: "This charts shows external passers-by",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "# Passers-by"
                }
              }
            }
          }
        },
        //proximity
        "0a05efc5-28d6-4143-a5f6-77e449653f18P":{
          name : "Potential",
          infoButtonText: "This graph shows the daily count of potential in the selected period of time",
          chart:{
            yAxes:{
              "0":{
                title:{
                  text: "#Potential"
                }
              }
            }
          }
        },
        "da9e3eef-9965-4488-8510-4f59ebd6a4d5P":{
          name : "Potential distribution by day of week and hour of day",
          infoButtonText: "This map shows potential distribution for every hour of every day of week. Values as evaluated in the selected time period",
        },
        "b33616fa-cbe7-4251-afed-632bcaf593fcP":{
          name : "Potential distribution by day of week",
          infoButtonText: "This graph shows potential distribution over days of week. Values as evaluated in the selected time period",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "% of potential over total"
                }
              }
            }
          }
        },
        "ecff0235-ac1b-495b-82c4-4b169c52b1c0P":{
          name : "Potential distribution by hour of day",
          infoButtonText: "This graph shows potential distribution over hours of day. Values as evaluated in the selected time period",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "% of potential over total"
                }
              }
            }
          }
        },
          //SKY INSIGHTS 00
          "73dc1edc-3a2c-4625-8bd3-c7385e07d677":{
            name : "Average Daily Visits By Week",
            infoButtonText: "The graph shows the daily average visits into the PDV by week. Data is shown according to the selected filter timeframe and PDV selection",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Avg Visit count"
                  }
                }
              }
            }
          },
          "eb3cb899-530d-4aef-bd7c-fbc18bc39d75":{
            name : "Average Daily External Passers-by by Week",
            infoButtonText: "The graph shows the daily average external passers-by of the PDV by week. Data is shown according to the selected filter timeframe and PDV selection",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Avg External Passers-by count"
                  }
                }
              }
            }
          }
      },
      map: {
        hours: "hours"
      },
      "skyTable":{
        "5ac01bec-98b5-4ba4-9061-8ae4231e0183":{
          name : "Overview Table",
          infoButtonText: "This table shows the overview data grouped by week, quarter and year. All values are aggregated with AVERAGES",
        }
      }
    }
  }
};
