import {Component, Input, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import * as moment_ from "moment";
import {ActivatedRoute, Router} from "@angular/router";
import * as _ from 'lodash';
import {Subscription} from "rxjs";
import {AuthService, FuseTranslationLoaderService} from "core-fe-angular";
import {FilterService} from "./filter.service";
import {Filter, FilterTypeValues, QuickFilter} from "iottacle-ts-models";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { forEach } from '@angular/router/src/utils/collection';
import {TranslateService} from "@ngx-translate/core";
import {locale as english} from "./i18n/en";
import {locale as italian} from "./i18n/it";

const moment: any = moment_;
@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy{

    @Input()
    filter: Filter;

    @ViewChild('filterPanel') filterPanel;

    public highlightBackground: boolean = false;
    public actualFilter: any;
    public filterType: any;
    public toBeFilter: any = {};
    public relativeValue: any = 30;
    public relativeTimeAgo: any = "daysAgo";
    public rangeStartDate: any;
    public rangeEndDate: any;
    public rapido: any;
    public toBeFilterStoreCount = '';
    public actualFilterStoreCount = '';
    public toBeFilterTagsCount = "";
    public actualFilterTagsCount = "";
    public showPartitionCheck: boolean = false;
    // public translationLoader: FuseTranslationLoaderService;
    public storeList: any = [];
    public tagList: any = [];
    public dataArray: any = [];
    public storage: any = [];

    public visible: boolean = true;
    public selectable: boolean = true;
    public removable: boolean = true;
    public separatorKeysCodes: number[] = [ENTER, COMMA];
    public filteredStores: Observable<any>;
    public filteredTags: Observable<any>;
    public storeCtrl = new FormControl();
    public tagsCtrl = new FormControl();
    public addOnBlur: boolean = false;

    public allTags: { categoryName: string, categoryCode: string, tags: string[] }[] = [];

    @ViewChild('storeInput') storeInput: ElementRef<HTMLInputElement>;
    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

    activatedRouteSubscription: Subscription;
    filterChangedSubscription: Subscription;

    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
    weekend: boolean;
    weekday: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private filterService: FilterService,
        public translateService: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
    ) {

    this.translationLoader.loadTranslations(english, italian);

    }


    ngOnInit(): void {


        if (!this.filter) {
            this.filter = new Filter(this.authService.getUnderlyingLoginModel(), undefined, this.translateService);
        }


        let filterFiringFlag: number = 0;
        this.filterChangedSubscription = this.filter.filterChangedObservable()
            .subscribe((newFilterValue) => {
                this.toBeFilter = _.cloneDeep(this.filter.getValues());
                this.toBeFilter.customFilters = {
                    partition: false,
                    allowGrouping: false,
                    weeklyAggregation: false,
                    dow: [],
                    tags: []
                };
                if (newFilterValue.administrativeEntityFilter.selectedMultiple.length === 1) {
                    if (newFilterValue.administrativeEntityFilter.selected.administrativeEntityDetails.id !== newFilterValue.administrativeEntityFilter.selectedMultiple[0].administrativeEntityDetails.id) {
                        newFilterValue.administrativeEntityFilter.selectedMultiple = [];
                        newFilterValue.administrativeEntityFilter.selectedMultiple.push(newFilterValue.administrativeEntityFilter.selected);
                    }
                }
                this.storeCtrl.setValue(newFilterValue.administrativeEntityFilter.selectedMultiple);
                const selectedAdminEntityIds = newFilterValue.administrativeEntityFilter.selectedMultiple ? newFilterValue.administrativeEntityFilter.selectedMultiple.map((el) => el.administrativeEntityDetails.administrativeEntityId) : [];
                // @ts-ignore
                // @ts-ignore
                const stateParams: QuickFilter = {
                    filterType: newFilterValue.timeFilter.filterType,
                    filterRelativeValue: newFilterValue.timeFilter.filterTypeValues.filterRelativeValue,
                    filterRelativeTimesAgo: newFilterValue.timeFilter.filterTypeValues.filterRelativeTimesAgo,
                    filterAbsoluteFrom: newFilterValue.timeFilter.filterTypeValues.filterAbsoluteFrom,
                    filterAbsoluteTo: newFilterValue.timeFilter.filterTypeValues.filterAbsoluteTo,
                    filterQuickValue: newFilterValue.timeFilter.filterTypeValues.filterQuickValue,
                    administrativeEntityId: selectedAdminEntityIds ? selectedAdminEntityIds[0] : undefined,
                    administrativeEntities: selectedAdminEntityIds,
                    partition: newFilterValue.customFilters.partition,
                    tags: newFilterValue.customFilters.tags,
                    allowGrouping: newFilterValue.customFilters.allowGrouping,
                    weeklyAggregation: newFilterValue.customFilters.weeklyAggregation,
                    dow: newFilterValue.customFilters.dow
                };
                this.tagsCtrl.setValue(newFilterValue.customFilters.tags);
                this.toBeFilter.customFilters.allowGrouping = newFilterValue.customFilters.allowGrouping;
                this.toBeFilter.customFilters.weeklyAggregation = newFilterValue.customFilters.weeklyAggregation;
                this.toBeFilter.customFilters.dow = newFilterValue.customFilters.dow;
                this.toBeFilter.customFilters.partition = newFilterValue.customFilters.partition;
                this.filterService.queryParams = stateParams;
                filterFiringFlag = filterFiringFlag + 1;
                this.router.navigate(
                    [],
                    {
                        relativeTo: this.activatedRoute,
                        queryParams: stateParams,
                        queryParamsHandling: "merge"
                    });
            });

        this.activatedRouteSubscription = this.activatedRoute
            .queryParams
            // .pipe(skip(1))
            .subscribe(p => {
                const params: any = p;

                let stateParams: QuickFilter;
                if (!params['filterType'] && !params['administrativeEntities']) {
                    stateParams = this.filterService.queryParams;
                } else {
                    let adminEntities = [];
                    if (params['administrativeEntities']) {
                        if (Array.isArray(params['administrativeEntities'])) {
                            adminEntities = params['administrativeEntities']
                                .map((el) => {
                                    const self = this;
                                    const adminEntity = self.filter.myAdministrativeEntities.find((e) => e.administrativeEntityDetails.administrativeEntityId === el);
                                    return adminEntity;
                                });
                        } else {
                            adminEntities = [params['administrativeEntities']];
                        }
                    }
                    let tags = [];
                    if (params["tags"]) {
                        if (Array.isArray(params["tags"])) {
                            tags = params["tags"];
                        } else {
                            tags = [params["tags"]];
                        }
                    }
                    let dow = [];
                    if (params["dow"]){
                        if (Array.isArray(params["dow"])) {
                            dow = params["dow"];
                        } else {
                            dow = [params["dow"]];
                        }
                    }

                    stateParams = {
                        filterType: params['filterType'],
                        filterRelativeValue: params['filterRelativeValue'],
                        filterRelativeTimesAgo: params['filterRelativeTimesAgo'],
                        filterAbsoluteFrom: params['filterAbsoluteFrom'],
                        filterAbsoluteTo: params['filterAbsoluteTo'],
                        filterQuickValue: params['filterQuickValue'],
                        administrativeEntityId: adminEntities.length > 0 ? adminEntities[0] : undefined,
                        administrativeEntities: adminEntities,
                        partition: params['partition'] === "true",
                        allowGrouping: params['allowGrouping'] === "true",
                        weeklyAggregation: params['weeklyAggregation'] === "true",
                        dow: dow,
                        tags: tags
                    };

                }

                if (filterFiringFlag < 2) {
                    this.filter.changeValueToQuick(stateParams);
                } else {
                    filterFiringFlag = filterFiringFlag + 1;
                }
            });

        this.actualFilter = this.filter.getValues();
        // this.actualFilter.customFilters.tags = [];
        this.dataArray = this.actualFilter.administrativeEntityFilter.allAdministrativeEntities;
        this.allTags = this.getTagsFromAdminEntities(this.actualFilter.administrativeEntityFilter.allAdministrativeEntities);
        if (this.actualFilter.administrativeEntityFilter.selectedMultiple && this.actualFilter.administrativeEntityFilter.selectedMultiple.length > 0) {
            this.storeList = this.actualFilter.administrativeEntityFilter.selectedMultiple;
            this.toBeFilterStoreCount = this.storeList.length > 1 ? ' +' + (this.storeList.length - 1) + ' more...' : '';
        } else {
            this.storeList.push(this.actualFilter.administrativeEntityFilter.selected);
            this.toBeFilterStoreCount = '';
        }
        this.actualFilterStoreCount = this.toBeFilterStoreCount;
        this.actualFilterTagsCount = this.toBeFilterTagsCount;
        this.toBeFilter = _.cloneDeep(this.filter.getValues());

        // console.log(this.actualFilter);


    }

    ngOnDestroy(): void {
        this.activatedRouteSubscription.unsubscribe();
        this.filterChangedSubscription.unsubscribe();
    }


    calcQuickToBeFilter = (quickValue) => {
        this.toBeFilter.timeFilter = this.filter.tryNewValues({
            timeFilter: {
                filterType: FilterTypeValues.QUICK,
                filterTypeValues: {
                    filterQuickValue: quickValue
                }
            }
        }).timeFilter;

        this.rapido = quickValue;
        this.highlightBackground = true;
        setTimeout(() => {
            this.highlightBackground = false;
        }, 200);
    }

    StoreFilterUpdate = (event) => {
        if (event.value.length > 0) {
            // this.toBeFilter.administrativeEntityFilter.selectedMultiple = this.storeSelectedValues(event.value);
            // this.toBeFilter.administrativeEntityFilter.selected = this.toBeFilter.administrativeEntityFilter.selected || {};
            this.toBeFilter.administrativeEntityFilter.selected.administrativeEntityDetails = event.value[0] !== 1 ? event.value[0].administrativeEntityDetails : {};
            this.toBeFilterStoreCount = event.value.length > 1 ? ' +' + (event.value.length - 1) + ' more' : '';
            this.toBeFilter.customFilters.userSelection = event.value;
        } else {
            this.toBeFilter.administrativeEntityFilter.selected.administrativeEntityDetails = undefined;
            //  this.toBeFilter.administrativeEntityFilter.selected.administrativeEntityDetails = this.actualFilter.administrativeEntityFilter.selected.administrativeEntityDetails;
            this.toBeFilterStoreCount = '';
            this.toBeFilter.customFilters.userSelection = [];
        }
        this.highlightBackground = true;
        setTimeout(() => {
            this.highlightBackground = false;
        }, 200);
    }

    onStoreRemoved(store: any): void {
        const toppings = this.storeCtrl.value;
        this.removeFirst(toppings, store);
        this.storeCtrl.setValue(toppings); // To trigger change detection
        if (toppings.length > 0) {
            // this.toBeFilter.administrativeEntityFilter.selected = this.toBeFilter.administrativeEntityFilter.selected || {};
            this.toBeFilter.administrativeEntityFilter.selected.administrativeEntityDetails = toppings[0].administrativeEntityDetails;
            this.toBeFilterStoreCount = toppings.length > 1 ? ' +' + (toppings.length - 1) + ' more' : '';
            this.toBeFilter.customFilters.userSelection = toppings;
        } else {
            this.toBeFilter.administrativeEntityFilter.selected.administrativeEntityDetails = undefined;
            // this.toBeFilter.administrativeEntityFilter.selected.administrativeEntityDetails = this.actualFilter.administrativeEntityFilter.selected.administrativeEntityDetails;
            this.toBeFilterStoreCount = '';
            this.toBeFilter.customFilters.userSelection = [];
        }

    }

    selectAll(ev): void {

        if (ev._selected) {
            this.storeCtrl.setValue(this.dataArray);
            ev._selected = true;
        }
        if (ev._selected === false) {
            this.storeCtrl.setValue([]);
        }

    }

    TagFilterUpdate = (event) => {
        if (event.value.length > 0) {
            // this.toBeFilter.administrativeEntityFilter.selectedMultiple = this.storeSelectedValues(event.value);
            this.toBeFilter.customFilters.tags = event.value;
            this.toBeFilterTagsCount = event.value.length > 1 ? ' +' + (event.value.length - 1) + ' more' : '';
        } else {
            this.toBeFilter.customFilters.tags = [];
            this.toBeFilterTagsCount = '';
        }
        this.highlightBackground = true;
        setTimeout(() => {
            this.highlightBackground = false;
        }, 200);
    }

    private storeSelectedValues = (data) => {
        data.forEach((el) => {
            this.storage.push(el);
        });
        return this.storage = _.uniqBy(this.storage, 'administrativeEntityDetails.id');
    }

    private getTagsFromAdminEntities = (data): { categoryName: string, categoryCode: string, tags: string[] }[] => {
        const tags: { categoryName: string, categoryCode: string, tags: string[] }[] = [];
        for (const i in data) {
            if (data[i].administrativeEntityDetails.tags && data[i].administrativeEntityDetails.tags !== undefined && data[i].administrativeEntityDetails.tags.length > 0) {
                data[i].administrativeEntityDetails.tags.forEach((el) => {
                    const split = el.split("_");
                    let category: string;
                    let tagValue: string;
                    let categoryCode: string;
                    if (split.length === 1) {
                        category = "GenericGroup";
                        categoryCode = "";
                        tagValue = split[0];
                    } else {
                        category = split[0];
                        categoryCode = split[0] + "_";
                        tagValue = split.splice(1).join("_");
                    }
                    const tempCategory = this.translateService.instant("tags." + category);
                    if (tempCategory !== "tags." + category) {
                        category = tempCategory;
                    }
                    let groupElement = tags.find(el1 => el1.categoryName === category);
                    if (!groupElement) {
                        groupElement = {categoryName: category, categoryCode: categoryCode, tags: []};
                        tags.push(groupElement);
                    }
                    if (groupElement.tags.indexOf(tagValue) < 0) {
                        groupElement.tags.push(tagValue);
                    }
                });
            }
        }
        for (const tgroup of tags) {
            tgroup.tags = _.orderBy(tgroup.tags);
        }
        return tags;
    }

    onKey(value): void {
        this.dataArray = [];
        this.selectSearch(value);
    }

    onKeyTag(value): void {
        this.selectSearchTag(value);
    }

    selectSearch(value: any): void {
        const filter = value.toLowerCase();
        this.dataArray = this.actualFilter.administrativeEntityFilter.allAdministrativeEntities.filter(i => i.administrativeEntityDetails.name.toLowerCase().indexOf(filter) >= 0);
    }

    selectSearchTag(value: any): void {
        const filter = value.toLowerCase();
        const tagList: { categoryName: string, categoryCode: string, tags: string[] }[] = this.getTagsFromAdminEntities(this.actualFilter.administrativeEntityFilter.allAdministrativeEntities);
        // tagList = _.cloneDeep(tagList);
        for (const t of tagList) {
            t.tags = t.tags.filter(i => i.toLowerCase().indexOf(filter) >= 0);
        }
        this.allTags = tagList;
    }

    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    onTagRemoved(tag: any): void {
        const toppings = this.tagsCtrl.value;
        this.removeFirst(toppings, tag);
        this.tagsCtrl.setValue(toppings); // To trigger change detection
        if (toppings.length > 0) {
            this.toBeFilter.customFilters.tags = toppings;
            this.toBeFilterTagsCount = toppings.length > 1 ? ' +' + (toppings.length - 1) + ' more' : '';
        } else {
            this.toBeFilter.customFilters.tags = [];
            this.toBeFilterTagsCount = '';
        }

    }

    IsGroupingDisabled = () => {
        if (this.toBeFilter.customFilters.tags === undefined) {
            this.toBeFilter.customFilters.tags = [];
        }
        if (this.toBeFilter.administrativeEntityFilter.selectedMultiple.length <= 1 && this.toBeFilter.customFilters.tags && this.toBeFilter.customFilters.tags.length === 0) {
            return true;
        } else if (this.toBeFilter.administrativeEntityFilter.selectedMultiple.length <= 1 && this.toBeFilter.customFilters.tags && this.toBeFilter.customFilters.tags.length > 1) {
            return false;
        } else if (this.toBeFilter.administrativeEntityFilter.selectedMultiple.length > 1 && this.toBeFilter.customFilters.tags && this.toBeFilter.customFilters.tags.length === 0) {
            return false;
        } else if (this.toBeFilter.administrativeEntityFilter.selectedMultiple.length === 1 && this.toBeFilter.customFilters.tags && this.toBeFilter.customFilters.tags.length === 1) {
            return false;
        } else if (this.toBeFilter.administrativeEntityFilter.selectedMultiple.length > 1 && this.toBeFilter.customFilters.tags && this.toBeFilter.customFilters.tags.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    CheckedPartitionFlag = (event) => {
        // console.log(this.toBeFilter.customFilters.partition)
        this.toBeFilter.customFilters.partition = event.checked;
    }

    CheckedallowGroupingCheck = (event) => {
        this.toBeFilter.customFilters.allowGrouping = event.checked;
    }

    weeklyAggregationCheck = (event) => {
        this.toBeFilter.customFilters.weeklyAggregation = event.checked;
    }

    checkDow = (event, dows, clear?) => {
        this.toBeFilter.customFilters.dow = this.toBeFilter.customFilters.dow || [];
        if (clear){
            for (const d of this.toBeFilter.customFilters.dow){
                this[d] = false;
            }
            this.toBeFilter.customFilters.dow = [];
        }
        const dow = this.toBeFilter.customFilters.dow;

        for (const d of dows){
            const index = dow.indexOf(d);
            if (index >= 0){
                if (!event.checked){
                    dow.splice(index, 1);
                    this[d] = false;
                }
            }else{
                if (event.checked){
                    dow.push(d);
                    this[d] = true;
                }
            }
        }
        // is weekend
        if (dow.indexOf('sat') >= 0 && dow.indexOf('sun') >= 0 && dow.indexOf('mon') < 0 && dow.indexOf('tue') < 0 && dow.indexOf('wed') < 0 && dow.indexOf('thu') < 0 && dow.indexOf('fri') < 0){
            this.weekend = true;
            this.weekday = false;
        }else if (dow.indexOf('mon') >= 0 && dow.indexOf('tue') >= 0 && dow.indexOf('wed') >= 0 && dow.indexOf('thu') >= 0 && dow.indexOf('fri') >= 0 && dow.indexOf('sat') < 0 && dow.indexOf('sun') < 0){
            this.weekday = true;
            this.weekend = false;
        }else{
            this.weekend = false;
            this.weekday = false;
        }
    }

    changeFilterType(type): void{
        this.filterType = type;
    }

    calcRelativeToBeFilter = (relativeValue, relativeTimeAgo) => {

        this.toBeFilter.timeFilter = this.filter.tryNewValues({
            timeFilter: {
                filterType: FilterTypeValues.RELATIVE,
                filterTypeValues: {
                    filterRelativeValue: relativeValue,
                    filterRelativeTimesAgo: relativeTimeAgo
                }
            }
        }).timeFilter;
        this.highlightBackground = true;
        setTimeout(() => {
            this.highlightBackground = false;
        }, 200);
    }

    calcAbsoluteToBeFilter = (from, to) => {

        this.toBeFilter.timeFilter = this.filter.tryNewValues({
            timeFilter: {
                filterType: FilterTypeValues.ABSOLUTE,
                filterTypeValues: {
                    filterAbsoluteFrom: from ? moment(from.value).startOf("day").valueOf() : this.toBeFilter.timeFilter.outcome.timeRangeBoundaries.from.valueOf(),
                    filterAbsoluteTo: to ? moment(to.value).endOf("day").valueOf() : this.toBeFilter.timeFilter.outcome.timeRangeBoundaries.to.valueOf()
                }
            }
        }).timeFilter;
        this.highlightBackground = true;
        setTimeout(() => {
            this.highlightBackground = false;
        }, 200);
    }

    applyFilter = () => {

        this.filter.changeValueTo(this.toBeFilter);
        // this.filter.fireFilterChangedEvent();
        this.filterPanel.close();

        this.actualFilterStoreCount = this.toBeFilterStoreCount;
        this.actualFilterTagsCount = this.toBeFilterTagsCount;

        if (this.toBeFilter.timeFilter.filterType !== FilterTypeValues.QUICK) {
            this.rapido = '';
        }
    }

    undoFilter = () => {
        this.toBeFilter = _.cloneDeep(this.filter.getValues());
        this.filterPanel.close();
    }

    selectDateFromLocation = ($item, $model) => {
        this.toBeFilter.timeFilter = this.filter.tryNewValues({
            timeFilter: {
                filterType: FilterTypeValues.ABSOLUTE,
                filterTypeValues: {
                    filterAbsoluteFrom: moment.utc($item.start).valueOf(),
                    filterAbsoluteTo: moment.utc($item.end).valueOf(),
                }
            }
        }).timeFilter;
        this.highlightBackground = true;
        setTimeout(() => {
            this.highlightBackground = false;
        }, 200);
    }

}
