import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {TranslateService} from "@ngx-translate/core";
import {DeviceStatusListTemplateConfig} from "./device-status-list-template-config";
import {
  AuthService,
  Domain,
  DomainCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig,
  IottacleFormDialogComponent, WebsocketService
} from "core-fe-angular";
import {DashboardItemFactoryChart} from "../../../store/next-one-sky-insights-00/utils";
import {
  DashboardItemFactory,
  DataSourceAssemblerChain,
  ElasticToIottacleDataSourceAssembler, IottacleMultipleElasticGenericRepo, MultipleFilterBuilderGenericElastic, Utils
} from "dashboard-items-catalog";
import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DataSource,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal
} from "iottacle-dashboard";
import {HttpClient} from "@angular/common/http";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {Filter, FilterQuickValues, FilterVisitTimeFilterStrategies} from "iottacle-ts-models";
import {DashboardConsolePersistency} from "../../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import _moment from "moment";
const moment = _moment;

@Component({
  selector: 'evice-status-list-template',
  templateUrl: './device-status-list-template.component.html',
  styleUrls: ['./device-status-list-template.component.scss']
})
export class DeviceStatusListTemplateComponent implements OnInit {


  @Input()
  domain:Domain;

  sampleDialogRef: MatDialogRef<IottacleFormDialogComponent>;

  i18nString:string;

  config:DeviceStatusListTemplateConfig;
  devices:any;
  adminEntities:any;
  websocketEventName:string;
  chart:DashboardItem;
  filter:Filter;
  dashboard:Dashboard;
  showDashboard;
  constructor(
    private fuseConfig: FuseConfigService,
    private dialog: MatDialog,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService:TranslateService,
    private authService:AuthService,
    private http:HttpClient,
    private changeDetectorRef:ChangeDetectorRef
    ) {
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {
      defaultFilterQuickValue : FilterQuickValues.LAST_7_DAYS
    }, this.translateService);
  }

  ngOnInit() {
    this.i18nString = this.domain.contextName+"."+this.domain.domainName+".";
    this.websocketEventName = "postDoCommand." + this.domain.getFqdn();
    if (this.domain.getTemplatesConfig()) {
      this.config = this.domain.getTemplatesConfig().listTemplate;
    }
    if (!this.config){
      this.config = {
        tableConfig:{
          addButton:{},
          actionColumn: {
            edit: {},
            view: true,
            delete: true
          }
        }
      };
      this.domain.logInfo("[listTemplate01] - template configuration not present. Using default values");
    }

    this.config.fieldsToShow =  this.domain.getSchema().mergeFieldsConfigs(this.config.fieldsToShow);

    let now = moment().valueOf();
    let _where = {bool:{must:[{range:{start:{lte:now}}}, {range:{end:{gte:now}}}]}};
    // let where = {bool:{must:[{range:{"deviceSetupsInAdminEntity.start":{lte:now}}}, {range:{"deviceSetupsInAdminEntity.end":{gte:now}}}]}};
    // this.domain.getQueryRepository().getEntitiesByElasticQuery(where,{id:true, status:true, deviceSetupsInAdminEntity:{_where:_where}},undefined,undefined,true)
    //   .subscribe(
    //     (res) => {
    //       this.devices = res;
    //       this.addWebsocketHandler();
    //       if (this.adminEntities){
    //         this.mergeData(res, this.adminEntities);
    //       }
    //     }
    //   );

    let adminEntity:Domain = DomainCatalog.getDomain("Client", "AdministrativeEntity");
    adminEntity.getQueryRepository().getAllEntities({id:true, administrativeEntityId:true, name:true, address:true, deviceSetups:{_where: _where, start: true, end: true, deviceId:{id:true, status: true}}})
      .subscribe(
        (res) => {
          this.adminEntities = res
          if (res.length){
            this.adminEntities = this.adminEntities.sort((a: any, b: any): any => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
            });
          }
          // if (this.devices){
          //   this.mergeData(this.devices, res);
          // }
        }
      );


  }

  onItemClick(device:any){
    this.chart = undefined;
    this.showDashboard = false;
    setTimeout(() => {
      let deviceId = device;
      let readOnly = true;
      this.chart = DeviceStatusListTemplateComponent.barChart_netdata(readOnly, this.translateService, this.authService, this.http,
        {x: 0, y: 0, cols: 36, rows: 11},
        //DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService))),
        DashboardItemFactoryChart.createXYChartItem({
          series: {
            count: {
              type: "line"
            }
          },
          //dateFormat: "weeklyView",
          advanceByAmount: 1,
          advanceByUnit: 'hour',
          export: {
            excel: true,
            csv: true,
            image: true
          }
        }), deviceId);

      let dashboardCfg = DashboardConfig.of(
        {
          id: "NextOneAccessBreakdown",
          name: "Next One",
          dashboardPersistency : DashboardConsolePersistency.of(),
          specificDashboardConfig : {
            gridsterConfig : new GridsterDashboardConfig()
          },
          initFilter : this.filter.filterValues
        });

      this.dashboard = Dashboard.of(dashboardCfg, [this.chart]);

      this.dashboard.load()
        .subscribe((hasLoaded) => {
          if (hasLoaded) {
            let context = {
              translateService: this.translateService
            };
            this.dashboard.reloadData(this.filter.filterValues, context);
            this.showDashboard = true;
          }
        });
    },1);

  }

  // private mergeData(devices, adminEntities){
  //   for(let d of devices){
  //     let a = adminEntities.find(el => !!(el.deviceSetups && el.deviceSetups.find(d1 => d1.deviceId && d1.deviceId.id == d.id)));
  //     if (a){
  //       d.adminEntity = a;
  //     }
  //   }
  // }

  ngOnDestroy(){
    this.removeWebsocketHandler()
  }

  removeWebsocketHandler(){
    WebsocketService.INSTANCE.unregisterHandler(this.websocketEventName);
  }

  addWebsocketHandler(){
    WebsocketService.INSTANCE.registerHandler(this.websocketEventName, this.websocketHandler(this));
  }

  private websocketHandler(thiz:DeviceStatusListTemplateComponent) {
    return (error: any, message: any) => {
      let status;
      let id;
      if (message && message.body.command && message.body.command.commandName === "UPDATE") {
        if (message.body.command.body && message.body.command.body.aggregate && message.body.command.body.aggregate.status) {
          status = message.body.command.body.aggregate.status;
        }
        if (message.body && message.body.response && message.body.response.hits && message.body.response.hits[0]) {
          id = message.body.response.hits[0].id;
        }
        if (status && id){
          let dev = this.devices.find(el => el.id == id);
          if (dev){
            dev.status = status;
            this.changeDetectorRef.detectChanges();
          }
        }
      }
    }
  }


  public static barChart_netdata(readOnly:boolean, translateService:any, authService:any, http:any, gridsterConfig:any, chartConfig:any, deviceId:any){

    let item =  DashboardItemFactory.createXYChartItem({
      id: "eb58e4a5-18ab-46a9-8284-6379a6dc462c",
      readOnly: readOnly,
      autoUpdateMs: 15000,
      specificItemConfig : {
        gridsterConfig : gridsterConfig
      },
      viewConfig: chartConfig,
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(new NetdataCounter(http, deviceId))),
      },
    }, translateService);

    return item;
  }
}

export class NetdataCounter implements DataSourceConfigInterfaceInternal{
  constructor(private http:any,private deviceId:any) {
  }
  name= "NetdataCounter";
  type= "elastic";
  assembler = new DataSourceAssemblerChain([
    new ElasticToIottacleDataSourceAssembler()
  ]);
  repo:any = new IottacleMultipleElasticGenericRepo(this.http);
  filter = new NetdataCountFilter().getFilter(this.deviceId);
  specificDataSourceConfig = {};
}


export class NetdataCountFilter{

  private filter:any;
  constructor(filterImpl?:FilterBuilderInterface) {
    this.filter = filterImpl ? filterImpl : new MultipleFilterBuilderGenericElastic();
  }

  public getFilter(deviceId:any){
    return this.filter.rehydrate(JSON.stringify(
      NetdataCountFilter.getFilterConfig(deviceId)
    ));
  }

  public static getFilterConfig(deviceId:any){
    return    {
      customData : {
        timeStrategy : {
          timestampField: 'tm',
          strategy: FilterVisitTimeFilterStrategies.QUICK,
          quickValue : FilterQuickValues.LAST_7_DAYS
        },
        query:{
          bool:{
            must:[{
              match_phrase:{
                dd:{
                  query:deviceId
                }
              }
            }]
          }
        },
        //locationReference : Utils.locationReference_fromFilterAdmins,
        //visitStrategyFilter : Utils.visitStrategyFilter_InternalVisits,
        xAxis: {
          "timeserie": {
            "date_histogram": {
              "field": "tm",
              "interval": "1h",
              "time_zone": "Europe/Rome",
              "min_doc_count": 0
            }
          }
        },
        splitSeries:[],
        metrics:[
          {
            "count": {
              "count": {
                "field": "foo"
              }
            }
          }
        ],
        index : "app-netdata",
        returnComplete : true
      }
    }
  }

}

