import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  Dashboard,
  DashboardConfig,
  DashboardItem, DashboardItemAssemblerFactory,
  DashboardItemConfig, DashboardItemFactory,
  DashboardItemViewGeneric, DashboardItemViewMetricDelta, DataResultInterface,
  DataSource,
  DataSourceAssemblerFactory,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory,
  ObservedLevel, RepositoryFactory
} from "iottacle-dashboard";
import {DecimalPipe} from "@angular/common";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {locale as english} from '../i18n/en';
import {locale as italian} from '../i18n/it';
import {
  FuseConfigService,
  FuseTranslationLoaderService,
  AuthService,
  RepositoryCatalog, GridsterDashboardConfig
} from "core-fe-angular";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {Observable, of} from "rxjs";
import * as shape from 'd3-shape';
import {DashboardLocalStoragePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-local-storage-persistency";
import {map} from "rxjs/operators";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import {
  Coordinate,
  Filter,
  FilterInterface,
  FilterQuickValues,
  FilterTypeValues,
  MyAdministrativeEntity
} from "iottacle-ts-models";

@Component({
  selector: 'property-overview',
  templateUrl: './property-overview.component.html',
  styleUrls: ['./property-overview.component.scss']
})
export class PropertyOverviewComponent implements OnInit, OnDestroy{


  dashboard:Dashboard;
  showDashboard = false;

  constructor(
    private auth:AuthService,
    private decimalPipe: DecimalPipe,
    private http:HttpClient,
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
  ){

    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };
  }

  ngOnDestroy(): void {
    this.dashboard.destroy();
  }

  ngOnInit(): void {

    let dashboardItems: DashboardItem[] = [];

    RepositoryCatalog.init(this.http);

    let filterBuilder: FilterBuilderInterface = new FilterBuilderGenericElastic();

    let commonFilter = new Filter(this.auth.getUnderlyingLoginModel());
    commonFilter.changeValueToQuick({
      filterType: FilterTypeValues.QUICK,
      filterQuickValue: FilterQuickValues.LAST_30_DAYS,
      administrativeEntityId: "wedsqweds",
      administrativeEntities: []
      //administrativeEntityId : this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities()[0].getAdministrativeEntityDetails().administrativeEntityId
    });


    //check in
    let checkIns = DashboardItemFactory.ofCloseableCustomRepo({
      id: "1",
      name: "Today's Check-in left",
      showHeader : true,
      readOnly: true,
      showName: true,
      subtitle: "Check-ins, out of 54",
      itemType: "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 0,
          cols: 12,
          rows: 5,
          draggable: {
            enabled: false
          }
        }
      },
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      const url = "analyticsapi/demo/domotics/states/lock.unknown_node_7_locked";
      return this.http.get<any>(url).pipe(
        map((val)=>{
          console.log("RECEIVED FROM HOME");
          console.log(val);
          return 23;
        })
      );

    });
    dashboardItems.push(checkIns);

    //check out
    let checkOut = DashboardItemFactory.ofCloseableCustomRepo({
      id: "2",
      name: "Today's Check-out left",
      showHeader : true,
      readOnly: true,
      showName: true,
      subtitle: "Check-out, out of 54",
      itemType: "metric-delta",
      specificItemConfig: {
        gridsterConfig: {
          x: 12,
          y: 0,
          cols: 12,
          rows: 5,
          draggable: {
            enabled: false
          }
        }
      },
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      return of({value: 12});
    });
    dashboardItems.push(checkOut);

    //Cleaning status
    let cleaningStatus = DashboardItemFactory.ofCloseableCustomRepo({
      id: "3",
      name: "Cleaning Status",
      showHeader : true,
      readOnly: true,
      showName: true,
      subtitle: "Needs to be cleaned",
      itemType: "metric-delta",
      specificItemConfig: {
        gridsterConfig: {
          x: 24,
          y: 0,
          cols: 12,
          rows: 5,
          draggable: {
            enabled: false
          }
        }
      },
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      return of({value: 4});
    });
    dashboardItems.push(cleaningStatus);

    //Property Alerts
    let propertyAlerts = DashboardItemFactory.ofCloseableCustomRepo({
      id: "4",
      name: "Property Alerts",
      showHeader : true,
      readOnly: true,
      showName: true,
      itemType: "data-table",
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 5,
          cols: 36,
          rows: 11,
          draggable: {
            enabled: false
          }
        },
        columns: [
          {
            name: 'Property Name',
            type: 'text'
          },
          {
            name: 'Guest Name',
            type: 'text'
          },
          {
            name: 'Status',
            type: 'text'
          },
          {
            name: 'Stay',
            type: 'text'
          },
          {
            name: 'Alert',
            type: 'text'
          }]
      }
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      return of([
        {
          "Property Name": "Cozy Heated Treehouse, with great in town location",
          'Guest Name': "",
          'Status': "Available",
          'Stay': "",
          'Alert': "Sink pipe broken",
          'color': 'red'
        },
        {
          "Property Name": "Bear Lake Luxury Cabin for Groups at The Reserve",
          'Guest Name': "Spencer",
          'Status': "Occupied",
          'Stay': "27-03-2019 -> 28-04-2019",
          'Alert': "Noise To Loud",
          'color': 'red'
        },
        {
          "Property Name": "Beautiful Family Home (2 Beds) A",
          'Guest Name': "Buvaneeka",
          'Status': "Occupied",
          'Stay': "31-03-2019 -> 12-04-2019",
          'Alert': "Temperature Too High",
          'color': 'orange'
        },
        {
          "Property Name": "Cottage near Santa Claus Village",
          'Guest Name': "Anneli",
          'Status': "Occupied",
          'Stay': "01-04-2019 -> 06-04-2019",
          'Alert': "Temperature Too Low",
          'color': 'orange'
        }
      ]);
    });
    dashboardItems.push(propertyAlerts);

    //CheckIn list
    let checkInList = DashboardItemFactory.ofCloseableCustomRepo({
      id: "5",
      name: "Today's Check-in",
      showHeader : true,
      readOnly: true,
      showName: true,
      itemType: "data-table",
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 17,
          cols: 36,
          rows: 12,
          draggable: {
            enabled: false
          }
        },
        columns: [
          {
            name: 'Property Name',
            type: 'text'
          },
          {
            name: 'Guest Name',
            type: 'text'
          },
          {
            name: 'Guest Access Code',
            type: 'text'
          },
          {
            name: 'Stay',
            type: 'text'
          },
          {
            name: 'Status',
            type: 'text'
          }]
      }
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      return of([
        {
          "Property Name": "Placid Studio Apartment with a Balcony",
          'Guest Name': "Molly",
          'Guest Access Code': "5688",
          'Stay': "02-04-2019 -> 03-04-2019",
          'Status': "Ready for Check-in"
        },
        {
          "Property Name": "R T Spacious Bright Modern Condo with Pool View",
          'Guest Name': "Bavelyn",
          'Guest Access Code': "6478",
          'Stay': "02-04-2019 -> 15-04-2019",
          'Status': "Ready for Check-in"
        },
        {
          "Property Name": "Historical studio in Art Nouveau house",
          'Guest Name': "Claire",
          'Guest Access Code': "7634",
          'Stay': "02-04-2019 -> 10-04-2019",
          'Status': "Ready for Check-in"
        },
        {
          "Property Name": "Luxury Fashion District Suite",
          'Guest Name': "Julie",
          'Guest Access Code': "9867",
          'Stay': "02-04-2019 -> 12-04-2019",
          'Status': "Ready for Cleaning"
        },
        {
          "Property Name": "Very Nice 2Br - Copacabana - WiFi",
          'Guest Name': "Matthias",
          'Guest Access Code': "1123",
          'Stay': "02-04-2019 -> 06-04-2019",
          'Status': "Ready for Cleaning"
        },
        {
          "Property Name": "Croatian sea & beautiful view 1",
          'Guest Name': "Siniša",
          'Guest Access Code': "7645",
          'Stay': "02-04-2019 -> 03-04-2019",
          'Status': "Ready for Check-in"
        },
        {
          "Property Name": "In the center of Mallorca, Rosella",
          'Guest Name': "Ruth",
          'Guest Access Code': "2375",
          'Stay': "02-04-2019 -> 15-04-2019",
          'Status': "Ready for Cleaning"
        },
        {
          "Property Name": "Prince-Bishop of Wroclaw's Palace",
          'Guest Name': "Jim",
          'Guest Access Code': "0998",
          'Stay': "02-04-2019 -> 23-04-2019",
          'Status': "Ready for Check-in"
        },
        {
          "Property Name": "Villa Monteserra",
          'Guest Name': "Simone",
          'Guest Access Code': "8678",
          'Stay': "02-04-2019 -> 07-04-2019",
          'Status': "Ready for Check-in"
        },
        {
          "Property Name": "Pleasant and welcoming apartment!",
          'Guest Name': "Sylvie",
          'Guest Access Code': "0843",
          'Stay': "02-04-2019 -> 12-04-2019",
          'Status': "Ready for Check-in"
        },
        {
          "Property Name": "50m To Beach 2 Br 10 mins Seafood market walk away",
          'Guest Name': "Matt",
          'Guest Access Code': "5967",
          'Stay': "02-04-2019 -> 19-04-2019",
          'Status': "Ready for Cleaning"
        },
        {
          "Property Name": "Bello Horizonte romantic apartment",
          'Guest Name': "Milan",
          'Guest Access Code': "6509",
          'Stay': "02-04-2019 -> 19-04-2019",
          'Status': "Ready for Check-in"
        }
      ]);
    });
    dashboardItems.push(checkInList);

    //Checkout list
    let checkOutList = DashboardItemFactory.ofCloseableCustomRepo({
      id: "6",
      name: "Today's Check-out",
      showHeader : true,
      readOnly: true,
      showName: true,
      itemType: "data-table",
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 29,
          cols: 36,
          rows: 12,
          draggable: {
            enabled: false
          }
        },
        columns: [
          {
            name: 'Property Name',
            type: 'text'
          },
          {
            name: 'Guest Name',
            type: 'text'
          },
          {
            name: 'Guest Access Code',
            type: 'text'
          },
          {
            name: 'Stay',
            type: 'text'
          },
          {
            name: 'Status',
            type: 'text'
          }]
      }
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      return of([
        {
          "Property Name": "Appartamento 6 Stara Novalja, Pag",
          'Guest Name': "Matej",
          'Guest Access Code': "6568",
          'Stay': "31-03-2019 -> 02-04-2019",
          'Status': "Occupied"
        },
        {
          "Property Name": "Luxury Amazing Sea View Apartment",
          'Guest Name': "Ozan",
          'Guest Access Code': "9982",
          'Stay': "01-04-2019 -> 02-04-2019",
          'Status': "Occupied"
        },
        {
          "Property Name": "Magico appartamento vista Montagna",
          'Guest Name': "Giuseppe",
          'Guest Access Code': "7590",
          'Stay': "20-03-2019 -> 02-04-2019",
          'Status': "Ready for Cleaning"
        },
        {
          "Property Name": "Apartments Fortunella #1",
          'Guest Name': "Slobodan",
          'Guest Access Code': "4688",
          'Stay': "23-03-2019 -> 02-04-2019",
          'Status': "Ready for Cleaning"
        },
        {
          "Property Name": "Convenient, Central Seaside Location!",
          'Guest Name': "Vito",
          'Guest Access Code': "6470",
          'Stay': "29-03-2019 -> 02-04-2019",
          'Status': "Ready for Cleaning"
        },
        {
          "Property Name": "LINDO FLAT EM TORRES - BRASIL",
          'Guest Name': "Ana Paula Cardoso",
          'Guest Access Code': "7657",
          'Stay': "12-03-2019 -> 02-04-2019",
          'Status': "Occupied"
        },
        {
          "Property Name": "[HomeOnly] Apart with swim pool and sea view",
          'Guest Name': "Jingfang",
          'Guest Access Code': "6699",
          'Stay': "14-03-2019 -> 02-04-2019",
          'Status': "Occupied"
        },
        {
          "Property Name": "Historic and Stunning, Large Central Bedford House",
          'Guest Name': "Anne",
          'Guest Access Code': "5467",
          'Stay': "31-03-2019 -> 02-04-2019",
          'Status': "Ready for Cleaning"
        },
        {
          "Property Name": "Luxury House Private Pool, 3 Floors",
          'Guest Name': "Lee",
          'Guest Access Code': "9985",
          'Stay': "27-03-2019 -> 02-04-2019",
          'Status': "Occupied"
        },
        {
          "Property Name": "Capao da Canoa",
          'Guest Name': "Capao da Canoa",
          'Guest Access Code': "9097",
          'Stay': "26-03-2019 -> 02-04-2019",
          'Status': "Ready for Cleaning"
        },
        {
          "Property Name": "Schicke Almhütte im Zillertal",
          'Guest Name': "Susanne",
          'Guest Access Code': "6369",
          'Stay': "30-03-2019 -> 02-04-2019",
          'Status': "Ready for Cleaning"
        },
        {
          "Property Name": "Linda Edícula c/ PISCINA, TV a CABO, AC, Wifi",
          'Guest Name': "Alexandre",
          'Guest Access Code': "9937",
          'Stay': "28-03-2019 -> 02-04-2019",
          'Status': "Occupied"
        }
      ]);
    });
    dashboardItems.push(checkOutList);


    //MAP admin entities
    let adminEntityLocationsMarker: DataSourceConfigInterfaceInternal = {
      name: "peopleMovement",
      type: "elastic",
      assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo: {
        getName: () => "custom",
        getChannelType: () => ChannelType.CLOSEABLE,
        invoke: (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
          let data: {
            markers?: Coordinate[],
          } = {
            markers: []
          };

          let adminEntities: MyAdministrativeEntity[] = this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities();
          for (let a in adminEntities) {
            data.markers.push(adminEntities[a].getAdministrativeEntityDetails().getCoordinate());
          }
          return of(data);
        },
        rehydrate: (serializedAssembler: string): CloseableRepositoryInterface => {
          return undefined;
        },
        serialize: (): string => {
          return JSON.stringify({
            name: "custom"
          });
        }
      },
      filter: FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let adminEntityMap: DashboardItem = DashboardItem.of(DashboardItemConfig.of({
      id: "7",
      observedLevel: ObservedLevel.ITEM_VIEW,
      name: "Where are my stores",
      showHeader : false,
      readOnly: true,
      showName: true,
      autoUpdateMs: 0,
      subtitle: "",
      infoButtonText: undefined,
      itemType: "map-with-polygons",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig: DashboardItemViewGeneric.empty(),
      specificItemConfig: {
        height: 300,
        heatmapRadius: 30,
        gridsterConfig: {
          x: 0,
          y: 0,
          cols: 18,
          rows: 12,
          draggable: {
            enabled: true
          }
        }
      },
      dataSources: {
        "a": DataSource.of(DataSourceConfig.of(adminEntityLocationsMarker)),
      },
      assembler: DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
    }));
    //dashboardItems.push(adminEntityMap);

    //ADMIN ENTITIES LIST
    let adminEntityLocationsDataSource: DataSourceConfigInterfaceInternal = {
      name: "peopleMovement",
      type: "elastic",
      assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo: {
        getName: () => "custom",
        getChannelType: () => ChannelType.CLOSEABLE,
        invoke: (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
          let data: any = [];
          let adminEntities: MyAdministrativeEntity[] = this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities();
          for (let a in adminEntities) {
            let adminDetail = adminEntities[a].getAdministrativeEntityDetails();
            data.push({
              name: adminDetail.name,
              address: adminDetail.address,
              status: adminEntities[a].status
            });
          }
          return of(data);
        },
        rehydrate: (serializedAssembler: string): CloseableRepositoryInterface => {
          return undefined;
        },
        serialize: (): string => {
          return JSON.stringify({
            name: "custom"
          });
        }
      },
      filter: FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let adminEntityLocations: DashboardItem = DashboardItem.of(DashboardItemConfig.of({
      id: "8",
      observedLevel: ObservedLevel.ITEM_VIEW,
      name: "Store List",
      showHeader : true,
      readOnly: true,
      showName: true,
      autoUpdateMs: 0,
      subtitle: "",
      infoButtonText: "",
      itemType: "data-table",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig: DashboardItemViewGeneric.empty(),
      specificItemConfig: {
        showTopText: false,
        topTextMatIconColorClass: "orange-fg",
        topTextTitle: "",
        topTextMatIcon: "warning",
        topTextSubtitle: "",
        columns: [
          {
            name: 'name',
            type: 'text'
          },
          {
            name: 'address',
            type: 'text'
          },
          {
            name: 'status',
            type: 'text'
          }],
        gridsterConfig: {
          x: 19,
          y: 0,
          cols: 18,
          rows: 12,
          draggable: {
            enabled: true
          }
        }
      },
      dataSources: {
        "1": DataSource.of(DataSourceConfig.of(adminEntityLocationsDataSource))
      },
      assembler: DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    //dashboardItems.push(adminEntityLocations);


    let dashboardCfg = DashboardConfig.of(
      {
        id: "PropertyOverview1234",
        name: "Property Overview",
        dashboardPersistency: DashboardLocalStoragePersistency.of(),
        specificDashboardConfig: {
          gridsterConfig: new GridsterDashboardConfig()
        },
        initFilter: commonFilter
      }
    );

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData();
          this.showDashboard = true;
        }
      });
  }

}


