import {
  CommandFactory,
  Entity, EntityModelCatalog, FieldToUse,
  IottacleHttpClientProxy, QueryFactory,
  SchemaFieldDetailFactory,
  SchemaFieldDetailsInterface, SchemaFieldTypesNames
} from "dashboard-fe-angular";
import {map} from "rxjs/operators";
import moment from "moment";
import {ReccoAdminEntityType} from "../core-extension/recco-admin-entity-type";
import {Job, JobDTO} from "../job/job";
import {LoginV3, MyAdministrativeEntity} from "iottacle-ts-models";

export class GetSingleVehicleDTO{
  id: number;
  name: string;
  vehicleType : string;
  status: string;
  jobs?:JobDTO[]
}

export class GetAllVehicleDTO {
  [key:string]:any;
  id:number;
  name:string;
  vehicleType? : string;
  status?: string;
  lastJob? : {
    drivers? : {
      driverName:string
    }[],
    trailers?: {
      trailerName:string
    }[],
    otherBeaconsCount? : number,
    start:number,
    end:number,
    locationName?:string
  }
}

export class Vehicle extends Entity{
  entityName:string;
  fieldDetails: { [p: string]: SchemaFieldDetailsInterface };
  entityValidators:any[];
  contextName:string;
  domainName:string;

  constructor(
    private httpClient: IottacleHttpClientProxy,
    private loginService:LoginV3
  ){
    super();

    //DOMAIN DETAILS
    this.contextName = "Recco";
    this.domainName = "Vehicle";

    //NAME
    this.entityName = "Vehicle";

    //SCHEMA
    this.fieldDetails = {};
    this.fieldDetails.id = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.id, "id");
    this.fieldDetails.name = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "name");
    this.fieldDetails.lastJob = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.valueObject, "lastJob");
    this.fieldDetails.status = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.valueObject, "status");
    this.fieldDetails.vehicleType = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.valueObject, "vehicleType");

    this.fieldDetails.lastDriverName = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "lastDriverName");
    this.fieldDetails.lastTrailerName = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "lastTrailerName");
    this.fieldDetails.otherDriversCount = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "otherDriversCount");
    this.fieldDetails.otherTrailersCount = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "otherTrailersCount");
    this.fieldDetails.otherBeaconsCount = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "otherBeaconsCount");
    this.fieldDetails.start=SchemaFieldDetailFactory.of(SchemaFieldTypesNames.datetime, {
      name :"start",
      momentGenerator : (fieldValue)=>{
        return moment.utc(fieldValue);
      }
    });
    this.fieldDetails.end=SchemaFieldDetailFactory.of(SchemaFieldTypesNames.datetime, {
      name :"end",
      momentGenerator : (fieldValue)=>{
        return moment.utc(fieldValue);
      }
    });
    this.fieldDetails.locationGeopoint = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.geoPoint, "locationGeopoint");
    this.fieldDetails.locationName = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "locationName");
    this.fieldDetails.deviceId = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "deviceId");
    this.fieldDetails.otherLocationsCount = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "otherLocationsCount");
    this.fieldDetails.jobs = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.valueObject, "jobs");

    //ENTITY VALIDATORS
    this.entityValidators = [];


    this.saveSchema = FieldToUse.filterEntityFieldsAsList(this.fieldDetails, undefined);
    this.readSchemaOfServer = this.saveSchema;
    this.updateSchema = this.saveSchema;


    this.getById = (entityId) => {
      entityId = Number(entityId);

      let jobModel:Job = <Job>EntityModelCatalog.getEntityModel("Recco", "Job");

      return jobModel.getTimelineJobsPerVehicleId(entityId)
        .pipe(
          map((jobs:any) =>{
            let vehicle:MyAdministrativeEntity = this.loginService.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities()
              .find((e) => {
                return e.getAdministrativeEntityDetails().id === entityId;
              });

            let status = "OFFLINE";
            if (jobs[0]){
              if (jobs[0].end > moment().valueOf() - 120000){
                status = "ONLINE";
              }
            }
            let toRet:GetSingleVehicleDTO = {
              id: vehicle.getAdministrativeEntityDetails().id,
              name: vehicle.getAdministrativeEntityDetails().name,
              vehicleType : "UNDEFINED",
              status: status,
              jobs:jobs
            };

            return {
              body : {
                hits: [toRet]
              }
            };
          })
        );
    };

    this.getAll = (whereClause:any) => {
      const url = "analyticsapi/executor/query";
      return this.httpClient.post(url, {
        query : "EXECUTE_QUERY",
        filter : {
          returnComplete:true,
          index : "recco_jobs_02",
          query: {
            "size" :0,
            "query": {
              "bool": {
                "must": [],
                "filter": [],
                "should": [],
                "must_not": []
              }
            },
            "aggs": {
              "group": {
                "terms": {
                  "field": "adminEntityId"
                },
                "aggs": {
                  "group_docs": {
                    "top_hits": {
                      "size": 1,
                      "sort": [
                        {
                          "end": {
                            "order": "desc"
                          }
                        }
                      ]
                    }
                  }
                }
              }
            }
          }
        }
      })
        .pipe(
          map((results) =>{
            let toRet:GetAllVehicleDTO[] = [];
            let vehicles = this.loginService.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities()
              .filter((e) => e.getAdministrativeEntityDetails().type === ReccoAdminEntityType.RECCO_VEHICLE);
            let hitByDeviceId = results.aggregations.group.buckets;
            for (let vehicle in vehicles){
              toRet[vehicle] = {
                id:vehicles[vehicle].getAdministrativeEntityDetails().id,
                name:vehicles[vehicle].getAdministrativeEntityDetails().name
              };
              const delta = 60000;
              let hit = hitByDeviceId.find((h) =>  h.key === vehicles[vehicle].getAdministrativeEntityDetails().id);
              if (hit){
                let doc = hit.group_docs.hits.hits[0]._source;
                let lastDrivers:any[] = [];
                let lastTrailers:any[] = [];
                let otherBeacons:any[] = [];
                for (let b in doc.b){
                  switch(doc.b[b].type){
                    case  'DRIVER':
                      lastDrivers.push(doc.b[b]);
                      break;
                    case  'TRAILER':
                      lastTrailers.push(doc.b[b]);
                      break;
                    default:
                      otherBeacons.push(doc.b[b]);
                      break;
                  }
                }

                let start = doc.start;
                let end = doc.end;
                toRet[vehicle].lastJob = {
                  drivers : lastDrivers,
                  trailers: lastTrailers,
                  otherBeaconsCount : otherBeacons.length,
                  start:start,
                  end:end,
                  locationName:doc.locationName
                };

                let status = "OFFLINE";
                if (toRet[vehicle].lastJob){
                  if (toRet[vehicle].lastJob.end > moment().valueOf() - 120000){
                    status = "ONLINE";
                  }
                }
                toRet[vehicle].status = status;
                toRet[vehicle].vehicleType = "UNDEFINED";
              }
            }

            return {
              body : {
                hits: toRet
              }
            };
          })
        );
    }
  }

  getFieldValue(fieldName: string, entity: any):any {
    return entity[fieldName];
  }

}
