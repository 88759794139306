import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FuseConfigService} from "core-fe-angular";

@Component({
  selector: 'smart-city-park',
  templateUrl: './smart-city-park.component.html',
  styleUrls: ['./smart-city-park.component.scss']
})
export class SmartCityParkComponent implements OnInit, OnDestroy{


  constructor(
    private fuseConfig: FuseConfigService
  ) {
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }


}


