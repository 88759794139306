import {
  CommandFactory,
  Entity, FieldToUse,
  IottacleHttpClientProxy, QueryFactory,
  SchemaFieldDetailFactory,
  SchemaFieldDetailsInterface, SchemaFieldTypesNames
} from "dashboard-fe-angular";


export class Driver extends Entity{
  entityName:string;
  fieldDetails: { [p: string]: SchemaFieldDetailsInterface };
  entityValidators:any[];
  contextName:string;
  domainName:string;

  constructor(
    private httpClient: IottacleHttpClientProxy,
  ){
    super();

    //DOMAIN DETAILS
    this.contextName = "Recco";
    this.domainName = "Driver";

    //NAME
    this.entityName = "Driver";

    //SCHEMA
    this.fieldDetails = {};
    this.fieldDetails.id = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.id, "id");
    this.fieldDetails.uuid = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "name");
    this.fieldDetails.major = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "beaconId");

    //ENTITY VALIDATORS
    this.entityValidators = [];


    //ENTITY BOILERPLATE STUFF
    this.saveSchema = FieldToUse.filterEntityFieldsAsList(this.fieldDetails, undefined);
    this.readSchemaOfServer = this.saveSchema;
    this.updateSchema = this.saveSchema;

  }

  getFieldValue(fieldName: string, entity: any):any {
    return entity[fieldName];
  }

}
