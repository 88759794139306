export const locale = {
    lang: 'en',
    data: {
       'nav': {
            'group-name' : 'Recco',
            'overview' : "Overview", 
            'recco': {
                'vehicle':{
                   'group-name' : 'Vehicles',
                 "list":'List'
                }
            }
         },
         'recco':{
            'vehicle':{
                 'entity-form':{
                       'general-information' : 'General Information',                       
                       'recco-jobs-timeline':{}
                 }
            }
       }  
    }
};
