export const locale = {
    lang: 'en',
    data: {
        labels: {
            partition: "Visit Partitioning",
            filter: "Filter",
            store: "Store",
            stores: "Stores",
            tags: "Tags",
            group: "Group visits",
            quick: "Quick",
            relative: "Relative",
            absolute: "Absolute",
            newFilter: "New Filter",
            from: "From",
            to: "To",
            cancel: "Cancel",
            apply: "Apply Filter",
            now: "NOW",
            when: "When",
            from2: "From",
            to2: "To",
            noFilter: "No Filter",
            selectAll: "Select All",
            weekdaysFilter: "Filter days of week",
            weeklyAggregation: "Weekly aggregation",
            weekdays: "weekdays",
            weekend: "weekend",
            mon: "Mon",
            tue: "Tue",
            wed: "Wed",
            thu: "Thu",
            fri: "Fri",
            sat: "Sat",
            sun: "Sun"
        },
        sentences: {
            availableAreasAndValidity: "Available areas and validity period",
            searchStores: "Find stores...",
            searchTags: "Find Tag...",
            groupVisits: "By selecting this option, all the stores and tags choosen will be grouped in a single serie",
            partitioning: "By selecting this option, each serie will be partitioned based on criteria chosen at configuration time (such as: by Visit Duration, Visit Location or both)"
        },
        timeFilters: {
            thisWeek: "This week",
            thisMonth: "This month",
            thisYear: "This year",
            fromFirstDOW: "From start of week",
            fromFirstDOM: "From start of month",
            fromFirstDOY: "From start of year",
            lastWeek: "Last week",
            lastMonth: "Last month",
            lastYear: "Last year",
            last7Days: "Last 7 days",
            last30Days: "Last 30 days",
            last60Days: "Last 60 days",
            last90Days: "Last 90 days",
            last6Months: "Last 6 months",
            lastYearCurrent: "Last year",
            last2Years: "Last 2 years",
            daysAgo: "Days ago",
            weeksAgo: "Weeks ago",
            monthsAgo: "Months ago",
            yearsAgo: "Years ago"
        },
        tags: {
            Region: "Regions",
            Province: "Provinces",
            Nielsen: "Nielsen Areas",
            GenericGroup: "Generic Cat."
        },
        floor: {
          floor_0: "First Floor",
          floor_1: "Second Floor",
        }
    }
};
