import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {DashboardPersistencyProviderInterface} from "iottacle-dashboard/dist/persistency/dashboard-persistency-provider-interface";


export class DashboardLocalStoragePersistency implements DashboardPersistencyProviderInterface{


  public constructor(){

  }

  public static of():DashboardLocalStoragePersistency{
    return new DashboardLocalStoragePersistency();
  }

  getElement(key: string): Observable<any> {
    let ret:Subject<any> = new Subject();
    setTimeout(()=>{
      try{
        let obj = localStorage.getItem(key);
        obj = JSON.parse(obj);
        ret.next(obj);
        ret.complete();
      }catch(e){
        ret.error(e);
        ret.complete();
      }
    }, 1);
    return ret.asObservable();
  }

  getName(): string {
    return "DashboardLocalStoragePersistency";
  }

  getSerializedElement(key: string): Observable<string> {
    let ret:Subject<any> = new Subject();
    setTimeout(()=>{
      try{
        let obj = localStorage.getItem(key);
        ret.next(obj);
        ret.complete();
      }catch(e){
        ret.error(e);
        ret.complete();
      }
    }, 1);
    return ret.asObservable();
  }

  putElement(key: string, item:any): Observable<void> {
    let ret:Subject<any> = new Subject();
    setTimeout(()=> {
      try {
        localStorage.setItem(key, JSON.stringify(item));
        ret.next();
        ret.complete();
      }catch (e){
        ret.error(e);
        ret.complete();
      }
    },1);
    return ret.asObservable();
  }

  rehydrate(serializedPersistency: string): DashboardPersistencyProviderInterface {
    return DashboardLocalStoragePersistency.of();
  }

  serialize(): string {
    return JSON.stringify({
      name : "DashboardLocalStoragePersistency"
    });
  }

}
