import {Component, Input, OnDestroy, OnInit, ViewEncapsulation,ViewChild} from '@angular/core';
import {MatTableDataSource,MatSort,MatPaginator} from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {fuseAnimations} from "core-fe-angular";

@Component({
    selector     : 'recco-jobs-timeline',
    templateUrl  : './recco-jobs-timeline.component.html',
    styleUrls    : ['./recco-jobs-timeline.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ReccoJobsTimelineComponent implements OnInit, OnDestroy
{
    timeline: any;

    displayedColumns: string[] = ['startText', 'endText', 'durationText', 'uuid','mi','ma'];
    dataSource: MatTableDataSource<any>;

    @Input()
    jobs:any;

    constructor(

    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
let i = this.jobs;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {

    }
}
