import {APP_INITIALIZER, ErrorHandler, Inject, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes, Router} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule, MatIconModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import { ApmService } from '@elastic/apm-rum-angular';
import { ApmErrorHandler } from '@elastic/apm-rum-angular';
import 'hammerjs';

import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseModule,
  FuseSharedModule,
  FuseSplashScreenService,
  FuseConfigService,
  fuseConfig,
  AuthInterceptor,
  AuthGuardService,
  Login2Module,
  AuthService,
  IottacleNavigationService,
  CommonPaths,
  DynamicHomeComponent,
  NoHomeDefinedComponent,
  NavigationModule,
  LayoutModule, DynamicHomeModule, NoHomeDefinedModule
} from 'core-fe-angular';
import {FuseThemeOptionsModule} from 'core-fe-angular';
import {AppComponent} from 'app/app.component';
import {JsonPipe, registerLocaleData} from "@angular/common";
import localeIt from "@angular/common/locales/it";
import {WeatherModule} from './main/context/weather/weather.module';
import {ActivitiesAndNotesModule} from "./main/context/common/activities-and-notes/activities-and-notes.module";
import {InMemoryWebApiModule} from "angular-in-memory-web-api";
import {FakeDbService} from "./fake-db/fake-db.service";
import {DeviceModule} from "./main/context/common/config/device/device.module";
import {AppBootstrapModule} from "./app-bootstrap.module";
import {SmartCityModule} from "./main/context/smart-city/smart-city/smart-city.module";
import {StoreModule} from "./main/context/store/store.module";
import {PropertyModule} from "./main/context/property-manager/property.module";
import {
  Neo4jFunnelDashboardItemRepository, TimeDirection
} from "./ext/neo4j-funnel-dashboard-item-repository/neo4j-funnel-dashboard-item-repository";
import moment from "moment";
import {FunnelImpl} from "./ext/neo4j-funnel-dashboard-item-repository/iottacle-funnel-query";
import {ReccoOverviewModule} from "./main/context/recco/recco/recco-overview.module";
import {DeviceBeaconModule} from "./main/context/device/beacon/device-beacon.module";
import {EntityModelCatalog, SchemaServiceAngularModule} from "dashboard-fe-angular";
import {DeviceDeviceModule} from "./main/context/device/device/device-device.module";
import {ReccoVehicleModule} from "./main/context/recco/vehicle/recco-vehicle.module";
import {ReccoModule} from "./main/context/recco/recco.module";
import {Device} from "../context/device/device/device";
import {Organization} from "../context/core/organization/organization";
import { ClientAdministrativeEntityModule } from './main/context/client-administrative-entity/client-administrative-entity.module';


const appRoutes: Routes = [
  {
    path      : '',
    pathMatch : "full",
    component : DynamicHomeComponent,
    //redirectTo: "smart-city/overview"
  },
  {
    path      : '**',
    component : DynamicHomeComponent,
    //redirectTo: "smart-city/overview"
  },
  {
    path      : CommonPaths.DYNAMIC_HOME,
    component : DynamicHomeComponent,
    //redirectTo: "smart-city/overview"
  },
  {
    path      : CommonPaths.NO_HOMEPAGE_DEFINED,
    component : NoHomeDefinedComponent,
  }
];

registerLocaleData(localeIt);

export function initializeApp(appInitService: AuthService) {
  return (): Promise<any> => {
    return appInitService.initLoadUser();
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports     : [
    AppBootstrapModule,
    NavigationModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    //RouterModule.forRoot(appRoutes, {enableTracing:true, onSameUrlNavigation: 'reload'}),
    RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'}),
    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    //mockup API
    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay             : 0,
      passThruUnknownUrl: true
    }),

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    //infrastructural modules
    SchemaServiceAngularModule,

    // App modules
    DynamicHomeModule,
    NoHomeDefinedModule,

    //SampleModule

    Login2Module,
    ActivitiesAndNotesModule,
    DeviceModule,
    ClientAdministrativeEntityModule,
    WeatherModule,


    //SMART CITY
    SmartCityModule,

    //STORE
    StoreModule,

    PropertyModule,

    DeviceBeaconModule,
    DeviceDeviceModule,

    ReccoModule,
    // CONFIG
    LayoutModule

  ],
  providers   : [
    {
      provide: ApmService,
      useClass: ApmService,
      deps: [Router]
    },
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    },
    IottacleNavigationService,
    { provide: LOCALE_ID, useValue: 'it-IT' },
    FuseSplashScreenService,
    FuseConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthGuardService,
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService],
      multi: true
    }
  ],
  bootstrap   : [
    AppComponent
  ]
})
export class AppModule {

  constructor(
    private httpClient: HttpClient,
    private auth:AuthService
  ){



    EntityModelCatalog.registerEntityModel(new Organization(httpClient, auth.loginService));

    let funnel = {
      steps :[
        {
          bool: {
            must : [
              {
                bool: {
                  should: [
                    {
                      match_phrase: {
                        id :{
                          _indexName:"administrativeEntity",
                          query : 71
                        }
                      }
                    }
                  ]
                }
              },
              {
                range: {
                  start: {
                    gte : moment().subtract(10, "days").valueOf(),
                    lte : moment().valueOf(),
                    format: "epoch_millis"
                  }
                }
              },
              {
                "bool": {
                  "should": [
                    {
                      "match_phrase": {
                        "startDow": 1
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 2
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 3
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 4
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 5
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 6
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 7
                      }
                    }
                  ],
                  "minimum_should_match": 1
                }
              },
              {
                range: {
                  startSod: {
                    gte : (6 * 60 * 60),
                    lte : (12 * 60 * 60),
                  }
                }
              },
              {
                range: {
                  avgSs: {
                    gte : -85,
                    lte : 0,
                  }
                }
              },
              {
                range: {
                  duration: {
                    gte : 100,
                    lte : 999999999,
                  }
                }
              }
            ]
          }
        },
        {
          bool: {
            must : [
              {
                bool: {
                  should: [
                    {
                      match_phrase: {
                        id :{
                          _indexName:"administrativeEntity",
                          query : 71
                        }
                      }
                    }
                  ]
                }
              },
              {
                iottacle_step_time: {
                  start: {
                    direction: TimeDirection.after,
                    relativeToStep:0,
                    untilMs: (30*24*60*60*1000) //30 days
                  }
                }
              },
              {
                "bool": {
                  "should": [
                    {
                      "match_phrase": {
                        "startDow": 1
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 2
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 3
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 4
                      }
                    },
                    {
                      "match_phrase":{
                        "startDow": 5
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 6
                      }
                    },
                    {
                      "match_phrase": {
                        "startDow": 7
                      }
                    }
                  ],
                  "minimum_should_match": 1
                }
              },
              {
                range: {
                  startSod: {
                    gte : (6 * 60 * 60),
                    lte : (12 * 60 * 60),
                  }
                }
              },
              {
                range: {
                  avgSs: {
                    gte : -85,
                    lte : 0,
                  }
                }
              },
              {
                range: {
                  duration: {
                    gte : 100,
                    lte : 999999999,
                  }
                }
              }
            ]
          }
        }
      ],
      aggs :{
        relativeToStep:1,
        aggs: {
          "2" : {
            histogram :{
              field: "start",
              interval: (24*60*60*1000),
              min_doc_count: 1
            },
            aggs: {
              "3": {
                cardinality: {
                  field: "id"
                }
              },
              "4": {
                avg: {
                  field : "duration"
                }
              }
            }
          }
        }
      }
    };


    let repo = new Neo4jFunnelDashboardItemRepository();

    repo.invoke(null,FunnelImpl.of(funnel));


  }
}
