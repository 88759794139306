import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from "rxjs";
import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemConfig,
  DashboardItemViewMetricDelta,
  DataResultInterface,
  DataSource,
  DataSourceAssemblerFactory,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {
  AuthService,
  FuseTranslationLoaderService,
  FuseConfigService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  GridsterDashboardConfig
} from "core-fe-angular";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {
  Filter,
  FilterLocationReferencesStrategy, FilterVisitJunkData, FilterVisitNewret, FilterVisitStrategy,
  FilterVisitTimeFilterStrategies, UserAction
} from "iottacle-ts-models";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
// import {FilterLocationReferences} from "../next-one-detail/multiple-filter-builder-generic-elastic";
import {FilterLocationReferences} from "dashboard-items-catalog";

@Component({
  selector: 'store-live',
  templateUrl: './store-live.component.html',
  styleUrls: ['./store-live.component.scss']
})
export class StoreLiveComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel());
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {

    let dashboardItems:DashboardItem[] = [];
    //RepositoryCatalog.init(this.http);

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();

    let filterBuilderElastic:FilterBuilderInterface = new FilterBuilderGenericElastic();

    //Store Visit Count
    let currentCount:DataSourceConfigInterfaceInternal = {
      name: "currentCount",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO, duration: 20, multiplier: 'minutes'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let countItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "1",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Live now - Visits' count" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "people",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:0,
          y:0,
          cols:9,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(currentCount))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].aggregations["1"].value;
          let toRet = {
            mainValue: v ? v : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(countItem);

    //Store Visit Duration
    let currentDuration:DataSourceConfigInterfaceInternal = {
      name: "currentDuration",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO, duration: 20, multiplier: 'minutes'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "avg": {
                "field": "visitDuration"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let durationItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "2",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Live now - Visits' duration" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "min",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:9,
          y:0,
          cols:9,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(currentDuration))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].aggregations["1"].value;
          let toRet = {
            mainValue: v ? (v / 1000 / 60) : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(durationItem);

    //Store Visit Recency
    let currentRecency:DataSourceConfigInterfaceInternal = {
      name: "currentRecency",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO, duration: 20, multiplier: 'minutes'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret: FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "avg": {
                "field": "recency"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let recencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Average Last Visit" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "days ago",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:18,
          y:0,
          cols:9,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(currentRecency))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].aggregations["1"].value;
          let toRet = {
            mainValue: v ? v / 1000 / 60 / 60 / 24 : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(recencyItem);

    //Store Visit Frequency
    let currentFrequency:DataSourceConfigInterfaceInternal = {
      name: "currentFrequency",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO, duration: 20, multiplier: 'minutes'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret: FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "avg": {
                "field": "frequency"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let frequencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "4",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Average Visits per Month" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "times per month",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:27,
          y:0,
          cols:9,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(currentFrequency))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].aggregations["1"].value;
          let toRet = {
            mainValue: v ? v : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(frequencyItem);

    //Store Lost returning Visit Count
    let lostReturningVisitCount:DataSourceConfigInterfaceInternal = {
      name: "currentFrequency",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO, duration: 20, multiplier: 'minutes'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret: FilterVisitNewret.NEW,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [], "must_not": [
                {"match_phrase": {"recency": {"query": 0}}}
              ]}},
          agg: {},
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let lostReturningVisitCountItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "5",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Come Back Lost Customers" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "people",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:0,
          y:5,
          cols:9,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(lostReturningVisitCount))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].hits.total;
          let toRet = {
            mainValue: v ? v : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(lostReturningVisitCountItem);

    //Store Lost returning Visit Recency
    let lostReturningVisitRecency:DataSourceConfigInterfaceInternal = {
      name: "lostReturningVisitRecency",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO, duration: 20, multiplier: 'minutes'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret: FilterVisitNewret.NEW,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [], "must_not": [
                {"match_phrase": {"recency": {"query": 0}}}
              ]}},
          agg: {
            "1": {
              "avg": {
                "field": "recency"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let lostReturningVisitRecencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "6",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Average Last Visit" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "days ago",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:9,
          y:5,
          cols:9,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(lostReturningVisitRecency))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].aggregations["1"].value;
          let toRet = {
            mainValue: v ? v / 1000 / 60 / 60 / 24 : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(lostReturningVisitRecencyItem);


    if (this.authService.getCurrentLoggedUser().canUserDoOnResourcePathWithCurrentOrganization('AllResources.frontEnd.storeSection.multiInternalLocation', UserAction.READ)){
      console.log("UserCan");
    }

    let dashboardCfg = DashboardConfig.of(
      {
        id: "StoreLive1234",
        name: "Store Live",
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        }
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);
    this.dashboard.setFilter(this.filter.filterValues);
    this.filterChangedSubscription = this.filter.filterChangedObservable()
      .subscribe((newFilterValue) => {
        this.dashboard.reloadData();
        this.showDashboard = true;
      });

  }


}


