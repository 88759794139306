import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DashboardItemConfig,
  DashboardItemViewGeneric,
  DashboardItemViewMetricDelta,
  DataResultInterface,
  DataSource,
  DataSourceAssemblerFactory,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory
} from "iottacle-dashboard";
import {DecimalPipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {locale as english} from '../i18n/en';
import {locale as italian} from '../i18n/it';
import {
  FuseConfigService,
  FuseTranslationLoaderService,
  AuthService,
  RepositoryCatalog,
  GridsterDashboardConfig
} from "core-fe-angular";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {Observable, of} from "rxjs";
import {Router} from "@angular/router";
import {DashboardLocalStoragePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-local-storage-persistency";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import {
  AdministrativeEntityType,
  Coordinate,
  Filter,
  FilterInterface, FilterLocationReferences, FilterLocationReferencesStrategy,
  FilterQuickValues,
  FilterTypeValues, FilterVisitJunkData, FilterVisitNewret, FilterVisitStrategy,
  MyAdministrativeEntity
} from "iottacle-ts-models";

@Component({
  selector: 'store-overview',
  templateUrl: './store-overview.component.html',
  styleUrls: ['./store-overview.component.scss']
})
export class StoreOverviewComponent implements OnInit, OnDestroy{


  dashboard:Dashboard;
  showDashboard = false;

  constructor(
    private auth:AuthService,
    private decimalPipe: DecimalPipe,
    private http:HttpClient,
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private router:Router
  ){

    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };
  }

  ngOnDestroy(): void {
    this.dashboard.destroy();
  }

  ngOnInit(): void {

    let dashboardItems:DashboardItem[] = [];

    RepositoryCatalog.init(this.http);

    let filterBuilder:FilterBuilderInterface = new FilterBuilderGenericElastic();

    let commonFilter = new Filter(this.auth.getUnderlyingLoginModel());
    commonFilter.changeValueToQuick({
      filterType : FilterTypeValues.QUICK,
      filterQuickValue: FilterQuickValues.LAST_30_DAYS,
      administrativeEntityId : this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities()[0].getAdministrativeEntityDetails().administrativeEntityId,
      administrativeEntities : [this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities()[0]]
    });

    //MAP admin entities
    let adminEntityLocationsMarker:DataSourceConfigInterfaceInternal = {
      name: "peopleMovement",
      type: "elastic",
      assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "custom",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          let data:{
            markers?:Coordinate[],
          } = {
            markers: []
          };

          let adminEntities:MyAdministrativeEntity[] = this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities();
          for (let a in adminEntities){
            data.markers.push(adminEntities[a].getAdministrativeEntityDetails().getCoordinate());
          }
          return of(data);
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return undefined;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let adminEntityMap:DashboardItem = DashboardItem.of(DashboardItemConfig.of({
      id: "1"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Where are my stores" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: undefined,
      itemType : "map-with-polygons",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        height: 300,
        heatmapRadius : 30,
        gridsterConfig : {
          x: 0,
          y: 0,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "a" : DataSource.of(DataSourceConfig.of(adminEntityLocationsMarker)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
    }));
    dashboardItems.push(adminEntityMap);

    //ADMIN ENTITIES LIST
    let adminEntityLocationsDataSource:DataSourceConfigInterfaceInternal = {
      name: "storeList",
      type: "elastic",
      assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "custom",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          let data:any = this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities()
            .filter ((a:MyAdministrativeEntity) => {
              return a.administrativeEntityDetails.type === AdministrativeEntityType.STORE;
            })
            .map((a:MyAdministrativeEntity) => {
              let adminDetail = a.administrativeEntityDetails;
              return {
                storeId : adminDetail.administrativeEntityId,
                name: adminDetail.name,
                address : adminDetail.address,
                status : a.status
              };
            });
          return of(data);
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return undefined;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let adminEntityLocations:DashboardItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "2"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Store List" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "data-table",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "orange-fg",
        topTextTitle: "",
        topTextMatIcon: "warning",
        topTextSubtitle: "",
        columns: [
          {
            name:'name',
            type:'link',
            onClick : (element, elementDefinition)=>{
              this.router.navigate(["/store/detail"], {queryParams: { administrativeEntityId: element.storeId }, queryParamsHandling: 'merge' }); //preserve
            }
          },
          {
            name:'address',
            type:'text'
          },
          {
            name:'status',
            type:'text'
          }],
        gridsterConfig : {
          x:19,
          y:0,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(adminEntityLocationsDataSource))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(adminEntityLocations);

    let globalTimeStrategyFilter = {
      timestampField:'start',
      strategy : 'time-ago', duration:10, multiplier:'days'
    };
    //OCCUPANCY METER
    let maxOccupancyAndTrend:DataSourceConfigInterfaceInternal = {
      name: "totalCountCurrentPeriod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : globalTimeStrategyFilter,
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_ADMINS
          },
          adminEntityType : [AdministrativeEntityType.STORE],
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "2": {
              "avg_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let occupancyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Average Occupancy" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 10 days",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: "%",
        miniChartConfig: {
          chartType : "XYChart",
          chart :{
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            cursor : {
              lineY : {
                disabled : true
              }
            },
            xAxes: [{
              type: "DateAxis",
              dataFields: {
                category: "name"
              },
              renderer: {
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            yAxes: [{
              type: "ValueAxis",
              renderer: {
                baseGrid: {
                  disabled: true
                },
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            series: [
              {
                type: "LineSeries",
                dataFields: {
                  valueY: "value",
                  dateX: "key"
                },
                tooltip: {
                  pointerOrientation: "vertical"
                },
                tensionX : 0.8,
                strokeWidth:2
              }
            ]
          }
        }
      })),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:0,
          cols:9,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(maxOccupancyAndTrend)),
      },

      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let maxOccupancy = result["1"].aggregations["1"].value;
            let avgPercOccupancy = result["1"].aggregations["2"].value / maxOccupancy * 100;
            let miniChartData:any = [];
            for (let b= 0; b< result["1"].aggregations["1-bucket"]["buckets"].length; b++){
              miniChartData.push({
                key : result["1"].aggregations["1-bucket"]["buckets"][b].key,
                value : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value
              })
            }
            toRet = {
              mainValue : avgPercOccupancy,
              miniChartData : miniChartData
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(occupancyItem);

    //Duration METER
    let maxDurationLast10daysAndTrend:DataSourceConfigInterfaceInternal = {
      name: "maxDurationLast10daysAndTrend",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : globalTimeStrategyFilter,
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_ADMINS
          },
          adminEntityType : [AdministrativeEntityType.STORE],
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "2": {
              "avg_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "avg": {
                    "field": "visitDuration"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let durationItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "4",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Average Duration" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 10 days",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: "min",
        miniChartConfig: {
          chartType : "XYChart",
          chart :{
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            cursor : {
              lineY : {
                disabled : true
              }
            },
            xAxes: [{
              type: "DateAxis",
              dataFields: {
                category: "name"
              },
              renderer: {
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            yAxes: [{
              type: "ValueAxis",
              renderer: {
                baseGrid: {
                  disabled: true
                },
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            series: [
              {
                type: "LineSeries",
                dataFields: {
                  valueY: "value",
                  dateX: "key"
                },
                tooltip: {
                  pointerOrientation: "vertical"
                },
                tensionX : 0.8,
                strokeWidth:2
              }
            ]
          }
        }
      })),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:9,
          cols:9,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(maxDurationLast10daysAndTrend))
      },

      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let maxDuration = result["1"].aggregations["1"].value;
            let avgDuration = result["1"].aggregations["2"].value / 1000 / 60;
            let miniChartData:any = [];
            for (let b= 0; b< result["1"].aggregations["1-bucket"]["buckets"].length; b++){
              miniChartData.push({
                key : result["1"].aggregations["1-bucket"]["buckets"][b].key,
                value : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value/1000/60
              })
            }
            toRet = {
              mainValue : avgDuration,
              miniChartData : miniChartData
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(durationItem);

    //opportunity
    let externalVisitsLast10days:DataSourceConfigInterfaceInternal = {
      name: "externalVisitsLast10days",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : globalTimeStrategyFilter,
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_ADMINS
          },
          adminEntityType : [AdministrativeEntityType.STORE],
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_EXTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "2": {
              "avg_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 0
              },
              "aggs": {
                "1-metric": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let internalVisitsLast10days:DataSourceConfigInterfaceInternal = {
      name: "internalVisitsLast10days",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : globalTimeStrategyFilter,
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_ADMINS
          },
          adminEntityType : [AdministrativeEntityType.STORE],
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "2": {
              "avg_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 0
              },
              "aggs": {
                "1-metric": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let opportunityItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "5",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Average Opportunity" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 10 days",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 1,
        maxFractionDigits : 2,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: "%",
        miniChartConfig: {
          chartType : "XYChart",
          chart :{
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            cursor : {
              lineY : {
                disabled : true
              }
            },
            xAxes: [{
              type: "DateAxis",
              dataFields: {
                category: "name"
              },
              renderer: {
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            yAxes: [{
              type: "ValueAxis",
              renderer: {
                baseGrid: {
                  disabled: true
                },
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            series: [
              {
                type: "LineSeries",
                dataFields: {
                  valueY: "value",
                  dateX: "key"
                },
                tooltip: {
                  pointerOrientation: "vertical"
                },
                tensionX : 0.8,
                strokeWidth:2
              }
            ]
          }
        }
      })),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:0,
          cols:9,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(externalVisitsLast10days)),
        "2" : DataSource.of(DataSourceConfig.of(internalVisitsLast10days))
      },

      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let temp = {};
            for (let b in result["1"].aggregations["1-bucket"]["buckets"]){
              let key = result["1"].aggregations["1-bucket"]["buckets"][b].key;
              temp[key] = {
                key : key,
                external : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value,
                internal : 0,
                ratio : 0
              };
            }
            for (let b in result["2"].aggregations["1-bucket"]["buckets"]){
              let key = result["1"].aggregations["1-bucket"]["buckets"][b].key;
              if (!temp[key]){
                temp[key] = {
                  key: key,
                  internal: result["2"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value,
                  external : 0,
                  ratio : 0
                };
              }else {
                temp[key].internal =
                  result["2"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value;
                temp[key].ratio = temp[key].internal / temp[key].external;
              }
            }

            let avgRatio = result["2"].aggregations["2"].value / result["1"].aggregations["2"].value *100;
            let miniChartData:any = [];
            let c = 0;
            for (let b in temp){
              miniChartData.push({
                key : temp[b].key,
                value : temp[b].ratio
              });
              if (c >= 10){
                break;
              }
              c++;
            }
            toRet = {
              mainValue : avgRatio,
              miniChartData : miniChartData
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(opportunityItem);

    //new vs ret
    let newVisitsLast10days:DataSourceConfigInterfaceInternal = {
      name: "newVisitsLast10days",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : globalTimeStrategyFilter,
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_ADMINS
          },
          adminEntityType : [AdministrativeEntityType.STORE],
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE,
            newret : FilterVisitNewret.NEW,
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "2": {
              "avg_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 0
              },
              "aggs": {
                "1-metric": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let retVisitsLast10days:DataSourceConfigInterfaceInternal = {
      name: "retVisitsLast10days",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : globalTimeStrategyFilter,
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_ADMINS
          },
          adminEntityType : [AdministrativeEntityType.STORE],
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE,
            newret : FilterVisitNewret.RETURNING,
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "2": {
              "avg_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 0
              },
              "aggs": {
                "1-metric": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let newretItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "6",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Returning Over New" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 10 days",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 1,
        maxFractionDigits : 2,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: "%",
        miniChartConfig: {
          chartType : "XYChart",
          chart :{
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            cursor : {
              lineY : {
                disabled : true
              }
            },
            xAxes: [{
              type: "DateAxis",
              dataFields: {
                category: "key"
              },
              renderer: {
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            yAxes: [{
              type: "ValueAxis",
              renderer: {
                baseGrid: {
                  disabled: true
                },
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            series: [
              {
                type: "LineSeries",
                dataFields: {
                  valueY: "value",
                  dateX: "key"
                },
                tooltip: {
                  pointerOrientation: "vertical"
                },
                tensionX : 0.8,
                strokeWidth:2
              }
            ]
          }
        }
      })),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:0,
          cols:9,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(newVisitsLast10days)),
        "2" : DataSource.of(DataSourceConfig.of(retVisitsLast10days))
      },

      assembler : {
        name : "custom123",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let temp = {};
            for (let b in result["1"].aggregations["1-bucket"]["buckets"]){
              let key = result["1"].aggregations["1-bucket"]["buckets"][b].key;
              temp[key] = {
                key : key,
                new : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value,
                ret : 0,
                ratio : 0
              };
            }
            for (let b in result["2"].aggregations["1-bucket"]["buckets"]){
              let key = result["2"].aggregations["1-bucket"]["buckets"][b].key;
              if (!temp[key]){
                temp[key] = {
                  key: key,
                  ret: result["2"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value,
                  new : 0,
                  ratio : 0
                };
              }else {
                temp[key].ret =
                  result["2"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value;
                temp[key].ratio = temp[key].ret / temp[key].new;
              }
            }

            let avgRatio = result["2"].aggregations["2"].value / result["1"].aggregations["2"].value *100;
            let miniChartData:any = [];
            let c = 0;
            for (let b in temp){
              miniChartData.push({
                key : temp[b].key,
                value : temp[b].ratio
              });
              if (c >= 10){
                break;
              }
              c++;
            }
            toRet = {
              mainValue : avgRatio,
              miniChart : miniChartData
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom123"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(newretItem);




    let experiments:any = this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities()
      .filter ((a:MyAdministrativeEntity) => {
        return a.administrativeEntityDetails.type === AdministrativeEntityType.EXPERIMENT;
      })
      .map((a:MyAdministrativeEntity) => {
        let adminDetail = a.administrativeEntityDetails;
        return {
          adminEntityId : adminDetail.administrativeEntityId,
          name: adminDetail.name,
          address : adminDetail.address,
          status : a.status
        };
      });
    //Experiment List
    if (experiments.length > 0) {
      let experimentDataSource: DataSourceConfigInterfaceInternal = {
        name: "experiments",
        type: "elastic",
        assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
        repo: {
          getName: () => "custom",
          getChannelType: () => ChannelType.CLOSEABLE,
          invoke: (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
            return of(experiments);
          },
          rehydrate: (serializedAssembler: string): CloseableRepositoryInterface => {
            return undefined;
          },
          serialize: (): string => {
            return JSON.stringify({
              name: "custom"
            });
          }
        },
        filter: FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
        specificDataSourceConfig: {},
      };
      let experimentListItem: DashboardItem = DashboardItem.of(DashboardItemConfig.of({
        id: "7",
        observedLevel: ObservedLevel.ITEM_VIEW,
        name: "Experiment List",
        showHeader : true,
        readOnly: true,
        showName: true,
        autoUpdateMs: 0,
        subtitle: "",
        infoButtonText: "",
        itemType: "data-table",
        style:{
          background : "white",
          'border-color' : "#00000042",
          'border-radius': "8px"
        },
        viewConfig: DashboardItemViewGeneric.empty(),
        specificItemConfig: {
          showTopText: false,
          topTextMatIconColorClass: "orange-fg",
          topTextTitle: "",
          topTextMatIcon: "warning",
          topTextSubtitle: "",
          columns: [
            {
              name: 'name',
              type: 'link',
              onClick: (element, elementDefinition) => {
                this.router.navigate(["/experiment/detail"], {
                  queryParams: {administrativeEntityId: element.storeId},
                  queryParamsHandling: 'merge'
                }); //preserve
              }
            },
            {
              name: 'address',
              type: 'text'
            },
            {
              name: 'status',
              type: 'text'
            }],
          gridsterConfig: {
            x: 17,
            y: 0,
            cols: 18,
            rows: 12,
            draggable: {
              enabled: true
            }
          }
        },
        dataSources: {
          "1": DataSource.of(DataSourceConfig.of(experimentDataSource))
        },
        assembler: DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
      }));
      dashboardItems.push(experimentListItem);
    }

    let dashboardCfg = DashboardConfig.of(
      {
        id: "OverviewDashboard1234",
        name : "Overview Dashboard",
        dashboardPersistency : DashboardLocalStoragePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        },
        initFilter : commonFilter
      }
      );

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    //this.dashboard.load();
    //this.dashboard.save();

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData();
          this.showDashboard = true;
        }
      });

  }

}


