import {
  DashboardItem, DashboardItemAssemblerFactory, DataResultInterface,
  DataSource,
  DataSourceAssemblerFactory, DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  GenericDataResult,
  RepositoryFactory
} from "iottacle-dashboard";
import {HttpClient} from "@angular/common/http";
import {DataSourceCatalog} from "iottacle-dashboard/dist/domain/data-source/data-source-catalog";
import {Observable, of} from "rxjs";
import {
  DataSourceAssemblerCatalog,
  RepositoryCatalog
} from "core-fe-angular";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import {FilterLocationReferences, FilterLocationReferencesStrategy} from "iottacle-ts-models";


export class SmartCityDashboardElements {


  constructor(
    http: HttpClient,
  ){
    RepositoryCatalog.init(http);
    DataSourceAssemblerCatalog.init();

    let visitFillAndWeatherSmartCityDashboardItemAssembler = {
      name : "visitFillAndWeatherSmartCityDashboardItemAssembler",
      assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
        if (result.dailyWeather){
          for (let i in result.nearVisitsFillOverTime){
            let timestamp = result.nearVisitsFillOverTime[i].key;
            let weather = result.dailyWeather.find(el => el.key == timestamp);
            if (weather){
              result.nearVisitsFillOverTime[i].weatherIconUrl = weather.weatherIconUrl;
            }

          }
        }
        return of(GenericDataResult.ofSuccess(thiz.getId().toString(), result.nearVisitsFillOverTime));
      },
      serialize : () => {
        return JSON.stringify({
          name : visitFillAndWeatherSmartCityDashboardItemAssembler.name
        });
      },
      rehydrate : (serializedAssembler:string) => {
        return visitFillAndWeatherSmartCityDashboardItemAssembler;
      }
    };
    DashboardItemAssemblerFactory.registerAssembler(visitFillAndWeatherSmartCityDashboardItemAssembler);


    /////////////////////
    // DATA SOURCE
    /////////////////////
    let filterBuilder:FilterBuilderInterface = new FilterBuilderGenericElastic();
    let nearVisitsFillOverTime:DataSourceConfigInterfaceInternal = {
      name: "nearVisitsFillOverTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("elasticDateHeatMapBucket"),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            strategy : "time-ago",
            timestampField:'timeToConsider',
            duration: 20,
            multiplier : "days"
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 10000,"lt": 700000000}}},
                {"range": {"avgSs": {"gte": -82, "lte": 0}}},
                {"bool": {"should": [{match_phrase: {adminEntityId: 60}}], "minimum_should_match": 1}}
                ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "dateBucket": {
              "date_histogram": {
                "field": "timeToConsider",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "average": {
                  "avg": {
                    "field": "visitDuration"
                  }
                },
                "uniqueCount" : {
                  "cardinality" : {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits_count",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    DataSourceCatalog.registerDataSource(DataSource.of(DataSourceConfig.of(nearVisitsFillOverTime)));
  }

  public static init(http: HttpClient):SmartCityDashboardElements{
    return new SmartCityDashboardElements(http);
  }
}
