import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";

import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DataSource,
  DataSourceConfig
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardConsolePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import {
  AuthService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {Filter, MyAdministrativeEntity} from "iottacle-ts-models";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {
  DashboardItemFactoryChart
} from "../next-one-sky-insights-00/utils";
import {
  EventDataSource
} from "../next-one-detail/event-data-source";

import {
  DashboardItemCatalog,
  Utils
}  from "dashboard-items-catalog";
import {GenericDataSource} from "../next-one-detail/iottacle-generic-data-source";

@Component({
  selector: 'next-one-duration',
  templateUrl: './next-one-duration.component.html',
  styleUrls: ['./next-one-duration.component.scss']
})
export class NextOneDurationComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private translateService:TranslateService,
    private http:HttpClient
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {}, this.translateService);
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {
    let dashboardItems:DashboardItem[] = [];
    let user = this.authService.getCurrentLoggedUser();
    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let readOnly = true;

    //avg Duration Metric
    // let durationSamePeriodItem = DashboardItemCatalog.metricDelta_averageRangeVisitDuration(readOnly,this.translateService, this.http,
    //   {x:0, y:0, cols:14, rows:5});
    // dashboardItems.push(durationSamePeriodItem);
    let durationSamePeriodItem1 = DashboardItemCatalog.metricDelta_averageRangeVisitDuration_v2(
      {
        substituteDataSources: true,
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"metricDelta_averageRangeVisitDuration_v2", user )))
        },
        readOnly: readOnly,
        specificItemConfig:{
          gridsterConfig:{x:0, y:0, cols:14, rows:5}
        }
      },
      this.translateService, this.http);
    dashboardItems.push(durationSamePeriodItem1);

    //Visits duration distribution
    // let visitsDurationDistributionItem =  DashboardItemCatalog.barChart_visitsDurationDistributionSimplified(readOnly, this.translateService, this.http,
    //   {x:14, y:0, cols:22, rows:14}, DashboardItemFactoryChart.createXYChartItem({
    //     legend:{
    //       showLegendIntoAccordion: true
    //     },
    //     swappedAxes: true,
    //     export:{
    //       excel:true,
    //       csv:true,
    //       image:true
    //     }
    //   }));
    // dashboardItems.push(visitsDurationDistributionItem);
    let visitsDurationDistributionItem1 =  DashboardItemCatalog.barChart_visitsDurationDistributionSimplified_v2(
      {
        substituteDataSources: true,
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"barChart_visitsDurationDistributionSimplified_v2", user )))
        },
        readOnly: readOnly,
        specificItemConfig:{
          gridsterConfig:{x:14, y:0, cols:22, rows:14}
        },
        viewConfig:DashboardItemFactoryChart.createXYChartItem({
          legend:{
            showLegendIntoAccordion: true
          },
          swappedAxes: true,
          export:{
            excel:true,
            csv:true,
            image:true
          }
        })
      },
      this.translateService, this.http);
    dashboardItems.push(visitsDurationDistributionItem1);


    //Visits duration over time
    // let visitsDurationOverTimeSamePeriodItem =  DashboardItemCatalog.barChart_visitsDurationAlongDaysWithEvents(readOnly, this.translateService, this.authService, this.http,
    //   {x:0, y:10, cols:36, rows:12}, DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService))), DashboardItemFactoryChart.createXYChartItem({
    //     xSort: "asc",
    //     periodicVerticalLine:{
    //       period: "auto"
    //     },
    //     series : {
    //       percentiles : {
    //         type: "line"
    //       }
    //     },
    //     export:{
    //       excel:true,
    //       csv:true,
    //       image:true
    //   }
    //   }));
    // dashboardItems.push(visitsDurationOverTimeSamePeriodItem);
    let visitsDurationOverTimeSamePeriodItem1 =  DashboardItemCatalog.barChart_visitsDurationAlongDaysWithEvents_v2(
      {
        substituteDataSources: true,
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"barChart_visitsDurationAlongDaysWithEvents_v2", user ))),
          "events":DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService)))
        },
        readOnly: readOnly,
        specificItemConfig:{
          gridsterConfig:{x:0, y:10, cols:36, rows:12}
        },
        viewConfig:DashboardItemFactoryChart.createXYChartItem({
          xSort: "asc",
          periodicVerticalLine:{
            period: "auto"
          },
          series : {
            percentiles : {
              type: "line"
            }
          },
          export:{
            excel:true,
            csv:true,
            image:true
          }
        })
      },
      this.translateService, this.http);
    dashboardItems.push(visitsDurationOverTimeSamePeriodItem1);

    // let visitsOverDowItem =  DashboardItemCatalog.barChart_visitsDurationOverDowDistribution(readOnly, this.translateService, this.http,
    //   {x:0, y:20, cols:18, rows:12}, DashboardItemFactoryChart.createXYChartItem({
    //     legend:{
    //       showLegendIntoAccordion:true,
    //     },
    //     series : {
    //       percentiles : {
    //         type: "line"
    //       }
    //     },
    //     export:{
    //       excel:true,
    //       csv:true,
    //       image:true
    //   }
    //   }));
    // dashboardItems.push(visitsOverDowItem);
    let visitsOverDowItem1 =  DashboardItemCatalog.barChart_visitsDurationOverDowDistribution_v2(
      {
        substituteDataSources: true,
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"barChart_visitsDurationOverDowDistribution_v2", user )))
        },
        readOnly: readOnly,
        specificItemConfig:{
          gridsterConfig:{x:0, y:20, cols:18, rows:12}
        },
        viewConfig:DashboardItemFactoryChart.createXYChartItem({
          legend:{
            showLegendIntoAccordion:true,
          },
          series : {
            percentiles : {
              type: "line"
            }
          },
          export:{
            excel:true,
            csv:true,
            image:true
          }
        })
      },
      this.translateService, this.http);
    dashboardItems.push(visitsOverDowItem1);


    // let visitsOverHodItem =  DashboardItemCatalog.barChart_visitsDurationOverHodDistribution(readOnly, this.translateService, this.http,
    //   {x:18, y:20, cols:18, rows:12}, DashboardItemFactoryChart.createXYChartItem({
    //     legend:{
    //       showLegendIntoAccordion:true,
    //     },
    //     xSort: "asc",
    //     series : {
    //       percentiles : {
    //         type: "line"
    //       }
    //     },
    //     export:{
    //       excel:true,
    //       csv:true,
    //       image:true
    //   }
    //   }));
    // dashboardItems.push(visitsOverHodItem);
    let visitsOverHodItem1 =  DashboardItemCatalog.barChart_visitsDurationOverHodDistribution_v2(
      {
        substituteDataSources: true,
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"barChart_visitsDurationOverHodDistribution_v2", user )))
        },
        readOnly: readOnly,
        specificItemConfig:{
          gridsterConfig:{x:18, y:20, cols:18, rows:12}
        },
        viewConfig:DashboardItemFactoryChart.createXYChartItem({
          legend:{
            showLegendIntoAccordion:true,
          },
          xSort: "asc",
          series : {
            percentiles : {
              type: "line"
            }
          },
          export:{
            excel:true,
            csv:true,
            image:true
          }
        })
      },
      this.translateService, this.http);
    dashboardItems.push(visitsOverHodItem1);


    // @ts-ignore
    let adminEntities:MyAdministrativeEntity[] = this.authService.getCurrentLoggedUser().getMyOrganizations().flatMap(org => org.getMyAdministrativeEntities());

    let context = {
      adminEntities: adminEntities,
      fieldValueLookup: {
        adminEntityId : {
          lookup:(value:any) => {
            let adminEntity = adminEntities.find((el) => el.administrativeEntityDetails.id == value);
            return adminEntity ? adminEntity.administrativeEntityDetails.name : value;
          }
        },
        newret:Utils.fieldValueLookup.newret,
        startDow:Utils.fieldValueLookup.startDow(this.translateService),
        startSod:Utils.fieldValueLookup.startSod,
        visitDuration:Utils.fieldValueLookup.visitDuration,
      },
      translateService: this.translateService
    };

    let dashboardCfg = DashboardConfig.of(
      {
        id: "NextOneAccessBreakdown",
        name: "Next One",
        dashboardPersistency : DashboardConsolePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        },
        initFilter : this.filter.filterValues
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData(this.filter.filterValues, context);
          this.showDashboard = true;
          this.filterChangedSubscription = this.filter.filterChangedObservable()
            .subscribe((newFilterValue) => {
              this.dashboard.reloadData(newFilterValue, context);
              this.showDashboard = true;
            });
        }
      });

  }




}


