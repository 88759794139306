import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FilterModule} from "../../filter/filter.module";
import {AuthGuardService,FuseSharedModule,IottacleNavigationService} from "core-fe-angular";
import {RouterModule} from "@angular/router";
import {GridsterModule} from "angular-gridster2";
import {AngularDashboardModule} from "dashboard-fe-angular";
import {WeatherComponent} from "./weather.component";


const routes = [
  {
    path     : 'weather',
    component: WeatherComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  providers : [
  ],
  imports     : [
    CommonModule,
    FuseSharedModule,
    RouterModule.forChild(routes),
    FilterModule,
    GridsterModule,
    AngularDashboardModule
  ],
  declarations: [WeatherComponent]
})
export class WeatherModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService
  ){

  }
}
