import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, Subscription} from "rxjs";
import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DashboardItemConfig, DashboardItemFactory, DashboardItemViewGeneric,
  DashboardItemViewMetricDelta, DashboardItemViewSerialChart,
  DataResultInterface, DataSource,
  DataSourceAssemblerFactory,
  DataSourceAssemblerInterface,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory, SerialChartConfig, ViewAssemblerFactory
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {
  AuthService,
  FuseTranslationLoaderService,
  FuseConfigService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog, GridsterDashboardConfig
} from "core-fe-angular";
import {DecimalPipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {map} from "rxjs/operators";
import moment from "moment";
import {DashboardLocalStoragePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-local-storage-persistency";
import {MatDialog} from "@angular/material";
import {ClimateChangeDialog} from "./climate-change-dialog";
import {
  Filter,
  FilterInterface,
  FilterQuickValues,
  FilterTypeValues,
  FilterVisitTimeFilterStrategies
} from "iottacle-ts-models";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";


@Component({
  selector: 'property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    public dialog: MatDialog,
    private http:HttpClient,
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private auth:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

  }

  ngOnDestroy(): void {
    this.dashboard.destroy();
  }

  ngOnInit(): void {

    let dashboardItems:DashboardItem[] = [];
    //RepositoryCatalog.init(this.http);

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let filterBuilder:FilterBuilderInterface = new FilterBuilderGenericElastic();



    //lock
    let doorStatus = DashboardItemFactory.ofCloseableCustomRepo({
      id: "1",
      name: "Door Status",
      showHeader : false,
      readOnly: true,
      showName: true,
      subtitle: "",
      autoUpdateMs: 10000,
      itemType: "simple-icon",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 0,
          cols: 4,
          rows: 4,
          draggable: {
            enabled: false
          }
        }
      },
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      const url = "analyticsapi/demo/domotics/states/lock.poly_control_danalock_v3_btze_locked";
      return this.http.get<any>(url).pipe(
        map((val)=>{
          let r = {
            matIcon : val.state == "locked" ? 'lock' : 'lock_open',
            iconColor : val.state == 'locked' ? 'red' : 'green',
            subtitle: val.state
          };
          return r;
        })
      );

    });
    dashboardItems.push(doorStatus);

    //climate
    let climateStatus = DashboardItemFactory.ofCloseableCustomRepo({
      id: "2",
      name: "Climate Status",
      showHeader : false,
      readOnly: true,
      showName: true,
      subtitle: "",
      autoUpdateMs: 10000,
      itemType: "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 0,
          cols: 4,
          rows: 4,
          draggable: {
            enabled: false
          }
        }
      },
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      const url = "analyticsapi/demo/domotics/states/climate.netatmo_salone";
      return this.http.get<any>(url).pipe(
        map((val)=>{
          let r = {
            value: val.attributes.current_temperature
          };
          return r;
        })
      );

    });
    dashboardItems.push(climateStatus);

    //climate
    let co2Status = DashboardItemFactory.ofCloseableCustomRepo({
      id: "3",
      name: "Climate Status",
      showHeader : false,
      readOnly: true,
      showName: true,
      subtitle: "",
      autoUpdateMs: 10000,
      itemType: "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 0,
          cols: 4,
          rows: 4,
          draggable: {
            enabled: false
          }
        }
      },
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      return of({
        value: "OK"
      })

    });
    dashboardItems.push(co2Status);


    //current energy
    let currentEnergyStatus = DashboardItemFactory.ofCloseableCustomRepo({
      id: "4",
      name: "Climate Status",
      showHeader : false,
      readOnly: true,
      showName: true,
      subtitle: "",
      autoUpdateMs: 10000,
      itemType: "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 0,
          cols: 4,
          rows: 4,
          draggable: {
            enabled: false
          }
        }
      },
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      return of({
        value: "143 Kw"
      })

    });
    dashboardItems.push(currentEnergyStatus);

    let filterBuilderElastic:FilterBuilderInterface = new FilterBuilderGenericElastic();

    //current noise
    let currentNoiseStatusDataSource:DataSourceConfigInterfaceInternal = {
      name: "currentNoiseStatusDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'tm',
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration:5, multiplier:'minutes'
          },
          query: {"bool": {"must": [
                {"match_phrase": {"dd": {"query": 150}}}
              ]}},
          agg : {
            "2": {
              "avg": {
                "field": "db"
              }
            }
          },
          index : "noise_meter",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let currentNoiseStatus =  DashboardItem.of(DashboardItemConfig.of({
      id: "5"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Noise Level" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 10000,
      subtitle: "",
      infoButtonText : "",
      itemType : "percentage-with-icon",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        gridsterConfig : {
          x: 0,
          y: 0,
          cols: 4,
          rows: 4,
          draggable: {
            enabled: false
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(currentNoiseStatusDataSource)),
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          if (result){
            let chartData:any = [];
            let noise = result["1"].aggregations["2"]["value"];
            let maxNoise = 15; //decibel

            let perc = noise / maxNoise;

            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), {
              value: perc
            }));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(currentNoiseStatus);

    //current occupancy
    let currentOccupancyStatusDataSource:DataSourceConfigInterfaceInternal = {
      name: "currentOccupancyStatusDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'timeToConsider',
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration:30, multiplier:'minutes'
          },
          query: {"bool": {"must": [
                {"match_phrase": {"adminEntityId": {"query": 53}}},
                {"range": {"visitDuration": {"gte": 60000,"lt": 700000000}}},
                {"range": {"avgSs": {"gte": -82, "lte": 0}}}
              ]}},
          agg : {
            "2": {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index : "visits_count",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let currentOccupancyStatus =  DashboardItem.of(DashboardItemConfig.of({
      id: "6"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Occupancy Level" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 10000,
      subtitle: "",
      infoButtonText : "",
      itemType : "percentage-with-icon",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        gridsterConfig : {
          x: 0,
          y: 0,
          cols: 4,
          rows: 4,
          draggable: {
            enabled: false
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(currentOccupancyStatusDataSource)),
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          if (result){
            let chartData:any = [];
            let occ = result["1"].aggregations["2"]["value"];
            let maxOcc = 20; //people count

            let perc = occ / maxOcc;

            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), {
              value: perc
            }));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(currentOccupancyStatus);

    //Property Alerts
    let propertyAlerts = DashboardItemFactory.ofCloseableCustomRepo({
      id: "7",
      name: "Recent Activity",
      showHeader : true,
      readOnly: true,
      showName: true,
      autoUpdateMs : 10000,
      itemType: "data-table",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 5,
          cols: 8,
          rows: 11,
          draggable: {
            enabled: false
          }
        },
        columns: [
          {
            name: 'Action',
            type: 'text'
          },
          {
            name: 'Date & Time',
            type: 'text'
          }]
      }
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      let acceptedEntities  =["lock.poly_control_danalock_v3_btze_locked","climate.netatmo_salone"];
      const url = "analyticsapi/demo/domotics/history/period?filter_entity_id="  + acceptedEntities.join(",");
      return this.http.get<any>(url).pipe(
        map((val)=>{
          let r = [];
          let i=0;
          let maxElements = 3;
          while (r.length<maxElements && i<val.length){
            let element = val[val.length - 1 - i];
            let x = 0;
            while (i<maxElements && x<element.length) {
              let event = element[x];
              let entityId = event.entity_id;
              if (acceptedEntities.indexOf(entityId)>=0) {
                let state = event.state;
                let time = moment(event.last_updated);
                let timeFromNow = time.fromNow();

                let action = '';
                if (entityId == 'lock.poly_control_danalock_v3_btze_locked') {
                  if (state == 'locked') {
                    action = 'Door Lock Closed';
                  } else {
                    action = 'Door Lock Open';
                  }
                } else if (entityId == 'climate.netatmo_salone') {
                  action = 'Thermostat mode ' + state;
                } else {
                  action = entityId + " state " + state;
                }

                r.push({
                  'Action': action,
                  'Date & Time': timeFromNow,
                  'ts' : time.valueOf()
                });
              }
              x++;
            }
            i++;
          }
          r.sort((a, b) => (a.ts < b.ts) ? 1 : (a.ts > b.ts) ? -1 : 0);
          return r;
        })
      );

    });
    dashboardItems.push(propertyAlerts);


    //Lights status
    let lightsStatus = DashboardItemFactory.ofCloseableCustomRepo({
      id: "8",
      name: "Light Status",
      showHeader : true,
      readOnly: true,
      showName: true,
      autoUpdateMs : 10000,
      itemType: "data-table",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 5,
          cols: 8,
          rows: 11,
          draggable: {
            enabled: false
          }
        },
        columns: [
          {
            name: 'Name',
            type: 'text'
          },
          {
            name: 'Status',
            type: 'text'
          }]
      }
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      const url = "analyticsapi/demo/domotics/states/";
      return this.http.get<any>(url).pipe(
        map((val)=>{
          let r = [];
          for (let e in val){
            let type = val[e].entity_id.split(".")[0];
            if (type === "light"){
              val[e].Name = val[e].attributes.friendly_name;
              val[e].Status = val[e].state;
              r.push(val[e]);
            }
          }
          return r;
        })
      );

    });
    dashboardItems.push(lightsStatus);


    //Action Buttons
    let actionButtons = DashboardItemFactory.ofCloseableCustomRepo({
      id: "9",
      name: "Action Buttons",
      showHeader : false,
      readOnly: true,
      showName: false,
      autoUpdateMs : 0,
      itemType: "action-buttons",
      style:{
        background : "none",
        'border-color' : "#00000000",
        'border-radius': "8px"
      },
      specificItemConfig: {
        gridsterConfig: {
          x: 0,
          y: 5,
          cols: 8,
          rows: 11,
          draggable: {
            enabled: false
          }
        },
        buttonList: [
          {
            color: 'accent',
            label: 'Do Checkout',
            action: () =>{
              const postUrl = "analyticsapi/demo/domotics/services/script/turn_on";
              this.http.post(postUrl, {entity_id : "script.1559403637308"})
                .subscribe((val)=>{})
            }
          },
          {
            color: 'accent',
            label: 'Toggle Dimmer Light',
            action: () =>{
              const getUrl = "analyticsapi/demo/domotics/states/light.dimmer_switch_1";
              const postUrl = "analyticsapi/demo/domotics/services/light/";
              this.http.get<any>(getUrl)
                .subscribe((val) => {
                  let action = val.state == "off" ? "turn_on" : "turn_off";
                  this.http.post(postUrl + action, {
                    entity_id : "light.dimmer_switch_1"
                  })
                    .subscribe((val)=>{

                    })
                });
            }
          },
          {
            color: 'accent',
            label: 'Toggle Door Lock',
            action: () =>{
              const getUrl = "analyticsapi/demo/domotics/states/lock.poly_control_danalock_v3_btze_locked";
              const postUrl = "analyticsapi/demo/domotics/services/lock/";
              this.http.get<any>(getUrl)
                .subscribe((val) => {
                   let action = val.state == "locked" ? "unlock" : "lock";
                   this.http.post(postUrl + action, {
                     entity_id : "lock.poly_control_danalock_v3_btze_locked"
                   })
                     .subscribe((val)=>{

                     })
                });
            }
          },
          {
            color: 'accent',
            label: 'Change Climate',
            action: () =>{

              const url = "analyticsapi/demo/domotics/states/climate.netatmo_salone";
              this.http.get<any>(url)
                .subscribe((val)=>{
                  let dialogRef = this.dialog.open(ClimateChangeDialog, {
                    width:"50%",
                    data: {
                      item : val
                    }
                  });

                  dialogRef.afterClosed().subscribe(data => {
                    data.item.moreMenuClick = false;
                    data.item.hover = false;

                    data.item.item.mergeConfigValue({
                      showHeader : data.form.value.showHeader,
                      name : data.form.value.name,
                      showName : data.form.value.showName,
                      autoUpdateMs : data.form.value.autoUpdateMs,
                      subtitle : data.form.value.subtitle,
                      infoButtonText : data.form.value.infoButtonText,
                      style : {
                        background: data.form.value.background,
                        'border-color': data.form.value['border-color'],
                        'border-radius': data.form.value['border-radius']
                      }
                    });

                  });
                });
            }
          },
          {
            color: 'accent',
            label: 'Vacuum Back Home',
            action: () =>{
              const postUrl = "analyticsapi/demo/domotics/services/script/turn_on";
              this.http.post(postUrl, {entity_id : "script.1559403406107"})
                .subscribe((val)=>{})
            }
          }
          ]
      }
    }, (thiz: DataSourceInterface, filter?: FilterInterface): Observable<any> => {
      return of({});
    });
    dashboardItems.push(actionButtons);

    //Noise over time
    let noiseFilterTime:DataSourceConfigInterfaceInternal = {
      name: "noiseFilterTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'tm',
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration:3, multiplier:'days'
          },
          query: {"bool": {"must": [
                {"match_phrase": {"dd": {"query": 150}}}
              ]}},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "tm",
                "interval": "1h",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "avg": {
                    "field": "db"
                  }
                }
              }
            }
          },
          index : "noise_meter",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let noiseOverTimeItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "10",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Noise over time" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 30 days",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Count",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {dateX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
              "bullets": [{
                "type": "Bullet",
                "children": [{
                  "type": "Image",
                  "propertyFields": {
                    "href": "weatherIconUrl"
                  },
                  "width": 30,
                  "height": 30,
                  "horizontalCenter": "middle",
                  "verticalCenter": "middle"
                }]
              }]
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:18,
          y:0,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(noiseFilterTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let miniChartData:any = [];
          if (result){
            for (let b= 0; b< result["1"].aggregations["1-bucket"]["buckets"].length; b++){
              miniChartData.push({
                time : result["1"].aggregations["1-bucket"]["buckets"][b].key,
                value : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value
              })
            }
          }
          if (miniChartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), miniChartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(noiseOverTimeItem);

    //Count over time
    let countFilterTime:DataSourceConfigInterfaceInternal = {
      name: "countFilterTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration:3, multiplier:'days'
          },
          query: {"bool": {"must": [
                {"match_phrase": {"adminEntityId": {"query": 53}}},
                {"range": {"visitDuration": {"gte": 60000,"lt": 700000000}}},
                {"range": {"avgSs": {"gte": -82, "lte": 0}}}
              ]}},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1h",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let countSamePeriodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "11",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Occupancy over time" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 24 hours",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Count",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {dateX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
              "bullets": [{
                "type": "Bullet",
                "children": [{
                  "type": "Image",
                  "propertyFields": {
                    "href": "weatherIconUrl"
                  },
                  "width": 30,
                  "height": 30,
                  "horizontalCenter": "middle",
                  "verticalCenter": "middle"
                }]
              }]
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:18,
          y:0,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(countFilterTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let miniChartData:any = [];
          if (result){
            for (let b= 0; b< result["1"].aggregations["1-bucket"]["buckets"].length; b++){
              miniChartData.push({
                time : result["1"].aggregations["1-bucket"]["buckets"][b].key,
                value : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value
              })
            }
          }
          if (miniChartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), miniChartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(countSamePeriodItem);


    let commonFilter = new Filter(this.auth.getUnderlyingLoginModel());
    commonFilter.changeValueToQuick({
      filterType: FilterTypeValues.QUICK,
      filterQuickValue: FilterQuickValues.LAST_30_DAYS,
      administrativeEntityId: "wedsqweds",
      administrativeEntities:[]
      //administrativeEntityId : this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities()[0].getAdministrativeEntityDetails().administrativeEntityId
    });



    let dashboardCfg = DashboardConfig.of(
      {
        id: "PropertyDetails1234",
        name: "Property Details",
        dashboardPersistency : DashboardLocalStoragePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        },
        initFilter : commonFilter
      }
    );

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData();
          this.showDashboard = true;
        }
      });
  }


}


