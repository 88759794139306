import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material";


@Component({
  selector: 'climate-change-dialog',
  templateUrl: 'climate-change-dialog.html',
})
export class ClimateChangeDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    item : any
  }) {

  }

  setEcoMode(){

  }

  setPowerMode(){

  }
}
