export const locale = {
    lang: 'en',
    data: {
      'nav': {
        'recco': {
          'group-name' : 'Recco',
          'overview' : "Overview",
          'vehicle':{
			       'group-name' : 'Vehicles',
				   "list":'List'
			    }
        }
      }
    }
};
