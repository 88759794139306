import {
  CommandFactory,
  Entity, FieldToUse,
  IottacleHttpClientProxy, QueryFactory,
  SchemaFieldDetailFactory,
  SchemaFieldDetailsInterface, SchemaFieldTypesNames
} from "dashboard-fe-angular";
import {filter, map} from "rxjs/operators";
import {Utils} from "../../utils/utils";
import {LoginV3, MyOrganization} from "iottacle-ts-models";


export class Organization extends Entity{
  entityName:string;
  fieldDetails: { [p: string]: SchemaFieldDetailsInterface };
  entityValidators:any[];
  contextName:string;
  domainName:string;

  constructor(
    private httpClient: IottacleHttpClientProxy,
    private loginService:LoginV3
  ){
    super();

    //DOMAIN DETAILS
    this.contextName = "policy-manager";
    this.domainName = "Organization";

    //NAME
    this.entityName = "Organization";

    //SCHEMA
    this.fieldDetails = {};
    this.fieldDetails.organizationId = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.id, "organizationId");
    this.fieldDetails.parent = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.manyToOne, "parent");
    this.fieldDetails.description = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "description");
    this.fieldDetails.name = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "name");
    this.fieldDetails.flatName = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "flatName");
    this.fieldDetails.children = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.oneToMany, "children");
    this.fieldDetails.settings = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.valueObject, "settings");
    this.fieldDetails.keyValueMap = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.valueObject, "keyValueMap");
    this.fieldDetails.settings = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.valueObject, "settings");
    this.fieldDetails.administrativeEntities = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.oneToMany, "administrativeEntities");
    this.fieldDetails.userGroups = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.oneToMany, "userGroups");


    //ENTITY VALIDATORS
    this.entityValidators = [];


    //ENTITY BOILERPLATE STUFF
    this.saveSchema = FieldToUse.filterEntityFieldsAsList(this.fieldDetails, undefined);
    this.readSchemaOfServer = this.saveSchema;
    this.updateSchema = this.saveSchema;

    this.getById = (id) => {
      const url = "api/executor/query/q";
      return this.httpClient.post(url, {
        organizationId: id,
        query : "GET_ORGANIZATION_BY_ID"
      })
        .pipe(map((result) =>{
          return {
            body:{
              hits:[result]
            }
          }
        }));
    };
  }

  getFieldValue(fieldName: string, entity: any):any {
    return entity[fieldName];
  }

  getCurrentMyOrganizationChildren(childrenTypesFilter:string[] = []):MyOrganization[]{
    return Utils.flatMap(
      o => o.getChildren(),
      this.loginService.getCurrentLoggedUser().getMyOrganizations()
    )
      .filter(childO => childrenTypesFilter.length ? childrenTypesFilter.indexOf(childO.organizationType) : true)
  }



}
