import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {DashboardPersistencyProviderInterface} from "iottacle-dashboard/dist/persistency/dashboard-persistency-provider-interface";


export class DashboardConsolePersistency implements DashboardPersistencyProviderInterface{


  public constructor(){

  }

  public static of():DashboardConsolePersistency{
    return new DashboardConsolePersistency();
  }

  getElement(key: string): Observable<any> {
    let ret:Subject<any> = new Subject();
    setTimeout(()=>{
      ret.next([]);
      ret.complete();
    }, 1);
    return ret.asObservable();
  }

  getName(): string {
    return "DashboardConsolePersistency";
  }

  getSerializedElement(key: string): Observable<string> {
    let ret:Subject<any> = new Subject();
    setTimeout(()=>{
      try{
        ret.next("[]");
        ret.complete();
      }catch(e){
        ret.error(e);
        ret.complete();
      }
    }, 1);
    return ret.asObservable();
  }

  putElement(key: string, item:any): Observable<void> {
    let ret:Subject<any> = new Subject();
    setTimeout(()=> {
      try {
        for (let i in item){
          console.log("name: " + item[i].dashboardItemConfig.name + "\tx: " + item[i].dashboardItemConfig.specificItemConfig.gridsterConfig.x + "\ty: " + item[i].dashboardItemConfig.specificItemConfig.gridsterConfig.y + "\tcols: " + item[i].dashboardItemConfig.specificItemConfig.gridsterConfig.cols + "\trows: " + item[i].dashboardItemConfig.specificItemConfig.gridsterConfig.rows);
        }

        ret.next();
        ret.complete();
      }catch (e){
        ret.error(e);
        ret.complete();
      }
    },1);
    return ret.asObservable();
  }

  rehydrate(serializedPersistency: string): DashboardPersistencyProviderInterface {
    return DashboardConsolePersistency.of();
  }

  serialize(): string {
    return JSON.stringify({
      name : "DashboardConsolePersistency"
    });
  }

}
