import {
  Neo4jFunnelDashboardItemRepository
} from "./neo4j-funnel-dashboard-item-repository";

export interface ElasticBucketAggregate {
  [buketAggregateName:string]:any;
  aggs:{
    [aggName:string]:ElasticBucketAggregate|ElasticMetricAggregate|ElasticScriptMetricAggregate
  }
}

export abstract class ElasticBucketAggregateAbstract implements ElasticBucketAggregate{
  aggs: { [p: string]: ElasticBucketAggregate | ElasticMetricAggregate | ElasticScriptMetricAggregate };
  abstract buildNeoQuery(innerObject:any);
}

export class HistogramBucketAggregate implements ElasticBucketAggregate{
  histogram:{
    field: string,
    interval: number,
    min_doc_count: number
  };

  aggs: { [p: string]: ElasticBucketAggregate | ElasticMetricAggregate | ElasticScriptMetricAggregate };

  private constructor(private key:string, aggregate:any){
    this.histogram = aggregate.histogram;
    this.histogram.interval = this.histogram.interval ? this.histogram.interval : 1;
    this.aggs = {};
    for(let agg in aggregate.aggs){
      let a:any = ElasticAggregateQuerySyntaxImpl.of(agg, aggregate.aggs[agg]);
      let k:any = Object.keys(a);
      this.aggs[k[0]] = a[k[0]];
    }


  }

  public static of(key:string, aggregate:any){
    return new HistogramBucketAggregate(key, aggregate);
  }

  public buildNeoQuery(innerObject: any) {
    let before = [];
    if (Neo4jFunnelDashboardItemRepository.aggregateIsValid(this.aggs)){
      let innerBefore:any = {};
      for (let clause in this.aggs){
        let rc:any = this.aggs[clause];
        let beforeReturnQuery = rc.buildNeoQuery(innerBefore);
        if (beforeReturnQuery && beforeReturnQuery.length > 0){
          for (let b in beforeReturnQuery) {
            beforeReturnQuery[b][this.histogram.field] = `step.visit.${this.histogram.field}`;
          }
          before = before.concat(beforeReturnQuery);
        }
      }
      innerBefore.key = `step${before.length == 0 ? '.visit' : ''}.${this.histogram.field}/${this.histogram.interval}`;
      innerBefore.doc_count = before.length == 0 ? `count(step.visit) ` : `sum(step.doc_count) `;
      innerBefore[this.histogram.field] = `step${before.length == 0 ? '.visit' : ''}.${this.histogram.field}/${this.histogram.interval}`;
      before.push(innerBefore);

      innerObject["_" + this.key] = {
        //key : `step.${this.histogram.field}`,
        buckets : `collect(step)`
      };
    }

    return before;
  }

}

export interface TermsBucketAggregate extends ElasticBucketAggregate{
  terms:{
    field: string
  }
}

export interface ElasticMetricAggregate {
  [name:string]:any;
}

export class AvgMetricAggregate implements ElasticMetricAggregate{
  avg:{
    field?:string,
    script?:{
      inline:string,
      lang?:'cypher'
    }
  };

  private constructor(private key:string, field:any){
    this.avg = field.avg;
  }

  public static of(key:string, field:string){
    return new AvgMetricAggregate(key, field);
  }

  public buildNeoQuery(innerObject: any) {
    if (this.avg.script && this.avg.script.lang=='cypher'){
      innerObject["_"+this.key] = "{ value : avg("+this.avg.script.inline+")}";
    }else {
      innerObject["_"+this.key] = `{ value : avg(step.visit.${this.avg.field})}`;
    }
  }

}

export class CardinalityMetricAggregate implements ElasticMetricAggregate{
  cardinality: {
    field: string;
  };

  private constructor(private key:string, field:any){
    this.cardinality = field.cardinality;
  }

  public static of(key:string, field:string){
    return new CardinalityMetricAggregate(key, field);
  }

  public buildNeoQuery(innerObject: any) {
    innerObject["_"+this.key] = `{ value : count(distinct step.visit.${this.cardinality.field}) }`;
  }

}

export abstract class ElasticMetricAggregateAbstract implements ElasticMetricAggregate{
  abstract buildNeoQuery(innerObject:any);
}

export interface ElasticScriptMetricAggregate {
  [name: string]: {
    script: {
      inline: string,
      lang: "cypher"
    }
  }
}

export interface ElasticAggregateQuerySyntax {
  [key:string]:ElasticBucketAggregate|ElasticMetricAggregate;
}


export class ElasticBucketAggregateFactory{
  public static elasticBucketAggregates: {
    [bucketAggregateName: string]: (key:string, aggregate: any) => any
  }={};

  public static of(key:string, aggregate:any):ElasticBucketAggregateAbstract{
    let keys = Object.keys(aggregate);
    let bukAggName = keys[0] === "aggs" ? keys[1] : keys[0];

    if (this.elasticBucketAggregates[bukAggName]){
      return this.elasticBucketAggregates[bukAggName](key, aggregate);
    }
  }
}

export class ElasticMetricAggregateFactory{
  public static elasticMetricAggregates: {
    [metricAggregateName: string]: (key:string, aggregate: any) => any
  } = {};

  public static of(key:string, aggregate:any):ElasticMetricAggregateAbstract{
    let keys = Object.keys(aggregate);
    if (this.elasticMetricAggregates[keys[0]]){
      return this.elasticMetricAggregates[keys[0]](key, aggregate);
    }
  }
}



export class ElasticAggregateQuerySyntaxImpl implements ElasticAggregateQuerySyntax{
  [key: string]: ElasticBucketAggregateAbstract | ElasticMetricAggregateAbstract;

  private constructor(key:string, value:ElasticBucketAggregateAbstract | ElasticMetricAggregateAbstract){
    this[key] = value;
  }

  public static of(key:string, value:any){
    if (Neo4jFunnelDashboardItemRepository.isValidBucketAggregate(value)){
      return new ElasticAggregateQuerySyntaxImpl(key, ElasticBucketAggregateFactory.of(key, value));
    }else if (Neo4jFunnelDashboardItemRepository.isValidMetricAggregate(value)){
      return new ElasticAggregateQuerySyntaxImpl(key, ElasticMetricAggregateFactory.of(key, value));
    }
  }
}
