import {Component, Injectable, OnDestroy, OnInit} from '@angular/core';
import { Subscription} from "rxjs";

import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DataSource,
  DataSourceConfig
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardConsolePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import {
  AuthService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {
  Filter, MyAdministrativeEntity
} from "iottacle-ts-models";

import {TranslateService} from "@ngx-translate/core";
import {
  DashboardItemFactoryChart
} from "../next-one-sky-insights-00/utils";
import {
  EventDataSource
} from "../next-one-detail/event-data-source";
import {HttpClient} from "@angular/common/http";
import {
  DashboardItemFactory,
  DashboardItemCatalog,
  Utils
}  from "dashboard-items-catalog";
import {GenericDataSource} from "../next-one-detail/iottacle-generic-data-source";


@Component({
  selector: 'next-one-loyalty',
  templateUrl: './next-one-loyalty.component.html',
  styleUrls: ['./next-one-loyalty.component.scss']
})
export class NextOneLoyaltyComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private translateService:TranslateService,
    private http:HttpClient
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {}, this.translateService);
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {
    let user = this.authService.getCurrentLoggedUser();
    let readOnly = true;
    let dashboardItems:DashboardItem[] = [];

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();

    // Store Visit Recency
    // let recencyItem = DashboardItemCatalog.metricDelta_averageVisitRecency(readOnly,this.translateService,this.decimalPipe, this.http,
    //   {x:0, y:0, cols:14, rows:5});
    // dashboardItems.push(recencyItem);

    //Store Visit Frequency
    // let frequencyMetricItem = DashboardItemCatalog.metricDelta_averageVisitFrequency(readOnly,this.translateService,this.decimalPipe, this.http,
    //   {x:0, y:0, cols:14, rows:5});
    // dashboardItems.push(frequencyMetricItem);
    let frequencyMetricItem1 = DashboardItemCatalog.metricDelta_averageVisitFrequency_v2({
        substituteDataSources: true,
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"metricDelta_averageVisitFrequency_v2", user )))
        },
        specificItemConfig:{
          gridsterConfig:{x:0, y:0, cols:14, rows:5}
        }
      },this.translateService, this.http, this.decimalPipe);
    dashboardItems.push(frequencyMetricItem1);

    //New vs Ret
    // let newVsRetSamePeriodItem = DashboardItemCatalog.pieChart_newRetForPieChart(readOnly,this.translateService, this.http,
    //   {x:14, y:0, cols:22, rows:10}, DashboardItemFactoryChart.createPieChartItem({
    //     export:{
    //       excel:true,
    //       csv:true,
    //       image:true
    //   }}));
    // dashboardItems.push(newVsRetSamePeriodItem);
    let newVsRetSamePeriodItem1 = DashboardItemCatalog.pieChart_newRetForPieChart_v2(
      {
        substituteDataSources: true,
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"pieChart_newRetForPieChart_v2", user )))
        },
        specificItemConfig:{
          gridsterConfig:{x:14, y:0, cols:22, rows:10}
        },
        viewConfig:DashboardItemFactoryChart.createPieChartItem({
          export:{
            excel:true,
            csv:true,
            image:true
          }})
      },
      this.translateService, this.http);
    dashboardItems.push(newVsRetSamePeriodItem1);

    //newret per day
    // let newVsRetPerDayItem = DashboardItemCatalog.barChart_newRetPerDay(readOnly,this.translateService, this.authService, this.http,
    //   {x:0, y:10, cols:36, rows:13}, DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService))), DashboardItemFactoryChart.createXYChartItem({
    //     xSort: "asc",
    //     periodicVerticalLine:{
    //       period: "auto"
    //     },
    //       series : {
    //         ratio : {
    //           type: "line"
    //         }
    //       },
    //       export:{
    //         excel:true,
    //         csv:true,
    //         image:true
    //       }
    //     }));
    // dashboardItems.push(newVsRetPerDayItem);
    let newVsRetPerDayItem1 = DashboardItemCatalog.barChart_newRetPerDay_v2(
      {
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"barChart_newRetPerDay_v2", user ))),
          "events":DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService)))
        },
        specificItemConfig:{
          gridsterConfig:{x:0, y:10, cols:36, rows:13}
        },
        viewConfig:DashboardItemFactoryChart.createXYChartItem({
          xSort: "asc",
          periodicVerticalLine:{
            period: "auto"
          },
          series : {
            ratio : {
              type: "line"
            }
          },
          export:{
            excel:true,
            csv:true,
            image:true
          }
        })
      },
      this.translateService, this.http);
    dashboardItems.push(newVsRetPerDayItem1);


    // avg frequency per day
    const frequencyPerDayItem = DashboardItemCatalog.lineChart_FrequencyPerDay(
      {
        dataSources: {
          "events": DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService)))
        },
        specificItemConfig: {
          gridsterConfig: {
            x: 0,
            y: 10,
            cols: 36,
            rows: 13
          }
        },
        viewConfig: DashboardItemFactoryChart.createXYChartItem({series : {average : {type: "line"}}, export:{
            excel:true,
            csv:true,
            image:true
          }})
      },
      this.translateService, this.http
    );
    //dashboardItems.push(frequencyPerDayItem); commented 02/05/2024

    // avg recency per day
    const recencyPerDayItem = DashboardItemCatalog.lineChart_RecencyPerDay({
        dataSources: {
          "events": DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService)))
        },
        specificItemConfig: {
          gridsterConfig: {
            x: 0,
            y: 23,
            cols: 36,
            rows: 13
          }
        },
        viewConfig: DashboardItemFactoryChart.createXYChartItem({series : {average : {type: "line"}}, export:{
            excel:true,
            csv:true,
            image:true
          }})
      },
      this.translateService, this.http
    );
    //dashboardItems.push(recencyPerDayItem); commented 02/05/2024

    // let heatmap = DashboardItemCatalog.heatmap_regainedCustomersFrequencyDistribution(readOnly, this.translateService, this.http,
    //   {x:18, y:21, cols:18, rows:14}, DashboardItemFactoryChart.createHeatmapItem());
    // dashboardItems.push(heatmap);
    let heatmap1 = DashboardItemCatalog.heatmap_regainedCustomersFrequencyDistribution_v2(
      {
        substituteDataSources: true,
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"heatmap_regainedCustomersFrequencyDistribution_v2", user )))
        },
        specificItemConfig:{
          gridsterConfig:{x:18, y:21, cols:18, rows:14}
        },
        viewConfig:DashboardItemFactoryChart.createHeatmapItem()
      }, this.translateService, this.http);
    // dashboardItems.push(heatmap1); commented 02/05/2024

    //after when get back lost customer
    // let getBackLostCustomersItem = DashboardItemCatalog.barChart_regainedCustomersFrequencyDistribution(readOnly,this.translateService, this.http,
    //   {x:0, y:21, cols:18, rows:14}, DashboardItemFactoryChart.createXYChartItem({
    //     legend:{
    //       showLegendIntoAccordion:true,
    //     },
    //     export:{
    //       excel:true,
    //       csv:true,
    //       image:true
    //     }
    //   }));
    // dashboardItems.push(getBackLostCustomersItem);
    let getBackLostCustomersItem1 = DashboardItemCatalog.barChart_regainedCustomersFrequencyDistribution_v2(
      {
        substituteDataSources: true,
        dataSources : {
          "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"barChart_regainedCustomersFrequencyDistribution_v2", user )))
        },
        specificItemConfig:{
          gridsterConfig:{x:0, y:21, cols:18, rows:14}
        },
        viewConfig:DashboardItemFactoryChart.createXYChartItem({
          legend:{
            showLegendIntoAccordion:true,
          },
          export:{
            excel:true,
            csv:true,
            image:true
          }
        })
      },this.translateService, this.http);
    // dashboardItems.push(getBackLostCustomersItem1); commented 02/05/2024


    //Frequency Details
    // let frequencyTitleItemId = "59959e40-ce48-411d-8db5-f86a617b1975";
    // let frequencyTitleItem =  DashboardItemFactory.createTitleItem({
    //   id: frequencyTitleItemId,
    //   _repoText :this.translateService.instant('widgets.htmlText.'+frequencyTitleItemId+'.repoText'),
    //   specificItemConfig : {
    //     gridsterConfig : {x:0, y:39, cols:36, rows:3}
    //   }
    // }, this.translateService);
    //dashboardItems.push(frequencyTitleItem);

    //TODO: time since last visit distributoin (needs fix of the metric)

    let dashboardCfg = DashboardConfig.of(
      {
        id: "NextOneLoyalty",
        name: "Next One",
        dashboardPersistency : DashboardConsolePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        },
        initFilter : this.filter.filterValues
      });

    // @ts-ignore
    let adminEntities:MyAdministrativeEntity[] = this.authService.getCurrentLoggedUser().getMyOrganizations().flatMap(org => org.getMyAdministrativeEntities());

    let context = {
      adminEntities: adminEntities,
      fieldValueLookup: {
        adminEntityId : {
          lookup:(value:any) => {
            let adminEntity = adminEntities.find((el) => el.administrativeEntityDetails.id == value);
            return adminEntity ? adminEntity.administrativeEntityDetails.name : value;
          }
        },
        newret:Utils.fieldValueLookup.newret,
        startDow:Utils.fieldValueLookup.startDow(this.translateService),
        startSod:Utils.fieldValueLookup.startSod,
        recency:Utils.fieldValueLookup.recency,
        timeSinceLastVisit:Utils.fieldValueLookup.timeSinceLastVisit
      },
      translateService: this.translateService
    };

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData(this.filter.filterValues, context);
          this.showDashboard = true;
          this.filterChangedSubscription = this.filter.filterChangedObservable()
            .subscribe((newFilterValue) => {
              this.dashboard.reloadData(this.filter.filterValues, context);
              this.showDashboard = true;
            });
        }
      });
  }
}


