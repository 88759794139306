import {ElasticAggregateQuerySyntax, ElasticAggregateQuerySyntaxImpl} from "./elastic-aggregate-part";
import {ElasticStepQueryElementFactory, StepQueryElement} from "./elastic-query-part";


export interface StepQuery {
  [fieldName:string]:StepQueryElement;
}


export class ElasticStepQueryFactory {
  public static of(stepQuery:any):StepQuery{
    let step:any = {};
    for (let s in stepQuery){
      let stepQ:StepQueryElement = ElasticStepQueryElementFactory.of(s, stepQuery[s]);
      if (stepQ) {
        step[s] = stepQ;
      }
    }
    return step;
  }
}

export interface FunnelReturnClause {
  relativeToStep:number,
  aggs:ElasticAggregateQuerySyntax
}
export interface Funnel {
  steps: StepQuery[];
  aggs:FunnelReturnClause;
}


export class FunnelReturnClauseImpl implements FunnelReturnClause{
  aggs: ElasticAggregateQuerySyntaxImpl;
  relativeToStep: number;

  private constructor(aggregate: ElasticAggregateQuerySyntaxImpl, relativeToStep: number){
    this.aggs = aggregate;
    this.relativeToStep = relativeToStep;
  }

  public static of(retClauseObj:any){
    let aggregate:any = {};
    for(let agg in retClauseObj.aggs){
      let a:any = ElasticAggregateQuerySyntaxImpl.of(agg, retClauseObj.aggs[agg]);
      let k:any = Object.keys(a);
      aggregate[k[0]] = a[k[0]];
    }

    return new FunnelReturnClauseImpl(aggregate, retClauseObj.relativeToStep);
  }
}



export class FunnelImpl implements Funnel {
  aggs: FunnelReturnClauseImpl;
  steps: StepQuery[];

  private constructor(retAggs: FunnelReturnClauseImpl, steps: StepQuery[]){
    this.aggs = retAggs;
    this.steps = steps;
  }

  public static of(funnelObj:any):FunnelImpl{
    let retAggs: FunnelReturnClauseImpl = FunnelReturnClauseImpl.of(funnelObj.aggs);
    let steps: StepQuery[] = [];
    for (let s in funnelObj.steps){
      let val = ElasticStepQueryFactory.of(funnelObj.steps[s]);
      if (val){
        steps.push(val);
      }
    }

    return new FunnelImpl(retAggs, steps);
  }

}
