import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FilterModule} from "../../../../filter/filter.module";
import {RouterModule} from "@angular/router";
import {GridsterModule} from "angular-gridster2";
import {AngularDashboardModule} from "dashboard-fe-angular";
import {DeviceComponent} from "./device.component";
import {AuthGuardService,FuseSharedModule,IottacleNavigationService} from "core-fe-angular";


const routes = [
  {
    path     : 'device',
    component: DeviceComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  providers : [
  ],
  imports     : [
    CommonModule,
    FuseSharedModule,
    RouterModule.forChild(routes),
    FilterModule,
    GridsterModule,
    AngularDashboardModule
  ],
  declarations: [DeviceComponent]
})
export class DeviceModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService
  ){


  }
}
