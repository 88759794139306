import {Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription} from "rxjs";


import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DataSource,
  DataSourceConfig
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardConsolePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import {
  AuthService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {
  Filter,
  MyAdministrativeEntity
} from "iottacle-ts-models";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import deepmerge_ from 'deepmerge';
const deepmerge:any = deepmerge_;
import _moment from 'moment';
import {
  DashboardItemFactoryChart
} from "../next-one-sky-insights-00/utils";
import {
  EventDataSource
} from "../next-one-detail/event-data-source";
const moment = _moment;
import {DashboardItemCatalog, Utils}  from "dashboard-items-catalog";

@Component({
  selector: 'next-one-proximity',
  templateUrl: './next-one-proximity.component.html',
  styleUrls: ['./next-one-proximity.component.scss']
})
export class NextOneProximityComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private translateService:TranslateService,
    private http:HttpClient
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {}, this.translateService);
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {
    let dashboardItems:DashboardItem[] = [];

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let readOnly = true;

    //Visits over time
    let countOverTimeSamePeriodItem =  DashboardItemCatalog.barChart_visitsAlongDaysWithEventsProximity(readOnly, this.translateService, this.authService, this.http,
      {x:0, y:0, cols:36, rows:12}, DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService))), DashboardItemFactoryChart.createXYChartItem({
        periodicVerticalLine:{
          period: "auto"
        },
        export:{
          excel:true,
          csv:true,
          image:true
        }
      }) );
    dashboardItems.push(countOverTimeSamePeriodItem);

    //MetricDelta visits overall Count
    let countSamePeriodItem = DashboardItemCatalog.metricDelta_overallVisitCountProximity(readOnly,this.translateService, this.decimalPipe, this.http,
      {x:0, y:10, cols:14, rows:5});
    dashboardItems.push(countSamePeriodItem);

    //MetricDelta visitors overall Count
    // let visitorsCountSamePeriodItem = DashboardItemCatalog.metricDelta_overallVisitorsCount(readOnly,this.translateService, this.decimalPipe, this.http,
    //   {x:0, y:15, cols:14, rows:5});
    //dashboardItems.push(visitorsCountSamePeriodItem);

    //store access Heatmap
    let hourlyPerDayHeatmapItem = DashboardItemCatalog.heatmap_visitsDistributionOverHodAndDowProximity(readOnly, this.translateService, this.http,
      {x:13, y:10, cols:22, rows:10}, DashboardItemFactoryChart.createHeatmapItem());
    dashboardItems.push(hourlyPerDayHeatmapItem);

    //accessi:
    //access over time

    //distribuzione accessi giorno

    let visitsOverDowItem =  DashboardItemCatalog.barChart_visitsOverDowDistributionProximity(readOnly, this.translateService, this.http,
      {x:0, y:20, cols:18, rows:12}, DashboardItemFactoryChart.createXYChartItem({
        legend:{
          showLegendIntoAccordion:true,
        },
        export:{
          excel:true,
          csv:true,
          image:true
        }
      }));
    dashboardItems.push(visitsOverDowItem);

    let visitsOverHodItem =  DashboardItemCatalog.barChart_visitsOverHodDistributionProximity(readOnly, this.translateService, this.http,
      {x:18, y:20, cols:18, rows:12}, DashboardItemFactoryChart.createXYChartItem({
        legend:{
          showLegendIntoAccordion:true,
        },
        export:{
          excel:true,
          csv:true,
          image:true
        }
      }));
    dashboardItems.push(visitsOverHodItem);

    // @ts-ignore
    let adminEntities:MyAdministrativeEntity[] = this.authService.getCurrentLoggedUser().getMyOrganizations().flatMap(org => org.getMyAdministrativeEntities());

    let context = {
      adminEntities: adminEntities,
      fieldValueLookup: {
        adminEntityId : {
          lookup:(value:any) => {
            let adminEntity = adminEntities.find((el) => el.administrativeEntityDetails.id == value);
            return adminEntity ? adminEntity.administrativeEntityDetails.name : value;
          }
        },
        newret:Utils.fieldValueLookup.newret,
        startDow:Utils.fieldValueLookup.startDow(this.translateService),
        startSod:Utils.fieldValueLookup.startSod,
      },
      translateService: this.translateService
    };

    let dashboardCfg = DashboardConfig.of(
      {
        id: "NextOneAccessBreakdown",
        name: "Next One",
        dashboardPersistency : DashboardConsolePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        },
        initFilter : this.filter.filterValues
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData(this.filter.filterValues, context);
          this.showDashboard = true;
          this.filterChangedSubscription = this.filter.filterChangedObservable()
            .subscribe((newFilterValue) => {
              this.dashboard.reloadData(newFilterValue, context);
              this.showDashboard = true;
            });
        }
      });

  }




}


