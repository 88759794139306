import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDatepickerModule,
  MatExpansionModule,
  MatIconModule, MatInputModule,
  MatSelectModule,
  MatTabsModule,
  MatCheckboxModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatCardModule
} from "@angular/material";
import { MdePopoverModule } from '@material-extended/mde';

@NgModule({
  imports: [
    MatSelectModule,
    MatTabsModule,
    MatDatepickerModule,
    MatInputModule,
    MatExpansionModule,
    MatIconModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MdePopoverModule,
    MatCardModule
  ],
  declarations: [FilterComponent,
  ],
  exports : [
    FilterComponent

  ]
})
export class FilterModule { }
