import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AuthGuardService,
  FuseSharedModule,
  FuseSidebarModule, FuseWidgetModule,
  IottacleNavigationService
} from "core-fe-angular";
import {AngularDashboardModule, EntityModelCatalog} from "dashboard-fe-angular";
import {HttpClient} from "@angular/common/http";
import {Device} from "../../../../../context/device/device/device";
import {ReccoVehicleListComponent} from "./list/recco-vehicle-list.component";
import {ReccoOverviewComponent} from "../recco/overview/recco-overview.component";
import {RouterModule} from "@angular/router";
import {ReccoVehicleFormComponent} from "./entity-form/recco-vehicle-form.component";
import {ReccoJobsTimelineComponent} from "./entity-form/recco-jobs-timeline/recco-jobs-timeline.component";
import {MatDividerModule, MatIconModule, MatTableModule, MatPaginatorModule,MatSortModule} from "@angular/material";
import {ReccoJobComponent} from "./recco-job/recco-job.component";
    

const routes = [
  {
    path     : 'man',
    canActivate: [AuthGuardService],
    children : [
      {
        path     : 'Recco',
        canActivate: [AuthGuardService],
        children : [
          {
            path     : 'Vehicle',
            canActivate: [AuthGuardService],
            children : [
              {
                path     : 'list',
                component: ReccoVehicleListComponent,
                canActivate: [AuthGuardService]
              },
              {
                path     : ':entityId',
                component: ReccoVehicleFormComponent,
                canActivate: [AuthGuardService]
              }
            ]
          }
        ]
      }
    ]
  }
];


@NgModule({
  providers : [
    ReccoVehicleListComponent,
    ReccoVehicleFormComponent,
    ReccoJobsTimelineComponent,
    ReccoJobComponent
  ],
  imports     : [
    CommonModule,
    FuseSharedModule,
    FuseSidebarModule,
    RouterModule.forChild(routes),
    AngularDashboardModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    FuseWidgetModule,
    MatPaginatorModule,
    MatSortModule
  ],
  declarations: [
    ReccoVehicleListComponent,
    ReccoVehicleFormComponent,
    ReccoJobsTimelineComponent,
    ReccoJobComponent
  ]
})
export class ReccoVehicleModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService,
    private httpClient:HttpClient
  ){

    EntityModelCatalog.registerEntityModel(new Device(httpClient));
    //instruct navigation
    this._fuseNavigationService.addNavigationItem({
      'id'       : 'recco',
      'title'    : 'Recco',
      'translate': 'nav.recco.group-name',
      'type'     : 'group',
      'children' : [
        {
          'id'       : 'recco.vehicle',
          'title'    : 'Vehicle',
          'translate': 'nav.recco.vehicle.group-name',
          'type'     : 'collapsable',
          'icon'     : 'store',
          'children' : [
            {
              'id'       : 'recco.vehicle.list',
              'title'    : 'List',
              'translate': 'nav.recco.vehicle.list',
              'type'     : 'item',
              'icon'     : 'location_on',
              'url'  : 'man/Recco/Vehicle/list'
            }
            ]
        }
      ]
    }, "end");
  }
}
