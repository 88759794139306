import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit, OnDestroy{


  constructor(
  ) {
  }


  ngOnInit() {

  }

  ngOnDestroy(){

  }
}
