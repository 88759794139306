import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DashboardItemConfig, DashboardItemViewGeneric,
  DashboardItemViewMetricDelta, DashboardItemViewSerialChart,
  DataResultInterface, DataSource,
  DataSourceAssemblerFactory,
  DataSourceAssemblerInterface,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory, SerialChartConfig, ViewAssemblerFactory
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {
  AuthService,
  FuseTranslationLoaderService,
  FuseConfigService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  GridsterDashboardConfig
} from "core-fe-angular";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import {
  Filter,
  FilterLocationReferences,
  FilterLocationReferencesStrategy,
  FilterVisitTimeFilterStrategies
} from "iottacle-ts-models";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";


@Component({
  selector: 'accesses-breakdown',
  templateUrl: './store-accesses-breakdown.component.html',
  styleUrls: ['./store-accesses-breakdown.component.scss']
})
export class StoreAccessesBreakdownComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel());
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {

    let dashboardItems:DashboardItem[] = [];
    //RepositoryCatalog.init(this.http);

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();

    let filterBuilder:FilterBuilderInterface = new FilterBuilderGenericElastic();

    //store access Heatmap
    let hourlyPerDayHeatmapDataSource:DataSourceConfigInterfaceInternal = {
      name: "hourlyPerDayHeatmapDataSource",
      type: "elastic",
      assembler : {
        name:"idempotentDataSourceAssembler",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          let data:{
            startHod:number,
            startDow:string,
            count:number
          }[] = [];

          let dowMapping = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
          for (let r in result.aggregations["2"].buckets){
            let sod = result.aggregations["2"].buckets[r].key;
            let hod = (sod / 3600);

            for (let b in result.aggregations["2"].buckets[r]["3"].buckets){
              let dow = result.aggregations["2"].buckets[r]["3"].buckets[b].key;
              let count = result.aggregations["2"].buckets[r]["3"].buckets[b].doc_count;
              data.push({
                startHod :hod,
                startDow : dowMapping[dow],
                count:count
              });
            }
          }

          return of(GenericDataResult.ofSuccess(thiz.getName(), data));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return DataSourceAssemblerFactory.idempotentDataSourceAssembler;
        },
        serialize : () => {
          return JSON.stringify({
            name : DataSourceAssemblerFactory.idempotentDataSourceAssembler.name
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            strategy : "same-period",
            timestampField:'start'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 60000,"lt": 700000000}}},
                {"range": {"avgSs": {"gte": -85, "lte": 0}}}],
              "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0,
                "extended_bounds": {
                  "min": 0,
                  "max": 86400
                }
              },
              "aggs": {
                "3": {
                  "histogram": {
                    "field": "startDow",
                    "interval": 1,
                    "min_doc_count": 0,
                    "extended_bounds": {
                      "min": 1,
                      "max": 7
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let hourlyPerDayHeatmapItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "1"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Heatmap Access" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText : "This heatmap displays the visits count over time per each day of the week",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        createChart : (chart)=> {
          chart.maskBullets = false;

          var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
          var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

          xAxis.dataFields.category = "startHod";
          yAxis.dataFields.category = "startDow";

          xAxis.renderer.grid.template.disabled = true;
          xAxis.renderer.minGridDistance = 40;

          yAxis.renderer.grid.template.disabled = true;
          yAxis.renderer.inversed = true;
          yAxis.renderer.minGridDistance = 30;

          var series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "startHod";
          series.dataFields.categoryY = "startDow";
          series.dataFields.value = "count";
          series.sequencedInterpolation = true;
          series.defaultState.transitionDuration = 3000;

          var bgColor = new am4core.InterfaceColorSet().getFor("background");

          var columnTemplate = series.columns.template;
          columnTemplate.strokeWidth = 1;
          columnTemplate.strokeOpacity = 0.2;
          columnTemplate.stroke = bgColor;
          columnTemplate.tooltipText = "{startHod}, {startDow}: {value.workingValue.formatNumber('#.')}";
          columnTemplate.width = am4core.percent(100);
          columnTemplate.height = am4core.percent(100);

          series.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color(bgColor),
            max: chart.colors.getIndex(0)
          });

// heat legend
          var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
          heatLegend.width = am4core.percent(100);
          heatLegend.series = series;
          heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
          heatLegend.valueAxis.renderer.minGridDistance = 30;

// heat legend behavior
          series.columns.template.events.on("over", (event) => {
            handleHover(event.target);
          })

          series.columns.template.events.on("hit", (event) => {
            handleHover(event.target);
          })

          function handleHover(column) {
            if (!isNaN(column.dataItem.value)) {
              heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
              heatLegend.valueAxis.hideTooltip();
            }
          }

          series.columns.template.events.on("out", (event) => {
            heatLegend.valueAxis.hideTooltip();
          });

          return chart;

        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:12,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "hourlyPerDayHeatmapDataSource" : DataSource.of(DataSourceConfig.of(hourlyPerDayHeatmapDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(hourlyPerDayHeatmapItem);

    //Count over time
    let countFilterTime:DataSourceConfigInterfaceInternal = {
      name: "countFilterTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 100,"lt": 7200000}}},
                {"range": {"avgSs": {"gte": -85, "lte": 0}}}
              ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let countSamePeriodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "2",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Accesses over time" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 24 hours",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Count",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {dateX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
              "bullets": [{
                "type": "Bullet",
                "children": [{
                  "type": "Image",
                  "propertyFields": {
                    "href": "weatherIconUrl"
                  },
                  "width": 30,
                  "height": 30,
                  "horizontalCenter": "middle",
                  "verticalCenter": "middle"
                }]
              }]
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:18,
          y:0,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(countFilterTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let miniChartData:any = [];
          if (result){
            for (let b= 0; b< result["1"].aggregations["1-bucket"]["buckets"].length; b++){
              miniChartData.push({
                time : result["1"].aggregations["1-bucket"]["buckets"][b].key,
                value : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value
              })
            }
          }
          if (miniChartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), miniChartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(countSamePeriodItem);

    //Duration over time
    let durationFilterTime:DataSourceConfigInterfaceInternal = {
      name: "durationFilterTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 100,"lt": 7200000}}},
                {"range": {"avgSs": {"gte": -85, "lte": 0}}}
              ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "avg": {
                    "field": "visitDuration"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let durationSamePeriodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Avg Duration over time" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 24 hours",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Minutes",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {dateX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
              "bullets": [{
                "type": "Bullet",
                "children": [{
                  "type": "Image",
                  "propertyFields": {
                    "href": "weatherIconUrl"
                  },
                  "width": 30,
                  "height": 30,
                  "horizontalCenter": "middle",
                  "verticalCenter": "middle"
                }]
              }]
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:18,
          y:0,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(durationFilterTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let miniChartData:any = [];
          if (result){
            for (let b= 0; b< result["1"].aggregations["1-bucket"]["buckets"].length; b++){
              miniChartData.push({
                time : result["1"].aggregations["1-bucket"]["buckets"][b].key,
                value : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value / 1000 / 60
              })
            }
          }
          if (miniChartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), miniChartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(durationSamePeriodItem);



    let dashboardCfg = DashboardConfig.of(
      {
        id: "StoreAccessesBdwn1234",
        name : "Store Accesses Breakdown",
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        }
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);
    this.dashboard.setFilter(this.filter.filterValues);
    this.filterChangedSubscription = this.filter.filterChangedObservable()
      .subscribe((newFilterValue) => {
        this.dashboard.reloadData();
        this.showDashboard = true;
      });

  }


}



