import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";

import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DashboardItemConfig, DashboardItemViewGeneric,
  DashboardItemViewMetricDelta, DashboardItemViewSerialChart,
  DataResultInterface, DataSource,
  DataSourceAssemblerFactory,
  DataSourceAssemblerInterface,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory, SerialChartConfig, ViewAssemblerFactory
} from "iottacle-dashboard";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import {DashboardConsolePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import {
  AuthService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import {FilterBuilderGenericNeo} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-neo";
import {
  Filter,
  FilterInterface,
  FilterLocationReferences,
  FilterLocationReferencesStrategy, FilterVisitJunkData, FilterVisitNewret, FilterVisitStrategy,
  FilterVisitTimeFilterStrategies
} from "iottacle-ts-models";


@Component({
  selector: 'next-one',
  templateUrl: './next-one.component.html',
  styleUrls: ['./next-one.component.scss']
})
export class NextOneComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;

  filterChangedSubscription:Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {});
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {

    let dashboardItems:DashboardItem[] = [];
    //RepositoryCatalog.init(this.http);

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let filterBuilderElastic:FilterBuilderInterface = new FilterBuilderGenericElastic();
    let filterBuilderNeo:FilterBuilderInterface = new FilterBuilderGenericNeo();



    //LIVE NOW

    let liveNowTitle:DataSourceConfigInterfaceInternal = {
      name: "custom",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          return of("# Live Data");
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let liveNowTitleItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "569a9e6a-9142-43e6-a757-39953be334a1",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "" ,
      showHeader : false,
      readOnly : true,
      showName: false,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "markdown-text",
      style:{
        background : "none",
        'border-color' : "#ffffff00",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:0,
          cols:36,
          rows:2,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(liveNowTitle))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(liveNowTitleItem);


    //Store Visit Count
    let currentCount:DataSourceConfigInterfaceInternal = {
      name: "currentCount",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 24, multiplier: 'hours'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let countItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "df7f2403-fcb6-45d5-beb6-ad62d5e32367",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Visits' count" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "people",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "people",
        gridsterConfig : {
          x:0,
          y:2,
          cols:12,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(currentCount))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].aggregations["1"].value;
          let toRet = {
            mainValue: v ? v : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(countItem);

    //Store Visit Duration
    let currentDuration:DataSourceConfigInterfaceInternal = {
      name: "currentDuration",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 24, multiplier: 'hours'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "avg": {
                "field": "visitDuration"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let durationItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "ad2f4a52-3a45-4471-995d-6ff6ecb3cbd5",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Visits' duration" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "min",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "access_time",
        gridsterConfig : {
          x:12,
          y:2,
          cols:12,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(currentDuration))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].aggregations["1"].value;
          let toRet = {
            mainValue: v ? (v / 1000 / 60) : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(durationItem);

    //Store Visit Recency
    let currentRecency:DataSourceConfigInterfaceInternal = {
      name: "currentRecency",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 24, multiplier: 'hours'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret: FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "avg": {
                "field": "recency"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let recencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "7f2cbd41-53c1-47b3-a631-68145400ec5d",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Average of Last Visit" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "days ago",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:24,
          y:2,
          cols:12,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(currentRecency))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].aggregations["1"].value;
          let toRet = {
            mainValue: v ? v / 1000 / 60 / 60 / 24 : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(recencyItem);

    //Store Visit Frequency
    let currentFrequency:DataSourceConfigInterfaceInternal = {
      name: "currentFrequency",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 24, multiplier: 'hours'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret: FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "avg": {
                "field": "frequency"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let frequencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "0ede09b0-cd7c-4c2a-af73-5cda8c63a037",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Average Visits per Month" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "times per month",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:0,
          y:7,
          cols:12,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(currentFrequency))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].aggregations["1"].value;
          let toRet = {
            mainValue: v ? v : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(frequencyItem);

    //Store Lost returning Visit Count
    let lostReturningVisitCount:DataSourceConfigInterfaceInternal = {
      name: "currentFrequency",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 24, multiplier: 'hours'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret: FilterVisitNewret.NEW,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [], "must_not": [
                {"match_phrase": {"recency": {"query": 0}}}
              ]}},
          agg: {},
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let lostReturningVisitCountItem =  DashboardItem.of(DashboardItemConfig.of( {
      id: "14cc191b-9d0e-4490-a936-1f9da3e4e0c9",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Regained Lost Customers" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "people",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:12,
          y:7,
          cols:12,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(lostReturningVisitCount))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].hits.total;
          let toRet = {
            mainValue: v ? v : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(lostReturningVisitCountItem);

    //Store Lost returning Visit Recency
    let lostReturningVisitRecency:DataSourceConfigInterfaceInternal = {
      name: "lostReturningVisitRecency",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 24, multiplier: 'hours'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret: FilterVisitNewret.NEW,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [], "must_not": [
                {"match_phrase": {"recency": {"query": 0}}}
              ]}},
          agg: {
            "1": {
              "avg": {
                "field": "recency"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let lostReturningVisitRecencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "78b17956-9835-4a5b-960a-75133f2e1be1",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Regained Customer Avg Last Visit" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 15000,
      subtitle: "days ago",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "",
        topTextMatIcon: "",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:24,
          y:7,
          cols:12,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(lostReturningVisitRecency))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let v = result["1"].aggregations["1"].value;
          let toRet = {
            mainValue: v ? v / 1000 / 60 / 60 / 24 : 0
          };
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(lostReturningVisitRecencyItem);



    //DETAILS

    let accessDetailsTitle:DataSourceConfigInterfaceInternal = {
      name: "custom",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          return of("# Access Details");
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let accessDetailsTitleItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "95ab0c00-db9b-4e95-b953-5c9f759f275e",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "accessDetailsTitle" ,
      showHeader : false,
      readOnly : true,
      showName: false,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "markdown-text",
      style:{
        background : "none",
        'border-color' : "#ffffff00",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:12,
          cols:36,
          rows:2,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(accessDetailsTitle))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(accessDetailsTitleItem);

    //Duration METER
    let maxDurationLast10daysAndTrend:DataSourceConfigInterfaceInternal = {
      name: "maxDurationLast10daysAndTrend",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:10, multiplier:'days'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "avg": {
                    "field": "visitDuration"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let currentDuration1:DataSourceConfigInterfaceInternal = {
      name: "currentDuration",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'timeToConsider',
            strategy: 'time-ago', duration: 1, multiplier: 'days'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "avg": {
                "field": "visitDuration"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let durationItem1 =  DashboardItem.of(DashboardItemConfig.of({
      id: "ff57d508-3ad6-4149-9d46-7609b9995804",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Average Duration" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 24 hours",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: "min",
        miniChartConfig: {
          chartType : "XYChart",
          chart :{
            colors:{
              list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
            },
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            cursor : {
              lineY : {
                disabled : true
              }
            },
            xAxes: [{
              type: "DateAxis",
              dataFields: {
                category: "name"
              },
              renderer: {
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            yAxes: [{
              type: "ValueAxis",
              renderer: {
                baseGrid: {
                  disabled: true
                },
                grid : {
                  disabled : true
                },
                labels : {
                  disabled: true
                }
              },
              cursorTooltipEnabled : false
            }],
            series: [
              {
                type: "LineSeries",
                dataFields: {
                  valueY: "value",
                  dateX: "key"
                },
                tooltip: {
                  pointerOrientation: "vertical"
                },
                tensionX : 0.8,
                strokeWidth:2
              }
            ]
          }
        }
      })),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:0,
          y:14,
          cols:9,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "maxDurationLast10daysAndTrend" : DataSource.of(DataSourceConfig.of(maxDurationLast10daysAndTrend)),
        "currentDuration" : DataSource.of(DataSourceConfig.of(currentDuration1))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let maxDuration = result.maxDurationLast10daysAndTrend.aggregations["1"].value;
            let percDuration = result.currentDuration.aggregations["1"].value /1000/60;
            let miniChartData:any = [];
            for (let b= 0; b< result.maxDurationLast10daysAndTrend.aggregations["1-bucket"]["buckets"].length && b<10; b++){
              miniChartData.push({
                key : result.maxDurationLast10daysAndTrend.aggregations["1-bucket"]["buckets"][b].key,
                value : result.maxDurationLast10daysAndTrend.aggregations["1-bucket"]["buckets"][b]["1-metric"].value/1000/60
              })
            }
            toRet = {
              mainValue : percDuration,
              miniChartData : miniChartData
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(durationItem1);

    //Count over time
    let countFilterTime:DataSourceConfigInterfaceInternal = {
      name: "countFilterTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let countSamePeriodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "a0e71fd2-1946-487e-9f16-8bddbb5bfbfb",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Accesses over time" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 24 hours",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          colors:{
            list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
          },
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Count",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            },
          }],

          "series": [
            {
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {dateX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
              "bullets": [{
                "type": "Bullet",
                "children": [{
                  "type": "Image",
                  "propertyFields": {
                    "href": "weatherIconUrl"
                  },
                  "width": 30,
                  "height": 30,
                  "horizontalCenter": "middle",
                  "verticalCenter": "middle"
                }]
              }]
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:9,
          y:14,
          cols:27,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(countFilterTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let miniChartData:any = [];
          if (result){
            for (let b= 0; b< result["1"].aggregations["1-bucket"]["buckets"].length; b++){
              miniChartData.push({
                time : result["1"].aggregations["1-bucket"]["buckets"][b].key,
                value : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value
              })
            }
          }
          if (miniChartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), miniChartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(countSamePeriodItem);

    //store access Heatmap
    let hourlyPerDayHeatmapDataSource:DataSourceConfigInterfaceInternal = {
      name: "hourlyPerDayHeatmapDataSource",
      type: "elastic",
      assembler : {
        name:"idempotentDataSourceAssembler",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          let data:{
            startHod:number,
            startDow:string,
            count:number
          }[] = [];

          let dowMapping = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
          for (let r in result.aggregations["2"].buckets){
            let sod = result.aggregations["2"].buckets[r].key;
            let hod = (sod / 3600);

            for (let b in result.aggregations["2"].buckets[r]["3"].buckets){
              let dow = result.aggregations["2"].buckets[r]["3"].buckets[b].key;
              let count = result.aggregations["2"].buckets[r]["3"].buckets[b].doc_count;
              data.push({
                startHod :hod,
                startDow : dowMapping[dow],
                count:count
              });
            }
          }

          return of(GenericDataResult.ofSuccess(thiz.getName(), data));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return DataSourceAssemblerFactory.idempotentDataSourceAssembler;
        },
        serialize : () => {
          return JSON.stringify({
            name : DataSourceAssemblerFactory.idempotentDataSourceAssembler.name
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {
            "bool": {
              "must": [
                {"match_all": {}},
                {"range": {"visitDuration": {"gte": 1000, "lt": 1800000}}},
                {"match_phrase": {"adminEntityId": {"query": 68}}},
                {"range": {"avgSs": {"gte": -82, "lt": 0}}}
              ],
              "filter": [],
              "should": [],
              "must_not": []
            }
          },
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0,
                "extended_bounds": {
                  "min": 0,
                  "max": 86400
                }
              },
              "aggs": {
                "3": {
                  "histogram": {
                    "field": "startDow",
                    "interval": 1,
                    "min_doc_count": 0,
                    "extended_bounds": {
                      "min": 1,
                      "max": 7
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let hourlyPerDayHeatmapItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "4aeb17d8-667b-4f20-be28-4bf969d7af7a" ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Heatmap Access" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText : "This heatmap displays the visits count over time per each day of the week",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        createChart : (chart)=> {
          chart.maskBullets = false;

          chart.colors.list = [
              am4core.color('#9d9fd9'),
              am4core.color('#4f51ab'),
              am4core.color('#EAEDF2'),
              am4core.color('#EAEDF2')
          ];
          var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
          var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

          xAxis.dataFields.category = "startHod";
          yAxis.dataFields.category = "startDow";

          xAxis.renderer.grid.template.disabled = true;
          xAxis.renderer.minGridDistance = 40;

          yAxis.renderer.grid.template.disabled = true;
          yAxis.renderer.inversed = true;
          yAxis.renderer.minGridDistance = 30;

          var series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "startHod";
          series.dataFields.categoryY = "startDow";
          series.dataFields.value = "count";
          series.sequencedInterpolation = true;
          series.defaultState.transitionDuration = 3000;

          var bgColor = new am4core.InterfaceColorSet().getFor("background");

          var columnTemplate = series.columns.template;
          columnTemplate.strokeWidth = 1;
          columnTemplate.strokeOpacity = 0.2;
          columnTemplate.stroke = bgColor;
          columnTemplate.tooltipText = "{startHod}, {startDow}: {value.workingValue.formatNumber('#.')}";
          columnTemplate.width = am4core.percent(100);
          columnTemplate.height = am4core.percent(100);

          series.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color(bgColor),
            max: chart.colors.getIndex(0)
          });

// heat legend
          var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
          heatLegend.width = am4core.percent(100);
          heatLegend.series = series;
          heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
          heatLegend.valueAxis.renderer.minGridDistance = 30;

// heat legend behavior
          series.columns.template.events.on("over", (event) => {
            handleHover(event.target);
          })

          series.columns.template.events.on("hit", (event) => {
            handleHover(event.target);
          })

          function handleHover(column) {
            if (!isNaN(column.dataItem.value)) {
              heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
              heatLegend.valueAxis.hideTooltip();
            }
          }

          series.columns.template.events.on("out", (event) => {
            heatLegend.valueAxis.hideTooltip();
          });

          return chart;

        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:20,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "hourlyPerDayHeatmapDataSource" : DataSource.of(DataSourceConfig.of(hourlyPerDayHeatmapDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(hourlyPerDayHeatmapItem);

    //New vs Ret
    let newVsRetFilterTime:DataSourceConfigInterfaceInternal = {
      name: "newVsRetFilterTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {
            "bool": {
              "must": [
                {"match_all": {}},
                {"range": {"visitDuration": {"gte": 1000, "lt": 1800000}}},
                {"match_phrase": {"adminEntityId": {"query": 68}}},
                {"range": {"avgSs": {"gte": -82, "lt": 0}}}
              ],
              "filter": [],
              "should": [],
              "must_not": []
            }
          },
          agg : {
            "2": {
              "terms": {
                "field": "newret",
                "size": 2,
                "order": {
                  "1": "desc"
                }
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let newVsRetSamePeriodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "10ba5a69-ec3e-4168-8b23-938cc25bd3ac",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "New & Returning" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 24 hours",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "PieChart",
        chart :{
          "series": [
            {
              colors:{
                list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
              },
              "type": "PieSeries",
              "dataFields": {
                "value": "value",
                "category": "newret"
              }
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:20,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(newVsRetFilterTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            if (result["1"].aggregations["2"].buckets[0].key === 1){
              chartData.push({
                newret : "New",
                value : result["1"].aggregations["2"].buckets[0]["1"].value
              });
              chartData.push({
                newret : "Returning",
                value : result["1"].aggregations["2"].buckets[1]["1"].value
              })
            }else{
              chartData.push({
                newret : "New",
                value : result["1"].aggregations["2"].buckets[1]["1"].value
              });
              chartData.push({
                newret : "Returning",
                value : result["1"].aggregations["2"].buckets[0]["1"].value
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(newVsRetSamePeriodItem);

    //CHURN, LOYALTY & OPPORTUNITY
    let churnLoyaltyOpportunityTitle:DataSourceConfigInterfaceInternal = {
      name: "churnLoyaltyOpportunityTitle",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          return of("# Churn, Loyalty & Opportunity");
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let churnLoyaltyOpportunityTitleItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "6f608737-3e9b-41b5-bd98-2e1a0547d84d",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "churnLoyaltyOpportunityTitle" ,
      showHeader : false,
      readOnly : true,
      showName: false,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "markdown-text",
      style:{
        background : "none",
        'border-color' : "#ffffff00",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:32,
          cols:36,
          rows:2,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(churnLoyaltyOpportunityTitle))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(churnLoyaltyOpportunityTitleItem);

    // churn rate over time
    let churnRateOverTime:DataSourceConfigInterfaceInternal = {
      name: "churnRateOverTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'ts',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          query: {
            "bool": {
              "must": [
                {"match_all": {}},
                {"match_phrase": {"adminEntityId": {"query": 68}}}
              ],
              "filter": [],
              "should": [],
              "must_not": []
            }
          },
          agg : {
            "2": {
              "date_histogram": {
                "field": "ts",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1": {
                  "avg": {
                    "field": "churnRate"
                  }
                }
              }
            }
          },
          index : "store_kpi",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let churnRateOverTimeItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "51fcd450-aeeb-41f3-8aab-04c230692abe",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Churn rate over time" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          colors:{
            list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
          },
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Rate (%)",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "strokeWidth": 3,
              "tooltip": {
                "pointerOrientation": "vertical"
              }
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:0,
          y:34,
          cols:36,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(churnRateOverTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            for (let b= 0; b< result["1"].aggregations["2"]["buckets"].length; b++){
              chartData.push({
                time : result["1"].aggregations["2"]["buckets"][b].key,
                value : result["1"].aggregations["2"]["buckets"][b]["1"].value
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(churnRateOverTimeItem);

    // Opportunity rate over time
    let externalVisitsOverTime:DataSourceConfigInterfaceInternal = {
      name: "externalVisitsOverTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {
            "bool": {
              "must": [
                {"match_all": {}},
                {"range": {"visitDuration": {"gte": 1000, "lt": 1800000}}},
                {"match_phrase": {"adminEntityId": {"query": 68}}},
                {"range": {"avgSs": {"gte": -82, "lt": 0}}}
              ],
              "filter": [],
              "should": [],
              "must_not": []
            }
          },
          agg : {
            "2": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let internalVisitsOverTime:DataSourceConfigInterfaceInternal = {
      name: "internalVisitsOverTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let opportunityRateOverTimeItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "29f6da3f-368c-40d1-8bc2-8e8abff2ff4c",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Opportunity rate over time" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          colors:{
            list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
          },
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Rate %",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "strokeWidth": 3,
              "tooltip": {
                "pointerOrientation": "vertical"
              }
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:40,
          cols:36,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(internalVisitsOverTime)),
        "2" : DataSource.of(DataSourceConfig.of(externalVisitsOverTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            for (let b in result["1"].aggregations["2"]["buckets"]){
              let internal = result["1"].aggregations["2"]["buckets"][b]["1"].value;
              let external = result["2"].aggregations["2"]["buckets"][b]["1"].value;
              chartData.push({
                time : result["1"].aggregations["2"]["buckets"][b].key,
                value : Math.round(internal / external * 100)
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(opportunityRateOverTimeItem);

    // Opportunity rate over dow
    let externalVisitsOverDow:DataSourceConfigInterfaceInternal = {
      name: "externalVisitsOverDow",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_EXTERNAL_VISITS_PREDEFINED_FILTERS,
            junk:FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startDow",
                "interval": 1,
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let internalVisitsOverDow:DataSourceConfigInterfaceInternal = {
      name: "internalVisitsOverDow",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk:FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startDow",
                "interval": 1,
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let opportunityRateOverDowItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "7376b762-390a-4d4c-a357-22649c367ac2",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Opportunity rate over Day of Week" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          colors:{
            list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
          },
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Rate %",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              name : "Opportunity Rate",
              "dataFields": {
                "valueY": "value",
                "categoryX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0
                },
                "strokeOpacity": 0
              },
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:46,
          cols:18,
          rows:9,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(internalVisitsOverDow)),
        "2" : DataSource.of(DataSourceConfig.of(externalVisitsOverDow))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            for (let b in result["1"].aggregations["2"]["buckets"]){
              let internal = result["1"].aggregations["2"]["buckets"][b]["1"].value;
              let external = result["2"].aggregations["2"]["buckets"][b]["1"].value;
              chartData.push({
                time : result["1"].aggregations["2"]["buckets"][b].key,
                value : Math.round(internal / external * 100)
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(opportunityRateOverDowItem);


    // Opportunity rate over hod
    let externalVisitsOverHod:DataSourceConfigInterfaceInternal = {
      name: "externalVisitsOverHod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_EXTERNAL_VISITS_PREDEFINED_FILTERS,
            junk:FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let internalVisitsOverHod:DataSourceConfigInterfaceInternal = {
      name: "internalVisitsOverHod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let opportunityRateOverHodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "6161e72e-0a09-4865-8ad3-ab780f654245",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Opportunity rate over hour of Day" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          colors:{
            list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
          },
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Rate %",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              name : "Opportunity Rate",
              "dataFields": {
                "valueY": "value",
                "categoryX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0
                },
                "strokeOpacity": 0
              },
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:46,
          cols:18,
          rows:9,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(internalVisitsOverHod)),
        "2" : DataSource.of(DataSourceConfig.of(externalVisitsOverHod))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            for (let b in result["1"].aggregations["2"]["buckets"]){
              let internal = result["1"].aggregations["2"]["buckets"][b]["1"].value;
              let external = result["2"].aggregations["2"]["buckets"][b]["1"].value;
              chartData.push({
                time : result["1"].aggregations["2"]["buckets"][b].key/3600,
                value : Math.round(internal / external * 100)
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(opportunityRateOverHodItem);


    // New vs Ret distribution over weekdays
    let newretDistributionOverWeekdaysDataSource:DataSourceConfigInterfaceInternal = {
      name: "newretDistributionOverWeekdaysDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startDow",
                "interval": 1,
                "min_doc_count": 0
              },
              "aggs": {
                "3": {
                  "terms": {
                    "field": "newret",
                    "size": 5,
                    "order": {
                      "_count": "desc"
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let newretDistributionOverWeekdaysItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "ff689e3a-6015-4a67-af40-c31852f02acf",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "New & Returning Distribution Over Week" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          colors:{
            list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
          },
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "dow"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "%",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              name : "New",
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "new",
                "categoryX": "dow"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0
                },
                "strokeOpacity": 0
              },
            },
            {
              "type": "ColumnSeries",
              stacked: true,
              name : "Returning",
              "dataFields": {
                "valueY": "returning",
                "categoryX": "dow"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:0,
          y:55,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(newretDistributionOverWeekdaysDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            let total = result[1].hits.total;
            for (let b in result["1"].aggregations["2"]["buckets"]){
              chartData.push({
                dow : result["1"].aggregations["2"]["buckets"][b].key,
                new : result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"].length > 0 &&
                result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].key == 1 ?
                  (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].doc_count / total * 100 ): result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1].key == 1 ? (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1].doc_count / total * 100 ) : 0,
                returning : result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"].length > 0 &&
                result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1].key == 0 ?
                  (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1].doc_count / total * 100 ) : result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].key == 0 ? (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].doc_count / total * 100 ) : 0
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(newretDistributionOverWeekdaysItem);

    // New vs Ret distribution over hod
    let newretDistributionOverHodDataSource:DataSourceConfigInterfaceInternal = {
      name: "newretDistributionOverHodDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0
              },
              "aggs": {
                "3": {
                  "terms": {
                    "field": "newret",
                    "size": 5,
                    "order": {
                      "_count": "desc"
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let newretDistributionOverHodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "7d54cb44-81e9-4976-a239-f00718505b41",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "New & Returning Distribution Over Hour Of Day" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          colors:{
            list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
          },
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "hod"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "%",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              name : "New",
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "new",
                "categoryX": "hod"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0
                },
                "strokeOpacity": 0
              },
            },
            {
              "type": "ColumnSeries",
              stacked: true,
              name : "Returning",
              "dataFields": {
                "valueY": "returning",
                "categoryX": "hod"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:18,
          y:55,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(newretDistributionOverHodDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            let total = result[1].hits.total;
            for (let b in result["1"].aggregations["2"]["buckets"]){
              let newCount;
              let retCount;
              if (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"].length > 0){
                if (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0]){
                  if (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].key == 1){
                    newCount = (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].doc_count / total * 100 );
                    if (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1]){
                      retCount = (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1].doc_count / total * 100 );
                    }else{
                      retCount = 0;
                    }
                  }else{
                    if (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1]){
                      newCount = (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][1].doc_count / total * 100 );
                    }else{
                      newCount = 0;
                    }
                    retCount = (result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0].doc_count / total * 100 );
                  }
                }else{
                  newCount = 0;
                  retCount = 0
                }
              }else{
                newCount = 0;
                retCount = 0
              }
              chartData.push({
                hod : result["1"].aggregations["2"]["buckets"][b].key/3600,
                new : newCount,
                returning : retCount
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(newretDistributionOverHodItem);


    //Funnel - from morning to evening
    let funnelMorningCountDataSource:DataSourceConfigInterfaceInternal = {
      name: "funnelMorningCountDataSource",
      type: "neo",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleNeo4jGenericQuery"),
      filter : filterBuilderNeo.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 4, multiplier:"days"
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          queryTemplate: "match (m:MacAddress)-[:HAS]->(:Visits)-[:HAS]->(v:Visit)<-[:HAS]-(:AdminVisits)-[:HAPPENED_IN]->(l:LocationDetails)<-[:LOCATION_DETAILS]-(:AdministrativeEntity) " +
            " where l.id in [__locationDetailsIds__] and v.start > __start_start__ and v.start < __start_end__ and v.startSod >= 28800 and v.startSod <= 39600 " +
            " return count(distinct m) as countMorning",
          params : {}
        }
      })),
      specificDataSourceConfig: {},
    };
    let funnelMorningToEveningDataSource:DataSourceConfigInterfaceInternal = {
      name: "funnelMorningToEveningDataSource",
      type: "neo",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleNeo4jGenericQuery"),
      filter : filterBuilderNeo.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 2, multiplier:"days"
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          queryTemplate: "match (m:MacAddress)-[:HAS]->(:Visits)-[:HAS]->(v:Visit)<-[:HAS]-(:AdminVisits)-[:HAPPENED_IN]->(l:LocationDetails)<-[:LOCATION_DETAILS]-(:AdministrativeEntity) " +
            " where l.id in [__locationDetailsIds__] and v.start > __start_start__ and v.start < __start_end__ and v.startSod >= 28800 and v.startSod <= 39600\n" +
            "\n" +
            " match (m)-[:HAS]->(:Visits)-[:HAS]->(v1:Visit)<-[:HAS]-(:AdminVisits)-[:HAPPENED_IN]->(l1:LocationDetails)<-[:LOCATION_DETAILS]-(:AdministrativeEntity) " +
            " where l1.id in [__locationDetailsIds__] and v1.start > (v.start + (1000*60*60)) and v1.startSod >= 64800 and v1.startSod <= 82800 return count(distinct m) as countm",
          params : {}
        }
      })),
      specificDataSourceConfig: {},
    };
    let funnelMorningToEveningItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "c15ce893-8d73-47e5-936c-ab1a7a552b50",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Conversion Morning-Evening" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 1,
        maxFractionDigits : 1,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: "%"
      })),
      specificItemConfig : {
        showTopText : true,
        topTextMatIconColorClass: "blue-fg",
        topTextTitle: "How many morning customer comes also on evening:",
        gridsterConfig : {
          x:0,
          y:67,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(funnelMorningCountDataSource)),
        "2" : DataSource.of(DataSourceConfig.of(funnelMorningToEveningDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          if (result){
            let toRet;
            let allMorningVisits = result[1][0]._fields[0].low;
            let afternoonVisits = result[2][0]._fields[0].low;
            toRet = {
              mainValue : afternoonVisits / allMorningVisits * 100
            };
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(funnelMorningToEveningItem);

    //Funnel - how many times outside bwfore getting in
    let outsideToInsideDataSource:DataSourceConfigInterfaceInternal = {
      name: "outsideToInsideDataSource",
      type: "neo",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleNeo4jGenericQuery"),
      filter : filterBuilderNeo.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 4, multiplier:"days"
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          queryTemplate: "match (m:MacAddress)-[:HAS]->(:Visits)-[:HAS]->(v:Visit)<-[:HAS]-(:AdminVisits)-[:HAPPENED_IN]->(l:LocationDetails)<-[:LOCATION_DETAILS]-(:AdministrativeEntity) " +
            " where l.id in [__locationDetailsIds__] and v.start > __start_start__ and v.start < __start_end__ and v.avgSs < -82 " +
            " match (m)-[:HAS]->(:Visits)-[:HAS]->(v1:Visit)<-[:HAS]-(:AdminVisits)-[:HAPPENED_IN]->(l1:LocationDetails)<-[:LOCATION_DETAILS]-(:AdministrativeEntity) " +
            " where l1.id in [__locationDetailsIds__] and  v1.start > v.start and v1.avgSs >= -82 " +
            " with m as m, count(v1) as countV1 " +
            " return avg(countV1) as averageTimes ",
          params : {}
        }
      })),
      specificDataSourceConfig: {},
    };
    let outsidetoInsideItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "d81740c6-70a5-476d-953b-eae00a7e1781",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "How many times outside before getting in" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: "times"
      })),
      specificItemConfig : {
        showTopText : true,
        topTextMatIconColorClass: "blue-fg",
        topTextTitle: "How many times pass outside before getting in:",
        gridsterConfig : {
          x:18,
          y:67,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(outsideToInsideDataSource)),
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          if (result){
            let toRet;
            let averageTimes = result[1][0]._fields[0];
            toRet = {
              mainValue : averageTimes
            };
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(outsidetoInsideItem);


    //after when get back lost customer
    let getBackLostCustomersDataSource:DataSourceConfigInterfaceInternal = {
      name: "getBackLostCustomersDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.NEW,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [], "must_not": [
                {"match_phrase": {"recency": {"query": 0}}}
              ]}},
          agg : {
            "2": {
              "histogram": {
                "field": "recency",
                "interval": 86000000,
                "min_doc_count": 1
              },
              "aggs": {
                "3": {
                  "terms": {
                    "field": "newret",
                    "size": 5,
                    "order": {
                      "1": "desc"
                    }
                  },
                  "aggs": {
                    "1": {
                      "cardinality": {
                        "field": "ma_sha1"
                      }
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let getBackLostCustomersItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "e9d66492-7e70-4f17-b508-a74f207bf527",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "After how many days lost customers come back" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          colors:{
            list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
          },
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "xAxesValue_01"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "%",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              name : "Distribution",
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "yAxesValue_01",
                "categoryX": "xAxesValue_01"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0
                },
                "strokeOpacity": 0
              },
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:0,
          y:73,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(getBackLostCustomersDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            //let timeToRemove = 30*24*60*60*1000; //30 days in ms
            let total = result["1"].hits.total;
            for (let b in result["1"].aggregations["2"]["buckets"]){
              let recency = Math.round(result["1"].aggregations["2"]["buckets"][b].key / 1000 / 60 / 60 / 24);// - timeToRemove;
              let distribution = result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"].length > 0 ?
                result["1"].aggregations["2"]["buckets"][b]["3"]["buckets"][0][1].value : 0;
              distribution = distribution / total * 100;
              chartData.push({
                xAxesValue_01 : recency,
                yAxesValue_01 : distribution
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(getBackLostCustomersItem);

    //distribution over weekday & hour, when they come back
    let regainedHourlyPerDayHeatmapDataSource:DataSourceConfigInterfaceInternal = {
      name: "regainedHourlyPerDayHeatmapDataSource",
      type: "elastic",
      assembler : {
        name:"idempotentDataSourceAssembler",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          let data:{
            startHod:number,
            startDow:string,
            count:number
          }[] = [];

          let dowMapping = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
          for (let r in result.aggregations["2"].buckets){
            let sod = result.aggregations["2"].buckets[r].key;
            let hod = (sod / 3600);

            for (let b in result.aggregations["2"].buckets[r]["3"].buckets){
              let dow = result.aggregations["2"].buckets[r]["3"].buckets[b].key;
              let count = result.aggregations["2"].buckets[r]["3"].buckets[b].doc_count;
              data.push({
                startHod :hod,
                startDow : dowMapping[dow],
                count:count
              });
            }
          }

          return of(GenericDataResult.ofSuccess(thiz.getName(), data));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return DataSourceAssemblerFactory.idempotentDataSourceAssembler;
        },
        serialize : () => {
          return JSON.stringify({
            name : DataSourceAssemblerFactory.idempotentDataSourceAssembler.name
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.CUSTOM_PERIOD,
            format : "YYYY-MM-DD",
            start: "2019-04-18",
            end: "2019-05-25",
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.NEW,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [],
              "must_not": [
                {"match_phrase": {"recency": {"query": 0}}}]}},
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0,
                "extended_bounds": {
                  "min": 0,
                  "max": 86400
                }
              },
              "aggs": {
                "3": {
                  "histogram": {
                    "field": "startDow",
                    "interval": 1,
                    "min_doc_count": 0,
                    "extended_bounds": {
                      "min": 1,
                      "max": 7
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let regainedHourlyPerDayHeatmapItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "c1653188-e43b-4389-bbb7-9618de2c5e7f"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Heatmap Access Of Regained Customers" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText : "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        createChart : (chart)=> {
          chart.maskBullets = false;

          chart.colors.list = [
              am4core.color('#9d9fd9'),
              am4core.color('#4f51ab'),
              am4core.color('#EAEDF2'),
              am4core.color('#EAEDF2')
          ];
          var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
          var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

          xAxis.dataFields.category = "startHod";
          yAxis.dataFields.category = "startDow";

          xAxis.renderer.grid.template.disabled = true;
          xAxis.renderer.minGridDistance = 40;

          yAxis.renderer.grid.template.disabled = true;
          yAxis.renderer.inversed = true;
          yAxis.renderer.minGridDistance = 30;

          var series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "startHod";
          series.dataFields.categoryY = "startDow";
          series.dataFields.value = "count";
          series.sequencedInterpolation = true;
          series.defaultState.transitionDuration = 3000;

          var bgColor = new am4core.InterfaceColorSet().getFor("background");

          var columnTemplate = series.columns.template;
          columnTemplate.strokeWidth = 1;
          columnTemplate.strokeOpacity = 0.2;
          columnTemplate.stroke = bgColor;
          columnTemplate.tooltipText = "{startHod}, {startDow}: {value.workingValue.formatNumber('#.')}";
          columnTemplate.width = am4core.percent(100);
          columnTemplate.height = am4core.percent(100);

          series.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color(bgColor),
            max: chart.colors.getIndex(0)
          });

// heat legend
          var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
          heatLegend.width = am4core.percent(100);
          heatLegend.series = series;
          heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
          heatLegend.valueAxis.renderer.minGridDistance = 30;

// heat legend behavior
          series.columns.template.events.on("over", (event) => {
            handleHover(event.target);
          })

          series.columns.template.events.on("hit", (event) => {
            handleHover(event.target);
          })

          function handleHover(column) {
            if (!isNaN(column.dataItem.value)) {
              heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
              heatLegend.valueAxis.hideTooltip();
            }
          }

          series.columns.template.events.on("out", (event) => {
            heatLegend.valueAxis.hideTooltip();
          });

          return chart;

        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:73,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(regainedHourlyPerDayHeatmapDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(regainedHourlyPerDayHeatmapItem);


    let customRepoDataSource:DataSourceConfigInterfaceInternal = {
      name: "totalCountCurrentPeriod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          return of({
            center : {
              lat : 45.513659,
              lon : 9.324193
            },
            //kDiff : 0.0003838819983883468,
            //dDiff : 0.0013203716277985222,
            //rot : -4.52805957639483,
            //imgUrl : 'https://www.pointinside.com/wp-content/uploads/2017/06/Visual_Analytics@2x.jpg.jpg',
            //zoom : 21
            polygons : [
              {
                name : "Main - hypermarket",
                coordinates : [
                  {
                    lat:45.5452528,
                    lon:9.3284653,
                    z:0
                  },
                  {
                    lat:45.5447437,
                    lon:9.3285297,
                    z:0
                  },
                  {
                    lat:45.5449034,
                    lon:9.3309196,
                    z:0
                  },
                  {
                    lat:45.5451852,
                    lon:9.330874,
                    z:0
                  },
                  {
                    lat:45.5452209,
                    lon:9.330772,
                    z:0
                  },
                  {
                    lat:45.5453035,
                    lon:9.3307613,
                    z:0
                  },
                  {
                    lat:45.5453223,
                    lon:9.3308069,
                    z:0
                  },
                  {
                    lat:45.5454237,
                    lon:9.3307935,
                    z:0
                  },
                  {
                    lat:45.5454181,
                    lon:9.3306594,
                    z:0
                  },
                  {
                    lat:45.5455327,
                    lon:9.3306487,
                    z:0
                  },
                  {
                    lat:45.545435,
                    lon:9.3289615,
                    z:0
                  },
                  {
                    lat:45.5452904,
                    lon:9.3289857,
                    z:0
                  },
                  {
                    lat:45.5452528,
                    lon:9.3284653,
                    z:0
                  }],
                color : "#ffffff",
                alpha : 0.5
              },
              {
                name : "Service Counter",
                coordinates : [
                  {
                    lat:45.5453768,
                    lon:9.3293746,
                    z:0
                  },
                  {
                    lat:45.5454407,
                    lon:9.3303295,
                    z:0
                  },
                  {
                    lat:45.545512,
                    lon:9.3303187,
                    z:0
                  },
                  {
                    lat:45.5454576,
                    lon:9.3293585,
                    z:0
                  },
                  {
                    lat:45.5453768,
                    lon:9.3293746,
                    z:0
                  }
                ],
                color : "#ffffff",
                alpha : 0.5
              },
              {
                name : "Tech",
                coordinates : [
                  {
                    lat:45.5448057,
                    lon:9.3288677,
                    z:0
                  },
                  {
                    lat:45.54511,
                    lon:9.3288221,
                    z:0
                  },
                  {
                    lat:45.5450894,
                    lon:9.3287041,
                    z:0
                  },
                  {
                    lat:45.5452434,
                    lon: 9.328688,
                    z:0
                  },
                  {
                    lat:45.5452303,
                    lon:9.3284948,
                    z:0
                  },
                  {
                    lat:45.5447757,
                    lon:9.3285646,
                    z:0
                  },
                  {
                    lat:45.5448057,
                    lon:9.3288677,
                    z:0
                  }
                ],
                color : "#ffffff",
                alpha : 0.5
              },
              {
                name : "Daily Offers",
                coordinates : [
                  {
                    lat:45.5448057,
                    lon:9.3288677,
                    z:0
                  },
                  {
                    lat:45.5448226,
                    lon:9.3291842,
                    z:0
                  },
                  {
                    lat:45.5453392,
                    lon:9.3291144,
                    z:0
                  },
                  {
                    lat:45.5453279,
                    lon: 9.3289803,
                    z:0
                  },
                  {
                    lat:45.5452904,
                    lon:9.3289857,
                    z:0
                  },
                  {
                    lat:45.5452829,
                    lon:9.3289213,
                    z:0
                  },
                  {
                    lat:45.5451006,
                    lon:9.3289401,
                    z:0
                  },
                  {
                    lat:45.54511,
                    lon:9.3288221,
                    z:0
                  },
                  {
                    lat:45.5448057,
                    lon:9.3288677,
                    z:0
                  }
                ],
                color : "#ffffff",
                alpha : 0.5
              }
            ]
          });
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let interiorMap:DashboardItem = DashboardItem.of(DashboardItemConfig.of({
      id: "1"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Interior Map" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "interiorMap",
      infoButtonText:"",
      itemType : "map-with-polygons",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        showInteriorMap : true,
        gridsterConfig : {
          x:0,
          y:85,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "customRepoDataSource" : DataSource.of(DataSourceConfig.of(customRepoDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
    }));
    //dashboardItems.push(interiorMap);


    //Median Frequency
    let medianFrequencyDataSource:DataSourceConfigInterfaceInternal = {
      name: "medianFrequencyDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "percentiles": {
                "field": "frequency",
                "percents": [
                  50
                ],
                "keyed": false
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let medianFrequencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "0eca5d7c-f5c7-4e60-9934-ebcb81ac349",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Your customer returns about:" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "times in a week",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "blue-fg",
        topTextTitle: "",
        gridsterConfig : {
          x:0,
          y:10,
          cols:18,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(medianFrequencyDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            toRet = {
              mainValue : result["1"].aggregations["1"].values[0].value
            };
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    //dashboardItems.push(medianFrequencyItem);

    //Median Recency
    let medianRecencyDataSource:DataSourceConfigInterfaceInternal = {
      name: "medianFrequencyDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField: "start",
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "1": {
              "percentiles": {
                "field": "recency",
                "percents": [
                  50
                ],
                "keyed": false
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let medianRecencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "08d7f2b1-5f1d-41eb-a5d6-df0246b3f858",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Your customer generally come back after:" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "days",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 1,
        maxFractionDigits : 1,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: ""
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "blue-fg",
        topTextTitle: "",
        gridsterConfig : {
          x:18,
          y:10,
          cols:18,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(medianRecencyDataSource))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            toRet = {
              mainValue : result["1"].aggregations["1"].values[0].value/1000/60/60/24
            };
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    //dashboardItems.push(medianRecencyItem);

    // ACCESS BREAKDOWN

    //Duration over time
    let durationFilterTime:DataSourceConfigInterfaceInternal = {
      name: "durationFilterTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 100,"lt": 7200000}}},
                {"range": {"avgSs": {"gte": -85, "lte": 0}}}
              ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1": {
              "max_bucket": {
                "buckets_path": "1-bucket>1-metric"
              }
            },
            "1-bucket": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "1-metric": {
                  "avg": {
                    "field": "visitDuration"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let durationSamePeriodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "27a79b65-8ed2-44d8-a490-35e1d3a40fdd",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Avg Duration over time" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "last 24 hours",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          colors:{
            list :['#9d9fd9', '#4f51ab', '#EAEDF2', '#EAEDF2']
          },
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Minutes",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {dateX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
              "bullets": [{
                "type": "Bullet",
                "children": [{
                  "type": "Image",
                  "propertyFields": {
                    "href": "weatherIconUrl"
                  },
                  "width": 30,
                  "height": 30,
                  "horizontalCenter": "middle",
                  "verticalCenter": "middle"
                }]
              }]
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        matIcon: "access_time",
        gridsterConfig : {
          x:18,
          y:0,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(durationFilterTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let miniChartData:any = [];
          if (result){
            for (let b= 0; b< result["1"].aggregations["1-bucket"]["buckets"].length; b++){
              miniChartData.push({
                time : result["1"].aggregations["1-bucket"]["buckets"][b].key,
                value : result["1"].aggregations["1-bucket"]["buckets"][b]["1-metric"].value / 1000 / 60
              })
            }
          }
          if (miniChartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), miniChartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    //dashboardItems.push(durationSamePeriodItem);


    //RETURN RATE

    //Recency & Frequency
    let recencyFrequencyDataSource:DataSourceConfigInterfaceInternal = {
      name: "recencyFrequencyDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            newret : FilterVisitNewret.RETURNING,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"recency": {"gte": 0, "lt": 604800000}}}
              ]}},
          agg : {
            "2": {
              "histogram": {
                "field": "recency",
                "interval": 86400000,
                "min_doc_count": 0
              },
              "aggs": {
                "3": {
                  "histogram": {
                    "field": "frequency",
                    "interval": 1,
                    "min_doc_count": 0
                  },
                  "aggs": {
                    "1": {
                      "cardinality": {
                        "field": "ma_sha1"
                      }
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let recencyFrequencyItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "1cff0669-64c9-4f19-989c-b541acfb1e47"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Recency Vs Frequency" ,
      showHeader : false,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText : "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#D0D6D9",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        createChart : (chart)=> {
          chart.maskBullets = false;

          chart.colors.list = [
              am4core.color('#9d9fd9'),
              am4core.color('#4f51ab'),
              am4core.color('#EAEDF2'),
              am4core.color('#EAEDF2')
          ];
          var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
          var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

          xAxis.dataFields.category = "recency";
          yAxis.dataFields.category = "frequency";

          xAxis.renderer.grid.template.disabled = true;
          xAxis.renderer.minGridDistance = 40;

          yAxis.renderer.grid.template.disabled = true;
          yAxis.renderer.inversed = true;
          yAxis.renderer.minGridDistance = 30;

          var series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "recency";
          series.dataFields.categoryY = "frequency";
          series.dataFields.value = "value";
          series.sequencedInterpolation = true;
          series.defaultState.transitionDuration = 3000;

          var bgColor = new am4core.InterfaceColorSet().getFor("background");

          var columnTemplate = series.columns.template;
          columnTemplate.strokeWidth = 1;
          columnTemplate.strokeOpacity = 0.2;
          columnTemplate.stroke = bgColor;
          columnTemplate.tooltipText = "{recency}, {frequency}: {value.workingValue.formatNumber('#.')}";
          columnTemplate.width = am4core.percent(100);
          columnTemplate.height = am4core.percent(100);

          series.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color(bgColor),
            max: chart.colors.getIndex(0)
          });

          // heat legend
          var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
          heatLegend.width = am4core.percent(100);
          heatLegend.series = series;
          heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
          heatLegend.valueAxis.renderer.minGridDistance = 30;

          // heat legend behavior
          series.columns.template.events.on("over", (event) => {
            handleHover(event.target);
          })

          series.columns.template.events.on("hit", (event) => {
            handleHover(event.target);
          })

          function handleHover(column) {
            if (!isNaN(column.dataItem.value)) {
              heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
              heatLegend.valueAxis.hideTooltip();
            }
          }

          series.columns.template.events.on("out", (event) => {
            heatLegend.valueAxis.hideTooltip();
          });

          return chart;

        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:0,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(recencyFrequencyDataSource)),
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          if (result){
            let chartData:any = [];
            for (let r in result["1"].aggregations["2"]["buckets"]){
              let recency = result["1"].aggregations["2"]["buckets"][r].key;
              for (let f in result["1"].aggregations["2"]["buckets"][r]["3"].buckets){
                let frequency = result["1"].aggregations["2"]["buckets"][r]["3"].buckets[f].key;
                let value = result["1"].aggregations["2"]["buckets"][r]["3"].buckets[f]["1"].value;
                chartData.push({
                  recency : Math.round(recency / 1000 / 60 / 60 / 24),
                  frequency : frequency,
                  value: Math.log(value)*100
                })
              }
            }
            let maxFreq = 0;
            let maxRec = 0;
            for (let c in chartData){
              maxFreq = chartData[c].frequency > maxFreq ? chartData[c].frequency : maxFreq;
              maxRec = chartData[c].recency > maxFreq ? chartData[c].recency : maxRec;
            }
            chartData.sort((a, b) => {
              //100:x = maxFreq:a.frequency
              let scaledFreqA =(maxFreq/a.frequency)*(1/100);
              let scaledRecA =(maxRec/a.recency)*(1/100);
              let scaledFreqB =(maxFreq/b.frequency)*(1/100);
              let scaledRecB =(maxRec/b.recency)*(1/100);

              return Math.min(scaledRecB , scaledFreqB) - Math.min(scaledFreqA , scaledRecA);
              //return (b.frequency + b.recency) - (a.recency - a.frequency);
            });
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    //dashboardItems.push(recencyFrequencyItem);


    let dashboardCfg = DashboardConfig.of(
      {
        id: "NextOne1234",
        name: "Next One",
        dashboardPersistency : DashboardConsolePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        },
        initFilter : this.filter.filterValues
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData();
          this.showDashboard = true;
        }
      });

    // this.filterChangedSubscription = this.filter.filterChangedObservable()
    //   .subscribe((newFilterValue) => {
    //     this.dashboard.reloadData();
    //     this.showDashboard = true;
    //   });

  }


}


