import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FilterModule} from "../../../filter/filter.module";
import {AuthGuardService,FuseSharedModule,FuseSidebarModule,FuseWidgetModule,IottacleNavigationService} from "core-fe-angular";
import {RouterModule} from "@angular/router";
import {GridsterModule} from "angular-gridster2";
import {AngularDashboardModule} from "dashboard-fe-angular";
import {SmartCityOverviewComponent} from "./overview/smart-city-overview.component";

import {
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatOptionModule,
  MatListModule
} from '@angular/material';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { AgmCoreModule } from '@agm/core';
import {SmartCityParkComponent} from "./park/smart-city-park.component";
import {SmartCitySupermarketComponent} from "./supermarket/smart-city-supermarket.component";
import {CastelfrancoEmiliaComponent} from "./castelfranco-emilia/castelfranco-emilia.component";
import {CastelfrancoEmiliaDetailComponent} from "./castelfranco-emilia-detail/castelfranco-emilia-detail.component";


const routes = [
  {
    path     : 'smart-city',
    canActivate: [AuthGuardService],
    children : [
      {
        path     : 'overview',
        component: SmartCityOverviewComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'park-usage',
        component: SmartCityParkComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'supermarket-details',
        component: SmartCitySupermarketComponent,
        canActivate: [AuthGuardService]
      },
      {
        path     : 'castelfranco-emilia',
        canActivate: [AuthGuardService],
        children : [
          {
            path     : 'overview',
            component: CastelfrancoEmiliaComponent,
            canActivate: [AuthGuardService]
          }
        ]
      },

    ]
  }
];

@NgModule({
  providers : [
  ],
  imports     : [
    CommonModule,
    FuseSharedModule,
    FuseSidebarModule,
    RouterModule.forChild(routes),
    FilterModule,
    GridsterModule,
    AngularDashboardModule,

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    FuseWidgetModule,
    NgxChartsModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBvZWorK4nmcjUJ1lP6HooJyGsCjYYyqn0'
    })
  ],
  declarations: [
    SmartCityOverviewComponent, SmartCityParkComponent, SmartCitySupermarketComponent,
    CastelfrancoEmiliaComponent,CastelfrancoEmiliaDetailComponent
  ]
})
export class SmartCityModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService
  ){

    //instruct navigation
    this._fuseNavigationService.addNavigationItem({
      'id'       : 'smart-city',
      'title'    : 'Smart City',
      'translate': 'nav.smart-city.group-name',
      'type'     : 'group',
      'children' : [
        {
          'id'       : 'smart-city.overview',
          'title'    : 'Overview',
          'translate': 'nav.smart-city.overview',
          'type'     : 'item',
          'icon'     : 'timeline',
          'url'  : '/smart-city/overview'
        },
        {
          'id'       : 'smart-city.park-usage',
          'title'    : 'Park Usage',
          'translate': 'nav.smart-city.park-usage',
          'type'     : 'item',
          'icon'     : 'terrain',
          'url'  : '/smart-city/park-usage'
        },
        {
          'id'       : 'smart-city.supermarket-usage',
          'title'    : 'Supermarket Usage',
          'translate': 'nav.smart-city.supermarket-usage',
          'type'     : 'item',
          'icon'     : 'store',
          'url'  : '/smart-city/supermarket-details'
        }
      ]
    }, "end");
  }
}
