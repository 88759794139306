import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'activities-and-notes',
  templateUrl: './activities-and-notes.component.html',
  styleUrls: ['./activities-and-notes.component.scss']
})
export class ActivitiesAndNotesComponent implements OnInit, OnDestroy{


  constructor(
  ) {
  }


  ngOnInit() {

  }

  ngOnDestroy(){

  }
}
