import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {
  Dashboard, DashboardConfig,
  DashboardItem, DashboardItemAssemblerFactory,
  DashboardItemConfig,
  DashboardItemFactory, DashboardItemViewGeneric, DashboardItemViewMetric,
  DashboardItemViewMetricDelta, DashboardItemViewSerialChart, DataResultInterface,
  DataSource,
  DataSourceAssemblerFactory, DataSourceAssemblerInterface, DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface, FilterBuilderFactory,
  GenericDataResult, MetricConfig,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory, SerialChartConfig, ViewAssemblerFactory
} from "iottacle-dashboard";
import {Observable, of} from "rxjs";
import {
  AuthService,
  FuseTranslationLoaderService,
  FuseConfigService,
  RepositoryCatalog, GridsterDashboardConfig
} from "core-fe-angular";
import {DecimalPipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import moment from 'moment';
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {WeightedLocation} from "../../../../../../context/common/weighted-location";
import {locale as english} from '../i18n/en';
import {locale as italian} from '../i18n/it';
import {Circle} from "../../../../../../context/common/circle";
import * as am4charts from "@amcharts/amcharts4/charts";
import {SmartCityDashboardElements} from "../../../../../../context/dashboard-catalog/smart-city-dashboard-elements";
import {DashboardConsolePersistency} from "../../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import {
  Coordinate,
  Filter, FilterInterface,
  FilterLocationReferences, FilterLocationReferencesStrategy,
  FilterQuickValues,
  FilterTypeValues, FilterVisitJunkData,
  FilterVisitTimeFilterStrategies, Polygon
} from "iottacle-ts-models";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";


@Component({
  selector: 'castelfranco-emilia',
  templateUrl: './castelfranco-emilia.component.html',
  styleUrls: ['./castelfranco-emilia.component.scss']
})
export class CastelfrancoEmiliaComponent implements OnInit, OnDestroy{


  dashboard:Dashboard;
  showDashboard = false;


  mapItem:DashboardItem;
  tableItem:DashboardItem;
  totalCountItem:DashboardItem;
  liveFillItem:DashboardItem;
  dailyWakeUp:DashboardItem;
  weekendsWakeUp:DashboardItem;
  visitsFillOverPeriod:DashboardItem;

  constructor(
    private auth:AuthService,
    private decimalPipe: DecimalPipe,
    private http:HttpClient,
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
  ){

    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };
  }

  ngOnDestroy(): void {
    this.dashboard.destroy();
  }

  ngOnInit(): void {
    let dashboardItems:DashboardItem[] = [];


    RepositoryCatalog.init(this.http);
    SmartCityDashboardElements.init(this.http);

    let commonFilter = new Filter(this.auth.getUnderlyingLoginModel());
    commonFilter.changeValueToQuick({
      filterType : FilterTypeValues.QUICK,
      filterQuickValue: FilterQuickValues.LAST_24_HOURS,
      administrativeEntityId : this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities()[0].getAdministrativeEntityDetails().administrativeEntityId,
      administrativeEntities: [this.auth.getCurrentLoggedUser().getSelectedOrganization().getMyAdministrativeEntities()[0]]
    });

    let enableUpdates = true;

    let filterBuilder:FilterBuilderInterface = new FilterBuilderGenericElastic();
    // let commonFilter = {
    //   timeFilter: {
    //     outcome: {
    //       timeRangeBoundaries: {
    //         from: moment().subtract(30, 'days'),
    //         to: moment()
    //       }
    //     }
    //   },
    //   administrativeEntityFilter :{
    //     selected : {
    //       administrativeEntityDetails : {}
    //     }
    //   },
    //   isEmpty : () => false
    // };

    //LIVE NOW
    let liveNowTitle:DataSourceConfigInterfaceInternal = {
      name: "custom",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          let t = "Metriche Live";
          return of(t);
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let liveNowTitleItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "569a9e6a-9142-43e6-a757-39953be334a1",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "" ,
      showHeader : false,
      readOnly : !enableUpdates,
      showName: false,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "markdown-text",
      style:{
        background : "none",
        'border-color' : "#ffffff00",
        'border-radius': "2px",
        headerFxLayoutAlign : "start center"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:0,
          cols:36,
          rows:2,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(liveNowTitle))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(liveNowTitleItem);

    //current count
    let countLast24hours:DataSourceConfigInterfaceInternal = {
      name: "countLast24hours",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [119]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 600000,"lt": 14400000}}} //10 minutes - 4 hours
                ],
              "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1" : {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let countLast48hours:DataSourceConfigInterfaceInternal = {
      name: "countLast48hours",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.PREVIOUS_PERIOD,
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [119]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 600000,"lt": 14400000}}} //10 minutes - 4 hours
              ],
              "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1" : {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let currentCount:DataSourceConfigInterfaceInternal = {
      name: "currentDuration",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'timeToConsider',
            strategy: 'time-ago', duration: 1, multiplier: 'hour'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [119]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
            {"range": {"visitDuration": {"gte": 1000,"lt": 14400000}}} //10 minutes - 4 hours
          ]}},
          agg: {
            "1": {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let countItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "142b2b22-9b5d-48e2-a514-60a5e6cd75b3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Presenze attuali (ultima ora)" ,
      showHeader : false,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 60000,
      subtitle: "Trend ultime 24 ore",
      infoButtonText: "Indica la stima delle presenze ponderate nell'ultima ora di analisi. <br/> Il conteggio considera coloro che visitano la città per qualche ora, coloro che lavorano e abitano vengono esclusi",
      itemType : "metric-delta",
      style:{
        background : "none",
        'border-color' : "#00000000",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: " visite",
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "Live now - Visits' count",
        topTextMatIcon: "people",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:0,
          y:2,
          cols:13,
          rows:6,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(countLast24hours)),
        "2" : DataSource.of(DataSourceConfig.of(countLast48hours)),
        "3" : DataSource.of(DataSourceConfig.of(currentCount))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let count = result["3"].aggregations["1"].value;
            let last24Hours = result["1"].aggregations["1"].value;
            let last48Hours = result["2"].aggregations["1"].value;
            let perc =  last48Hours / last24Hours;
            let derivedCount = count * perc;
            toRet = {
              mainValue : count,
              secondaryValue: derivedCount
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(countItem);

    //PER AREA COUNT
    let vittoriaCount:DataSourceConfigInterfaceInternal = {
      name: "vittoriaCount",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: 'time-ago', duration: 1, multiplier: 'hour'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [121]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 1000,"lt": 14400000}}} //10 minutes - 4 hours
              ]}},
          agg: {
            "1": {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let vittoriaItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "142b2b22-9b5d-48e2-a514-60a5e6cd75b3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Presenze Piazza Vittoria (ultima ora)" ,
      showHeader : false,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 60000,
      subtitle: "",
      infoButtonText: "Indica la stima delle presenze ponderate nell'ultima ora di analisi. <br/> Il conteggio considera coloro che visitano la città per qualche ora, coloro che lavorano e abitano vengono esclusi",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: " visite",
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "Live now - Visits' count",
        topTextMatIcon: "people",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:18,
          y:14,
          cols:9,
          rows:5,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(vittoriaCount))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let count = result["1"].aggregations["1"].value;
            toRet = {
              mainValue : count
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(vittoriaItem);

    let bergaminiCount:DataSourceConfigInterfaceInternal = {
      name: "bergaminiCount",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: 'time-ago', duration: 1, multiplier: 'hour'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [120]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 1000,"lt": 14400000}}} //10 minutes - 4 hours
              ]}},
          agg: {
            "1": {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let bergaminiItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "142b2b22-9b5d-48e2-a514-60a5e6cd75b3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Presenze Piazza Bergamini (ultima ora)" ,
      showHeader : false,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 60000,
      subtitle: "",
      infoButtonText: "Indica la stima delle presenze ponderate nell'ultima ora di analisi. <br/> Il conteggio considera coloro che visitano la città per qualche ora, coloro che lavorano e abitano vengono esclusi",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: " visite",
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "Live now - Visits' count",
        topTextMatIcon: "people",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:0,
          y:14,
          cols:9,
          rows:5,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(bergaminiCount))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let count = result["1"].aggregations["1"].value;
            toRet = {
              mainValue : count
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(bergaminiItem);

    let garibaldiCount:DataSourceConfigInterfaceInternal = {
      name: "garibaldiCount",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: 'time-ago', duration: 1, multiplier: 'hour'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [123]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 1000,"lt": 14400000}}} //10 minutes - 4 hours
              ]}},
          agg: {
            "1": {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let garibaldiItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "142b2b22-9b5d-48e2-a514-60a5e6cd75b3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Presenze Piazza Garibaldi (ultima ora)" ,
      showHeader : false,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 60000,
      subtitle: "",
      infoButtonText: "Indica la stima delle presenze ponderate nell'ultima ora di analisi. <br/> Il conteggio considera coloro che visitano la città per qualche ora, coloro che lavorano e abitano vengono esclusi",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: " visite",
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "Live now - Visits' count",
        topTextMatIcon: "people",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:27,
          y:14,
          cols:9,
          rows:5,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(garibaldiCount))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let count = result["1"].aggregations["1"].value;
            toRet = {
              mainValue : count
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(garibaldiItem);

    let emiliaCount:DataSourceConfigInterfaceInternal = {
      name: "emiliaCount",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: 'time-ago', duration: 1, multiplier: 'hour'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [124]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 1000,"lt": 14400000}}} //10 minutes - 4 hours
              ]}},
          agg: {
            "1": {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let emiliaItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "142b2b22-9b5d-48e2-a514-60a5e6cd75b3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Presenze Via Emilia (ultima ora)" ,
      showHeader : false,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 60000,
      subtitle: "",
      infoButtonText: "Indica la stima delle presenze ponderate nell'ultima ora di analisi. <br/> Il conteggio considera coloro che visitano la città per qualche ora, coloro che lavorano e abitano vengono esclusi",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: " visite",
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "Live now - Visits' count",
        topTextMatIcon: "people",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:9,
          y:14,
          cols:9,
          rows:5,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(emiliaCount))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let count = result["1"].aggregations["1"].value;
            toRet = {
              mainValue : count
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(emiliaItem);


    //average duration
    let durationLast24hours:DataSourceConfigInterfaceInternal = {
      name: "durationLast24hours",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [119]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 600000,"lt": 14400000}}} //10 minutes - 4 hours
              ],
              "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1" : {
              "avg": {
                "field": "visitDuration"
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let durationLast48hours:DataSourceConfigInterfaceInternal = {
      name: "durationLast48hours",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.PREVIOUS_PERIOD,
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [119]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 600000,"lt": 14400000}}} //10 minutes - 4 hours
              ],
              "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1" : {
              "avg": {
                "field": "visitDuration"
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let currentDuration:DataSourceConfigInterfaceInternal = {
      name: "currentDuration",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData: {
          timeStrategy: {
            timestampField: 'start',
            strategy: 'time-ago', duration: 1, multiplier: 'hour'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [119]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg: {
            "1": {
              "avg": {
                "field": "visitDuration"
              }
            }
          },
          index: "visits_count",
          returnComplete: true
        }
      })),
      specificDataSourceConfig: {},
    };
    let durationItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "142b2b22-9b5d-48e2-a514-60a5e6cd75b3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Durata Media (ultima ora)" ,
      showHeader : false,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 60000,
      subtitle: "Trend ultime 24 ore",
      infoButtonText: "",
      itemType : "metric-delta",
      style:{
        background : "none",
        'border-color' : "#00000000",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2,
        postfix: " min",
      })),
      specificItemConfig : {
        showTopText : false,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "Live now - Visits' count",
        topTextMatIcon: "people",
        topTextSubtitle: "",
        matIcon: "",
        gridsterConfig : {
          x:0,
          y:8,
          cols:13,
          rows:6,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(durationLast24hours)),
        "2" : DataSource.of(DataSourceConfig.of(durationLast48hours)),
        "3" : DataSource.of(DataSourceConfig.of(currentDuration))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let toRet;
          if (result){
            let count = result["3"].aggregations["1"].value/1000/60;
            let last24Hours = result["1"].aggregations["1"].value;
            let last48Hours = result["2"].aggregations["1"].value;
            let perc = last48Hours / last24Hours;
            let derivedCount = count * perc;
            toRet = {
              mainValue : count,
              secondaryValue: derivedCount
            }
          }
          if (toRet !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), toRet));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(durationItem);

    //COUNT BY AREA - TABLE
    let totalCountLast24Hours:DataSourceConfigInterfaceInternal = {
      name: "totalCountLast24Hours",
      type: "elastic",
      assembler : {
        name:"custom",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          let elements = [];
          for (let b in result.aggregations["2"].buckets){
            elements.push({
              "Area" : result.aggregations["2"].buckets[b].key,
              "Last 24h": result.aggregations["2"].buckets[b]["1"].value,
              "Avg Time (min)": result.aggregations["2"].buckets[b]["5"].value/1000/60,
            });
          }
          return of(GenericDataResult.ofSuccess(thiz.getName(), elements));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return null;
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'timeToConsider',
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration:24, multiplier:'hours'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [120,121,123,124]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 2000,"lt": 14400000}}} //10 minutes - 4 hours
              ]}},
          agg : {
            "2": {
              "terms": {
                "field": "tags",
                "size": 50,
                "order": {
                  "1": "desc"
                }
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                },
                "4": {
                  "avg": {
                    "field": "avgSs"
                  }
                },
                "5": {
                  "avg": {
                    "script": {
                      "inline": "doc['visitDuration'].value",
                      "lang": "painless"
                    }
                  }
                }
              }
            }
          },
          index : "visits_count",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let tableItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "10"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Conteggio Per Area" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "(Presenze Uniche)",
      infoButtonText: "",
      itemType : "data-table",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        columns: [
          {
            name:'Area',
            type:'text'
          },
          {
            name:'Last 24h',
            type:'text'
          },
          {
            name:'Avg Time (min)',
            type:'text'
          }
        ],
        gridsterConfig : {
          x:0,
          y:29,
          cols:36,
          rows:13,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        "totalCountLast24Hours" : DataSource.of(DataSourceConfig.of(totalCountLast24Hours))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    dashboardItems.push(tableItem);


    //VISIT FILL OVERALL
    let nearVisitsFillOverTime:DataSourceConfigInterfaceInternal = {
      name: "nearVisitsFillOverTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("elasticDateHeatMapBucket"),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'timeToConsider',
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 20,
            multiplier : "days"
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [119]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 2000,"lt": 14400000}}} //10 minutes - 4 hours
              ]}},
          agg : {
            "dateBucket": {
              "date_histogram": {
                "field": "timeToConsider",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "average": {
                  "avg": {
                    "field": "visitDuration"
                  }
                },
                "uniqueCount" : {
                  "cardinality" : {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits_count",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let dailyWeatherBucket:DataSourceAssemblerInterface = {
      name:"dailyWeatherBucket",
      assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
        let buckets = result ?
          result.aggregations ?
            result.aggregations["4"] ?
              result.aggregations["4"] ?
                result.aggregations["4"].buckets ?
                  result.aggregations["4"].buckets : [] : [] : [] : [] : [];
        let assembledBuckets = [];
        for (let b in buckets){
          assembledBuckets.push({
            keyAsString : buckets[b].key_as_string,
            key : buckets[b].key,
            weatherIconUrl : "https://openweathermap.org/img/w/" + buckets[b]["2"].buckets[0].key.replace('n','d') + ".png"
          });
        }

        return of(GenericDataResult.ofSuccess(thiz.getName(), assembledBuckets));
      },
      rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
        return dailyWeatherBucket;
      },
      serialize : () => {
        return JSON.stringify({
          name : dailyWeatherBucket.name
        });
      }
    };
    let dailyWeather:DataSourceConfigInterfaceInternal = {
      name: "dailyWeather",
      type: "elastic",
      assembler : dailyWeatherBucket,
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            strategy : "time-ago",
            timestampField:'dt',
            duration: 20,
            multiplier : "days"
          },
          "query": {
            "bool": {
              "must": [
                {
                  "match_phrase": {
                    "zip": {
                      "query": "41013"
                    }
                  }
                }
              ],
              "must_not": [
                {
                  "match_phrase": {
                    "weather.icon.keyword": {
                      "query": "50d"
                    }
                  }
                }
              ]
            }
          },
          agg : {
            "4": {
              "date_histogram": {
                "field": "dt",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "2": {
                  "terms": {
                    "field": "weather.icon.keyword",
                    "size": 1,
                    "order": {
                      "_count": "desc"
                    }
                  }
                }
              }
            }
          },
          index : "weather",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let visitsFillOverPeriod =  DashboardItem.of(DashboardItemConfig.of({
      id: "6f70b6f1-f0d3-45e6-868d-d110b0eee6b7"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Historical Trend + Weather" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "(Minuti)",
      infoButtonText: "This histogram shows the historical trend of people's layover (at least 2 seconds) around all the monitored areas.",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "key"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Count",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "total",
                "dateX": "key"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {dateX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
              "bullets": [{
                "type": "Bullet",
                "children": [{
                  "type": "Image",
                  "propertyFields": {
                    "href": "weatherIconUrl"
                  },
                  "width": 30,
                  "height": 30,
                  "horizontalCenter": "middle",
                  "verticalCenter": "middle"
                }]
              }]
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:42,
          cols:36,
          rows:10,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        nearVisitsFillOverTime : DataSource.of(DataSourceConfig.of(nearVisitsFillOverTime)),
        dailyWeather : DataSource.of(DataSourceConfig.of(dailyWeather)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("visitFillAndWeatherSmartCityDashboardItemAssembler")
    }));
    dashboardItems.push(visitsFillOverPeriod);

    //VISIT FILL BERGAMINI
    let bergaminiNearVisitsFillOverTime:DataSourceConfigInterfaceInternal = {
      name: "bergaminiNearVisitsFillOverTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("elasticDateHeatMapBucket"),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'timeToConsider',
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration: 20,
            multiplier : "days"
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [120]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 1000,"lt": 14400000}}} //10 minutes - 4 hours
              ]}},
          agg : {
            "dateBucket": {
              "date_histogram": {
                "field": "timeToConsider",
                "interval": "1h",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "average": {
                  "avg": {
                    "field": "visitDuration"
                  }
                },
                "uniqueCount" : {
                  "cardinality" : {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits_count",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let bergaminiVisitsFillOverPeriod =  DashboardItem.of(DashboardItemConfig.of({
      id: "6f70b6f1-f0d3-45e6-868d-d110b0eee6b7"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Visite piazza bergamini" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "key"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Count",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              "dataFields": {
                "valueY": "total",
                "dateX": "key"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {dateX}\nValue: {valueY}",
                  "tooltipY": 0,
                  "cornerRadiusTopLeft": 5,
                  "cornerRadiusTopRight": 5
                },
                "strokeOpacity": 0
              },
              "bullets": [{
                "type": "Bullet",
                "children": [{
                  "type": "Image",
                  "propertyFields": {
                    "href": "weatherIconUrl"
                  },
                  "width": 30,
                  "height": 30,
                  "horizontalCenter": "middle",
                  "verticalCenter": "middle"
                }]
              }]
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:19,
          cols:36,
          rows:10,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(bergaminiNearVisitsFillOverTime)),
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          return of(GenericDataResult.ofSuccess(thiz.getId().toString(), result["1"]));
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return null;
        }
      }
    }));
    dashboardItems.push(bergaminiVisitsFillOverPeriod);


    let polygonsDataSource:DataSourceConfigInterfaceInternal = {
      name: "polygonsDataSource",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          let user = this.auth.getCurrentLoggedUser();

          let locations:Coordinate[] = [];
          let polygons:Polygon[] = [];
          if (user) {
            locations = user.getSelectedOrganization().getMyAdministrativeEntities().map((el) => {
              return el.getAdministrativeEntityDetails().lat ? Coordinate.of(
                el.getAdministrativeEntityDetails().name,
                el.getAdministrativeEntityDetails().lat,
                el.getAdministrativeEntityDetails().lon,
              ) : undefined;
            })
              .filter(el => !!el);

            polygons = user.getSelectedOrganization().getMyAdministrativeEntities()
              .map(el => el.getAdministrativeEntityDetails().locations)
              .reduce((x, y) => x.concat(y), [])
              .filter((el) => el.name!= 'all')
              .map(el => el.polygon);
          }
          return of({
            markers: locations,
            polygons: polygons
          });
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let heatmapLiveFillDataSource:DataSourceConfigInterfaceInternal = {
      name: "liveFillDataSource",
      type: "elastic",
      assembler : {
        name:"idempotentDataSourceAssembler",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          let res:WeightedLocation[] = [];
          for (let p in result.aggregations.filter_agg["2"].buckets){
            let point = result.aggregations.filter_agg["2"].buckets[p];
            res.push({
              location : Coordinate.of("coord",point["3"].location.lat, point["3"].location.lon ),
              weight : point["1"].value
            });
          }
          return of(GenericDataResult.ofSuccess(thiz.getName(), {
            center : res[0].location,
            //zoom:19,
            heatmap : res
          }));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return DataSourceAssemblerFactory.idempotentDataSourceAssembler;
        },
        serialize : () => {
          return JSON.stringify({
            name : DataSourceAssemblerFactory.idempotentDataSourceAssembler.name
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.TIME_AGO,
            duration:6, multiplier:'hours'
          },
          locationReference : {
            from: FilterLocationReferences.FROM_GIVEN_LOCATION_IDS,
            locationIds: [119,120,121,123,124]
          },
          visitStrategyFilter : {
            junk: FilterVisitJunkData.REMOVE
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 600000,"lt": 14400000}}} //10 minutes - 4 hours
              ]}},
          agg: {
            "filter_agg": {
              "filter": {
                "geo_bounding_box": {
                  "ignore_unmapped": true,
                  "endLocation": {
                    "top_left": {
                      "lat": 44.606669999999994,
                      "lon": 11.031095
                    },
                    "bottom_right": {
                      "lat": 44.584950000000006,
                      "lon": 11.073915
                    }
                  }
                }
              },
              "aggs": {
                "2": {
                  "geohash_grid": {
                    "field": "endLocation",
                    "precision": 10
                  },
                  "aggs": {
                    "1": {
                      "cardinality": {
                        "field": "ma_sha1"
                      }
                    },
                    "3": {
                      "geo_centroid": {
                        "field": "endLocation"
                      }
                    }
                  }
                }
              }
            }
          },
          index : "visits_count",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    this.mapItem = DashboardItem.of(DashboardItemConfig.of({
      id: "2"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Devices Installed - Data Heatmap" ,
      showHeader : false,
      readOnly: !enableUpdates,
      showName: false,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText : "It shows how much data is coming per each device, in a heatmap-fashion. It shows lat 7 days of collected data.",
      itemType : "map-with-polygons",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        height: '400px',
        heatmapRadius : 40,
        gMapOptions : {
          mapTypeId : 'hybrid',
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true
        },
        gridsterConfig : {
          x: 13,
          y: 2,
          cols:23,
          rows:12,
          dragEnabled : enableUpdates,
          resizeEnabled: enableUpdates
        }
      },
      dataSources : {
        "polygonsDataSource" : DataSource.of(DataSourceConfig.of(polygonsDataSource)),
        "heatmapLiveFillDataSource" : DataSource.of(DataSourceConfig.of(heatmapLiveFillDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
    }));
    dashboardItems.push(this.mapItem);

    //MAP People movement
    let peopleMovement:DataSourceConfigInterfaceInternal = {
      name: "peopleMovement",
      type: "elastic",
      assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "custom",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          let data:string[] = [];//AppData.appData.split('\n');
          let items = [];
          let fieldMap = {
            cookie_id : 0,
            date: 1,
            day_night: 2,
            event:3,
            geohash:4,
            hour:5,
            lat:6,
            lon:7,
            period:8,
            timestamp:9,
            valid_until:10
          };
          let pointsAlongTime : {
            time : number,
            points : {
              location: Coordinate,
              id: string
            }[]
          }[] = [];
          let start:number = moment().valueOf();
          let end:number = 0;
          for (let d in data){
            let row:string[] = data[d].split(";");
            row[fieldMap.timestamp.toString()] = moment.utc(row[fieldMap.date.toString()],"YYYY/MM/DD").add(Number(row[fieldMap.hour.toString()]), "hours").valueOf();
            row[fieldMap.valid_until.toString()] = Number(row[fieldMap.timestamp.toString()]) + (Number(row[fieldMap.period.toString()]) * 1000);
            if (row[fieldMap.timestamp.toString()] < start){
              start = row[fieldMap.timestamp.toString()];
            }
            if (row[fieldMap.timestamp.toString()] > end){
              end = row[fieldMap.timestamp.toString()];
            }
            items.push(row);
          }
          for (let time=start; time<end; time = time + (1000*60*60)){
            let points:{ location: Coordinate, id: string }[] = [];
            for (let i in items){
              if (
                time <= items[i][fieldMap.valid_until.toString()] &&
                time >= items[i][fieldMap.timestamp.toString()]
              ){
                points.push({
                  id : items[i][fieldMap.cookie_id.toString()],
                  location : Coordinate.of(items[i][fieldMap.cookie_id.toString()], Number(items[i][fieldMap.lat.toString()]), Number(items[i][fieldMap.lon.toString()]))
                });
              }
            }
            pointsAlongTime.push({
              time:time,
              points:points
            });
          }
          return of({pointsAlongTime:pointsAlongTime});
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return undefined;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let peopleMap:DashboardItem = DashboardItem.of(DashboardItemConfig.of({
      id: "3"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "custom" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText:"",
      itemType : "map-with-polygons",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        height: 300,
        heatmapRadius : 30,
        gridsterConfig : {
          x: 0,
          y: 0,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "pointsAlongTime" : DataSource.of(DataSourceConfig.of(peopleMovement)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
    }));
    //dashboardItems.push(peopleMap);

    //MAP PEOPLE WORK KM AREA
    let peopleHomeWorkAreaDataSource:DataSourceConfigInterfaceInternal = {
      name: "peopleMovement",
      type: "elastic",
      assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "custom",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          let center = Coordinate.of("",45.439490, 9.088635);
          let data:{
            zoom?:number,
            center?:Coordinate,
            circles:Circle[]
          } = {
            zoom:10,
            center: center,
            circles : [
              Circle.of({
                center:center,
                radius: 20000,
                strokeColor:"#ffdb05",
                strokeOpacity:0.8,
                strokeWeight:2,
                fillColor:"#FFDB05",
                fillOpacity:0.2,
              })
            ]
          };
          return of(data);
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return undefined;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let peopleHomeWorkArea:DashboardItem = DashboardItem.of(DashboardItemConfig.of({
      id: "4"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Home-Work Area" ,
      showHeader : false,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "This area shows the estimated average area that citizens cover while going to work.",
      itemType : "map-with-polygons",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        height: 300,
        heatmapRadius : 30,
        gridsterConfig : {
          x: 0,
          y: 0,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "a" : DataSource.of(DataSourceConfig.of(peopleHomeWorkAreaDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
    }));
    //dashboardItems.push(peopleHomeWorkArea);

    //CHORD Diagram between locations
    let chordLocationsDataSource:DataSourceConfigInterfaceInternal = {
      name: "chordLocationsDataSource",
      type: "elastic",
      assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "custom",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          let rawData = "BOOKCROSSING_BOSCO_2,GIOCHI_BOSCO_1,102\n" +
            "BOOKCROSSING_BOSCO_2,ORTO_BOSCO_2,263\n" +
            "BOOKCROSSING_BOSCO_2,FITNESS_BOSCO_2,149\n" +
            "BOOKCROSSING_BOSCO_2,INGRESSO_BOSCO_3,63\n" +
            "FITNESS_BOSCO_2,INGRESSO_BOSCO_3,67\n" +
            "FITNESS_BOSCO_2,BOOKCROSSING_BOSCO_2,197\n" +
            "FITNESS_BOSCO_2,ORTO_BOSCO_2,65\n" +
            "FITNESS_BOSCO_2,GIOCHI_BOSCO_1,3\n" +
            "GIOCHI_BOSCO_1,ORTO_BOSCO_2,10\n" +
            "GIOCHI_BOSCO_1,FITNESS_BOSCO_2,7\n" +
            "GIOCHI_BOSCO_1,INGRESSO_BOSCO_3,38\n" +
            "GIOCHI_BOSCO_1,BOOKCROSSING_BOSCO_2,73\n" +
            "INGRESSO_BOSCO_3,FITNESS_BOSCO_2,22\n" +
            "INGRESSO_BOSCO_3,GIOCHI_BOSCO_1,8\n" +
            "INGRESSO_BOSCO_3,BOOKCROSSING_BOSCO_2,17\n" +
            "INGRESSO_BOSCO_3,ORTO_BOSCO_2,3\n" +
            "ORTO_BOSCO_2,INGRESSO_BOSCO_3,5\n" +
            "ORTO_BOSCO_2,BOOKCROSSING_BOSCO_2,456\n" +
            "ORTO_BOSCO_2,FITNESS_BOSCO_2,33\n" +
            "ORTO_BOSCO_2,GIOCHI_BOSCO_1,39";
          let rows = rawData.split("\n");
          let data:any[] = [];
          for (let r in rows){
            let row = rows[r].split(",");
            data.push({
              from: row[0],
              to: row[1],
              value: row[2]
            });
          }

          return of(data);
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return undefined;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let chordLocations:DashboardItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "5"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Location Cross Visits" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "It shows how many people moved from one location to the other in the last 30 days",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "ChordDiagram",
        createChart : (chart) => {
          chart.dataFields.fromName = "from";
          chart.dataFields.toName = "to";
          chart.dataFields.value = "value";

          // make nodes draggable
          let nodeTemplate = chart.nodes.template;
          nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
          nodeTemplate.showSystemTooltip = true;

          let nodeLink = chart.links.template;
          let bullet = nodeLink.bullets.push(new am4charts.CircleBullet());
          bullet.fillOpacity = 1;
          bullet.circle.radius = 5;
          bullet.locationX = 0.5;

          // create animations
          chart.events.on("ready", function() {
            for (var i = 0; i < chart.links.length; i++) {
              let link = chart.links.getIndex(i);
              let bullet = link.bullets.getIndex(0);

              animateBullet(bullet);
            }
          });

          function animateBullet(bullet) {
            let duration = 3000 * Math.random() + 2000;
            let animation = bullet.animate([{ property: "locationX", from: 0, to: 1 }], duration);
            animation.events.on("animationended", function(event) {
              animateBullet(event.target.object);
            })
          }
          return chart;
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x: 18,
          y: 0,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "chordLocations" : DataSource.of(DataSourceConfig.of(chordLocationsDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    //dashboardItems.push(chordLocations);

    //Total count
    let totalCountCurrentPeriod:DataSourceConfigInterfaceInternal = {
      name: "totalCountCurrentPeriod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("elasticGetHitsAssembler"),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:24, multiplier:'hours'
          },

          query: {"bool": {"must": [
                  {"range": {"visitDuration": {"gte": 100,"lt": 700000000}}},
                  {"range": {"avgSs": {"gte": -85, "lte": 0}}},
                  {"bool": {"should": [{match_phrase: {adminEntityId: 60}}], "minimum_should_match": 1}}
                ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1": {
              "cardinality": {
                "field": "ma_sha1"
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let totalCountPreviousPeriod:DataSourceConfigInterfaceInternal = {
      name: "totalCountPreviousPeriod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("elasticGetHitsAssembler"),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'timeToConsider',
            strategy:'previous-period'
          },

          query: {"bool": {"must": [
            {"range": {"visitDuration": {"gte": 60000,"lt": 700000000}}},
                {"range": {"avgSs": {"gte": -85, "lte": 0}}},
                {"bool": {"should": [{match_phrase: {locationId: 53}}], "minimum_should_match": 1}}
                ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1": {
              "cardinality": {
                "field": "ma"
              }
            }
          },
          index : "visits_count",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    this.totalCountItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "6"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Total Layovers" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "(Last 24 hours)",
      infoButtonText: "Unique Count of the people passed by all the sensors in the last 24 hours",
      itemType : "metric-delta",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetricDelta.of(MetricDeltaConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0,
        deltaMinIntegerDigits :1,
        deltaMinFractionDigits :2,
        deltaMaxFractionDigits : 2
      })),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:0,
          cols:9,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "totalCurrentPeriod" : DataSource.of(DataSourceConfig.of(totalCountCurrentPeriod)),
        "totalPreviousPeriod" : DataSource.of(DataSourceConfig.of(totalCountPreviousPeriod))
      },

      assembler : DashboardItemAssemblerFactory.getAssembler("metricDeltaDashboardItemAssembler")
    }));
    //dashboardItems.push(this.totalCountItem);

    //BOOKCROSSING COUNT
    let bookcrossingCountDataSource:DataSourceConfigInterfaceInternal = {
      name: "bookcrossingCount",
      type: "elastic",
      assembler : {
        name:"elasticGetHitsAssembler",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          return of(GenericDataResult.ofSuccess(thiz.getName(), result ? result.aggregations ? result.aggregations["1"].value: 0 : 0));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return DataSourceAssemblerFactory.elasticGetHitsAssembler;
        },
        serialize : () => {
          return JSON.stringify({
            name : DataSourceAssemblerFactory.elasticGetHitsAssembler.name
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:24, multiplier:'hours'
          },

          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 1000,"lt": 3600000}}},
                {"range": {"avgSs": {"gte": -84, "lte": 0}}},
                {"bool": {"should": [{match_phrase: {tags: "BOOKCROSSING_BOSCO_2"}}], "minimum_should_match": 1}}
              ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1": {
              "cardinality": {
                field:"ma_sha1"
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let bookcrossingCount:DashboardItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "7"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Bookcrossing Layover" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "(last 24 hours)",
      infoButtonText: "Unique Count of the people passed by the Bookcrossing area in the last 24 hours",
      itemType : "metric",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetric.of(MetricConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0
      })),
      specificItemConfig : {
        matIcon: "book",
        gridsterConfig : {
          x:34,
          y:18,
          cols:9,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        metricValue : DataSource.of(DataSourceConfig.of(bookcrossingCountDataSource))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("metricDashboardItemAssembler")
    }));
    //dashboardItems.push(bookcrossingCount);

    //INGRESSO BOSCO 3 COUNT
    let bosco3EntranceCountDataSource:DataSourceConfigInterfaceInternal = {
      name: "bosco3EntranceCountDataSource",
      type: "elastic",
      assembler : {
        name:"elasticGetHitsAssembler",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          return of(GenericDataResult.ofSuccess(thiz.getName(), result ? result.aggregations ? result.aggregations["1"].value: 0 : 0));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return DataSourceAssemblerFactory.elasticGetHitsAssembler;
        },
        serialize : () => {
          return JSON.stringify({
            name : DataSourceAssemblerFactory.elasticGetHitsAssembler.name
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:24, multiplier:'hours'
          },

          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 1000,"lt": 3600000}}},
                {"range": {"avgSs": {"gte": -84, "lte": 0}}},
                {"bool": {"should": [{match_phrase: {tags: "INGRESSO_BOSCO_3"}}], "minimum_should_match": 1}}
              ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1": {
              "cardinality": {
                field:"ma_sha1"
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let bosco3Entrance:DashboardItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "8"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Ingresso Bosco 3, Layover" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "(last 24 hours)",
      infoButtonText: "Unique Count of the people passed by Ingresso Bosco 3 in the last 24 hours",
      itemType : "metric",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetric.of(MetricConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0
      })),
      specificItemConfig : {
        matIcon: "directions_walk",
        gridsterConfig : {
          x:34,
          y:18,
          cols:9,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        metricValue : DataSource.of(DataSourceConfig.of(bosco3EntranceCountDataSource))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("metricDashboardItemAssembler")
    }));
    //dashboardItems.push(bosco3Entrance);

    //ORTO BOSCO 2 COUNT
    let giochiBosco1CountDataSource:DataSourceConfigInterfaceInternal = {
      name: "giochiBosco1CountDataSource",
      type: "elastic",
      assembler : {
        name:"elasticGetHitsAssembler",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          return of(GenericDataResult.ofSuccess(thiz.getName(), result ? result.aggregations ? result.aggregations["1"].value: 0 : 0));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return DataSourceAssemblerFactory.elasticGetHitsAssembler;
        },
        serialize : () => {
          return JSON.stringify({
            name : DataSourceAssemblerFactory.elasticGetHitsAssembler.name
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:24, multiplier:'hours'
          },

          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 1000,"lt": 3600000}}},
                {"range": {"avgSs": {"gte": -84, "lte": 0}}},
                {"bool": {"should": [{match_phrase: {tags: "GIOCHI_BOSCO_1"}}], "minimum_should_match": 1}}
              ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1": {
              "cardinality": {
                field:"ma_sha1"
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let giochiBosco1:DashboardItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "9"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Giochi Bosco 1, Layover" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "(last 24 hours)",
      infoButtonText: "Unique Count of the people passed by Orto Bosco 2 area in the last 24 hours",
      itemType : "metric",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetric.of(MetricConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0
      })),
      specificItemConfig : {
        matIcon: "category",
        gridsterConfig : {
          x:34,
          y:18,
          cols:9,
          rows:5,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        metricValue : DataSource.of(DataSourceConfig.of(giochiBosco1CountDataSource))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("metricDashboardItemAssembler")
    }));
    //dashboardItems.push(giochiBosco1);

    // WAKE-UP + BACK HOME on weekdays
    let wakeUpDataSourceAssembler = {
      name:"custom",
      assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
        let elements = [];
        for (let b in result.aggregations["2"].buckets){
          elements.push({
            "time" : result.aggregations["2"].buckets[b].key / 3600,
            "count": result.aggregations["2"].buckets[b]["1"].value
          });
        }

        let start = undefined;
        let stop = undefined;
        let prevValue = undefined;
        let currValue = undefined;
        let currSlope = undefined;
        let mode = "start";
        for (let e in elements){
          if (elements[e].time > 4 && elements[e].time <= 11){
            if (!prevValue) {
              prevValue = elements[e]
            }else{
              prevValue = currValue;
            }
            currValue = elements[e];
            currSlope = (prevValue.time - currValue.time) / (prevValue.count - currValue.count);
            currSlope = isNaN(currSlope)? -1000 : currSlope;
            if (currSlope >=0 && (!start || start.slope > currSlope)) {
              start = elements[e];
              start.slope = currSlope;
            }
          }
        }

        prevValue = undefined;
        currValue = undefined;
        currSlope = undefined;
        for (let e in elements){
          if (elements[e].time >= start.time && elements[e].time <= 11){
            if (!prevValue) {
              prevValue = elements[e]
            }else{
              prevValue = currValue;
            }
            currValue = elements[e];
            currSlope = (prevValue.time - currValue.time) / (prevValue.count - currValue.count);
            currSlope = isNaN(currSlope)? 1000 : currSlope;
            if (currSlope <=0 && (!stop || stop.slope <= currSlope)) {
              stop = elements[e];
              stop.slope = currSlope;
            }
          }
        }

        return of(GenericDataResult.ofSuccess(thiz.getName(), {
          start : start,
          stop : stop
        }));
      },
      rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
        return null;
      },
      serialize : () => {
        return JSON.stringify({
          name : "custom"
        });
      }
    };
    let dailyDistribution:DataSourceConfigInterfaceInternal = {
      name: "totalCountLast24Hours",
      type: "elastic",
      assembler : wakeUpDataSourceAssembler,
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:30, multiplier:'days'
          },

          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 100,"lt": 9999999999}}},
                //{"range": {"avgSs": {"gte": -80, "lte": 0}}},
                {"bool": {"should": [{match_phrase: {adminEntityId: 60}}], "minimum_should_match": 1}},
                {"bool": {"should": [
                      {"match_phrase": {"startDow": "1"}},
                      {"match_phrase": {"startDow": "2"}},
                      {"match_phrase": {"startDow": "3"}},
                      {"match_phrase": {"startDow": "4"}},
                      {"match_phrase": {"startDow": "5"}}
                    ], "minimum_should_match": 1}}
              ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg: {
            "2": {
              "histogram": {
                "field": "endSod",
                "interval": 3600,
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    this.dailyWakeUp =  DashboardItem.of(DashboardItemConfig.of({
      id: "11",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Wake Up & Back Home (Weekdays)",
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "When citizens wake up",
      infoButtonText: "KPI indicating the estimated time range in which inhabitants wake up to leave the buildings during WEEKDAYS. Note: data gathered is relative to the monitored areas",
      itemType : "time-range",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        materialIcon : "wb_sunny",
        gridsterConfig : {
          x:18,
          y:12,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "dailyDistribution" : DataSource.of(DataSourceConfig.of(dailyDistribution))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    //dashboardItems.push(this.dailyWakeUp);

    // WAKE-UP + BACK HOME on weekends
    let dailyDistributionWeekends:DataSourceConfigInterfaceInternal = {
      name: "totalCountLast24Hours",
      type: "elastic",
      assembler : wakeUpDataSourceAssembler,
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:30, multiplier:'days'
          },

          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 100,"lt": 9999999999}}},
                //{"range": {"avgSs": {"gte": -80, "lte": 0}}},
                {"bool": {"should": [{match_phrase: {adminEntityId: 60}}], "minimum_should_match": 1}},
                {"bool": {"should": [
                      {"match_phrase": {"startDow": "6"}},
                      {"match_phrase": {"startDow": "6"}}
                    ], "minimum_should_match": 1}}
              ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg: {
            "2": {
              "histogram": {
                "field": "endSod",
                "interval": 3600,
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    this.weekendsWakeUp =  DashboardItem.of(DashboardItemConfig.of({
      id: "12",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Wake Up & Back Home (Weekend)",
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "When citizens wake up",
      infoButtonText: "KPI indicating the estimated time range in which inhabitants wake up on WEEKENDS. Note: data gathered is relative to the monitored areas",
      itemType : "time-range",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        materialIcon : "wb_sunny",
        gridsterConfig : {
          x:18,
          y:18,
          cols:18,
          rows:6,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "dailyDistributionWeekends" : DataSource.of(DataSourceConfig.of(dailyDistributionWeekends))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    //dashboardItems.push(this.weekendsWakeUp);


    //GREEN AREA USAGE
    let greenHourUsageHoursDataSource:DataSourceConfigInterfaceInternal = {
      name: "greenHourUsageHoursDataSource",
      type: "elastic",
      assembler : {
        name:"elasticGetHitsAssembler",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          return of(GenericDataResult.ofSuccess(thiz.getName(), result ? result.aggregations ? result.aggregations["1"].value/60: 0 : 0));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return DataSourceAssemblerFactory.elasticGetHitsAssembler;
        },
        serialize : () => {
          return JSON.stringify({
            name : DataSourceAssemblerFactory.elasticGetHitsAssembler.name
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:30, multiplier:'days'
          },

          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 1000,"lt": 3600000}}},
                {"range": {"avgSs": {"gte": -84, "lte": 0}}},
                {"bool": {"should": [{match_phrase: {locationId: 57}},{match_phrase: {locationId: 58}},{match_phrase: {locationId: 59}}], "minimum_should_match": 1}}
              ], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg : {
            "1": {
              "sum": {
                "script": {
                  "inline": "doc['visitDuration'].value / 1000 / 60",
                  "lang": "painless"
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let totalGreenAreaUsage:DashboardItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "14"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Green Area Usage" ,
      showHeader : false,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "(Hours)",
      infoButtonText: "",
      itemType : "metric",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewMetric.of(MetricConfig.of({
        decimalPipe : this.decimalPipe,
        minIntegerDigits : 1,
        minFractionDigits : 0,
        maxFractionDigits : 0
      })),
      specificItemConfig : {
        showTopText : true,
        topTextMatIconColorClass: "green-fg",
        topTextTitle: "Green Area Usage",
        topTextMatIcon: "terrain",
        topTextSubtitle: "How many hours green areas have been used in the last 30 days (in hours)",
        matIcon: "access_time",
        gridsterConfig : {
          x:34,
          y:18,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        metricValue : DataSource.of(DataSourceConfig.of(greenHourUsageHoursDataSource))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("metricDashboardItemAssembler")
    }));
    //dashboardItems.push(totalGreenAreaUsage);

    //NIGHT PATROL
    let nightPatrolDataSource:DataSourceConfigInterfaceInternal = {
      name: "nightPatrolDataSource",
      type: "elastic",
      assembler : {
        name:"custom",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          let elements = [];
          for (let b in result.aggregations["2"].buckets){
            let t = result.aggregations["2"].buckets[b].key;
            for (let area in result.aggregations["2"].buckets[b]["3"].buckets) {
              elements.push({
                "Time" : moment.utc(t).format("ddd DD/MM/YYYY hh:mm"),
                "Area": result.aggregations["2"].buckets[b]["3"].buckets[area].key,
                "Count": result.aggregations["2"].buckets[b]["3"].buckets[area]["1"].value,
                "Layover Time (min)": result.aggregations["2"].buckets[b]["3"].buckets[area]["4"].value
              });
            }
          }
          return of(GenericDataResult.ofSuccess(thiz.getName(), elements));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return null;
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : 'time-ago', duration:24, multiplier:'days'
          },

          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 60000,"lt": 9999999999}}},
                {"range": {"avgSs": {"gte": -75, "lte": 0}}},
                {"bool": {"should": [{match_phrase: {adminEntityId: 60}}], "minimum_should_match": 1}}
              ], "must_not": [
                {"match_phrase": {"mpp": {"query": "da:a1:19"}}},
                {"range": {"startSod": {"gte": 14000, "lte": 82800}}}
                ]}},
          agg : {
            "2": {
              "date_histogram": {
                "field": "start",
                "interval": "1h",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 1
              },
              "aggs": {
                "3": {
                  "terms": {
                    "field": "tags",
                    "size": 5,
                    "order": {
                      "1": "desc"
                    }
                  },
                  "aggs": {
                    "1": {
                      "cardinality": {
                        "field": "ma_sha1"
                      }
                    },
                    "4": {
                      "avg": {
                        "script": {
                          "inline": "doc['visitDuration'].value / 1000 / 60",
                          "lang": "painless"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let nightPatrol:DashboardItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "15"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Night Patrol" ,
      showHeader : false,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "(Presenze Uniche)",
      infoButtonText: "",
      itemType : "data-table",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        showTopText : true,
        topTextMatIconColorClass: "orange-fg",
        topTextTitle: "Night Patrol",
        topTextMatIcon: "warning",
        topTextSubtitle: "Which areas have been used 'unconventionally' during night in the last 30 days",
        columns: [
          {
            name:'Time',
            type:'text'
          },
          {
            name:'Area',
            type:'text'
          },
          {
            name:'Count',
            type:'text'
          },
          {
            name:'Layover Time (min)',
            type:'text'
          }],
        gridsterConfig : {
          x:39,
          y:18,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "nightPatrolDataSource" : DataSource.of(DataSourceConfig.of(nightPatrolDataSource))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    //dashboardItems.push(nightPatrol);

    //AREA BREAKDOWN
    let areaBreakdownText:DashboardItem = DashboardItemFactory.ofCloseableCustomRepo({
      id:"16",
      itemType: "markdown-text",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      showHeader : false,
      specificItemConfig : {
        gridsterConfig : {
          x:44,
          y:0,
          cols:36,
          rows:2,
          draggable : {
            enabled : true
          }
        }
      }
    },(thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
        let text:string =
          `
          ##Area Breakdown
        `;
        return of(text);
      });
    //dashboardItems.push(areaBreakdownText);

    //HOURLY DISTRIBUTION PER AREA
    let last30DaysHourlyDistributionDataSource:DataSourceConfigInterfaceInternal = {
      name: "last30DaysHourlyDistributionDataSource",
      type: "elastic",
      assembler : {
        name:"custom",
        assemble : (result:any, thiz:DataSourceInterface):Observable<DataResultInterface<any>> => {
          let buckets = result ?
            result.aggregations ?
              result.aggregations["2"] ?
                result.aggregations["2"].buckets ?
                  result.aggregations["2"].buckets : [] : [] : [] : [];
          let hourlyBuckets = [];
          for (let b in buckets){
            let hb = {
              key : buckets[b].key,
              total: 0
            };
            for (let area in buckets[b]["3"].buckets){
              hb[buckets[b]["3"].buckets[area].key] = buckets[b]["3"].buckets[area]["1"].value,
              hb.total = hb.total + buckets[b]["3"].buckets[area]["1"].value
            }
            hourlyBuckets.push(hb);
          }
          return of(GenericDataResult.ofSuccess(thiz.getName(), hourlyBuckets));
        },
        rehydrate: (serializedAssembler:string):DataSourceAssemblerInterface => {
          return undefined;
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilder.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            strategy : "time-ago",
            timestampField:'start',
            duration: 20,
            multiplier : "days"
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          query: {"bool": {"must": [
                {"range": {"visitDuration": {"gte": 60000,"lt": 700000000}}},
                {"bool": {"should": [{match_phrase: {adminEntityId: 60}}], "minimum_should_match": 1}},
                {"range": {"avgSs": {"gte": -81, "lte": 0}}}], "must_not": [{"match_phrase": {"mpp": {"query": "da:a1:19"}}}]}},
          agg :  {
            "2": {
              "histogram": {
                "script": {
                  "inline": "doc['start'].value.hourOfDay",
                  "lang": "painless"
                },
                "interval": 1,
                "min_doc_count": 1
              },
              "aggs": {
                "3": {
                  "terms": {
                    "field": "tags",
                    "size": 10,
                    "order": {
                      "1": "desc"
                    }
                  },
                  "aggs": {
                    "1": {
                      "cardinality": {
                        "field": "ma_sha1"
                      }
                    }
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let last30DaysHourlyDistribution:DashboardItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "17"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Daily trend per area" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "It shows the average hourly distribution per monitored area.",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "key"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Average count layover presence",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],
          "legend" : {

          },
          "series": [
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "total",
                "categoryX": "key"
              },
              name : "Total",
              "legendSettings": {
                "labelText": " {name}"
              },
              "sequencedInterpolation" : true,
              fillOpacity: 0.6,
              strokeWidth:2
            },
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "INGRESSO_BOSCO_3",
                "categoryX": "key"
              },
              name : "INGRESSO_BOSCO_3",
              "legendSettings": {
                "labelText": " {name}"
              },
              "sequencedInterpolation" : true,
              fillOpacity: 0.6,
              strokeWidth:2,
              fill:"#00b1ff",
              hidden : true
            },
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "BOOKCROSSING_BOSCO_2",
                "categoryX": "key"
              },
              name : "BOOKCROSSING_BOSCO_2",
              "legendSettings": {
                "labelText": " {name}"
              },
              "sequencedInterpolation" : true,
              fillOpacity: 0.6,
              strokeWidth:2,
              fill:"#00ff96",
              hidden : true
            },
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "GIOCHI_BOSCO_1",
                "categoryX": "key"
              },
              name : "GIOCHI_BOSCO_1",
              "legendSettings": {
                "labelText": " {name}"
              },
              "sequencedInterpolation" : true,
              fillOpacity: 0.6,
              strokeWidth:2,
              fill:"#edff00",
              hidden : true
            },
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "ORTO_BOSCO_2",
                "categoryX": "key"
              },
              name : "ORTO_BOSCO_2",
              "legendSettings": {
                "labelText": " {name}"
              },
              "sequencedInterpolation" : true,
              fillOpacity: 0.6,
              strokeWidth:2,
              fill:"#ff7500",
              hidden : true
            },
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "FITNESS_BOSCO_2",
                "categoryX": "key"
              },
              name : "FITNESS_BOSCO_2",
              "legendSettings": {
                "labelText": " {name}"
              },
              "sequencedInterpolation" : true,
              fillOpacity: 0.6,
              strokeWidth: 2,
              fill:"#ff00d8",
              hidden : true
            }]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        showTopText : false,
        topTextTitle: "Daily distribution over all areas",
        topTextSubtitle: "How the different areas are attended (citizens counting)",
        gridsterConfig : {
          x:46,
          y:0,
          cols:36,
          rows:10,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "fillOverPeriod" : DataSource.of(DataSourceConfig.of(last30DaysHourlyDistributionDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    //dashboardItems.push(last30DaysHourlyDistribution);

    //Gender distribution
    let genderDistributionDataSource:DataSourceConfigInterfaceInternal = {
      name: "genderDistributionDataSource",
      type: "elastic",
      assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "custom",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          let data:any[] = [
            {
              text: "female",
              male: 46,
              female: 54,
              gender: 54,
              color: "#ffd0f3"
            },
            {
              text: "male",
              female: 46,
              male: 54,
              gender:46,
              color: "#ffffff"
            },
            {
              text: "",
              male1: 46,
              female1: 54,
              color:"#bee5ff"
            },
            {
              text: "",
              female1: 46,
              male1: 54,
              color:"#ffffff"
            }
          ];
          return of(data);
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return undefined;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let man = "M53.5,476c0,14,6.833,21,20.5,21s20.5-7,20.5-21V287h21v189c0,14,6.834,21,20.5,21 c13.667,0,20.5-7,20.5-21V154h10v116c0,7.334,2.5,12.667,7.5,16s10.167,3.333,15.5,0s8-8.667,8-16V145c0-13.334-4.5-23.667-13.5-31 s-21.5-11-37.5-11h-82c-15.333,0-27.833,3.333-37.5,10s-14.5,17-14.5,31v133c0,6,2.667,10.333,8,13s10.5,2.667,15.5,0s7.5-7,7.5-13 V154h10V476 M61.5,42.5c0,11.667,4.167,21.667,12.5,30S92.333,85,104,85s21.667-4.167,30-12.5S146.5,54,146.5,42 c0-11.335-4.167-21.168-12.5-29.5C125.667,4.167,115.667,0,104,0S82.333,4.167,74,12.5S61.5,30.833,61.5,42.5z"
    let woman = "m 50,5 c -4.203213,0 -7.625,3.421907 -7.625,7.625 0,4.203113 3.421787,7.59375 7.625,7.59375 4.203013,0 7.625,-3.390637 7.625,-7.59375 C 57.625,8.421907 54.203013,5 50,5 z m 0,16.46875 c -4.555535,0.03726 -11.770662,1.119437 -13.375,6.25 -2.240786,7.165384 -4.602504,14.280035 -6.96875,21.40625 -0.137191,0.413183 -0.28125,0.849173 -0.28125,1.3125 0,1.790461 1.459757,3.21875 3.25,3.21875 1.646689,0 2.723272,-1.287892 3.21875,-2.8125 1.865399,-5.741183 5.537681,-17.045889 5.59375,-17.21875 0.06601,-0.203181 0.161459,-0.375 0.375,-0.375 0.213739,0 0.429479,0.168419 0.375,0.375 l -7.875,29.84375 c -0.280147,1.062455 0.931336,2.312122 2.03125,2.3125 l 5.875,0 c -0.01591,6.645986 -0.03125,19.175801 -0.03125,25.6875 0,1.943856 1.578765,3.53125 3.53125,3.53125 1.952486,0 3.522899,-1.578864 3.53125,-3.53125 0,-6.266008 -0.002,-18.926293 0,-25.6875 0.674223,-4e-5 1.357224,5.9e-5 2.03125,0 C 51.26534,72.427912 51.25,84.957847 51.25,91.46875 51.25,93.412606 52.828765,95 54.78125,95 c 1.952287,0 3.5227,-1.578864 3.53125,-3.53125 0,-6.266763 -0.002,-18.926949 0,-25.6875 l 5.34375,0 c 1.099914,0 2.311398,-1.250045 2.03125,-2.3125 L 57.8125,33.625 c -0.05468,-0.206581 0.161459,-0.375 0.375,-0.375 0.213739,0 0.308989,0.171819 0.375,0.375 0.05607,0.172861 3.728351,11.477567 5.59375,17.21875 0.495478,1.524608 1.572062,2.8125 3.21875,2.8125 1.790044,0 3.25,-1.428289 3.25,-3.21875 0,-0.463327 -0.14386,-0.899317 -0.28125,-1.3125 C 67.977703,41.998785 65.788765,34.827706 63.375,27.71875 61.667271,22.689728 54.555534,21.431509 50,21.46875 z";
    let genderDistribution:DashboardItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "18"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Gender Distribution" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "It shows estimated gender distribution of all the people in Cesano Boscone area that have app of JoinTag's network installed",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "SlicedChart",
        chart :{
          "series": [
            {
              "type": "PictorialStackedSeries",
              "maskSprite": {
                "path": "M53.5,476c0,14,6.833,21,20.5,21s20.5-7,20.5-21V287h21v189c0,14,6.834,21,20.5,21 c13.667,0,20.5-7,20.5-21V154h10v116c0,7.334,2.5,12.667,7.5,16s10.167,3.333,15.5,0s8-8.667,8-16V145c0-13.334-4.5-23.667-13.5-31 s-21.5-11-37.5-11h-82c-15.333,0-27.833,3.333-37.5,10s-14.5,17-14.5,31v133c0,6,2.667,10.333,8,13s10.5,2.667,15.5,0s7.5-7,7.5-13 V154h10V476 M61.5,42.5c0,11.667,4.167,21.667,12.5,30S92.333,85,104,85s21.667-4.167,30-12.5S146.5,54,146.5,42 c0-11.335-4.167-21.168-12.5-29.5C125.667,4.167,115.667,0,104,0S82.333,4.167,74,12.5S61.5,30.833,61.5,42.5z"
              },
              dataFields : {
                value: "gender",
                category: "text"
              },
              "sequencedInterpolation" : true,
              alignLabels: true,
              slices:{
                template :{
                  propertyFields:{
                    color:"color",
                    stroke:"color"
                  }
                }
              }
            },
            // {
            //   "type": "PictorialStackedSeries",
            //   "maskSprite": {
            //     "path": "m 50,5 c -4.203213,0 -7.625,3.421907 -7.625,7.625 0,4.203113 3.421787,7.59375 7.625,7.59375 4.203013,0 7.625,-3.390637 7.625,-7.59375 C 57.625,8.421907 54.203013,5 50,5 z m 0,16.46875 c -4.555535,0.03726 -11.770662,1.119437 -13.375,6.25 -2.240786,7.165384 -4.602504,14.280035 -6.96875,21.40625 -0.137191,0.413183 -0.28125,0.849173 -0.28125,1.3125 0,1.790461 1.459757,3.21875 3.25,3.21875 1.646689,0 2.723272,-1.287892 3.21875,-2.8125 1.865399,-5.741183 5.537681,-17.045889 5.59375,-17.21875 0.06601,-0.203181 0.161459,-0.375 0.375,-0.375 0.213739,0 0.429479,0.168419 0.375,0.375 l -7.875,29.84375 c -0.280147,1.062455 0.931336,2.312122 2.03125,2.3125 l 5.875,0 c -0.01591,6.645986 -0.03125,19.175801 -0.03125,25.6875 0,1.943856 1.578765,3.53125 3.53125,3.53125 1.952486,0 3.522899,-1.578864 3.53125,-3.53125 0,-6.266008 -0.002,-18.926293 0,-25.6875 0.674223,-4e-5 1.357224,5.9e-5 2.03125,0 C 51.26534,72.427912 51.25,84.957847 51.25,91.46875 51.25,93.412606 52.828765,95 54.78125,95 c 1.952287,0 3.5227,-1.578864 3.53125,-3.53125 0,-6.266763 -0.002,-18.926949 0,-25.6875 l 5.34375,0 c 1.099914,0 2.311398,-1.250045 2.03125,-2.3125 L 57.8125,33.625 c -0.05468,-0.206581 0.161459,-0.375 0.375,-0.375 0.213739,0 0.308989,0.171819 0.375,0.375 0.05607,0.172861 3.728351,11.477567 5.59375,17.21875 0.495478,1.524608 1.572062,2.8125 3.21875,2.8125 1.790044,0 3.25,-1.428289 3.25,-3.21875 0,-0.463327 -0.14386,-0.899317 -0.28125,-1.3125 C 67.977703,41.998785 65.788765,34.827706 63.375,27.71875 61.667271,22.689728 54.555534,21.431509 50,21.46875 z"
            //   },
            //   dataFields : {
            //     value: "female",
            //     category: "text"
            //   },
            //   "sequencedInterpolation" : true,
            //   alignLabels: true,
            //   slices:{
            //     template :{
            //       propertyFields:{
            //         color:"color",
            //         stroke:"color"
            //       }
            //     }
            //   }
            // }
            ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:46,
          y:0,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "fillOverPeriod" : DataSource.of(DataSourceConfig.of(genderDistributionDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    //dashboardItems.push(genderDistribution);

    //age distribution
    let ageDistributionDataSource:DataSourceConfigInterfaceInternal = {
      name: "ageDistribution",
      type: "elastic",
      assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "custom",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          let data:any[] = [
            {
            "age": "85+",
              "male": -0.3,
              "female": 0.6
          }, {
            "age": "80-54",
              "male": -0.5,
              "female": 0.8
          }, {
            "age": "75-79",
              "male": -0.8,
              "female": 1.0
          }, {
            "age": "70-74",
              "male": -1.1,
              "female": 1.3
          }, {
            "age": "65-69",
              "male": -1.7,
              "female": 1.9
          }, {
            "age": "60-64",
              "male": -2.2,
              "female": 2.5
          }, {
            "age": "55-59",
              "male": -2.8,
              "female": 3.0
          }, {
            "age": "50-54",
              "male": -3.4,
              "female": 3.6
          }, {
            "age": "45-49",
              "male": -4.2,
              "female": 4.1
          }, {
            "age": "40-44",
              "male": -5.2,
              "female": 4.8
          }, {
            "age": "35-39",
              "male": -5.6,
              "female": 5.1
          }, {
            "age": "30-34",
              "male": -5.1,
              "female": 5.1
          }, {
            "age": "25-29",
              "male": -3.8,
              "female": 3.8
          }, {
            "age": "20-24",
              "male": -3.2,
              "female": 3.4
          }, {
            "age": "15-19",
            "male": -2.5,
            "female": 2.8
          }, {
            "age": "10-14",
            "male": -2.2,
            "female": 2.4
          }];
          return of(data);
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return undefined;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "custom"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let ageDistribution:DashboardItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "19"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Age Distribution" ,
      showHeader : true,
      readOnly: !enableUpdates,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "(Counts)",
      infoButtonText: "It shows estimated age distribution of all the people in Cesano Boscone area that have app of JoinTag's network installed",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        // chart : {
        //   numberFormatter :{
        //     numberFormat:"#.#s"
        //   },
        //   "yAxes": [{
        //     "type": "CategoryAxis",
        //     "dataFields": {
        //       "category": "age"
        //     },
        //     "renderer": {
        //       grid:{
        //         template:{
        //           location:0
        //         }
        //       },
        //       inversed :true
        //     }
        //   }],
        //
        //   "xAxes": [{
        //     "type": "ValueAxis",
        //     extraMin:0.1,
        //     extraMax:0.1,
        //     "renderer": {
        //       minGridDistance :40,
        //       ticks : {
        //         template : {
        //           length : 5,
        //           disabled : false,
        //           strokeOpacity: 0.4
        //         }
        //       }
        //     }
        //   }],
        //
        //   "series": [{
        //     "type": "ColumnSeries",
        //     "interpolationDuration" : 500,
        //     "sequencedInterpolation" : true,
        //     "dataFields": {
        //       "valueX": "male",
        //       "categoryY": "age",
        //       clustered : false
        //     }
        //   },
        //     {
        //       "type": "ColumnSeries",
        //       "interpolationDuration" : 500,
        //       "sequencedInterpolation" : true,
        //       "dataFields": {
        //         "valueX": "female",
        //         "categoryY": "age",
        //         clustered : false
        //       }
        //     }]
        // },
        createChart : (chart) => {
          // Use only absolute numbers
          chart.numberFormatter.numberFormat = "#.#s";

          // Create axes
          var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
          categoryAxis.dataFields.category = "age";
          categoryAxis.renderer.grid.template.location = 0;
          categoryAxis.renderer.inversed = true;

          var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
          valueAxis.extraMin = 0.1;
          valueAxis.extraMax = 0.1;
          valueAxis.renderer.minGridDistance = 40;
          valueAxis.renderer.ticks.template.length = 5;
          valueAxis.renderer.ticks.template.disabled = false;
          valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
          valueAxis.renderer.labels.template.adapter.add("text", function(text) {
            return text == "Male" || text == "Female" ? text : text + "%";
          })

          // Create series
          var male = chart.series.push(new am4charts.ColumnSeries());
          male.dataFields.valueX = "male";
          male.dataFields.categoryY = "age";
          male.clustered = false;

          var maleLabel = male.bullets.push(new am4charts.LabelBullet());
          maleLabel.label.text = "{valueX}%";
          maleLabel.label.hideOversized = false;
          maleLabel.label.truncate = false;
          maleLabel.label.horizontalCenter = "right";
          maleLabel.label.dx = -10;

          var female = chart.series.push(new am4charts.ColumnSeries());
          female.dataFields.valueX = "female";
          female.dataFields.categoryY = "age";
          female.clustered = false;

          var femaleLabel = female.bullets.push(new am4charts.LabelBullet());
          femaleLabel.label.text = "{valueX}%";
          femaleLabel.label.hideOversized = false;
          femaleLabel.label.truncate = false;
          femaleLabel.label.horizontalCenter = "left";
          femaleLabel.label.dx = 10;

          var maleRange = valueAxis.axisRanges.create();
          maleRange.value = -10;
          maleRange.endValue = 0;
          maleRange.label.text = "Male";
          maleRange.label.fill = chart.colors.list[0];
          maleRange.label.dy = 20;
          maleRange.label.fontWeight = '600';
          maleRange.grid.strokeOpacity = 1;
          maleRange.grid.stroke = male.stroke;

          var femaleRange = valueAxis.axisRanges.create();
          femaleRange.value = 0;
          femaleRange.endValue = 10;
          femaleRange.label.text = "Female";
          femaleRange.label.fill = chart.colors.list[1];
          femaleRange.label.dy = 20;
          femaleRange.label.fontWeight = '600';
          femaleRange.grid.strokeOpacity = 1;
          femaleRange.grid.stroke = female.stroke;
          return chart;
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:6,
          cols:18,
          rows:12,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "ageDistribution" : DataSource.of(DataSourceConfig.of(ageDistributionDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    //dashboardItems.push(ageDistribution);

    let dashboardCfg = DashboardConfig.of(
      {
        id: "CastelfrancoEmilia2019",
        name: "Castelfranco - Smart City Overview",
        dashboardPersistency : DashboardConsolePersistency.of(),
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        }
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);
    this.dashboard.setFilter(commonFilter.filterValues);
    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData();
          this.showDashboard = true;
        }
      });
  }


}


