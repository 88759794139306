import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

import {locale as english} from './i18n/en';
import {locale as italian} from './i18n/it';
import {DecimalPipe} from '@angular/common';
import {
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatTableModule
} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClient} from "@angular/common/http";
import {
  AuthService, CollectionType, DataSchema, Domain, DomainCatalog, FieldModelTypes, Fields,
  FuseSharedModule, FuseTranslationLoaderService, IottacleFormDialogComponent,
  IottacleFormElementsModule, IottacleFormModule,
  ListTemplateFactoryComponent,
  ViewTemplateFactoryComponent
} from "core-fe-angular";
import {IottacleManyToOneViewFieldConfig} from "core-fe-angular/ext/iottacle-form/elements/elements/many-to-one/iottacle-many-to-one-view-field-config";
import {ListTemplate01Config} from "core-fe-angular/ext/iottacle-form/list/templates/template01-list-template/list-template-01-config";


const routes = [
    {
        path     : 'Client/AdministrativeEntity/e/:entityId',
        component: ViewTemplateFactoryComponent,
        data: {
          domainName:"Client.AdministrativeEntity"
        }
    },
  {
    path     : 'client/administrativeEntity/e/:entityId',
    component: ViewTemplateFactoryComponent,
    data: {
      domainName:"Client.AdministrativeEntity"
    }
  },
    {
      path     : 'Client/AdministrativeEntity/list',
      component: ListTemplateFactoryComponent,
      data: {
        domainName:"Client.AdministrativeEntity"
      }
    },
  {
    path     : 'client/administrativeEntity/list',
    component: ListTemplateFactoryComponent,
    data: {
      domainName:"Client.AdministrativeEntity"
    }
  },
  {
    path     : 'client/administrativeEntity/sky-list',
    component: ListTemplateFactoryComponent,
    data: {
      domainName:"Client.AdministrativeEntity",
      templateConfigName: "skyListTemplate"
    }
  }
];

export enum FieldsName{
  id="id",
  administrativeEntityId="administrativeEntityId",
  name="name",
  active="active",
  analyzeKpi = "analyzeKpi",
  analyzeVisits="analyzeVisits",
  address="address",
  zip="zip",
  description="description",
  type="type",
  tags="tags",
  lat="lat",
  lon="lon",
  windowInactivityGapMs="windowInactivityGapMs",
  deltaTimeFrequencyMs="deltaTimeFrequencyMs",
  deltaTimeForFrequencyMs="deltaTimeForFrequencyMs",
  deltaTimeConsideringNewVisit="deltaTimeConsideringNewVisit",
  externalVisitsQuery="externalVisitsQuery",
  internalVisitsQuery="internalVisitsQuery",
  deviceSetups="deviceSetups",
  deviceSetups_id="id",
  deviceSetups_start="start",
  deviceSetups_end="end",
  deviceSetups_lat="lat",
  deviceSetups_lon="lon",
  deviceSetups_maxPossibleDistanceMt="maxPossibleDistanceMt",
  deviceSetups_gpsSupport="gpsSupport",
  deviceSetups_deviceId="deviceId",
  organization="organization",
  mo_hours="mo_hours",
  tu_hours="tu_hours",
  we_hours="we_hours",
  th_hours="th_hours",
  fr_hours="fr_hours",
  sa_hours="sa_hours",
  su_hours="su_hours",
  locationDetails="locationDetails",
  locationDetails_id="id",
  locationDetails_name="name",
  locationDetails_start="start",
  locationDetails_end="end",
  locationDetails_asInside="asInside",
  locationDetails_polygon="polygon",
  locationDetails_geohashes="geohashes",
  locationDetails_tags="tags",
  correctionFactors="correctionFactors",
  correctionFactors_id="id",
  correctionFactors_start="start",
  correctionFactors_end="end",
  correctionFactors_dailyCorrectionFactor="dailyCorrectionFactor",
  correctionFactors_hourlyCorrectionFactor="hourlyCorrectionFactor",
  correctionFactors_passersByCorrectionFactor="passersByCorrectionFactor",
  globalCorrectionFactors="globalCorrectionFactors",
  globalCorrectionFactors_id="id",
  globalCorrectionFactors_start="start",
  globalCorrectionFactors_end="end",
  globalCorrectionFactors_factor="factor",
  dataCleanupConfig="dataCleanupConfig",
  dataCleanupConfig_start="start",
  dataCleanupConfig_end="end",
  dataCleanupConfig_weeklyMaxMissingDataTolerance="weeklyMaxMissingDataTolerance",
  internalVisitPartitioning="internalVisitPartitioning",
  internalVisitPartitioning_id="id",
  internalVisitPartitioning_name="name",
  internalVisitPartitioning_filter="filter",
  antiCorruptionLayers="antiCorruptionLayers",
  antiCorruptionLayers_id="id",
  antiCorruptionLayers_externalSystemName="externalSystemName",
  antiCorruptionLayers_fieldName="fieldName",
  antiCorruptionLayers_fieldValue="fieldValue",
  physicalInternalVisitsQuery="physicalInternalVisitsQuery",
  physicalExternalVisitsQuery="physicalExternalVisitsQuery",
  extraExternalVisitsQuery="extraExternalVisitsQuery",
  mediumVisitsQuery="mediumVisitsQuery",
  mediumVisitPartitioning="mediumVisitPartitioning",
  considerDevicesAsDirectlyConnectedWithAdminEntity="considerDevicesAsDirectlyConnectedWithAdminEntity"

}

@NgModule({
    providers: [DecimalPipe],
    declarations: [
    ],
    imports     : [
      RouterModule.forChild(routes),
      TranslateModule,
      FuseSharedModule,
      MatIconModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      MatTableModule,
      MatDialogModule,
      ReactiveFormsModule,
      IottacleFormElementsModule,
      IottacleFormModule
    ],
    exports     : [
    ],
    entryComponents: [IottacleFormDialogComponent]
})

export class ClientAdministrativeEntityModule {


  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService:TranslateService,
    private http:HttpClient,private authService:AuthService
  ) {

    //inject Core.Organization domain details into catalog
    this._fuseTranslationLoaderService.loadTranslations(english,italian);

    let schema =  DataSchema.ofConfig(
      {
        fields: [
          Fields.uniqueKey({
            name: FieldsName.id
          }),
          Fields.uniqueKey({
            name:  FieldsName.administrativeEntityId
          }),
          Fields.string({
            name:  FieldsName.name,
            fieldConfig: {
              editFormConfig: {
                syncValidators: [{name: "required"}]
              },
              viewFormConfig: {
               templateName: "two-line-view"
              }
            }
          }),
          Fields.boolean({
            name:  FieldsName.active,
            fieldConfig: {
              editFormConfig: {
                syncValidators: [{name: "required"}]
              },

            }
          }),
          Fields.boolean({
            name:  FieldsName.analyzeKpi,
          }),
          Fields.boolean({
            name:  FieldsName.analyzeVisits,
          }),
          Fields.address({
            name:  FieldsName.address,
            modelType: FieldModelTypes.flat,
            textualAddressFieldName: FieldsName.address,
            zipFieldName:FieldsName.zip,
            latFieldName:FieldsName.lat,
            lonFieldName:FieldsName.lon
          }),
          Fields.simpleText({
            name:  FieldsName.description
          }),
          Fields.enum({
            name:  FieldsName.type,
            enumValues: ["STORE", "EXPERIMENT"]
          }),
          Fields.string({
            name:  FieldsName.tags,
            collection: CollectionType.LIST,
            fieldConfig: {
              editFormConfig: {
                templateName: "chips"
              }
            }
          }),
          Fields.number({
            name:  FieldsName.windowInactivityGapMs,
            fieldConfig: {
              editFormConfig: {
                showTooltipDescriptionInForm: true,
                syncValidators: [{name: "min", param: 60000}, {name: "max", param: (60000 * 60)}],
              }
            }
          }),
          Fields.number({
            order: 1,
            name:  FieldsName.deltaTimeFrequencyMs
          }),
          Fields.number({
            order: 2,
            name:  FieldsName.deltaTimeForFrequencyMs
          }),
          Fields.number({
            name:  FieldsName.deltaTimeConsideringNewVisit
          }),
          Fields.json({
            name:  FieldsName.externalVisitsQuery
          }),

          Fields.json({
            name:  FieldsName.internalVisitsQuery
          }),
          Fields.json({
            name:  FieldsName.physicalInternalVisitsQuery
          }),
          Fields.json({
            name:  FieldsName.physicalExternalVisitsQuery
          }),
          Fields.json({
            name:  FieldsName.extraExternalVisitsQuery
          }),
          Fields.json({
            name:  FieldsName.mediumVisitsQuery
          }),
          Fields.json({
            name:  FieldsName.mediumVisitPartitioning
          }),
          Fields.boolean({
            name:  FieldsName.considerDevicesAsDirectlyConnectedWithAdminEntity
          }),
          Fields.valueObject({
            name:  FieldsName.deviceSetups,
            collection: CollectionType.LIST,
            fieldConfig: {
              editFormConfig: {
                showTooltipDescriptionInForm: true
              }
            },
            subFields: [
              Fields.uniqueKey({
                name:  FieldsName.id
              }),
              Fields.rangeDate({
                name: "start&end",
                startFieldName: FieldsName.deviceSetups_start,
                endFieldName: FieldsName.deviceSetups_end
              }),
              Fields.geoPoint({
                name: "coordinate",
                latFieldName: FieldsName.deviceSetups_lat,
                lonFieldName: FieldsName.deviceSetups_lon,
                fieldConfig: {
                  editFormConfig: {
                    templateName: "map"
                  },
                  showCenterFromOtherFieldName: {
                    referFieldNameToRoot: true,
                    latFieldName: FieldsName.lat,
                    lonFieldName: FieldsName.lon,
                  }
                }
              }),
              Fields.number({
                name:  FieldsName.deviceSetups_maxPossibleDistanceMt
              }),
              Fields.boolean({
                name:  FieldsName.deviceSetups_gpsSupport
              }),
              Fields.manyToOne({
                name:  FieldsName.deviceSetups_deviceId,
                otherDomain: "Device.Device",
                fieldConfig: {
                  editFormConfig: {
                    fieldsToShowOnList: ["id"],
                    fieldsToShowOnSelect: ["id"],
                    orderBy: [{id: 'asc'}]
                  },
                  viewFormConfig:<IottacleManyToOneViewFieldConfig>{
                    fieldsToShow:["id"]
                  }
                },

              }),
            ]
          }),
          Fields.inverseOneToMany({
            name:  FieldsName.organization,
            otherDomain: "Core.Organization",
            fieldConfig: {
              editFormConfig: {
                fieldsToShowOnList: ["name"],
                fieldsToShowOnSelect: ["name"],
                orderBy: [{name: 'asc'}],
                syncValidators: [{name: "inverseOneToManyRequired"}]
              }
            }
          }),


          Fields.openingHours({
            name: "openingHours",
            modelType: FieldModelTypes.flat,
            monFieldName: FieldsName.mo_hours,
            tueFieldName: FieldsName.tu_hours,
            wedFieldName: FieldsName.we_hours,
            thuFieldName: FieldsName.th_hours,
            friFieldName: FieldsName.fr_hours,
            satFieldName: FieldsName.sa_hours,
            sunFieldName: FieldsName.su_hours,
          }),
          Fields.valueObject({
            name:  FieldsName.locationDetails,
            collection: CollectionType.LIST,
            subFields: [
              Fields.uniqueKey({
                name:  FieldsName.locationDetails_id,
              }),
              Fields.string({
                name:  FieldsName.locationDetails_name
              }),
              Fields.rangeDate({
                name: "start&end",
                startFieldName: FieldsName.locationDetails_start,
                endFieldName: FieldsName.locationDetails_end
              }),
              Fields.boolean({
                name:  FieldsName.locationDetails_asInside
              }),
              Fields.geoPolygon({
                name:  FieldsName.locationDetails_polygon,
                //storeGeohashesFieldName:"geohashes",
                fieldConfig: {
                  showCenterFromOtherFieldName: {
                    referFieldNameToRoot: true,
                    latFieldName: FieldsName.lat,
                    lonFieldName: FieldsName.lon,
                  },
                  showOtherPoints: [{
                    referFieldNamesToRoot: true,
                    latFieldName: FieldsName.deviceSetups + "." + FieldsName.deviceSetups_lat,
                    lonFieldName: FieldsName.deviceSetups + "." + FieldsName.deviceSetups_lon,
                  }],
                  showOtherPolygons: [{
                    referFieldNamesToRoot: true,
                    polygonFieldName: FieldsName.locationDetails + "." + FieldsName.locationDetails_polygon
                  }]
                },

              }),
              Fields.string({
                name:  FieldsName.locationDetails_tags,
                collection: CollectionType.LIST
              }),
            ]
          }),
          Fields.valueObject({
            name:  FieldsName.correctionFactors,
            collection: CollectionType.LIST,
            subFields: [
              Fields.uniqueKey({
                name:  FieldsName.correctionFactors_id
              }),
              Fields.rangeDate({
                name: "start&end",
                startFieldName: FieldsName.correctionFactors_start,
                endFieldName: FieldsName.correctionFactors_end
              }),
              Fields.number({
                name:  FieldsName.correctionFactors_dailyCorrectionFactor
              }),
              Fields.number({
                name:  FieldsName.correctionFactors_hourlyCorrectionFactor
              }),
              Fields.number({
                name:  FieldsName.correctionFactors_passersByCorrectionFactor
              }),
            ]
          }),
          Fields.valueObject({
            name:  FieldsName.globalCorrectionFactors,
            collection: CollectionType.LIST,
            subFields: [
              Fields.uniqueKey({
                name:  FieldsName.globalCorrectionFactors_id
              }),
              Fields.rangeDate({
                name: "start&end",
                startFieldName: FieldsName.globalCorrectionFactors_start,
                endFieldName: FieldsName.globalCorrectionFactors_end
              }),
              Fields.number({
                name:  FieldsName.globalCorrectionFactors_factor
              })
            ]
          }),
          Fields.valueObject({
            name:  FieldsName.dataCleanupConfig,
            collection: CollectionType.LIST,
            subFields: [
              Fields.rangeDate({
                name: "start&end",
                startFieldName: FieldsName.dataCleanupConfig_start,
                endFieldName: FieldsName.dataCleanupConfig_end
              }),
              Fields.number({
                name:  FieldsName.dataCleanupConfig_weeklyMaxMissingDataTolerance
              })
            ]
          }),
          Fields.valueObject({
            name:  FieldsName.internalVisitPartitioning,
            collection: CollectionType.LIST,
            subFields: [
              Fields.uniqueKey({
                name:  FieldsName.internalVisitPartitioning_id
              }),
              Fields.string({
                name:  FieldsName.internalVisitPartitioning_name
              }),
              Fields.json({
                name:  FieldsName.internalVisitPartitioning_filter
              })
            ]
          }),
          Fields.valueObject({
            name:  FieldsName.antiCorruptionLayers,
            collection: CollectionType.LIST,
            subFields: [
              Fields.uniqueKey({
                name:  FieldsName.antiCorruptionLayers_id
              }),
              Fields.string({
                name:  FieldsName.antiCorruptionLayers_externalSystemName
              }),
              Fields.string({
                name:  FieldsName.antiCorruptionLayers_fieldName
              }),
              Fields.string({
                name:  FieldsName.antiCorruptionLayers_fieldValue
              })
            ]
          }),
        ],
        defaultAssembleAs: {
          id:true,
          name:true
        }
      }
    );

    let adminEntityDomain = Domain.ofConfig({
      contextName: "Client",
      domainName: "AdministrativeEntity",
     // i18n: [english, italian],
      schema:schema,
      templatesConfig:{
        listTemplate: <ListTemplate01Config>{
          templateName : "list-template-01",
          fieldsToShow: [FieldsName.id, FieldsName.administrativeEntityId, FieldsName.name,
            FieldsName.active, FieldsName.analyzeKpi, FieldsName.analyzeVisits, FieldsName.address,
            FieldsName.description, FieldsName.type, FieldsName.tags,
            FieldsName.windowInactivityGapMs, FieldsName.deltaTimeFrequencyMs,
            FieldsName.deltaTimeForFrequencyMs, FieldsName.deltaTimeConsideringNewVisit, FieldsName.externalVisitsQuery,
            FieldsName.internalVisitsQuery, FieldsName.deviceSetups, FieldsName.organization, 'openingHours',
            FieldsName.locationDetails, FieldsName.correctionFactors,
            FieldsName.globalCorrectionFactors, FieldsName.internalVisitPartitioning, FieldsName.antiCorruptionLayers],
          tableConfig:{
            fieldsToShow: ["id","name"],
            actionColumn:{
              edit:{
                dialog:{
                  fieldsToShow: [FieldsName.id, FieldsName.administrativeEntityId, FieldsName.name,
                    FieldsName.active, FieldsName.analyzeKpi, FieldsName.analyzeVisits, FieldsName.address,
                    FieldsName.description, FieldsName.type, FieldsName.tags,
                    FieldsName.windowInactivityGapMs, FieldsName.deltaTimeFrequencyMs,
                    FieldsName.deltaTimeForFrequencyMs, FieldsName.deltaTimeConsideringNewVisit, FieldsName.externalVisitsQuery,
                    FieldsName.internalVisitsQuery, FieldsName.deviceSetups, FieldsName.organization, 'openingHours',
                    FieldsName.locationDetails, FieldsName.correctionFactors,
                    FieldsName.globalCorrectionFactors, FieldsName.internalVisitPartitioning, FieldsName.antiCorruptionLayers],
                }
              },
              view:true,
              delete:true
            },
            addButton:{
              dialog:{
                fieldsToShow: [FieldsName.id, FieldsName.administrativeEntityId, FieldsName.name,
                  FieldsName.active, FieldsName.analyzeKpi, FieldsName.analyzeVisits, FieldsName.address,
                  FieldsName.description, FieldsName.type, FieldsName.tags,
                  FieldsName.windowInactivityGapMs, FieldsName.deltaTimeFrequencyMs,
                  FieldsName.deltaTimeForFrequencyMs, FieldsName.deltaTimeConsideringNewVisit, FieldsName.externalVisitsQuery,
                  FieldsName.internalVisitsQuery, FieldsName.deviceSetups, FieldsName.organization, 'openingHours',
                  FieldsName.locationDetails, FieldsName.correctionFactors,
                  FieldsName.globalCorrectionFactors, FieldsName.internalVisitPartitioning, FieldsName.antiCorruptionLayers],
              }
            }
          }
        },
        skyListTemplate: <ListTemplate01Config>{
          templateName : "list-template-01",
          fieldsToShow: [FieldsName.id,FieldsName.name, FieldsName.address, FieldsName.tags, FieldsName.dataCleanupConfig],
          tableConfig:{
            fieldsToShow: [FieldsName.name, FieldsName.address, FieldsName.tags],
            actionColumn:{
              edit:{
                dialog:{
                  fieldsToShow: [FieldsName.id,FieldsName.tags, FieldsName.dataCleanupConfig],
                }
              }
            }
          }
        },
        viewTemplate:{
          //template della scheda
          fieldsToShow: [FieldsName.id, FieldsName.administrativeEntityId, FieldsName.name,
            FieldsName.active, FieldsName.analyzeKpi, FieldsName.analyzeVisits, FieldsName.address,
            FieldsName.description, FieldsName.type, FieldsName.tags,
            FieldsName.windowInactivityGapMs, FieldsName.deltaTimeFrequencyMs,
            FieldsName.deltaTimeForFrequencyMs, FieldsName.deltaTimeConsideringNewVisit, FieldsName.externalVisitsQuery,
            FieldsName.internalVisitsQuery, FieldsName.deviceSetups, FieldsName.organization, 'openingHours',
            FieldsName.locationDetails, FieldsName.correctionFactors,
            FieldsName.globalCorrectionFactors, FieldsName.internalVisitPartitioning, FieldsName.antiCorruptionLayers]
        }
      },
      http:http,
      auth:authService
    });
    DomainCatalog.addDomain(adminEntityDomain)

  }
}
