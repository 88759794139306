import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild,ElementRef} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import { DataSource as AngularDataSource } from '@angular/cdk/collections';

import {
  DashboardItem
} from "iottacle-dashboard";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DataSourceAssemblerCatalog, fuseAnimations} from 'core-fe-angular';
import {AuthService, FuseConfigService, FuseTranslationLoaderService} from "core-fe-angular";
import {HttpClient} from "@angular/common/http";
import {
  Entity,
  EntityModelCatalog,
} from "dashboard-fe-angular";
import {GetAllVehicleDTO} from "../../../../../../context/recco/vehicle/vehicle";
import moment from 'moment';



@Component({
  selector: 'recco-vehicle-list',
  templateUrl: './recco-vehicle-list.component.html',
  styleUrls: ['./recco-vehicle-list.component.scss'],
  animations : fuseAnimations
})
export class ReccoVehicleListComponent implements OnInit, OnDestroy{

  itemsList:DashboardItem;
  entityModel:Entity;
  dataChangeSubscription:Subscription;

  rowData:GetAllVehicleDTO[];
  vehicleList:any;
  //vehicleList : MatTableDataSource<any>;

  allEntitiesSubscription:Subscription;
  tableColumns = [ 'name','startText','endText','durationText','drivers','trailers','locationName','otherBeaconsCount' ];

  //tableColumns = [ 'name','startText','endText','durationText','drivers','trailers','locationName','otherBeaconsCount','status','vehicleType'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter')filter: ElementRef;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private httpClient:HttpClient,
    private changeDetectorRef:ChangeDetectorRef,
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

  }

  goToVehiclePage(row:GetAllVehicleDTO){
    this.router.navigate(["/man/Recco/Vehicle/" + row.id], {queryParamsHandling: 'merge' }); //preserve
  }

  ngOnDestroy(): void {
    if (this.itemsList){ this.itemsList.destroy()}
    if (this.allEntitiesSubscription) {this.allEntitiesSubscription.unsubscribe()}
  }

  ngOnInit(): void {
    this.vehicleList = {};
    this.vehicleList.onListChanged = new BehaviorSubject({});
    this.entityModel = EntityModelCatalog.getEntityModel("Recco", "Vehicle");
    this.allEntitiesSubscription = this.entityModel.getAllEntities()
      .subscribe(
        (res) =>{
          this.rowData = res.map((e) => {
            if (e.lastJob) {
              let momentStart = moment(e.lastJob.start);
              e.lastJob.startText = momentStart.format("DD/MM/YYYY hh:mm");
              let momentEnd = moment(e.lastJob.end);
              e.lastJob.endText = momentEnd.format("DD/MM/YYYY hh:mm");
              e.lastJob.durationText = momentStart.diff(momentEnd, "hours") + "h";
            }
            return e;
          });
          this.vehicleList.onListChanged.next(res);
          this.vehicleList.dataSource = new VehicleDataSource(this.vehicleList);
        },
        (err) =>{
          //make better error management
          //inform user
          console.error(err);
        }
      );



    // DataSourceAssemblerCatalog.init();
    // this.activatedRoute.params
    //   .subscribe(
    //     (p) => {
    //       let params: any = p;
    //       let stateParams:any = {
    //         contextName: params['contextName'],
    //         domainName: params['domainName'],
    //         entityId : params['entityId']
    //       };
    //       this.entityModel = EntityModelCatalog.getEntityModel("Recco", "Vehicle");
    //
    //       this.itemsList = undefined;
    //       this.changeDetectorRef.detectChanges();
    //       if (this.dataChangeSubscription){
    //         this.dataChangeSubscription.unsubscribe();
    //         this.dataChangeSubscription = undefined;
    //       }
    //       let entityDataSource:DataSourceConfigInterfaceInternal = {
    //         name: "entityDataSource",
    //         type: "elastic",
    //         assembler: DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
    //         repo : {
    //           getName : () => "custom",
    //           getChannelType : () => ChannelType.CLOSEABLE,
    //           invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
    //             return this.entityModel.getAllEntities();
    //           },
    //           rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
    //             return undefined;
    //           },
    //           serialize : ():string => {
    //             return JSON.stringify({
    //               name : "custom"
    //             });
    //           }
    //         },
    //         filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
    //         specificDataSourceConfig: {},
    //       };
    //       let tempItem =  DashboardItem.of(DashboardItemConfig.of({
    //         id: "2"  ,
    //         observedLevel : ObservedLevel.ITEM_VIEW,
    //         name : "Entity List",
    //         autoUpdateMs : 0,
    //         itemType : "data-table",
    //         style:{
    //           background : "white",
    //           'border-color' : "#00000042",
    //           'border-radius': "8px"
    //         },
    //         viewConfig : DashboardItemViewGeneric.empty(),
    //         specificItemConfig : {
    //           columns: SchemaFieldDefinitionToColumnsForDataTableDashboardItem.translate(this.entityModel,
    //             undefined),
    //           defaultNavigateToElementCallback : (item:DashboardItem, entityId:any) =>{
    //             let entityModel:Entity = this.entityModel;
    //             let path = "/man/" + entityModel.contextName + "/" + entityModel.domainName + "/" + entityId;
    //             this.router.navigate([path], {queryParams: { }, queryParamsHandling: 'merge' }); //preserve
    //           }
    //         },
    //         dataSources : {
    //           "1" : DataSource.of(DataSourceConfig.of(entityDataSource))
    //         },
    //         assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    //       }));
    //       this.dataChangeSubscription = tempItem.observeDataChange()
    //           .subscribe(
    //               (res) =>{
    //                 this.itemsList = tempItem;
    //                 this.changeDetectorRef.detectChanges();
    //               }
    //           );
    //       tempItem.reloadData();
    //
    //     },
    //     (err) => {
    //       //do something clever to inform user
    //       console.error(err);
    //     }
    //   );
  }

  ngAfterViewInit() {
    this.vehicleList.paginator = this.paginator;
    this.vehicleList.sort = this.sort;
  }

    applyFilter(filterValue: string) {
      this.vehicleList.filter = filterValue.trim().toLowerCase();

      if (this.vehicleList.paginator) {
        this.vehicleList.paginator.firstPage();
      }
    }

}


export class VehicleDataSource extends AngularDataSource<GetAllVehicleDTO>
{
  /**
   * Constructor
   *
   * @param data
   */
  constructor(private data)
  {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<GetAllVehicleDTO[]>
  {
    return this.data.onListChanged;
  }

  /**
   * Disconnect
   */
  disconnect(): void
  {
  }
}
