import {TimeDirection} from "./neo4j-funnel-dashboard-item-repository";

export class ElasticStepQueryElementFactory{
  public static elasticQueryElements: {
    [queryElementName: string]: (value:any) => any
  } = {};

  public static of(key:string, value:any):StepQueryElement{
    if (this.elasticQueryElements[key]){
      return this.elasticQueryElements[key](value);
    }
  }

}

export interface StepQueryElement {
  buildNeo4jQuery(step:number):string;
}

export interface TermLevelQuery extends StepQueryElement{

}

export interface TextQuery extends StepQueryElement{

}

export class MatchPhraseQueryElement implements TextQuery{
  private readonly matchPhraseFieldName:string;
  private constructor(private match_phrase : {
    [fieldName:string]:{
      query:string|number,
      _indexName? : string,
    }
  }) {
    this.matchPhraseFieldName = Object.keys(match_phrase)[0];
    this.match_phrase[this.matchPhraseFieldName]._indexName = this.match_phrase[this.matchPhraseFieldName]._indexName ? this.match_phrase[this.matchPhraseFieldName]._indexName : "visit";
  }

  public static of(match_phrase : {
    [fieldName:string]:{
      query:string|number,
      _indexName? : string,
    }|string
  }){
    let matchPhraseFieldName:string = Object.keys(match_phrase)[0];
    let new_match_phrase:any;

    if (typeof match_phrase[matchPhraseFieldName] == "string"){
      let str:string = "" + match_phrase[matchPhraseFieldName];
      new_match_phrase = {};
      new_match_phrase[matchPhraseFieldName] = {
        query : str
      }
    }else if (typeof match_phrase[matchPhraseFieldName] == "number"){
      let str = Number(match_phrase[matchPhraseFieldName]);
      new_match_phrase = {};
      new_match_phrase[matchPhraseFieldName] = {
        query : str
      }
    }else{
      new_match_phrase = match_phrase;
    }
    return new MatchPhraseQueryElement(new_match_phrase);
  }


  buildNeo4jQuery(step:number): string {
    let q:any = this.match_phrase[this.matchPhraseFieldName].query;
    let apices = typeof q == "number" ? "" : "'";
    return ` ${this.match_phrase[this.matchPhraseFieldName]._indexName}_${step}.${this.matchPhraseFieldName} = ${apices}${q}${apices} `;
  }

}

export class RangeLevelStepQueryElement implements TermLevelQuery{
  private readonly rangeFieldName:string;
  private constructor(private range : {
    [fieldName:string]:{
      _indexName?:string,
      gt ?: number,
      gte?: number,
      lt ?: number,
      lte?: number
    }
  }) {
    this.rangeFieldName = Object.keys(range)[0];
    let indexName:string = this.range[this.rangeFieldName]._indexName;
    this.range[this.rangeFieldName]._indexName = indexName ? indexName :  "visit";
  }

  public static of(range : {
    [fieldName:string]:{
      _indexName?:string,
      gt ?: number,
      gte?: number,
      lt ?: number,
      lte?: number
    }
  }){
    return new RangeLevelStepQueryElement(range);
  }

  buildNeo4jQuery(step:number): string {
    let firstPart = "";
    let secondPart = "";
    if (this.range[this.rangeFieldName].gt || this.range[this.rangeFieldName].gte){
      let value = this.range[this.rangeFieldName].gt ? this.range[this.rangeFieldName].gt : this.range[this.rangeFieldName].gte;
      firstPart = ` ${this.range[this.rangeFieldName]._indexName}_${step}.${this.rangeFieldName} >${this.range[this.rangeFieldName].gte? '=':''} ${value} `;
    }
    if (this.range[this.rangeFieldName].lt || this.range[this.rangeFieldName].lte){
      let value = this.range[this.rangeFieldName].lt ? this.range[this.rangeFieldName].lt : this.range[this.rangeFieldName].lte;
      secondPart = ` ${firstPart? ' AND ' : ''} ${this.range[this.rangeFieldName]._indexName}_${step}.${this.rangeFieldName} <${this.range[this.rangeFieldName].lte? '=':''} ${value} `;
    }

    return firstPart + secondPart;
  }

}


export class IottacleStepTimeLevelStepQueryElement implements TermLevelQuery{
  private readonly rangeFieldName:string;

  private constructor(private step_time : {
    [fieldName:string]:{
      _indexName?:string,
      direction:TimeDirection,
      relativeToStep:number,
      untilMs?:number
    }
  }) {
    this.rangeFieldName = Object.keys(step_time)[0];
    let indexName:string = this.step_time[this.rangeFieldName]._indexName;
    this.step_time[this.rangeFieldName]._indexName = indexName ? indexName :  "visit";
  }

  public static of(step_time : {
    [fieldName:string]:{
      _indexName?:string,
      direction:TimeDirection,
      relativeToStep:number,
      untilMs?:number
    }
  }){
    return new IottacleStepTimeLevelStepQueryElement(step_time);
  }

  buildNeo4jQuery(step:number): string {
    let firstPart = "";
    let secondPart = "";
    let direction = this.step_time[this.rangeFieldName].direction === TimeDirection.after? " > " : " < ";
    let oppositeDirection = this.step_time[this.rangeFieldName].direction === TimeDirection.after? " < " : " > ";
    let oppositeSign = this.step_time[this.rangeFieldName].direction === TimeDirection.after? " + " : " - ";

    firstPart = ` ${this.step_time[this.rangeFieldName]._indexName}_${step}.${this.rangeFieldName} ${direction} step_${this.step_time[this.rangeFieldName].relativeToStep}.visit.${this.rangeFieldName} `;
    if (this.step_time[this.rangeFieldName].untilMs){
      secondPart = ` AND ${this.step_time[this.rangeFieldName]._indexName}_${step}.${this.rangeFieldName} ${oppositeDirection} step_${this.step_time[this.rangeFieldName].relativeToStep}.visit.${this.rangeFieldName} ${oppositeSign} ${this.step_time[this.rangeFieldName].untilMs}`;
    }

    return firstPart + secondPart;
  }

}

export interface CompoundStepQueryElement extends StepQueryElement{

}

export class BoolStepQueryElement implements CompoundStepQueryElement{

  private constructor(
    private must?:StepQueryElement[],
    private must_not?:StepQueryElement[],
    private should?:StepQueryElement[]
  ){

  }

  public static of(value:{
    must?:StepQueryElement[],
    must_not?:StepQueryElement[],
    should?:StepQueryElement[]
  }):BoolStepQueryElement{
    let newMust;
    if (value.must){
      newMust = [];
      for (let m in value.must){
        let key = Object.keys(value.must[m])[0];
        let stepQ:StepQueryElement = ElasticStepQueryElementFactory.of(key, value.must[m][key]);
        if (stepQ) {
          let newVal = {};
          newVal[key] = stepQ;
          newMust.push(newVal);
        }
      }
    }

    let newMustNot;
    if (value.must_not){
      newMustNot = [];
      for (let m in value.must_not){
        let key = Object.keys(value.must_not[m])[0];
        let stepQ:StepQueryElement = ElasticStepQueryElementFactory.of(key, value.must_not[m][key]);
        if (stepQ) {
          let newVal = {};
          newVal[key] = stepQ;
          newMustNot.push(newVal);
        }
      }
    }

    let newShould;
    if (value.should){
      newShould = [];
      for (let m in value.should){
        let key = Object.keys(value.should[m])[0];
        let stepQ:StepQueryElement = ElasticStepQueryElementFactory.of(key, value.should[m][key]);
        if (stepQ) {
          let newVal = {};
          newVal[key] = stepQ;
          newShould.push(newVal);
        }
      }
    }
    return new BoolStepQueryElement(newMust, newMustNot, newShould);
  }


  buildNeo4jQuery(step:number): string {
    let mustQuery = "";
    if (this.must && this.must.length > 0){
      mustQuery = mustQuery + " ( true ";
      for(let m in this.must){
        let must = this.must[m];
        let k = Object.keys(must)[0];
        let q = must[k].buildNeo4jQuery(step);
        mustQuery = mustQuery + " AND " + q;
      }
      mustQuery = mustQuery + " ) ";
    }

    let mustNotQuery = "";
    if (this.must_not && this.must_not.length > 0){
      mustNotQuery = mustNotQuery + " ( true ";
      for(let m in this.must_not){
        let must_not = this.must_not[m];
        let k = Object.keys(must_not)[0];
        let q = must_not[k].buildNeo4jQuery(step);
        mustNotQuery = mustNotQuery + " AND " + q;
      }
      mustNotQuery = mustNotQuery + " ) ";
    }

    let shouldQuery = "";
    if (this.should && this.should.length > 0){
      shouldQuery = shouldQuery + " ( false ";
      for(let m in this.should){
        let should = this.should[m];
        let k = Object.keys(should)[0];
        let q = should[k].buildNeo4jQuery(step);
        shouldQuery = shouldQuery + " OR " + q;
      }
      shouldQuery = shouldQuery + " ) ";
    }

    let ret = "";
    if (mustQuery){
      ret = ret + " ( " + mustQuery + " ) ";
    }

    if (mustNotQuery){
      ret = ret + (ret? " AND NOT (" : " NOT (") + mustNotQuery + ") ";
    }

    if (shouldQuery){
      ret = ret + (ret? " AND ( " : "( ") + shouldQuery + ") ";
    }

    return ret;
  }
}
