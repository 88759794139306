import {
  DataSourceAssemblerFactory,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory
} from "iottacle-dashboard";
import {Observable, of, throwError} from "rxjs";
import {flatMap} from "rxjs/operators";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {
  FilterInterface} from "iottacle-ts-models";
import {IottacleCalendarService} from "calendar-fe-angular";



////////////////////////
//// DATA SOURCES //////
///////////////////////
export class EventDataSource implements DataSourceConfigInterfaceInternal{

    constructor(private http, private authService) {
    }
    name= "eventDataSource";
    type= "event";
    assembler =DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name);
    repo = {
      getName : () => "customRepo",
      getChannelType : () => ChannelType.CLOSEABLE,
      invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
        //TODO make call to event repo
        try {
          let start = filter.timeFilter.outcome.timeRangeBoundaries.from;
          let end = filter.timeFilter.outcome.timeRangeBoundaries.to;
          let storeIdes = [];
          //console.log(filter.administrativeEntityFilter.selectedMultiple.length);
          if (filter.administrativeEntityFilter.selectedMultiple.length) {
            (filter.administrativeEntityFilter.selectedMultiple).forEach(function (value) {
              storeIdes.push({
                "match_phrase": {
                  "adminEntityIds": {
                    "query": value.administrativeEntityDetails.administrativeEntityId
                  }
                }
              });
            });
          }
          if (filter.customFilters && filter.customFilters.tags){
            for (let tag of filter.customFilters.tags){
              let admins:any[] = filter.administrativeEntityFilter.allAdministrativeEntities.filter(el => el.administrativeEntityDetails.tags && el.administrativeEntityDetails.tags.indexOf(tag)>=0);
              admins.forEach(function (value) {
                storeIdes.push({
                  "match_phrase": {
                    "adminEntityIds": {
                      "query": value.administrativeEntityDetails.administrativeEntityId
                    }
                  }
                });
              })
            }
          }

          let calendarService: IottacleCalendarService = new IottacleCalendarService(this.http, this.authService.loginService);
          return calendarService.fetchEventByTime({
            "start_time": start.valueOf(),
            "end_time": end.valueOf(),
            'storeIdReq': storeIdes
          })
            .pipe(flatMap((result) => {
              return of({
                events: result['hits']
              });
            }));
        }catch(e){
          return throwError(e);
        }
      },
      rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
        return null;
      },
      serialize : ():string => {
        return JSON.stringify({
          name : "customRepo"
        });
      }
    };
    filter = FilterBuilderFactory.getFilter("idempotentFilterBuilder");
    specificDataSourceConfig= {}
}
