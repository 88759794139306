import {Component, OnDestroy, OnInit} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {locale as english} from '../i18n/en';
import {locale as italian} from '../i18n/it';
import {AuthService, FuseConfigService, FuseTranslationLoaderService} from "core-fe-angular";

@Component({
  selector: 'recco-overview',
  templateUrl: './recco-overview.component.html',
  styleUrls: ['./recco-overview.component.scss']
})
export class ReccoOverviewComponent implements OnInit, OnDestroy{


  constructor(
    private auth:AuthService,
    private decimalPipe: DecimalPipe,
    private http:HttpClient,
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
  ){

    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };
  }



  ngOnDestroy(): void {

  }

  ngOnInit(): void {

  }


}


