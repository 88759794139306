export const locale = {
  lang: 'it',
  data: {
      nav: {
          store: {
            'group-name' : "Store",
            overview : "Overview",
            detailGroup : 'Dettagli negozio',
            detail : {
              detail : 'Dettagli',
              'return-rate' : "Tasso di Ritorno",
              opportunity : "Opportunità",
              accesses : "Accessi"
            },
            one:{
              overview:{
                dashboardItems: {
                  occupancyOverHodAndDow: {
                    name: "Visite odierne rispetto alla media",
                    infoButtonText: "Questo grafico mostra la distribuzione media delle visite nella giornata corrente e l'attuale affollamento delle aree monitorate. La distribuzione media è ponderata su un intervallo temporale di 30 giorni",
                    chart: {
                      yAxes: {
                        0: {
                          title: {
                            text: "Occupazione Media"
                          }
                        }
                      },
                      series: {
                        0: {
                          columns: {
                            tooltipText: "Conteggio orario medio\nOrario: {categoryX}\nConteggio Medio: {valueY}"
                          }
                        }
                      },
                      occupancyMessages:{
                        low: "Meno affollato del solito",
                        med: "Affollato come al solito",
                        high: "Più affollato del solito",
                      }
                    }
                  }
                }
              }
            }
          }
      },
    widgets: {
        metricDelta:{
          "378debcb-65b1-44a0-a3a2-888860045f08":{
            name: "Visite",
            subtitle:"Variazione rispetto al periodo di confronto",
            infoButtonText:"Numero di visite totale nel periodo selezionato. Il periodo di confronto corrisponde al periodo immediatamente precedente del filtro selezionato. Ad esempio: se la selezione è 'ultimi 30 giorni' il periodo di confronto sarà 'da 60 a 30 giorni prima'. ",
            viewConfig:{
              "postfix":"visite",
              "variationSign": "%"
            }
          },
          "378debcb-65b1-44a0-a3a2-888860045f08P":{
            name: "Potenziali",
            subtitle:"Variazione rispetto al periodo di confronto",
            infoButtonText:"Numero dei potenziali totali nel periodo selezionato. Il periodo di confronto corrisponde al periodo immediatamente precedente del filtro selezionato. Ad esempio: se la selezione è 'ultimi 30 giorni' il periodo di confronto sarà 'da 60 a 30 giorni prima'. ",
            viewConfig:{
              "postfix":"potenziali",
              "variationSign": "%"
            }
          },
          "21ca3123-9097-401a-8e15-760e6b41a0c2":{//per overview page
            name: "Visite dell'ultima settimana",
            subtitle:"Variazione rispetto alla settimana precedente",
            infoButtonText:"Numero di visite totale nel periodo selezionato. Il periodo di confronto corrisponde al periodo immediatamente precedente del filtro selezionato. Ad esempio: se la selezione è 'ultimi 30 giorni' il periodo di confronto sarà 'da 60 a 30 giorni prima'. ",
            viewConfig:{
              "postfix":"visite",
              "variationSign": "%"
            }
          },
          "b62d7ba0-f183-4296-abb2-33569b5ed634":{
            name: "Visitatori",
            subtitle:"Variazione rispetto al periodo di confronto",
            infoButtonText:"Numero di visitatori unici registrati. Il periodo di confronto corrisponde al periodo immediatamente precedente del filtro selezionato. Ad esempio: se la selezione è 'ultimi 30 giorni' il periodo di confronto sarà 'da 60 a 30 giorni prima'. ",
            viewConfig:{
              "postfix":"visitatori",
              "variationSign": ""
            }
          },
          "c4831b24-95f9-483c-b924-eb4aacd6660d":{
            name: "Giorni dall'ultima visita",
            subtitle:"Variazione rispetto al periodo di confronto",
            infoButtonText:"Numero di giorni (medi) trascorsi dall'ultima visita, basata sui visitatori esterni",
            viewConfig:{
              "postfix":"giorni",
              "variationSign": ""
            }
          },
          "8b880152-6185-4629-b6f7-90ab09d0653b":{
            name: "Visite Medie Settimanali",
            subtitle:"Variazione rispetto periodo di confronto",
            infoButtonText:"Numero di visite medio per settimana calcolato su tutti i visitatori che hanno effettuato almeno 2 visite all'esterno",
            viewConfig:{
              "postfix":"volte",
              "variationSign": ""
            }
          }
        },
        textMetric:{
          "9e60366d-786e-4773-8c27-ebcb317fc873":{
            name: "Durata delle visite",
            subtitle:"Variazione rispetto al periodo di confronto",
            infoButtonText:"Questo dato mostra il range di durata delle visite al PDV. Il periodo di confronto corrisponde al periodo immediatamente precedente del filtro selezionato. Ad esempio: se la selezione è 'ultimi 30 giorni' il periodo di confronto sarà 'da 60 a 30 giorni prima'. ",
            viewConfig:{
              "postfix":"min"
            }
          },
          "1b585688-8f7b-46a2-9fe0-7521b8e1f2d4":{
            name: "Durata delle visite nell'ultima settimana",
            subtitle:"Settimana precedente",
            infoButtonText:"Questo dato mostra il range di durata delle visite al PDV. Il periodo di confronto corrisponde al periodo immediatamente precedente del filtro selezionato. Ad esempio: se la selezione è 'ultimi 30 giorni' il periodo di confronto sarà 'da 60 a 30 giorni prima'. ",
            viewConfig:{
              "postfix":"min"
            }
          }
        },
        htmlText: {
          "e5cded76-ab98-4c9e-9614-fca074c0fc86": {
            repoText: "# Fedeltà \n In questa sezione puoi valutare le performance dei tuoi stores dal punto di vista di fedeltà del cliente (retention, tasso di abbandono, nuovi/ritorno...)"
          },
          "6f608737-3e9b-41b5-bd98-2e1a0547d84d":{
            repoText:"# Clienti riacquisiti \n In questa sezione viene mostrata l'analisi dei clienti 'riacquisiti'"
          },
          "59959e40-ce48-411d-8db5-f86a617b1975":{
            repoText:"# Frequency \n In questa sezione viene mostrata l'analisi della Frequency delle visite dei clienti"
          },
          //Opportunity
          "acd623f7-9ce8-495f-8c28-c5aa5de006d3":{
            repoText:"# Opportunity \n In questa sezione vengono mostrati i KPI legati al passaggio esterno e la relazione con le visite interne"
          }
        },
        chart : {
          //details
          "0a05efc5-28d6-4143-a5f6-77e449653f18":{
            name : "Visite",
            infoButtonText: "Il grafico mostra il conteggio giornaliero delle visite nel periodo selezionato",
            chart:{
              yAxes:{
                "0":{
                  title:{
                    text: "#Visite"
                  }
                }
              }
            }
          },
          "b33616fa-cbe7-4251-afed-632bcaf593fc":{
            name : "Distribuzione visite per giorno della settimana",
            infoButtonText: "Il grafico mostra la distribuzione delle visite nei giorni della settimana. Valori ponderati nel periodo selezionato",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "% Visite sul totale"
                  }
                }
              }
            }
          },
          "da9e3eef-9965-4488-8510-4f59ebd6a4d5":{
            name : "Distribuzione visite per giorno e ora",
            infoButtonText: "La heatmap mostra il conteggio delle visite per giorno e ora del PDV relativamente al periodo selezionato.",
          },
          "ecff0235-ac1b-495b-82c4-4b169c52b1c0":{
            name : "Distribuzione visite per ora del giorno",
            infoButtonText: "Il grafico mostra la distribuzione delle visite nelle ore del giorno. Valori ponderati nel periodo selezionato",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "% Visite sul totale"
                  }
                }
              }
            }
          },
          //duration
          "0cd8b36d-5eff-4d64-91b6-c6d86bc743eb":{
            name : "Distribuzione durata delle visite",
            infoButtonText: "Il grafico mostra la distribuzione delle visite in range di 10 minuti, fino ad un massimo di 1 ora, le visite maggiori di 1 ora sono aggregate nell'ultimo slot",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "% visite sul totale"
                  }
                }
              }
            }
          },
          "3c077094-6641-41fe-a039-bb489420f9a9":{
            name : "Indice di esposizione del brand",
            /* infoButtonText: "Il grafico mostra la distribuzione delle visite in range di 10 minuti, fino ad un massimo di 1 ora, le visite maggiori di 1 ora sono aggregate nell'ultimo slot",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "% visite sul totale"
                  }
                }
              }
            } */
          },
          "99501a85-4196-4f36-a498-a230906a4218":{
            name : "Durata delle visite nel tempo",
            infoButtonText: "Il grafico mostra il range di durata (min-max) media delle visite nel tempo.",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Durata (min)"
                  }
                }
              }
            }
          },
          "a7e86485-9227-4bfb-86c3-25ecd32e21b7":{
            name : "Durata delle visite nei giorni della settimana",
            infoButtonText: "Il grafico mostra il range di durata (min-max) media delle visite nei giorni della settimana",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Durata (min)"
                  }
                }
              }
            }
          },
          "2d53afe1-d17d-44d4-8493-d0d3e87cddf1":{
            name : "Durata delle visite nelle ore del giorno",
            infoButtonText: "Il grafico mostra il range di durata (min-max) media delle visite nelle ore del giorno",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Durata (min)"
                  }
                }
              }
            }
          },
          //loyalty
          "51fcd450-aeeb-41f3-8aab-04c230692abe" : {
            name : "Tasso di abbandono",
            infoButtonText: "Il tasso di abbandono considera il numero di abbandoni giornalieri, ponderando un 'abbandono' come un individuo che non effettua una visita entro {{churnPeriod}} giorni",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Tasso abbandono"
                  }
                }
              }
            }
          },
          "ff689e3a-6015-4a67-af40-c31852f02acf": {
            name : "Ritorni (% su totale)",
            infoButtonText: "Il grafico mostra la distribuzione settimanale delle visite frequenti sul totale delle visite",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Ritorni (% su totale)"
                  }
                }
              }
            }
          },
          "132d5d0d-4d1e-4705-b61f-40643e286d5c":{
            name : "Ritorni (% su totale)",
            infoButtonText: "Il grafico mostra la distribuzione giornaliera delle visite frequenti sul totale delle visite",
            chart:{
              yAxes:{
                "0": {
                  title:{
                    text: "Ritorni (% su totale)"
                  }
                }
              }
            }
          },
          "7d54cb44-81e9-4976-a239-f00718505b41":{
            name : "Ritorni (% su totale)",
            infoButtonText: "Il grafico mostra la distribuzione nel tempo delle visite frequenti sul totale delle visite",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Ritorni (% su totale)"
                  }
                }
              }
            }
          },
          "e9d66492-7e70-4f17-b508-a74f207bf527":{
            name : "Distribuzione percentuale giorni dall'ultima visita",
            infoButtonText: "Il grafico mostra dopo quandi giorni i visitatori persi sono ritornati",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "# di visitatori (%)"
                  }
                }
              }
            }
          },
          "606d38b5-30d3-4833-bb7b-76ff6ed98ea3":{
            name: "Distribuzione Frequency per giorni della settimana",
            infoButtonText: "Il grafico mostra la distribuzione media settimanale della frequency dei frequentatori del luogo",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Frequency (media)"
                  }
                }
              }
            }
          },
          "fc46c930-912f-4fd6-bba5-ba28a47e83ba":{
            name: "Distribuzione Frequency per giorni della settimana",
            infoButtonText: "Il grafico mostra la distribuzione media giornaliera della frequency dei frequentatori del luogo",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Frequency (media)"
                  }
                }
              }
            }
          },
          "bec411b6-ed76-4142-af72-69b584dc6303":{
            name: "Visitatori nuovi e di ritorno",
            infoButtonText: "Visitatori nuovi e di ritorno sulla base delle impostazioni per il calcolo del ritorno. Un visitatore è definito nuovo se il suo device non è stato registrato per un intervallo di tempo definito per la specifica installazione."
          },
          "90c55c68-0bd4-4fe5-96e1-31e8ef01b93b":{
            name: "Visitatori di ritorno",
            infoButtonText: "Il grafico mostra il rapporto tra visite di ritorno e visite totali su scala temporale ( ret / (new+ret) )",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "Ratio (%)"
                  }
                }
              }
            }
          },
          "c1653188-e43b-4389-bbb7-9618de2c5e7f":{
            name: "Visitatori Riacquisiti (heatmap giorno/ora)",
            infoButtonText: "Il grafico mostra la distribuzione delle visite dei visitatori riacquisiti in relazione al giorno della settimana e ora del giorno. Un visitatore 'riacquisito' è colui che NON ha frequentato il PDV entro i giorni definiti per lo specifico PDV (es 30gg)",
          },
          "f377352a-2d23-4c74-a327-e0f63b2cd315": {
            name: "Frequenza media",
            infoButtonText: "Il grafico mostra frequenza media di tutte le visite intercorse nel periodo selezionato.",
            chart: {
              yAxes: {
                "0": {
                  title: {
                    text: "Frequenza Media"
                  }
                }
              }
            }
          },
          "89e569a7-3e34-4808-a2bb-cc8c80c0b820": {
            name: "Recency media",
            infoButtonText: "Il grafico mostra recency media di tutte le visite intercorse nel periodo selezionato.",
            chart: {
              yAxes: {
                "0": {
                  title: {
                    text: "Recency Media"
                  }
                }
              }
            }
          },
        //Opportunity
        "29f6da3f-368c-40d1-8bc2-8e8abff2ff4c":{
          name: "Pubblico potenziale intercettato",
          infoButtonText: "Il grafico mostra il rapporto tra visite e passanti esterni. Rappresenta il concetto di 'Opportunità' del punto vendita, un indice più alto significa una capacità maggiore di 'attirare' i passanti esterni all'interno del punto vendita.",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "Pubblico intercettato (%)"
                }
              }
            }
          }
        },
        "7376b762-390a-4d4c-a357-22649c367ac2":{
          name: "Pubblico potenziale intercettato nei giorni della settimana",
          infoButtonText: "Il grafico mostra il rapporto tra visite e passanti esterni distribuito lungo i giorni della settimana. Rappresenta il concetto di 'Opportunità' del punto vendita, un indice più alto significa una capacità maggiore di 'attirare' i passanti esterni all'interno del punto vendita.",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "Pubblico intercettato (%)"
                }
              }
            }
          }
        },
        "6161e72e-0a09-4865-8ad3-ab780f654245":{
          name: "Pubblico potenziale intercettato nelle ore del giorno",
          infoButtonText: "Il grafico mostra il rapporto tra visite e passanti esterni distribuito lungo le ore del giorno. Rappresenta il concetto di 'Opportunità' del punto vendita, un indice più alto significa una capacità maggiore di 'attirare' i passanti esterni all'interno del punto vendita.",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "Pubblico intercettato (%)"
                }
              }
            }
          }
        },
        "8afc6fb0-536f-4b86-a1ab-dcc0a1040d6e":{
          name: "Pubblico potenziale (passanti esterni)",
          infoButtonText: "Il grafico mostra il conteggio dei passanti esterni al punto vendita",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "# Passanti"
                }
              }
            }
          }
        },
        //proximity
        "0a05efc5-28d6-4143-a5f6-77e449653f18P":{
          name : "Potenziale",
          infoButtonText: "Il grafico mostra il conteggio giornaliero dei potenziali nel periodo selezionato",
          chart:{
            yAxes:{
              "0":{
                title:{
                  text: "#Potenziale"
                }
              }
            }
          }
        },
        "da9e3eef-9965-4488-8510-4f59ebd6a4d5P":{
          name : "Distribuzione dei potenziali per giorno e ora",
          infoButtonText: "La heatmap mostra il conteggio dei potenziali per giorno e ora del PDV relativamente al periodo selezionato.",
        },
        "b33616fa-cbe7-4251-afed-632bcaf593fcP":{
          name : "Distribuzione dei potenziali per giorno della settimana",
          infoButtonText: "Il grafico mostra la distribuzione dei potenziali nei giorni della settimana. Valori ponderati nel periodo selezionato",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "% Potenziali sul totale"
                }
              }
            }
          }
        },
        "ecff0235-ac1b-495b-82c4-4b169c52b1c0P":{
          name : "Distribuzione dei potenziali per ora del giorno",
          infoButtonText: "Il grafico mostra la distribuzione dei potenziali nelle ore del giorno. Valori ponderati nel periodo selezionato",
          chart:{
            yAxes:{
              "0": {
                title: {
                  text: "% Potenziali sul totale"
                }
              }
            }
          }
        },
          //SKY INSIGHTS 00
          "73dc1edc-3a2c-4625-8bd3-c7385e07d677":{
            name : "Media Visite giornaliere per settimane",
            infoButtonText: "Il grafico mostra la media giornaliera delle visite al PDV con aggregazione settimanale. Valori ponderati nel periodo selezionato",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "#Visite medie"
                  }
                }
              }
            }
          },
          "eb3cb899-530d-4aef-bd7c-fbc18bc39d75":{
            name : "Media Passaggi esterni giornalieri per settimane",
            infoButtonText: "Il grafico mostra la media giornaliera dei passaggi esterni al PDV con aggregazione settimanale. Valori ponderati nel periodo selezionato",
            chart:{
              yAxes:{
                "0": {
                  title: {
                    text: "# Passaggi esterni medi"
                  }
                }
              }
            }
          }
      },
      map: {
        hours: "ore"
      },
      "skyTable":{
        "5ac01bec-98b5-4ba4-9061-8ae4231e0183":{
          name : "Tabella riassuntiva",
          infoButtonText: "La tabella mostra i dati riassuntivi settimanali, di quarter e annuali. Tutti i conteggi sono aggregati con MEDIE",
        }
      }
    }
  }
};

