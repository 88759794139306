export const locale = {
    lang: 'it',
    data: {
        'nav': {
            'property': {
              overview : "Overview"
            }

        }
    }
};
