export const locale = {
    lang: 'en',
    data: {
      'nav': {
        'property': {
          overview : "Overview"
        }

      }
    }
};
