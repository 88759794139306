import {
  DashboardItem, DashboardItemAssemblerFactory, DashboardItemConfig, DashboardItemViewGeneric, DataSource,
  DataSourceAssemblerFactory, DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory, ObservedLevel
} from "iottacle-dashboard";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {FilterInterface} from "iottacle-ts-models";
import {Observable, of} from "rxjs";

import _ from "lodash";


export class StoreUtils{

  public static getTransparentBadgeStyle():any{
    return {
      background : "none",
      'border-color' : "#ffffff00",
      'border-radius': "2px",
      headerFxLayoutAlign : "start center"
    };
  }

  public static getWhiteBadgeStyle():any{
    return {
      background : "white",
      'border-color' : "#D0D6D9",
      'border-radius': "2px",
      headerFxLayoutAlign : "start center"
    };
  }

  public static getTitleDashboardItem(config:{
    content:string,
    readOnly:boolean,
    gridsterConfig:any
  }):DashboardItem{
    let gridsterConfig = _.defaultsDeep(config.gridsterConfig,{
      x:0,
      y:0,
      cols:36,
      rows:2,
      dragEnabled: !config.readOnly,
      resizeEnabled: ! config.readOnly
    });
    let titleDataSource:DataSourceConfigInterfaceInternal = {
      name: "custom",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          let t = config.content;
          return of(t);
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let titleItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "569a9e6a-9142-43e6-a757-39953be334a1",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "" ,
      showHeader : false,
      readOnly : config.readOnly,
      showName: false,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "markdown-text",
      style:StoreUtils.getTransparentBadgeStyle(),
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        gridsterConfig : gridsterConfig
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(titleDataSource))
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("idempotentFirstItemDashboardItemAssembler")
    }));
    return titleItem;
  }
}
