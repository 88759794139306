import {
  Entity, FieldToUse,
  IottacleHttpClientProxy, QueryFactory,
  SchemaFieldDetailFactory,
  SchemaFieldDetailsInterface, SchemaFieldTypesNames
} from "dashboard-fe-angular";
import moment from "moment";
import {flatMap, map} from "rxjs/operators";
import {forkJoin, Observable, Observer, of} from "rxjs";
import {LoginV3, MyOrganization, Polygon} from "iottacle-ts-models";

export class JobDetailsDTO{
  job : JobDTO;
  geopoints: JobGeopointsDTO[];
  fieldDetails?: {
    fieldOwnerName:string,
    fieldName:string,
    polygon: Polygon;
  }
}

export class JobGeopointsDTO {
  altitude : number;
  tm : number; //timestamp of this geopoint (when the geopoint occurred)
  speed : number;
  location : {
    lat : number;
    lon : number;
  };
}

export class JobDTO{
  id:string;
  isLive:boolean;
  start:number; //timestamp of job start time
  startText:string; // a string representation of start time (eg DD/MM/YYYY hh:mm)
  end:number; //timestamp of job end time
  endText:string; // a string representation of end time (eg DD/MM/YYYY hh:mm)
  durationText:string; // a tring containing the hours/minutes that this job lasts
  location: { // the last point registered for this job
    "lon": number,
    "lat": number
  };
  adminEntityId:number; //the administrativeEntityId related with this Job (eg id of the vehicle that created this job)
  organizationId:string;//the organizationID related with this Job (eg id of the company that owns the vehicle that created this job)
  dd: number; // the deviceId that was installed into the vehicle that generated the raw data
  locationName: string; // the name of the last location (if any) on which the vehicle went
  locationTags: string[]; // the tags related with the above location (if any)
  locAdminEntityId: number; // the administrativeId of the location (if any). It represents the field details of the field owner
  locAdminEntityName: string; // the administrativeName of the location (if any). It represents the field details of the field owner
  locOrganizationId: string; // the organizationId of the location (if any). It represents the owner of the field
  locOrganizationName: string; // the organizationName of the location (if any). It represents the owner of the field
  trailers?:{ //list of traiers that have been attached to the vehicle
    start:number, //timestamp of the start time of this trailer has been attached to
    startText:string,
    end:number,//timestamp of the end time of this trailer has been attached to
    endText:string,
    durationText:string,
    trailerName:string,
    trailerId:string
  }[];
  drivers?:{//list of drivers available in this job
    start:number, //timestamp of the start time of this driver in this job
    startText:string,
    end:number,//timestamp of the end time of this driver in this job
    endText:string,
    durationText:string,
    driverName:string,
    driverId:string
  }[];
  otherBeacons?:{//list of other beacons that have not been recognized (that are not (yet) into the database
    start:number,
    startText:string,
    end:number,
    endText:string,
    durationText:string,
    uuid:string,
    mi:number,
    ma:number
  }[]
}

export class Job extends Entity {

  entityName: string;
  fieldDetails: { [p: string]: SchemaFieldDetailsInterface };
  entityValidators: any[];
  contextName: string;
  domainName: string;

  constructor(
    private httpClient: IottacleHttpClientProxy,
    private loginService:LoginV3
  ) {
    super();

    //DOMAIN DETAILS
    this.contextName = "Recco";
    this.domainName = "Job";

    //NAME
    this.entityName = "Job";

    //SCHEMA
    this.fieldDetails = {};
    this.fieldDetails.id = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.id, "id");
    this.fieldDetails.count = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "count");
    this.fieldDetails.withLocation = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.boolean, "withLocation");
    this.fieldDetails.start = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.datetime, {
      name: "start",
      momentGenerator: (fieldValue) => {
        return moment.utc(fieldValue);
      }
    });
    this.fieldDetails.end = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.datetime, {
      name: "end",
      momentGenerator: (fieldValue) => {
        return moment.utc(fieldValue);
      }
    });
    this.fieldDetails.dd = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "dd");
    this.fieldDetails.locationId = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "locationId");
    this.fieldDetails.locationName = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "locationName");
    this.fieldDetails.locationTags = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, {
      name :"locationTags",
      isArray : true
    });
    this.fieldDetails.adminEntityId = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "adminEntityId");
    this.fieldDetails.adminEntityName = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "adminEntityName");
    this.fieldDetails.organizationId = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "organizationId");
    this.fieldDetails.organizationName = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "organizationName");
    this.fieldDetails.location = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.geoPoint, "location");

    this.fieldDetails.lastDriverName = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "lastDriverName");
    this.fieldDetails.lastTrailerName = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "lastTrailerName");
    this.fieldDetails.otherDriversCount = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "otherDriversCount");
    this.fieldDetails.otherTrailersCount = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "otherTrailersCount");
    this.fieldDetails.otherBeaconsCount = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "otherBeaconsCount");
    this.fieldDetails.b = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.valueObject, "b"); //beacon data

    //ENTITY VALIDATORS
    this.entityValidators = [];


    //ENTITY BOILERPLATE STUFF
    this.saveSchema = FieldToUse.filterEntityFieldsAsList(this.fieldDetails, undefined);
    this.readSchemaOfServer = this.saveSchema;
    this.updateSchema = this.saveSchema;


    this.getByDeviceId = (deviceId) => {
      const url = "analyticsapi/executor/query";
      return this.httpClient.post(url, {
        query : "EXECUTE_QUERY",
        filter : {
          returnComplete:true,
          index : "recco_jobs_02",
          query: {
            "size" :100,
            "sort" : [
              { "start" : {"order" : "desc"}}
            ],
            "query": {
              "bool": {
                "must": [{
                  "match_phrase": {
                    "dd": {
                      "query": deviceId
                    }
                  }
                }],
                "filter": [],
                "should": [],
                "must_not": []
              }
            }
          }
        }
      })
        .pipe(map((results) =>{
          return {
            body:{
              hits:results.hits.hits.map((e) => e._source)
            }
          }
        }));
    };

    this.getEntitiesByDeviceId = (deviceId) => {
      deviceId = Number(deviceId);
      return this.getAllEntities(null, deviceId, null, this.getByDeviceId);
    };

    this.getTimelineJobsPerVehicleId = (entityId) => {
      entityId = Number(entityId);
      const url = "analyticsapi/executor/query";
      return this.httpClient.post(url, {
        query : "EXECUTE_QUERY",
        filter : {
          returnComplete:true,
          index : "recco_jobs_02",
          query: {
            "size" :10,
            "sort": [
              {
                "end": {
                  "order": "desc"
                }
              }
            ],
            "query": {
              "bool": {
                "must": [{
                  term : {
                    adminEntityId : entityId
                  }
                }],
                "filter": [],
                "should": [],
                "must_not": []
              }
            }
          }
        }
      })
        .pipe(
          map((results) =>{
            let jobs = results.hits.hits.map((j) => j._source);
            for (let j in jobs) {
              let drivers: any[] = [];
              let trailers: any[] = [];
              let otherBeacons: any[] = [];
              let startMoment = moment(jobs[j].start);
              jobs[j].startText = startMoment.format("DD/MM/YYYY HH:mm");
              let endMoment = moment(jobs[j].end);
              jobs[j].endText = endMoment.format("DD/MM/YYYY HH:mm");
              jobs[j].durationText = endMoment.diff(startMoment, "hours") + "h";
              for (let b in jobs[j].b) {
                let startMoment = moment(jobs[j].b[b].start);
                jobs[j].b[b].startText = startMoment.format("DD/MM/YYYY HH:mm");
                let endMoment = moment(jobs[j].b[b].end);
                jobs[j].b[b].endText = endMoment.format("DD/MM/YYYY HH:mm");
                jobs[j].b[b].durationText = endMoment.diff(startMoment, "hours") + "h";
                switch (jobs[j].b[b].type) {
                  case  'DRIVER':
                    drivers.push(jobs[j].b[b]);
                    break;
                  case  'TRAILER':
                    trailers.push(jobs[j].b[b]);
                    break;
                  default:
                    otherBeacons.push(jobs[j].b[b]);
                    break;
                }
              }
              jobs[j].drivers = drivers;
              jobs[j].trailers = trailers;
              jobs[j].otherBeacons=otherBeacons;
              jobs[j].isLive = jobs[j].end > moment().valueOf() - 120000;
            }
            return jobs;
          })
        );
    };

    this.getLastJobPerVehicleId = (entityId):Observable<JobDetailsDTO> => {
      entityId = Number(entityId);
      const url = "analyticsapi/executor/query";
      return this.httpClient.post(url, {
        query : "EXECUTE_QUERY",
        filter : {
          returnComplete:true,
          index : "recco_jobs_02",
          query: {
            "size" :1,
            "sort": [
              {
                "end": {
                  "order": "desc"
                }
              }
            ],
            "query": {
              "bool": {
                "must": [{
                  term : {
                    adminEntityId : entityId
                  }
                }],
                "filter": [],
                "should": [],
                "must_not": []
              }
            }
          }
        }
      })
        .pipe(
          map((results) =>{
            let jobs = results.hits.hits.map((j) => j._source);
            for (let j in jobs) {
              let drivers: any[] = [];
              let trailers: any[] = [];
              let otherBeacons: any[] = [];
              let startMoment = moment(jobs[j].start);
              jobs[j].startText = startMoment.format("DD/MM/YYYY HH:mm");
              let endMoment = moment(jobs[j].end);
              jobs[j].endText = endMoment.format("DD/MM/YYYY HH:mm");
              jobs[j].durationText = endMoment.diff(startMoment, "hours") + "h";
              for (let b in jobs[j].b) {
                let startMoment = moment(jobs[j].b[b].start);
                jobs[j].b[b].startText = startMoment.format("DD/MM/YYYY HH:mm");
                let endMoment = moment(jobs[j].b[b].end);
                jobs[j].b[b].endText = endMoment.format("DD/MM/YYYY HH:mm");
                jobs[j].b[b].durationText = endMoment.diff(startMoment, "hours") + "h";
                switch (jobs[j].b[b].type) {
                  case  'DRIVER':
                    drivers.push(jobs[j].b[b]);
                    break;
                  case  'TRAILER':
                    trailers.push(jobs[j].b[b]);
                    break;
                  default:
                    otherBeacons.push(jobs[j].b[b]);
                    break;
                }
              }
              jobs[j].drivers = drivers;
              jobs[j].trailers = trailers;
              jobs[j].otherBeacons=otherBeacons;
              jobs[j].isLive = jobs[j].end > moment().valueOf() - 120000;
            }
            return jobs[0];
          }),
          flatMap((lastJob:JobDTO) => {
            let q = [];
            q.push(of(lastJob));
            q.push(this.getJobGeopointDetails(lastJob.dd,lastJob.start, lastJob.end));
            return forkJoin(q);
          }),
          flatMap((res) => {
            let q = [];
            const concat = (x,y) => x.concat(y);

            const flatMap = (f,xs) => xs.map(f).reduce(concat, []);

            let fields = flatMap((e:any) => {
                return e.getAdministrativeEntityDetails().locations.map((l:any) => {
                  l.organizationName = e.organizationName;
                  l.organizationId = e.organizationId;
                  l.adminEntityId = e.id;
                  l.adminEntityName = e.name;
                  return l;
                });
              },
              flatMap( (o:MyOrganization) => o.getMyAdministrativeEntities().map((e:any) => {
                e.organizationName = o.getName();
                e.organizationId = o.organizationId;
                return e
              }), this.loginService.getCurrentLoggedUser().getMyOrganizations().filter((o:MyOrganization) => {
                return o.organizationType === "RECCO_FIELD_OWNER";
              }))
            );
            q.push(of(res[0]));
            q.push(of(res[1]));
            q.push(of(fields));
            return forkJoin(q);
          }),
          map((res) =>{
            return {
              job : res[0],
              geopoints: res[1],
              fieldDetails : res[2]
            }
          })
        );
    };

    this.getJobById = (entityId):Observable<JobDetailsDTO> => {
      entityId = Number(entityId);
      const url = "analyticsapi/executor/query";
      return this.httpClient.post(url, {
        query : "EXECUTE_QUERY",
        filter : {
          returnComplete:true,
          index : "recco_jobs_02",
          query: {
            "size" :1,
            "query": {
              "bool": {
                "must": [{
                  term : {
                    id : entityId
                  }
                }],
                "filter": [],
                "should": [],
                "must_not": []
              }
            }
          }
        }
      })
        .pipe(
          map((results) =>{
            let jobs = results.hits.hits.map((j) => j._source);
            for (let j in jobs) {
              let drivers: any[] = [];
              let trailers: any[] = [];
              let otherBeacons: any[] = [];
              let startMoment = moment(jobs[j].start);
              jobs[j].startText = startMoment.format("DD/MM/YYYY HH:mm");
              let endMoment = moment(jobs[j].end);
              jobs[j].endText = endMoment.format("DD/MM/YYYY HH:mm");
              jobs[j].durationText = endMoment.diff(startMoment, "hours") + "h";
              for (let b in jobs[j].b) {
                let startMoment = moment(jobs[j].b[b].start);
                jobs[j].b[b].startText = startMoment.format("DD/MM/YYYY HH:mm");
                let endMoment = moment(jobs[j].b[b].end);
                jobs[j].b[b].endText = endMoment.format("DD/MM/YYYY HH:mm");
                jobs[j].b[b].durationText = endMoment.diff(startMoment, "hours") + "h";
                switch (jobs[j].b[b].type) {
                  case  'DRIVER':
                    drivers.push(jobs[j].b[b]);
                    break;
                  case  'TRAILER':
                    trailers.push(jobs[j].b[b]);
                    break;
                  default:
                    otherBeacons.push(jobs[j].b[b]);
                    break;
                }
              }
              jobs[j].drivers = drivers;
              jobs[j].trailers = trailers;
              jobs[j].otherBeacons=otherBeacons;
            }
            return jobs[0];
          }),
          flatMap((lastJob:JobDTO) => {
            let q = [];
            q.push(of(lastJob));
            q.push(this.getJobGeopointDetails(lastJob.dd,lastJob.start, lastJob.end));
            return forkJoin(q);
          }),
          flatMap((res) => {
            let q = [];
            const concat = (x,y) => x.concat(y);

            const flatMap = (f,xs) => xs.map(f).reduce(concat, []);

            let fields = flatMap((e:any) => {
              return e.getAdministrativeEntityDetails().locations.map((l:any) => {
                l.organizationName = e.organizationName;
                l.organizationId = e.organizationId;
                l.adminEntityId = e.id;
                l.adminEntityName = e.name;
                return l;
              });
              },
              flatMap( (o:MyOrganization) => o.getMyAdministrativeEntities().map((e:any) => {
                e.organizationName = o.getName();
                e.organizationId = o.organizationId;
                return e
              }), this.loginService.getCurrentLoggedUser().getMyOrganizations().filter((o:MyOrganization) => {
                return o.organizationType === "RECCO_FIELD_OWNER";
              }))
            );


            q.push(of(res[0]));
            q.push(of(res[1]));
            q.push(of(fields));
            return forkJoin(q);
          }),
          map((res) =>{
            return {
              job : res[0],
              geopoints: res[1],
              fieldDetails : res[2]
            }
          })
        );
    };

    /**
     *
     * @param deviceId: the deviceId eg: 153
     * @param start: timestamp format (millis since the epoch)
     * @param end: timestamp format (millis since the epoch)
     */
    this.getJobGeopointDetails = (deviceId, start, end):Observable<JobGeopointsDTO[]> => {
      deviceId = Number(deviceId);
      start = Number(start);
      end = Number(end);
      const url = "analyticsapi/executor/query";
      return this.httpClient.post(url, {
        query : "EXECUTE_QUERY",
        filter : {
          returnComplete:true,
          index : "gps_data",
          query: {
            "_source": ["tm", "location", "speed", "altitude"],
            "size" : 100,
            "query": {
              "function_score": {
                "query": {
                  "bool": {
                    "must": [
                      {
                        "term" : {
                          "dd": deviceId
                        }
                      },
                      {
                        "range": {
                          "tm": {
                            "gte": start,
                            "lte": end,
                            "format": "epoch_millis"
                          }
                        }
                      }
                    ],
                    "filter": [],
                    "should": [],
                    "must_not": []
                  }
                },
                "functions": [
                  {
                    "random_score": {
                      "seed": 10,
                      "field": "_seq_no"
                    }
                  }
                ]
              }
            }
          }
        }
      })
        .pipe(
          map((results) =>{
            let res:JobGeopointsDTO[] = results.hits.hits.map((j) => j._source);
            return res;
          })
        );

    }
  }

  getFieldValue(fieldName: string, entity: any):any {
    return entity[fieldName];
  }

}

/**
 * EXAMPLE OF JOB OBJECT
 *
 * "job": {
    "count": 20,
    "withLocation": true,
    "id": "153_1561477280608",
    "start": 1561477280608,
    "end": 1561478211362,
    "dd": 153,
    "locationId": 91,
    "locationName": "DEPO02",
    "locationTags": [
      "DEPO02"
    ],
    "adminEntityId": 72,
    "adminEntityName": "Recco_test",
    "organizationId": "e9c392cf-267a-4c9a-9f4a-bbf9675ff87e",
    "organiztaionName": "Recco",
    "location": {
      "lon": 10.253343333,
      "lat": 45.11945
    },
    "b": [
      {
        "start": 1561478162000,
        "end": 1561478272000,
        "dataPts": 6,
        "uuid": "EBEFD083-70A2-47C8-9837-E7B5634DF524",
        "ma": 12955,
        "mi": 30184,
        "ss": -53,
        "location": {
          "lon": 10.253343333,
          "lat": 45.11945
        },
        "type": "TRAILER",
        "organizationId": "e9c392cf-267a-4c9a-9f4a-bbf9675ff87e",
        "organizationName": "Recco",
        "trailerId": "93938d99-c031-47e0-8740-507ca70cca22",
        "trailerName": "Aratro"
      }
    ]
  },
 *
 *
 */
