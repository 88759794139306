import {DataSourceConfigInterfaceInternal, DataSourceAssemblerFactory} from "iottacle-dashboard";
import {GenericRepo} from "./iottacle-generic-repo";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {GenericHalfIdempotentFilter} from "./iottacle-generic-half-idempotent-filter";

export class GenericDataSource implements DataSourceConfigInterfaceInternal{
    constructor(private http:any, private path:string, private currentUser:any) {}
    name = "genericDataSource";
    type = "elastic";
    assembler = DataSourceAssemblerFactory.idempotentDataSourceAssembler;
    repo = new GenericRepo(this.http, this.path);
    filter = new GenericHalfIdempotentFilter(this.currentUser)
    //filter = this.filterConfig.getFilter();
    specificDataSourceConfig= {};
}