export const locale = {
    lang: 'it',
    data: {
      Client: {
        AdministrativeEntity: {
          title: "Administrative Entity",
          fields : {
            name: {
              _name: "Nome"
            },
            active : {
              _name: "Attivo"
            },
            analyzeKpi: {
              _name: "Analizza KPI"
            },
            analyzeVisits: {
              _name: "Analizza Visits"
            },
            description: {
              _name: "Descrizione"
            },
            address:{
              _name: "Indirizzo"
            },
            type: {
              _name: "Tipo"
            },
            tags: {
              _name: "Tags"
            },
            windowInactivityGapMs: {
              _name: "windowInactivityGapMs",
              _tooltipDescriptionInForm: "Sono i millisecondi di tolleranza (nel ricevere un nuovo probe per la visita) dopo i quali una nuova visita verrà creata"
            },
            id:{
              _name: "id"
            },
            administrativeEntityId:{
              _name: "Admin entity Id"
            },
            deltaTimeFrequencyMs:{
              _name: "Delta Time Frequency (ms)"
            },
            deltaTimeForFrequencyMs:{
              _name: "Delta Time For Frequency (ms)"
            },
            deltaTimeConsideringNewVisit:{
              _name: "Delta Time Considering New Visit (ms)"
            },
            externalVisitsQuery:{
              _name: "External Visits Query"
            },
            internalVisitsQuery:{
              _name: "Internal Visits Query"
            },
            dataCleanupConfig:{
              _name: "Data Cleanup Configurations",
              "start&end":{
                _name: "Duration",
                start:{
                  _name: "From"
                },
                end:{
                  _name: "To"
                }
              },
              weeklyMaxMissingDataTolerance:{
                _name: "Weekly max missing data tolerance (0..1)"
              }
            },
            deviceSetups:{
              _name: "Device Setups",
              id :{
                _name:"id"
              },
              "start&end":{
                _name: "Durata",
                start:{
                  _name: "Inizio"
                },
                end:{
                  _name: "Fine"
                }
              },
              coordinate:{
                _name: "Coordinate"
              },
              maxPossibleDistanceMt:{
                _name: "Distanza Massima ammissibile (mt)"
              },
              gpsSupport:{
                _name: "Supporto GPS"
              },
              deviceId:{
                _name: "Device"
              }
            },
            organization:{
              _name: "Organization"
            },
            openingHours:{
              _name: "Opening Hours"
            },
            locationDetails:{
              _name: "Location Details",
              id:{
                _name: "Id"
              },
              name:{
                _name: "Nome"
              },
              "start&end":{
                _name: "Durata",
                start:{
                  _name: "Inizio"
                },
                end:{
                  _name: "Fine"
                }
              },
              asInside:{
                _name: "Considera come Area Interna"
              },
              polygon:{
                _name: "Polygono"
              },
              tags:{
                _name: "Tags"
              }
            },
            correctionFactors:{
              _name: "Correction Factors",
              "start&end":{
                _name: "",
                start:{
                  _name: "Inizio"
                },
                end:{
                  _name: "Fine"
                }
              },
              id:{
                _name: "Id"
              },
              dailyCorrectionFactor:{
                _name: "Fattore di Correzione Giornaliera"
              },
              hourlyCorrectionFactor:{
                _name: "Fattore di Correzione Oraria"
              },
              passersByCorrectionFactor:{
                _name: "Fattore di Correzione Passanti"
              }
            },
            globalCorrectionFactors:{
              _name: "Global Correction Factors",
              "start&end":{
                _name: "Durata",
                start:{
                  _name: "Inizio"
                },
                end:{
                  _name: "Fine"
                }
              },
              id:{
                _name: "Id"
              },
              factor:{
                _name: "Fattore"
              }
            },
            internalVisitPartitioning:{
              _name: "Internal Visit Partitioning",
              id:{
                _name: "Id"
              },
              name:{
                _name: "Nome"
              },
              filter:{
                _name: "Filtro"
              }
            },
            antiCorruptionLayers:{
              _name: "Anti-corruption Layers",
              id:{
                _name: "Id"
              },
              externalSystemName:{
                _name: "Nome Sistema Esterno"
              },
              fieldName:{
                _name: "Nome Campo"
              },
              fieldValue:{
                _name: "Valore Campo"
              }
            },
          }
        }
      }
    }
};
