import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from "rxjs";

import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DashboardItemConfig,
  DashboardItemConfigInterface,
  DashboardItemFactory,
  DashboardItemStatus,
  DashboardItemViewGeneric,
  DashboardItemViewMetricDelta,
  DataResultInterface,
  DataSource,
  DataSourceAssemblerFactory,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  DataSourceInterface,
  FilterBuilderFactory,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory,
} from "iottacle-dashboard";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialog, MatTableDataSource} from "@angular/material";
import {DataSourceAssemblerCatalog, fuseAnimations} from "core-fe-angular";
import {AuthService, FuseConfigService, FuseTranslationLoaderService} from "core-fe-angular";
import {HttpClient} from "@angular/common/http";
import {
  Entity,
  EntityModelCatalog,
  FieldToUse,
  FormAction,
  SchemaFieldDefinitionToAngularFormBuilderElements,
  SchemaFieldDetailsInterface
} from "dashboard-fe-angular";
import {ChannelType, CloseableRepositoryInterface} from "iottacle-dashboard/dist/repositories/repository-interface";
import {map} from "rxjs/operators";
import {FilterInterface} from "iottacle-ts-models";


@Component({
  selector: 'recco-vehicle-form',
  templateUrl: './recco-job.component.html',
  styleUrls: ['./recco-job.component.scss'],
  animations : fuseAnimations
})
export class ReccoJobComponent implements OnInit, OnDestroy{

  private dataChangeSubscription:Subscription;
  entityModel:Entity;
  fieldsToShow: SchemaFieldDetailsInterface[];
  entityData:any;
  itemStatus:DashboardItemStatus;

  mapItem:DashboardItem;

  dashboardItem:DashboardItem;
  dashboardItemStatus = DashboardItemStatus;


  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private httpClient:HttpClient
  ){
    //this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

  }

  ngOnDestroy(): void {
    this.dataChangeSubscription.unsubscribe();
  }

  ngOnInit(): void {
    DataSourceAssemblerCatalog.init();

    this.activatedRoute.params
      .subscribe(
        (p) =>{
          let params: any = p;
          let stateParams:any = {
            contextName: 'Recco',
            domainName: 'Vehicle',
            entityId : params['entityId']
          };
          this.entityModel = EntityModelCatalog.getEntityModel(stateParams.contextName, stateParams.domainName);

          this.fieldsToShow = FieldToUse.filterEntityFieldsAsList(this.entityModel.fieldDetails, undefined);

          this.dashboardItem =  DashboardItemFactory.ofCloseableCustomRepo({},
            (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
              return this.entityModel.getEntityById(params.entityId);
            })
            .reloadData();

          this.dataChangeSubscription = this.dashboardItem.observeDataChange()
            .subscribe(
              (res) =>{
                if (res && res.result) {
                  for (let j in res.result.jobs){
                    res.result.jobs[j].mapItem = this.createTimelineMapItem(res.result.jobs[j].location)
                  }
                  this.entityData = res.result;
                }
                this.itemStatus = DashboardItemStatus.SUCCESS;
              },
              (err) =>{
                //show something meaningful to screen
                this.itemStatus = DashboardItemStatus.ERROR;
                console.error(err);
                return err;
              }
            );

          this.initMapItem(params.entityId);
        },
        (err) =>{
          //do something clever to inform user
          console.error(err);
        }
      );
  }

  private initMapItem(entityId:any){
    let customRepoDataSource:DataSourceConfigInterfaceInternal = {
      name: "totalCountCurrentPeriod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          return this.entityModel.getEntityById(entityId)
            .pipe(
              map((result) => {
                return {
                  center : {
                    lat : result.locationGeopoint.lat,
                    lon : result.locationGeopoint.lon
                  },
                  markers : [
                    {
                      lat : result.locationGeopoint.lat,
                      lon : result.locationGeopoint.lon
                    }
                  ]
                }
              })
            )
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    this.mapItem = DashboardItem.of(DashboardItemConfig.of({
      id: "1"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      autoUpdateMs : 10000,
      itemType : "map-with-polygons", // not necessary since I'm binding the item directly to the directive element on the view
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        height: '400px',
        zoom:17,
        gMapOptions : {
          mapTypeId : 'hybrid',
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true
        }
      },
      dataSources : {
        "customRepoDataSource" : DataSource.of(DataSourceConfig.of(customRepoDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
    }));
    this.mapItem.reloadData();
  }

  private createTimelineMapItem(locationCenter):DashboardItem{
    let customRepoDataSource:DataSourceConfigInterfaceInternal = {
      name: "totalCountCurrentPeriod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler("idempotentDataSourceAssembler"),
      repo : {
        getName : () => "customRepo",
        getChannelType : () => ChannelType.CLOSEABLE,
        invoke : (thiz:DataSourceInterface, filter?:FilterInterface):Observable<any> => {
          return of({
            center : {
              lat : locationCenter.lat,
              lon : locationCenter.lon
            },
            markers : [
              {
                lat : locationCenter.lat,
                lon : locationCenter.lon
              }
            ]
          })
        },
        rehydrate : (serializedAssembler:string):CloseableRepositoryInterface => {
          return null;
        },
        serialize : ():string => {
          return JSON.stringify({
            name : "customRepo"
          });
        }
      },
      filter : FilterBuilderFactory.getFilter("idempotentFilterBuilder"),
      specificDataSourceConfig: {},
    };
    let mapItem = DashboardItem.of(DashboardItemConfig.of({
      id: "1"  ,
      observedLevel : ObservedLevel.ITEM_VIEW,
      autoUpdateMs : 0,
      itemType : "map-with-polygons", // not necessary since I'm binding the item directly to the directive element on the view
      viewConfig : DashboardItemViewGeneric.empty(),
      specificItemConfig : {
        height: '200px',
        zoom:17,
        gMapOptions : {
          mapTypeId : 'hybrid',
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true
        }
      },
      dataSources : {
        "customRepoDataSource" : DataSource.of(DataSourceConfig.of(customRepoDataSource)),
      },
      assembler : DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler")
    }));
    mapItem.reloadData();
    return mapItem;
  }

}


