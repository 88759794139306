import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";


import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DataSource,
  DataSourceConfig,
  DataSourceInterface
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {DecimalPipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardConsolePersistency} from "../../../../../context/ext/dashboard-persistency/dashboard-console-persistency";
import {
  AuthService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog,
  FuseConfigService,
  FuseTranslationLoaderService, GridsterDashboardConfig
} from "core-fe-angular";
import {
  Filter,
  MyAdministrativeEntity
} from "iottacle-ts-models";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import deepmerge_ from 'deepmerge';
import _moment from 'moment';
import {
  DashboardItemFactoryChart
} from "../next-one-sky-insights-00/utils";
import {
  EventDataSource
} from "./event-data-source";
import {DashboardItemCatalog, DashboardItemFactory, Utils} from "dashboard-items-catalog";
import {GenericDataSource} from "./iottacle-generic-data-source";
import {InternalVisitsCountPerDayDataSourceForDailyCountMicrolog} from "dashboard-items-catalog/dist/src/data-sources/internal-visits-count-per-day-data-source-for-daily-count-microlog";
import {VisitorsCountPerDayDataSourceForOverallCount} from "../../../../../../../dashboard-items-catalog/src/data-sources/visitors-count-per-day-data-source-for-overall-count";
import {VisitorsCountPerDayDataSourceForOverallCountPreviousPeriod} from "../../../../../../../dashboard-items-catalog/src/data-sources/visitors-count-per-day-data-source-for-overall-count-previous-period";
import {CountPerDayDataSourceForOverallCount} from "../../../../../../../dashboard-items-catalog/src/data-sources/count-per-day-data-source-for-overall-count";
import {CountPerDayDataSourceForOverallCountPreviousPeriod} from "../../../../../../../dashboard-items-catalog/src/data-sources/count-per-day-data-source-for-overall-count-previous-period";
import * as lodash from "lodash";
import {CountPerDayDataSourceForOverallCountMicrolog} from "dashboard-items-catalog/dist/src/data-sources/count-per-day-data-source-for-overall-count-microlog";
import {CountPerDayDataSourceForOverallCountPreviousPeriodMicrolog} from "dashboard-items-catalog/dist/src/data-sources/count-per-day-data-source-for-overall-count-previous-period-microlog";
const _= lodash;
const moment = _moment;
const deepmerge: any = deepmerge_;

@Component({
  selector: 'next-one-detail',
  templateUrl: './next-one-detail.component.html',
  styleUrls: ['./next-one-detail.component.scss']
})
export class NextOneDetailComponent implements OnInit, OnDestroy {

  filter: Filter;
  dashboard: Dashboard;
  showDashboard = false;

  filterChangedSubscription: Subscription;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService: AuthService,
    private decimalPipe: DecimalPipe,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private http: HttpClient
  ) {
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel(), {}, this.translateService);
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {
    let dashboardItems: DashboardItem[] = [];
    let user = this.authService.getCurrentLoggedUser();

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let readOnly = true;

    //Visits over time
    // Check if it is Svicom Organization, they use Microlog
    const fn = user.getSelectedOrganization().organizationId === "645e6240-4345-440f-8b29-1642e0ff30ff" || user.getSelectedOrganization().organizationId === "4309eca9-1229-45bd-9ab4-f8972e06d09e" ? NextOneDetailComponent.barChart_visitsAlongDaysWithEvents_microlog :  DashboardItemCatalog.barChart_visitsAlongDaysWithEvents;
    let countOverTimeSamePeriodItem = fn(readOnly, this.translateService, this.authService, this.http,
      {
        x: 0,
        y: 0,
        cols: 36,
        rows: 12
      },
      DataSource.of(DataSourceConfig.of(
        new EventDataSource(this.http, this.authService))), DashboardItemFactoryChart.createXYChartItem({
          legend: {
            showLegendIntoAccordion: false,
          },
          periodicVerticalLine: {
            period: "auto"
          },
          export: {
            excel: true,
            csv: true,
            image: true
          }
      }));
    dashboardItems.push(countOverTimeSamePeriodItem);
    // let countOverTimeSamePeriodItem1 =  DashboardItemCatalog.barChart_visitsAlongDaysWithEvents_V2(
    //   {
    //     substituteDataSources: true,
    //     dataSources : {
    //       "1" : DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http,"barChart_visitsAlongDaysWithEvents_V2", user ))),
    //       "events":DataSource.of(DataSourceConfig.of(new EventDataSource(this.http, this.authService)))
    //     },
    //     assembler:  DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler"),
    //
    //     readOnly:readOnly,
    //     specificItemConfig:{
    //       gridsterConfig: {x:0, y:0, cols:36, rows:12}
    //     },
    //     viewConfig: DashboardItemFactoryChart.createXYChartItem({
    //       legend:{
    //         showLegendIntoAccordion:false,
    //       },
    //       periodicVerticalLine:{
    //         period: "auto"
    //       },
    //       export:{
    //         excel:true,
    //         csv:true,
    //         image:true
    //       }
    //     })
    //   },
    //   this.translateService,this.http);
    // dashboardItems.push(countOverTimeSamePeriodItem1);

    //MetricDelta visits overall Count
    // let countSamePeriodItem2 = DashboardItemCatalog.metricDelta_overallVisitCount_v2({
    //   substituteDataSources: true,
    //   dataSources: {
    //     "1": DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http, "metricDelta_overallVisitCount_v2", user)))
    //   },
    //   assembler: DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler"),
    //   readOnly: readOnly,
    //   specificItemConfig: {
    //     gridsterConfig: {x: 0, y: 10, cols: 14, rows: 5}
    //   }
    // }, this.translateService, this.http, this.decimalPipe);
    // dashboardItems.push(countSamePeriodItem2);
    const fn1 = user.getSelectedOrganization().organizationId === "645e6240-4345-440f-8b29-1642e0ff30ff" || user.getSelectedOrganization().organizationId === "4309eca9-1229-45bd-9ab4-f8972e06d09e" ? NextOneDetailComponent.metricDelta_overallVisitCount_microlog :  DashboardItemCatalog.metricDelta_overallVisitCount_v2;
    let countSamePeriodItem2 = fn1({
      readOnly: readOnly,
      specificItemConfig: {
        gridsterConfig: {x: 0, y: 10, cols: 14, rows: 5}
      }
    }, this.translateService, this.http, this.decimalPipe);
    dashboardItems.push(countSamePeriodItem2);


    //MetricDelta visitors overall Count
    // let visitorsCountSamePeriodItem = DashboardItemCatalog.metricDelta_overallVisitorsCount(readOnly, this.translateService, this.decimalPipe, this.http,
    //   {x: 0, y: 10, cols: 14, rows: 5});
    // dashboardItems.push(visitorsCountSamePeriodItem);


    //store access Heatmap
    // let hourlyPerDayHeatmapItem = DashboardItemCatalog.heatmap_visitsDistributionOverHodAndDow(readOnly, this.translateService, this.http,
    //   {x:13, y:10, cols:22, rows:12}, DashboardItemFactoryChart.createHeatmapItem());
    // dashboardItems.push(hourlyPerDayHeatmapItem);
    let hourlyPerDayHeatmapItem = DashboardItemCatalog.heatmap_visitsDistributionOverHodAndDow_v2(
      {
        substituteDataSources: true,
        dataSources: {
          "hourlyPerDayHeatmapDataSource": DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http, "heatmap_visitsDistributionOverHodAndDow_v2", user)))
        },
        readOnly: readOnly,
        specificItemConfig: {
          gridsterConfig: {x: 13, y: 10, cols: 22, rows: 12}
        },
        viewConfig: DashboardItemFactoryChart.createHeatmapItem()
      },
      this.translateService, this.http);
    dashboardItems.push(hourlyPerDayHeatmapItem);

    //accessi:
    //access over time

    //distribuzione accessi giorno

    // let visitsOverDowItem =  DashboardItemCatalog.barChart_visitsOverDowDistribution(readOnly, this.translateService, this.http,
    //   {x:0, y:20, cols:18, rows:12}, DashboardItemFactoryChart.createXYChartItem({
    //     legend:{
    //       showLegendIntoAccordion:true,
    //     },
    //     export:{
    //       excel:true,
    //       csv:true,
    //       image:true
    //     }
    //   }));
    // dashboardItems.push(visitsOverDowItem);

    let visitsOverDowItem1 = DashboardItemCatalog.barChart_visitsOverDowDistribution_v2(
      {
        substituteDataSources: true,
        dataSources: {
          "1": DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http, "barChart_visitsOverDowDistribution_v2", user)))
        },
        assembler: DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler"),
        readOnly: readOnly,
        specificItemConfig: {
          gridsterConfig: {x: 0, y: 20, cols: 18, rows: 12}
        },
        viewConfig: DashboardItemFactoryChart.createXYChartItem({
          legend: {
            showLegendIntoAccordion: true,
          },
          export: {
            excel: true,
            csv: true,
            image: true
          }
        })
      }, this.translateService, this.http);
    dashboardItems.push(visitsOverDowItem1);

    // let visitsOverHodItem =  DashboardItemCatalog.barChart_visitsOverHodDistribution(readOnly, this.translateService, this.http,
    //   {x:18, y:20, cols:18, rows:12}, DashboardItemFactoryChart.createXYChartItem({
    //     legend:{
    //       showLegendIntoAccordion:true,
    //     },
    //     export:{
    //       excel:true,
    //       csv:true,
    //       image:true
    //     }
    //   }));
    // dashboardItems.push(visitsOverHodItem);

    let visitsOverHodItem1 = DashboardItemCatalog.barChart_visitsOverHodDistribution_v2(
      {
        substituteDataSources: true,
        dataSources: {
          "1": DataSource.of(DataSourceConfig.of(new GenericDataSource(this.http, "barChart_visitsOverHodDistribution_v2", user)))
        },
        assembler: DashboardItemAssemblerFactory.getAssembler("mergeDataSourcesDashboardItemAssembler"),
        readOnly: readOnly,
        specificItemConfig: {
          gridsterConfig: {x: 18, y: 20, cols: 18, rows: 12}
        },
        viewConfig: DashboardItemFactoryChart.createXYChartItem({
          legend: {
            showLegendIntoAccordion: true,
          },
          export: {
            excel: true,
            csv: true,
            image: true
          }
        })
      },
      this.translateService, this.http);
    dashboardItems.push(visitsOverHodItem1);

    // @ts-ignore
    let adminEntities: MyAdministrativeEntity[] = this.authService.getCurrentLoggedUser().getMyOrganizations().flatMap(org => org.getMyAdministrativeEntities());

    let context = {
      adminEntities: adminEntities,
      fieldValueLookup: {
        adminEntityId: {
          lookup: (value: any) => {
            let adminEntity = adminEntities.find((el) => el.administrativeEntityDetails.id == value);
            return adminEntity ? adminEntity.administrativeEntityDetails.name : value;
          }
        },
        "adminEntityId.keyword": {
          lookup: (value: any) => {
            let adminEntity = adminEntities.find((el) => el.administrativeEntityDetails.id == value);
            return adminEntity ? adminEntity.administrativeEntityDetails.name : value;
          }
        },
        newret: Utils.fieldValueLookup.newret,
        startDow: Utils.fieldValueLookup.startDow(this.translateService),
        startSod: Utils.fieldValueLookup.startSod,
      },
      translateService: this.translateService
    };

    let dashboardCfg = DashboardConfig.of(
      {
        id: "NextOneAccessBreakdown",
        name: "Next One",
        dashboardPersistency: DashboardConsolePersistency.of(),
        specificDashboardConfig: {
          gridsterConfig: new GridsterDashboardConfig()
        },
        initFilter: this.filter.filterValues
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);

    this.dashboard.load()
      .subscribe((hasLoaded) => {
        if (hasLoaded) {
          this.dashboard.reloadData(this.filter.filterValues, context);
          this.showDashboard = true;
          this.filterChangedSubscription = this.filter.filterChangedObservable()
            .subscribe((newFilterValue) => {
              this.dashboard.reloadData(newFilterValue, context);
              this.showDashboard = true;
            });
        }
      });

  }


  public static barChart_visitsAlongDaysWithEvents_microlog(readOnly: boolean, translateService: any, authService: any,
                                                   http: any, gridsterConfig: any, eventDataSource: DataSourceInterface, chartConfig: any) {

    let item = DashboardItemFactory.createXYChartItem({
      id: "0a05efc5-28d6-4143-a5f6-77e449653f18",
      readOnly: readOnly,
      specificItemConfig: {
        gridsterConfig: gridsterConfig
      },
      viewConfig: chartConfig,
      dataSources: {
        "1": DataSource.of(DataSourceConfig.of(new InternalVisitsCountPerDayDataSourceForDailyCountMicrolog(http))),
        "2": eventDataSource
      },
    }, translateService);

    return item;

  }

  public static metricDelta_overallVisitCount_microlog(dashboardItemConfig: any, translateService: any, http: any, decimalPipe: any,) {
    dashboardItemConfig = dashboardItemConfig || {};
    let finalConfig: any = {};
    _.defaultsDeep(finalConfig, dashboardItemConfig, {
      id: "378debcb-65b1-44a0-a3a2-888860045f08",
      specificItemConfig: {matIcon: "directions_walk"},
      dataSources: dashboardItemConfig.substituteDataSources ? {} : {
        "current": DataSource.of(DataSourceConfig.of(new CountPerDayDataSourceForOverallCountMicrolog(http))),
        "previous": DataSource.of(DataSourceConfig.of(new CountPerDayDataSourceForOverallCountPreviousPeriodMicrolog(http)))
      }
    });
    let item = DashboardItemFactory.createMetricDeltaItem(finalConfig, translateService, decimalPipe);
    return item;
  }


}






