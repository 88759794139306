import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FilterModule} from "../../../filter/filter.module";
import {FuseSharedModule,IottacleNavigationService,AuthGuardService} from "core-fe-angular";
import {RouterModule} from "@angular/router";
import {GridsterModule} from "angular-gridster2";
import {AngularDashboardModule} from "dashboard-fe-angular";
import {ActivitiesAndNotesComponent} from "./activities-and-notes.component";

const routes = [
  {
    path     : 'activities-and-notes',
    component: ActivitiesAndNotesComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  providers : [
  ],
  imports     : [
    CommonModule,
    FuseSharedModule,
    RouterModule.forChild(routes),
    FilterModule,
    GridsterModule,
    AngularDashboardModule
  ],
  declarations: [ActivitiesAndNotesComponent]
})
export class ActivitiesAndNotesModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService
  ){


  }
}
