import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private _queryParams:any = {};

  set queryParams(newQueryParams){
    this._queryParams = newQueryParams;
  }

  get queryParams():any{
    return this._queryParams;
  }


}
