import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {concat, forkJoin, Observable, of, Subject, throwError} from "rxjs";
import _lodash from "lodash";
const _ = _lodash;

export class GenericHalfIdempotentFilter implements FilterBuilderInterface{

    currentUser:any;
    constructor(currentUser?:any){
        this.currentUser = currentUser ? currentUser : {};
    }

    getName(): string {
        return "GenericHalfIdempotentFilter";
    }

    rehydrate(serializedFilter: string): FilterBuilderInterface {
        return new GenericHalfIdempotentFilter();
    }

    serialize(): string {
        return "";
    }

    transform(filter:any, context:any):Observable<any> {
        const userFilter = _.cloneDeep(filter);
        if(userFilter.administrativeEntityFilter.selectedMultiple){
            userFilter.administrativeEntityFilter.selectedMultipleIds = filter.administrativeEntityFilter.selectedMultiple.map(a => a.administrativeEntityDetails.id);
            delete userFilter.administrativeEntityFilter.selectedMultiple;
        }
        if(userFilter.administrativeEntityFilter.selected){
            delete userFilter.administrativeEntityFilter.selected;
        }
        delete userFilter.administrativeEntityFilter.allAdministrativeEntities;
        //userFilter.customFilters.accountId = this.currentUser.accountId;
        //userFilter.customFilters.organizationId = this.currentUser.organizations[0].organizationId;

        return of(userFilter);
    }

}
