import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {
  Dashboard,
  DashboardConfig,
  DashboardItem,
  DashboardItemAssemblerFactory,
  DashboardItemConfig, DashboardItemViewGeneric,
  DashboardItemViewMetricDelta, DashboardItemViewSerialChart,
  DataResultInterface, DataSource,
  DataSourceAssemblerFactory,
  DataSourceAssemblerInterface,
  DataSourceConfig,
  DataSourceConfigInterfaceInternal,
  GenericDataResult,
  MetricDeltaConfig,
  ObservedLevel,
  RepositoryFactory, SerialChartConfig, ViewAssemblerFactory
} from "iottacle-dashboard";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {
  AuthService,
  FuseTranslationLoaderService,
  FuseConfigService,
  DataSourceAssemblerCatalog,
  FilterBuilderCatalog, GridsterDashboardConfig
} from "core-fe-angular";
import {DecimalPipe} from "@angular/common";
import {
  Filter,
  FilterLocationReferences,
  FilterLocationReferencesStrategy, FilterVisitJunkData, FilterVisitStrategy,
  FilterVisitTimeFilterStrategies
} from "iottacle-ts-models";
import {FilterBuilderInterface} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-interface";
import {FilterBuilderGenericElastic} from "iottacle-ts-models/dist/iottacle/model/filter/filter-builder-generic-elastic";

@Component({
  selector: 'store-opportunity',
  templateUrl: './store-opportunity.component.html',
  styleUrls: ['./store-opportunity.component.scss']
})
export class StoreOpportunityComponent implements OnInit, OnDestroy{

  filter:Filter;
  dashboard:Dashboard;
  showDashboard = false;
  filterChangedSubscription:Subscription;


  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private authService:AuthService,
    private decimalPipe: DecimalPipe,
  ){
    this.translationLoader.loadTranslations(english, italian);
    // Configure the layout
    this.fuseConfig.config = {
      layout: {
        navbar   : {
          hidden: false
        },
        toolbar  : {
          hidden: false
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

    this.filter = new Filter(authService.getUnderlyingLoginModel());
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.dashboard.destroy();
  }

  ngOnInit(): void {

    let dashboardItems:DashboardItem[] = [];
    //RepositoryCatalog.init(this.http);

    DataSourceAssemblerCatalog.init();
    FilterBuilderCatalog.init();
    let filterBuilderElastic:FilterBuilderInterface = new FilterBuilderGenericElastic();

    // Opportunity rate over time
    let externalVisitsOverTime:DataSourceConfigInterfaceInternal = {
      name: "externalVisitsOverTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_EXTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let internalVisitsOverTime:DataSourceConfigInterfaceInternal = {
      name: "internalVisitsOverTime",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "date_histogram": {
                "field": "start",
                "interval": "1d",
                "time_zone": "Europe/Berlin",
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let opportunityRateOverTimeItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "1",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Opportunity rate over time" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "DateAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Rate %",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "LineSeries",
              "dataFields": {
                "valueY": "value",
                "dateX": "time"
              },
              "strokeWidth": 3,
              "tooltip": {
                "pointerOrientation": "vertical"
              }
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:0,
          y:0,
          cols:36,
          rows:8,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(internalVisitsOverTime)),
        "2" : DataSource.of(DataSourceConfig.of(externalVisitsOverTime))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            for (let b in result["1"].aggregations["2"]["buckets"]){
              let internal = result["1"].aggregations["2"]["buckets"][b]["1"].value;
              let external = result["2"].aggregations["2"]["buckets"][b]["1"].value;
              chartData.push({
                time : result["1"].aggregations["2"]["buckets"][b].key,
                value : Math.round(internal / external * 100)
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(opportunityRateOverTimeItem);

    // Opportunity rate over dow
    let externalVisitsOverDow:DataSourceConfigInterfaceInternal = {
      name: "externalVisitsOverDow",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_EXTERNAL_VISITS_PREDEFINED_FILTERS,
            junk:FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startDow",
                "interval": 1,
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let internalVisitsOverDow:DataSourceConfigInterfaceInternal = {
      name: "internalVisitsOverDow",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk:FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startDow",
                "interval": 1,
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let opportunityRateOverDowItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "2",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Opportunity rate over Day of Week" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Rate %",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              name : "Opportunity Rate",
              "dataFields": {
                "valueY": "value",
                "categoryX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0
                },
                "strokeOpacity": 0
              },
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:8,
          y:0,
          cols:18,
          rows:9,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(internalVisitsOverDow)),
        "2" : DataSource.of(DataSourceConfig.of(externalVisitsOverDow))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            for (let b in result["1"].aggregations["2"]["buckets"]){
              let internal = result["1"].aggregations["2"]["buckets"][b]["1"].value;
              let external = result["2"].aggregations["2"]["buckets"][b]["1"].value;
              chartData.push({
                time : result["1"].aggregations["2"]["buckets"][b].key,
                value : Math.round(internal / external * 100)
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(opportunityRateOverDowItem);


    // Opportunity rate over hod
    let externalVisitsOverHod:DataSourceConfigInterfaceInternal = {
      name: "externalVisitsOverHod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_EXTERNAL_VISITS_PREDEFINED_FILTERS,
            junk:FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let internalVisitsOverHod:DataSourceConfigInterfaceInternal = {
      name: "internalVisitsOverHod",
      type: "elastic",
      assembler : DataSourceAssemblerFactory.getAssembler(DataSourceAssemblerFactory.idempotentDataSourceAssembler.name),
      repo : RepositoryFactory.getRepository("iottacleElasticGenericQuery"),
      filter : filterBuilderElastic.rehydrate(JSON.stringify({
        customData : {
          timeStrategy : {
            timestampField:'start',
            strategy : FilterVisitTimeFilterStrategies.SAME_PERIOD
          },
          locationReference : {
            from: FilterLocationReferences.FROM_FILTER_LOCATIONS
          },
          visitStrategyFilter : {
            strategy : FilterVisitStrategy.USE_INTERNAL_VISITS_PREDEFINED_FILTERS,
            junk: FilterVisitJunkData.REMOVE
          },
          query: {},
          agg : {
            "2": {
              "histogram": {
                "field": "startSod",
                "interval": 3600,
                "min_doc_count": 0
              },
              "aggs": {
                "1": {
                  "cardinality": {
                    "field": "ma_sha1"
                  }
                }
              }
            }
          },
          index : "visits",
          returnComplete : true
        }
      })),
      specificDataSourceConfig: {},
    };
    let opportunityRateOverHodItem =  DashboardItem.of(DashboardItemConfig.of({
      id: "3",
      observedLevel : ObservedLevel.ITEM_VIEW,
      name : "Opportunity rate over hour of Day" ,
      showHeader : true,
      readOnly : true,
      showName: true,
      autoUpdateMs : 0,
      subtitle: "",
      infoButtonText: "",
      itemType : "chart",
      style:{
        background : "white",
        'border-color' : "#00000042",
        'border-radius': "8px"
      },
      viewConfig : DashboardItemViewSerialChart.of(SerialChartConfig.of({
        chartType : "XYChart",
        chart :{
          "hiddenState": {
            "properties": {
              "opacity": 0
            }
          },

          "xAxes": [{
            "type": "CategoryAxis",
            "dataFields": {
              "category": "time"
            },
            "renderer": {
              "grid": {
                "disabled": true
              }
            }
          }],

          "yAxes": [{
            "type": "ValueAxis",
            "title": {
              "text": "Rate %",
            },
            "min": 0,
            "renderer": {
              "baseGrid": {
                "disabled": true
              },
              "grid": {
                "strokeOpacity": 0.07
              }
            }
          }],

          "series": [
            {
              "type": "ColumnSeries",
              name : "Opportunity Rate",
              "dataFields": {
                "valueY": "value",
                "categoryX": "time"
              },
              "tooltip": {
                "pointerOrientation": "vertical"
              },
              "columns": {
                "column": {
                  "tooltipText": "Series: {name}\nCategory: {categoryX}\nValue: {valueY}",
                  "tooltipY": 0
                },
                "strokeOpacity": 0
              },
            }
          ]
        }
      }), ViewAssemblerFactory.getAssembler("idempotentViewAssembler")),
      specificItemConfig : {
        gridsterConfig : {
          x:18,
          y:8,
          cols:18,
          rows:9,
          draggable : {
            enabled : true
          }
        }
      },
      dataSources : {
        "1" : DataSource.of(DataSourceConfig.of(internalVisitsOverHod)),
        "2" : DataSource.of(DataSourceConfig.of(externalVisitsOverHod))
      },
      assembler : {
        name : "custom",
        assemble : (result:{[dataSourceId:string]:any}, thiz:DashboardItem):Observable<DataResultInterface<any>> => {
          let chartData:any = [];
          if (result){
            for (let b in result["1"].aggregations["2"]["buckets"]){
              let internal = result["1"].aggregations["2"]["buckets"][b]["1"].value;
              let external = result["2"].aggregations["2"]["buckets"][b]["1"].value;
              chartData.push({
                time : result["1"].aggregations["2"]["buckets"][b].key/3600,
                value : Math.round(internal / external * 100)
              })
            }
          }
          if (chartData !== undefined) {
            return of(GenericDataResult.ofSuccess(thiz.getId().toString(), chartData));
          }else{
            return of(GenericDataResult
              .ofError(thiz.getId().toString())
              .internalFeError({
                errorDescription: "there are no datasource results for itemId: " + thiz.getId().toString() + " name: " +thiz.getName()
              }));
          }
        },
        serialize : () => {
          return JSON.stringify({
            name : "custom"
          });
        },
        rehydrate : (serializedAssembler:string) => {
          return undefined
        }
      }
    }));
    dashboardItems.push(opportunityRateOverHodItem);


    let dashboardCfg = DashboardConfig.of(
      {
        id: "StoreOpportunity1234",
        name: "Store Opportunity",
        specificDashboardConfig : {
          gridsterConfig : new GridsterDashboardConfig()
        }
      });

    this.dashboard = Dashboard.of(dashboardCfg, dashboardItems);
    this.dashboard.setFilter(this.filter.filterValues);
    this.filterChangedSubscription = this.filter.filterChangedObservable()
      .subscribe((newFilterValue) => {
        this.dashboard.reloadData();
        this.showDashboard = true;
      });
  }


}


