import {
  CommandFactory,
  Entity, FieldToUse,
  IottacleHttpClientProxy, QueryFactory,
  SchemaFieldDetailFactory,
  SchemaFieldDetailsInterface, SchemaFieldTypesNames
} from "dashboard-fe-angular";


export class Device extends Entity{
  entityName:string;
  fieldDetails: { [p: string]: SchemaFieldDetailsInterface };
  entityValidators:any[];
  contextName:string;
  domainName:string;

  constructor(
    private httpClient: IottacleHttpClientProxy,
  ){
    super();

    //DOMAIN DETAILS
    this.contextName = "Device";
    this.domainName = "Device";

    //NAME
    this.entityName = "Device";

    //SCHEMA
    this.fieldDetails = {};
    this.fieldDetails.id = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.id, "id");
    this.fieldDetails.description = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.textarea, "description");
    this.fieldDetails.deviceId = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "deviceId");
    this.fieldDetails.sshPassword = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "sshPassword");
    this.fieldDetails.sshPort = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.number, "sshPort");
    this.fieldDetails.sshUrl = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "sshUrl");
    this.fieldDetails.sshUser = SchemaFieldDetailFactory.of(SchemaFieldTypesNames.string, "sshUser");

    //ENTITY VALIDATORS
    this.entityValidators = [];


    //ENTITY BOILERPLATE STUFF
    this.saveSchema = FieldToUse.filterEntityFieldsAsList(this.fieldDetails, undefined);
    this.readSchemaOfServer = this.saveSchema;
    this.updateSchema = this.saveSchema;

    //REMOTE RESOURCES
    this.saveNew = (serverModel) => {
      let assembleAs:any = {};
      for (const f in this.fieldDetails){
        assembleAs[this.fieldDetails[f].name] = true;
      }

      return this.httpClient.post("/api/v3/executor/command",
        CommandFactory.ofSave({
          contextName: this.contextName,
          domainName: this.domainName,
          body : {
            aggregate : serverModel,
            assembleAs : assembleAs
          }
        })
      )
    };

    this.update = (serverModel) => {
      let assembleAs:any = {};
      for (const f in this.fieldDetails){
        assembleAs[this.fieldDetails[f].name] = true;
      }

      let whereClause:any = {where : {}};
      whereClause.where[this.getKeyFieldDetails().name] = serverModel[this.getKeyFieldDetails().name];

      return this.httpClient.post("/api/v3/executor/command",
        CommandFactory.ofUpdate({
          contextName: this.contextName,
          domainName: this.domainName,
          body : {
            aggregate : serverModel,
            assembleAs : assembleAs,
            where : whereClause
          }
        })
      )
    };

    this.getById = (entityId) => {
      let assembleAs:any = {};
      for (const f in this.fieldDetails){
        assembleAs[this.fieldDetails[f].name] = true;
      }
      return this.httpClient.post(
          "/api/v3/executor/query",
          QueryFactory.ofGetById(
            entityId,
            {
              contextName : this.contextName,
              domainName: this.domainName,
              assembleAs : assembleAs
          },
          this
          )
        )
        // .pipe(
        //   map((res) => res.body.hits[0])
        // );
    };

    this.getAll = (whereClause:any) => {
      let assembleAs:any = {};
      for (const f in this.fieldDetails){
        assembleAs[this.fieldDetails[f].name] = true;
      }
      return this.httpClient.post(
        "/api/v3/executor/query",
        QueryFactory.ofGetAll(
          whereClause,
          {
            contextName : this.contextName,
            domainName: this.domainName,
            assembleAs : assembleAs
          },
          this
        )
      );
    }
  }

  getFieldValue(fieldName: string, entity: any):any {
    return entity[fieldName];
  }

}
