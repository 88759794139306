import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AuthService,
  CollectionType,
  DataSchema, Domain, DomainCatalog, Fields,
  FuseSharedModule,
  FuseSidebarModule, FuseTranslationLoaderService,
  IottacleNavigationService, ListTemplateFactoryComponent, ViewTemplateFactoryComponent, IottacleFormModule
} from "core-fe-angular";
import {EntityModelCatalog, AngularDashboardModule} from "dashboard-fe-angular";
import {HttpClient} from "@angular/common/http";
import {Device} from "../../../../../context/device/device/device";
import {locale as english} from "../i18n/en";
import {locale as italian} from "../i18n/it";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import { DeviceStatusListTemplateComponent } from './device-status-list-template/device-status-list-template.component';
import {DeviceStatusListTemplateConfig} from "./device-status-list-template/device-status-list-template-config";
import {TooltipModule} from "ng2-tooltip-directive";
import {
  MatCardModule
} from '@angular/material';

const routes = [
  {
    path     : 'device/list',
    component: ListTemplateFactoryComponent,
    data: {
      domainName:"Device.Device"
    }
  },
  {
    path     : 'Device/Device/list',
    component: ListTemplateFactoryComponent,
    data: {
      domainName:"Device.Device"
    }
  },
  {
    path     : 'device/device/list',
    component: ListTemplateFactoryComponent,
    data: {
      domainName:"Device.Device"
    }
  },
  {
    path     : 'Device/Device/e/:entityId',
    component: ViewTemplateFactoryComponent,
    data: {
      domainName:"Device.Device"
    }
  },
  {
    path     : 'device/device/e/:entityId',
    component: ViewTemplateFactoryComponent,
    data: {
      domainName:"Device.Device"
    }
  }
];


export enum FieldsName{
  id="id",
  acl="acl",
  acl_externalSystemName = "externalSystemName",
  acl_externalFieldName = "externalFieldName",
  acl_externalFieldValue = "externalFieldValue",
  acl_mDashToken="mDashToken",
  deviceId="deviceId",
  description="description",
  status="status",
  liveValues="liveValues",
  liveValues_phenomenon="phenomenon",
  liveValues_value1d = "value1d",
  liveValues_value2d = "value2d",
  liveValues_value3d = "value3d",
  liveValues_value4d = "value4d",
  liveValues_value5d = "value5d",
  liveValues_value1s = "value1s",
  liveValues_value2s = "value2s",
  liveValues_value3s = "value3s",
  liveValues_value4s = "value4s",
  liveValues_value5s = "value5s",
  template = "template",
  envVariables="envVariables"
}


@NgModule({
  providers : [
    DeviceStatusListTemplateComponent
  ],
  imports     : [
    RouterModule.forChild(routes),
    TranslateModule,
    CommonModule,
    FuseSharedModule,
    FuseSidebarModule,
    IottacleFormModule,
    TooltipModule,
    AngularDashboardModule,
    MatCardModule
  ],
  declarations: [
    DeviceStatusListTemplateComponent
  ],
  entryComponents:[
    DeviceStatusListTemplateComponent
  ]
})
export class DeviceDeviceModule {

  constructor(
    private _fuseNavigationService : IottacleNavigationService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private httpClient:HttpClient,private authService:AuthService
  ){

    EntityModelCatalog.registerEntityModel(new Device(httpClient));
    //instruct navigation
    this._fuseNavigationService.addNavigationItem({
      'id'       : 'device',
      'title'    : 'Device',
      'translate': 'nav.device.group-name',
      'type'     : 'group',
      'children' : [
        {
          'id'       : 'device.device',
          'title'    : 'Beacon',
          'translate': 'nav.device.device.group-name',
          'type'     : 'collapsable',
          'icon'     : 'store',
          'children' : [
            {
              'id'       : 'device.device.list',
              'title'    : 'List',
              'translate': 'nav.device.device.list',
              'type'     : 'item',
              'icon'     : 'location_on',
              'url'  : 'entity/Device/Device/list'
            }
            ]
        }
      ]
    }, "end");

    let schema =  DataSchema.ofConfig(
      {
        fields: [
          Fields.uniqueKey({
            name: FieldsName.id
          }),
          Fields.uniqueKey({
            name: FieldsName.deviceId
          }),
          Fields.string({
            name: FieldsName.description
          }),
          Fields.string({
            name: FieldsName.status
          }),
          Fields.valueObject({
            name:  FieldsName.acl,
            subFields: [
              Fields.string({
                name:  FieldsName.acl_externalSystemName
              }),
              Fields.string({
                name:  FieldsName.acl_externalFieldName
              }),
              Fields.string({
                name:  FieldsName.acl_externalFieldValue
              }),
              Fields.string({
                name:  FieldsName.acl_mDashToken
              })

            ]
          }),
          Fields.valueObject({
            name:  FieldsName.liveValues,
            collection: CollectionType.LIST,
            subFields: [
              Fields.enum({
                name:  FieldsName.liveValues_phenomenon,
                enumValues:["TEMP", "HUM", "OCC", "NOISE", "NOISE_CONV", "LUM", "PRESS", "AQ", "CO2", "VOC",
                  "RF_STATUS", "BATTERY", "BOILER_STATUS_COMMAND", "SETPOINT_TEMP"]
              }),
              Fields.number({
                name:  FieldsName.liveValues_value1d
              }),
              Fields.number({
                name:  FieldsName.liveValues_value2d
              }),
              Fields.number({
                name:  FieldsName.liveValues_value3d
              }),
              Fields.number({
                name:  FieldsName.liveValues_value4d
              }),
              Fields.number({
                name:  FieldsName.liveValues_value5d
              }),
              Fields.string({
                name:  FieldsName.liveValues_value1s
              }),
              Fields.string({
                name:  FieldsName.liveValues_value1s
              }),
              Fields.string({
                name:  FieldsName.liveValues_value2s
              }),
              Fields.string({
                name:  FieldsName.liveValues_value3s
              }),
              Fields.string({
                name:  FieldsName.liveValues_value4s
              }),
              Fields.string({
                name:  FieldsName.liveValues_value5s
              })
            ]
          }),
          Fields.inverseOneToMany({
            name: FieldsName.template,
            otherDomain: "Device.DeviceTemplate"
          }),
          Fields.json({
            name: FieldsName.envVariables,
            fieldConfig:{
              useJsonSerde: false
            }
          })
        ],
        defaultAssembleAs: {
          id:true
        }
      }
    );

    let deviceDeviceDomain = Domain.ofConfig({
      contextName: "Device",
      domainName: "Device",
      schema:schema,
      templatesConfig: {
        // listTemplate: <ListTemplate01Config>{
        //   templateName: "list-template-01",
        listTemplate: <DeviceStatusListTemplateConfig>{
          //templatePath: "./src/app/main/context/device/device/device-status-list-template/device-status-list-template.component.ts",
          templateInstance: DeviceStatusListTemplateComponent,
          tableConfig:{
            fieldsToShow: [FieldsName.status, FieldsName.id]
          }
        },
        viewTemplate:{
          templateName:"view-template-01",
          fieldsToShow:[FieldsName.status, FieldsName.id, FieldsName.deviceId, FieldsName.description, FieldsName.template, FieldsName.acl,
            FieldsName.envVariables, FieldsName.liveValues
          ],

        }
      },
      http:httpClient,
      auth:authService
    });
    DomainCatalog.addDomain(deviceDeviceDomain);

    this._fuseTranslationLoaderService.loadTranslations(english,italian);

  }
}
