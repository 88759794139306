

export class Utils{

  public static guid() {
    let underscore = "_";
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return s4() + s4() + underscore + s4() + underscore + s4() + underscore + s4() + underscore + s4() + s4() + s4();
  }


  public static concat(x,y){return x.concat(y);}

  public static flatMap (f,xs) {return xs.map(f).reduce(Utils.concat, []);};

}
