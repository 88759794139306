


export class AppData{

  public static appData:string=
    `idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/05;day;other;u0ne09s;10;45.54;9.17;1381
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0n6qz3;15;45.44;9.09;189
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0ne411;8;45.53;9.23;32
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;night;other;u0ne0fr;21;45.54;9.18;1363
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;day;other;u0ne14h;7;45.54;9.19;4
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0ndb2z;12;45.49;9.16;55
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/20;day;other;u0n6wbu;16;45.44;9.09;909
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;day;other;u0ne414;8;45.53;9.23;13
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ndb87;8;45.49;9.17;195
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;night;other;u0n6wck;21;45.45;9.09;2608
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/05;night;other;u0ne0fr;22;45.54;9.18;37373
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0n6wck;11;45.45;9.09;210
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;day;other;u0ne11r;7;45.53;9.19;61
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;night;other;u0ndc4p;22;45.5;9.19;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;night;other;u0ne0fr;21;45.54;9.18;1770
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/01;day;other;u0n6qzf;12;45.44;9.09;12134
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/11;day;other;u0nd9hs;8;45.46;9.19;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne1bb;12;45.53;9.22;17
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0n6x6v;8;45.46;9.12;676
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/05;day;other;u0n6wck;10;45.45;9.09;5061
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0ne132;8;45.53;9.2;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;day;other;u0ne414;8;45.53;9.23;99
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0n6xe0;8;45.46;9.12;815
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;day;other;u0n6x6v;8;45.46;9.12;658
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/27;day;other;u0ne0fr;10;45.54;9.18;7025
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne18m;10;45.53;9.21;76
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;night;other;u0n6wby;20;45.44;9.09;40
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/24;day;other;u0ndcyn;8;45.52;9.23;610
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/06;night;other;u0n6wb7;21;45.44;9.09;1351
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0n6qzd;15;45.44;9.09;12
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/20;day;other;u0ne00k;12;45.53;9.15;658
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/27;night;other;u0n6x59;21;45.46;9.1;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/27;day;other;u0ndbz3;15;45.52;9.18;209
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0n6wcm;16;45.45;9.09;36
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0ne141;6;45.54;9.19;672
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6qzn;15;45.43;9.09;42
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne11p;12;45.53;9.19;17
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;day;other;u0ne117;10;45.53;9.19;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;day;other;u0nd9he;15;45.46;9.19;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/05;day;other;u0n6qz3;15;45.44;9.09;2779
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0n6qws;14;45.43;9.08;662
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0ne132;7;45.53;9.2;226
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;day;other;u0n6x3q;8;45.45;9.12;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0ne416;7;45.53;9.23;282
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0n6qw4;9;45.43;9.08;795
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0n6wck;12;45.45;9.09;2900
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0ndb87;11;45.49;9.17;2113
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;night;other;u0ne146;21;45.54;9.19;20
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;day;other;u0n6x3m;16;45.45;9.12;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/11;night;other;u0ne2y0;0;45.6;9.17;872
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/11;day;other;u0n6wbr;13;45.44;9.1;28
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/11;day;other;u0n6x6u;9;45.46;9.11;425
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/18;day;other;u0ne14e;6;45.54;9.19;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;night;other;u0n6ws1;20;45.46;9.08;839
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;night;other;u0ne0fr;21;45.54;9.18;39776
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/25;night;other;u0n6ws3;21;45.46;9.08;748
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;night;other;u0n6wb3;21;45.44;9.09;266
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;night;other;u0ne0fr;23;45.54;9.18;1440
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/27;night;other;u0ne1fd;23;45.54;9.22;667
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ndc57;7;45.5;9.19;14
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;night;other;u0ne2wp;21;45.6;9.17;4374
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/07;night;other;u0ne10j;19;45.53;9.19;2241
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/18;day;other;u0ne132;6;45.53;9.2;13
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/18;night;other;u0ne00q;22;45.53;9.15;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;night;other;u0n6wfc;20;45.46;9.09;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;night;other;u0n6w9z;20;45.45;9.09;30
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;day;other;u0ne411;7;45.53;9.23;716
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6wck;15;45.45;9.09;10437
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6rp3;15;45.44;9.1;875
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;day;other;u0n6wbz;16;45.44;9.1;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0n6qzd;15;45.44;9.09;12
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/25;day;other;u0n6wck;16;45.45;9.09;9233
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/20;day;other;u0n6wb4;12;45.44;9.09;183
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/27;day;other;u0nd9rc;15;45.48;9.2;814
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/27;day;other;u0nd8t4;16;45.47;9.17;240
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/16;night;other;u0n6qw6;21;45.43;9.08;1086
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ndb87;8;45.49;9.17;1140
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;day;other;u0n6x3m;16;45.45;9.12;28
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;day;other;u0nd8v1;15;45.47;9.18;50
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0n6qz4;15;45.43;9.09;555
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0n6wcn;16;45.45;9.09;112
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0ndcyx;7;45.52;9.23;767
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0n6zmz;12;45.52;9.12;612
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;day;other;u0n7hes;15;45.55;8.99;1276
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;day;other;u0ne0fr;8;45.54;9.18;1083
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/27;day;other;u0ne11r;10;45.53;9.19;1106
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6rp3;14;45.44;9.1;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6rp2;14;45.44;9.1;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne1tj;7;45.56;9.21;139
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0n6x1m;16;45.45;9.1;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;night;other;u0ne0g3;19;45.55;9.18;682
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0n6qz1;15;45.43;9.09;37
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0ne1bw;8;45.53;9.23;19
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/25;day;other;u0n6wck;12;45.45;9.09;7829
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6qzp;15;45.43;9.1;411
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6x3m;8;45.45;9.12;207
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/07;night;other;u0ne143;18;45.54;9.19;3585
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0n6w7k;13;45.46;9.07;1311
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;night;other;u0ndcmn;21;45.51;9.2;695
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;night;other;u0ne14g;21;45.54;9.19;1502
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0n6wck;16;45.45;9.09;13860
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;day;other;u0n6wch;12;45.45;9.09;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;day;other;u0ne1bw;8;45.53;9.23;8
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/11;day;other;u0ne14h;8;45.54;9.19;29
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0ne12b;6;45.53;9.2;634
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne159;6;45.55;9.19;948
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6qzg;15;45.44;9.09;825
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ndcpv;7;45.53;9.19;8
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0n6wck;12;45.45;9.09;3389
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;night;other;u0ne0fr;22;45.54;9.18;284
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;day;other;u0nd9kc;9;45.47;9.2;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;night;other;u0n6wf8;21;45.45;9.09;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/11;day;other;u0n6wbm;13;45.44;9.09;1088
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne1gm;9;45.55;9.23;887
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ndfpc;11;45.53;9.23;47
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0ne14h;6;45.54;9.19;22
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne0fr;11;45.54;9.18;2074
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne411;15;45.53;9.23;14
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/16;night;other;u0n6qmw;21;45.43;9.07;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6wck;11;45.45;9.09;498
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ndbb3;8;45.49;9.18;13
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne18x;12;45.53;9.22;21
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;day;other;u0ne11r;9;45.53;9.19;3422
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;day;other;u0n6x09;16;45.44;9.1;50
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0n6qzc;15;45.44;9.09;329
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/24;day;other;u0nd9x1;8;45.48;9.21;306
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0n6wcm;8;45.45;9.09;3472
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;day;other;u0ne416;8;45.53;9.23;634
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6qz1;14;45.43;9.09;89
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0nd8vm;8;45.47;9.18;315
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;night;other;u0ne0fr;21;45.54;9.18;4597
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6qz4;14;45.43;9.09;97
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;day;other;u0n6wck;16;45.45;9.09;1131
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;night;other;u0n6w9z;20;45.45;9.09;670
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;day;other;u0ne14h;13;45.54;9.19;67
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne0fr;15;45.54;9.18;7177
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/21;night;other;u0n6qzf;17;45.44;9.09;11616
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;day;other;u0ne19p;15;45.53;9.22;40
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0n6wck;16;45.45;9.09;2426
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;night;other;u0n6x3x;20;45.45;9.12;48
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;night;other;u0n6w9z;21;45.45;9.09;912
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0n6qzf;16;45.44;9.09;1397
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;night;other;u0ne0fr;23;45.54;9.18;135
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0ne138;6;45.54;9.2;170
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ndcy5;10;45.52;9.22;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/07;day;other;u0ne11t;15;45.54;9.19;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/18;day;other;u0n6x6v;8;45.46;9.12;1757
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/05;day;other;u0n6qz1;16;45.43;9.09;312
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0n6qz3;14;45.44;9.09;340
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;night;other;u0ndc4u;21;45.5;9.19;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/25;night;other;u0n6wcb;21;45.45;9.09;16
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/25;day;other;u0ndcvm;9;45.51;9.23;620
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;night;other;u0nd8p6;22;45.48;9.14;28
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/01;day;other;u0n6rp2;12;45.44;9.1;14
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne14y;14;45.54;9.19;13
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/21;day;other;u0n6qz3;15;45.44;9.09;2191
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/18;day;other;u0ne411;7;45.53;9.23;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/07;night;other;u0ndcwf;20;45.52;9.21;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/24;day;other;u0nd9m1;8;45.47;9.2;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/24;night;other;u0n6wfc;20;45.46;9.09;963
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/24;day;other;u0ne11p;7;45.53;9.19;740
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0n6qzf;8;45.44;9.09;2858
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;night;other;u0n6x6b;21;45.46;9.11;943
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0ne139;8;45.54;9.2;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;night;other;u0ne0fr;21;45.54;9.18;1381
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ndb86;11;45.49;9.17;28
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ndbfr;12;45.5;9.18;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ne11v;13;45.54;9.19;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ndb8t;12;45.49;9.17;23
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6rp2;14;45.44;9.1;63
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;night;other;u0ne0fr;22;45.54;9.18;90
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/16;night;other;u0ne14g;21;45.54;9.19;754
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne143;10;45.54;9.19;23
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;day;other;u0ne0fr;10;45.54;9.18;4331
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;night;other;u0n6wck;19;45.45;9.09;5522
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0n6qzc;15;45.44;9.09;302
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;night;other;u0ne0fr;21;45.54;9.18;1508
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;day;other;u0n6wck;12;45.45;9.09;3539
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;night;other;u0ne0fr;23;45.54;9.18;20710
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0n6qw1;16;45.43;9.08;827
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0n6qwh;16;45.43;9.08;56
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0n6x12;8;45.45;9.1;244
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;day;other;u0n6x6u;9;45.46;9.11;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/20;day;other;u0ne14b;11;45.54;9.19;741
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0nd9he;7;45.46;9.19;6
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;day;other;u0n75te;14;45.56;8.95;2288
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/01;day;other;u0ne12b;15;45.53;9.2;41037
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/27;day;other;u0ndbwt;15;45.52;9.17;899
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/27;day;other;u0ndbwy;15;45.52;9.17;14
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/11;day;other;u0ne11q;8;45.53;9.19;655
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne14g;12;45.54;9.19;375
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne1f0;10;45.54;9.22;24
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne132;13;45.53;9.2;964
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ndfpc;11;45.53;9.23;2222
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/18;day;other;u0ne1bs;7;45.53;9.22;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0n6qzf;13;45.44;9.09;2488
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0n6qzc;16;45.44;9.09;18
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/25;night;other;u0ne0fr;22;45.54;9.18;30421
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0n6x1k;8;45.45;9.1;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;night;other;u0n6wbw;21;45.44;9.09;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;night;other;u0n6x7c;22;45.46;9.11;3
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ne141;13;45.54;9.19;13
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/11;day;other;u0n6qw5;13;45.43;9.08;438
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne1sr;9;45.55;9.22;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ndcx4;10;45.52;9.21;138
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne1tm;8;45.56;9.21;776
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne18r;15;45.53;9.22;57
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;night;other;u0n6wf2;20;45.45;9.09;669
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0n6qzf;13;45.44;9.09;143
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0n6w77;13;45.46;9.07;1276
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;night;other;u0n6x6v;20;45.46;9.12;65
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0ne1bb;8;45.53;9.22;128
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;day;other;u0n6x6g;9;45.46;9.11;75
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ne11u;13;45.54;9.19;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0n6wbr;15;45.44;9.1;32
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0n6w77;13;45.46;9.07;2688
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0n6qzc;14;45.44;9.09;8006
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0n6qzf;13;45.44;9.09;936
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0ne141;6;45.54;9.19;1117
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/24;day;other;u0n6wck;9;45.45;9.09;30784
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0ne082;7;45.53;9.16;730
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0ne11r;8;45.53;9.19;76
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ndb8x;12;45.49;9.17;436
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne1bx;12;45.53;9.23;689
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/18;day;other;u0ne163;6;45.54;9.2;12
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0ne1bs;8;45.53;9.22;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0ne14h;8;45.54;9.19;4
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/24;day;other;u0ne414;8;45.53;9.23;417
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/11;day;other;u0n6wbq;13;45.44;9.09;16
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6x6u;7;45.46;9.11;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne132;12;45.53;9.2;8
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/21;night;other;u0n6qzf;22;45.44;9.09;49298
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/18;day;other;u0nd9wb;7;45.48;9.21;301
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;day;other;u0n6wck;10;45.45;9.09;6070
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;day;other;u0ne1bx;7;45.53;9.23;15
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;night;other;u0ne143;19;45.54;9.19;37
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;night;other;u0ne14g;21;45.54;9.19;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0n6x9b;8;45.45;9.12;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0n6x6u;8;45.46;9.11;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0nd9jk;7;45.47;9.19;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ne143;13;45.54;9.19;229
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/21;day;other;u0n6qz3;16;45.44;9.09;2434
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0ne414;8;45.53;9.23;388
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0ne13c;7;45.54;9.2;53
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0ne14e;7;45.54;9.19;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0nd9m6;7;45.47;9.2;674
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/25;day;other;u0n6wck;15;45.45;9.09;1526
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;day;other;u0ne132;8;45.53;9.2;18
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;day;other;u0n6wck;9;45.45;9.09;129
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6qz3;14;45.44;9.09;610
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;night;other;u0ne18r;17;45.53;9.22;1051
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/11;day;other;u0n6wck;16;45.45;9.09;11684
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne1gk;9;45.55;9.22;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ndcy7;11;45.52;9.22;906
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne1b2;12;45.53;9.22;14
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/21;day;other;u0n6qz1;15;45.43;9.09;3187
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0n6qz1;14;45.43;9.09;61
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0nd9m6;9;45.47;9.2;304
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0nd9kc;8;45.47;9.2;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;day;other;u0n6qzf;13;45.44;9.09;8176
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ndb86;11;45.49;9.17;864
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ne11v;10;45.54;9.19;721
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6wck;9;45.45;9.09;4856
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0ne411;8;45.53;9.23;27
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ndcnm;13;45.52;9.19;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0ne14p;7;45.54;9.19;37
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;night;other;u0ne14g;18;45.54;9.19;1778
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/25;day;other;u0n6x02;16;45.44;9.1;616
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/25;day;other;u0nd8mr;9;45.47;9.16;711
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/25;day;other;u0n6wbp;16;45.44;9.1;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;day;other;u0ne140;7;45.54;9.19;80
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/26;day;other;u0ne14e;8;45.54;9.19;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;night;other;u0n6qz7;21;45.44;9.09;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6wck;15;45.45;9.09;2723
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6x6u;9;45.46;9.11;318
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;day;other;u0n75tu;15;45.56;8.95;1629
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/29;day;other;u0ndbz6;9;45.52;9.18;708
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne1tq;7;45.56;9.22;15
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ndbfx;9;45.5;9.18;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ndbfp;9;45.5;9.18;88
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne14n;12;45.54;9.19;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/18;day;other;u0n6wck;11;45.45;9.09;97
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;day;other;u0ne141;8;45.54;9.19;1186
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/23;day;other;u0n6x6v;9;45.46;9.12;792
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0ne140;6;45.54;9.19;1035
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0ndb2k;12;45.49;9.16;3
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;day;other;u0ne141;6;45.54;9.19;1422
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;night;other;u0ne0fr;21;45.54;9.18;4535
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0n6x31;8;45.45;9.11;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/31;day;other;u0n6x3k;8;45.45;9.11;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/01;day;other;u0ne147;15;45.54;9.19;128
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;day;other;u0ndb86;10;45.49;9.17;553
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/28;night;other;u0ndb6q;22;45.5;9.16;766
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6qz3;15;45.44;9.09;26
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6qz9;15;45.44;9.09;3
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/08;day;other;u0n6x02;8;45.44;9.1;71
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;night;other;u0n6x02;21;45.44;9.1;169
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/05;day;other;u0n6qz3;16;45.44;9.09;806
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/05;night;other;u0n6wck;20;45.45;9.09;1376
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/24;day;other;u0nd8m3;8;45.47;9.15;637
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0n6wbw;15;45.44;9.09;30
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;day;other;u0n6qzf;13;45.44;9.09;878
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/10;day;other;u0ne161;13;45.54;9.2;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ndbfe;9;45.5;9.18;715
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/07;night;other;u0ne18m;20;45.53;9.21;63
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;night;other;u0n6w9z;20;45.45;9.09;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/12;day;other;u0nd9kc;15;45.47;9.2;3
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/17;day;other;u0n6wb0;15;45.44;9.09;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/22;day;other;u0ne408;8;45.53;9.23;15
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0n6w77;15;45.46;9.07;2522
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/03;day;other;u0n6wcm;8;45.45;9.09;2698
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/13;night;other;u0ne0fr;0;45.54;9.18;814
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6qxx;14;45.44;9.09;300
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6x11;9;45.45;9.1;867
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6w9z;16;45.45;9.09;2742
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0n6x6u;16;45.46;9.11;3
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/09;day;other;u0ne138;9;45.54;9.2;66
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/07;night;other;u0ndcmz;19;45.52;9.21;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/19;day;other;u0nd8hg;9;45.47;9.15;685
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;night;other;u0ne14e;21;45.54;9.19;2
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/02;day;other;u0ndb87;10;45.49;9.17;4778
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/04;day;other;u0ne143;7;45.54;9.19;11
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/27;night;other;u0n6x5e;21;45.46;9.1;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6wbw;15;45.44;9.09;1
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6qz1;14;45.43;9.09;86
idfa_a_9e675272-b685-4011-b2a7-459528e1a83f;2019/01/30;day;other;u0n6w9y;13;45.45;9.08;1057
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;day;other;u0nd8uk;13;45.46;9.18;7808
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/17;day;other;u0n6rxv;7;45.44;9.13;2048
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;night;other;u0n6rrk;17;45.44;9.11;128
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;night;other;u0n6rr0;19;45.43;9.11;768
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;night;other;u0n6qzf;19;45.44;9.09;66944
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;day;other;u0nd8um;16;45.46;9.18;2688
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/17;night;other;u0n6xbx;17;45.44;9.14;1024
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;night;other;u0n6rr3;18;45.44;9.11;2560
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;night;other;u0n6qzf;23;45.44;9.09;24704
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;night;other;u0n6rr0;20;45.43;9.11;3456
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;night;other;u0n6qwh;18;45.43;9.08;128
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;night;other;u0n6rpp;22;45.43;9.11;2176
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;night;other;u0n6qzf;19;45.44;9.09;15616
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;day;other;u0n6qzf;6;45.44;9.09;2944
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;day;other;u0nd8uk;16;45.46;9.18;1664
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;night;other;u0n6rqb;18;45.43;9.11;3712
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;night;other;u0n6qzf;0;45.44;9.09;1280
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;night;other;u0n6rpp;22;45.43;9.11;768
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/17;night;other;u0n6qzf;21;45.44;9.09;33024
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;day;other;u0nd8uk;8;45.46;9.18;32640
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;day;other;u0nd8uk;8;45.46;9.18;10112
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;day;other;u0n6rxu;7;45.44;9.12;2304
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0n6qzc;11;45.44;9.09;768
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/27;night;other;u0n6qzf;17;45.44;9.09;18176
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/30;night;other;u0n6x00;17;45.44;9.1;2048
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/30;day;other;u0n6x1p;9;45.45;9.11;7424
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;night;other;u0n6rpp;20;45.43;9.11;768
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;night;other;u0n6rqb;18;45.43;9.11;3328
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;night;other;u0nd2g0;17;45.41;9.17;1920
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;day;other;u0nd8uk;15;45.46;9.18;5760
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;day;other;u0nd8uk;13;45.46;9.18;8576
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/13;day;other;u0n6qzf;11;45.44;9.09;256
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/14;day;other;u0n6qzf;11;45.44;9.09;69376
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/29;day;other;u0nd8uk;8;45.46;9.18;15104
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;night;other;u0n6qzf;0;45.44;9.09;20480
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;night;other;u0n6qzc;19;45.44;9.09;2304
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/17;day;other;u0nd8uh;8;45.46;9.18;2304
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/03;day;other;u20ent2;11;45.56;12.24;1152
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/03;day;other;u20ephs;13;45.55;12.27;4480
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/19;night;other;u0n6qzf;23;45.44;9.09;48768
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/03;night;other;u20ent2;17;45.56;12.24;4608
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;night;other;u0n6qzf;18;45.44;9.09;8832
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;night;other;u0nd83w;18;45.45;9.16;1920
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/17;day;other;u0nd8uh;12;45.46;9.18;1152
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0n6qzf;13;45.44;9.09;1152
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;day;other;u0nd8uk;13;45.46;9.18;2176
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;night;other;u0nd8uj;17;45.46;9.18;2176
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;night;other;u0nd9h0;17;45.46;9.19;256
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/03;day;other;u20entu;12;45.56;12.24;3072
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;night;other;u0n6wb1;23;45.44;9.09;1024
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;day;other;u0nd8uk;7;45.46;9.18;24576
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;day;other;u0nd8um;15;45.46;9.18;1792
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;night;other;u0n6rr0;19;45.43;9.11;1024
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;night;other;u0n6rr0;20;45.43;9.11;3072
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;night;other;u0n6rqb;22;45.43;9.11;5632
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/03;night;other;u20ent2;20;45.56;12.24;14848
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/14;day;other;u0n6xbx;7;45.44;9.14;1536
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;night;other;u0n6wb1;17;45.44;9.09;1152
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/29;day;other;u0nd8uh;12;45.46;9.18;1408
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;night;other;u0n6qzf;19;45.44;9.09;5632
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/29;night;other;u0nd8c7;18;45.45;9.18;2048
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/01;night;other;u0n6qzf;3;45.44;9.09;66816
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;night;other;u0nd8gv;17;45.46;9.18;1664
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;night;other;u0n6qz1;17;45.43;9.09;896
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;night;other;u0nd8dw;17;45.45;9.17;1024
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/17;day;other;u0nd8gg;12;45.46;9.18;768
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/17;night;other;u0n6qzf;17;45.44;9.09;512
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;day;other;u0nd8uk;15;45.46;9.18;1664
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/22;day;other;u0nd8uk;8;45.46;9.18;17536
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/22;day;other;u0n6qzf;7;45.44;9.09;3328
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/17;day;other;u0nd8uk;8;45.46;9.18;11520
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;day;other;u0nd8uh;12;45.46;9.18;1664
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;night;other;u0n6qzf;17;45.44;9.09;2048
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/20;day;other;u0n6qzf;13;45.44;9.09;82816
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;night;other;u0n6rpp;19;45.43;9.11;3200
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;day;other;u0nd2ej;16;45.41;9.17;1024
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;night;other;u0n6qzf;18;45.44;9.09;18304
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;day;other;u0n6xb3;7;45.44;9.13;1664
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/17;day;other;u0nd8uk;12;45.46;9.18;384
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/30;night;other;u0n6rqb;23;45.43;9.11;2048
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/14;day;other;u0nd8uk;8;45.46;9.18;2560
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/29;night;other;u0n6qzf;18;45.44;9.09;1664
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/29;night;other;u0n6qzf;23;45.44;9.09;25728
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/17;day;other;u0nd8uk;12;45.46;9.18;14976
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/12;night;other;u0n6rqb;18;45.43;9.11;896
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0n6qy8;13;45.43;9.09;1408
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;night;other;u0n6qzf;18;45.44;9.09;13312
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;night;other;u0n6rqb;22;45.43;9.11;2304
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/30;day;other;u0n6x1p;14;45.45;9.11;6784
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;night;other;u0n6xb6;17;45.44;9.13;768
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;night;other;u0n6qzf;18;45.44;9.09;16512
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;day;other;u0n6rxv;7;45.44;9.13;2688
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;day;other;u0nd2g4;16;45.41;9.18;2176
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/30;night;other;u0n6qzf;17;45.44;9.09;13312
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;day;other;u0n6qzf;6;45.44;9.09;1152
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;day;other;u0nd8uk;13;45.46;9.18;13568
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/13;day;other;u0n6qzf;16;45.44;9.09;21248
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0n6qzf;16;45.44;9.09;1408
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/30;night;other;u0n6qzf;0;45.44;9.09;27904
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;day;other;u0n6xb6;7;45.44;9.13;2048
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;day;other;u0nd8uk;7;45.46;9.18;17024
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;night;other;u0n6wbj;17;45.44;9.09;1408
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;night;other;u0n6xbx;17;45.44;9.14;640
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/21;day;other;u0n6qzc;7;45.44;9.09;768
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;night;other;u0n6qzf;23;45.44;9.09;128
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;day;other;u0nd8uk;16;45.46;9.18;3712
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0nd8bu;12;45.44;9.18;1664
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/16;night;other;u0n6rr1;21;45.43;9.11;3328
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;day;other;u0nd8gv;12;45.46;9.18;2432
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;night;other;u0n6qzf;18;45.44;9.09;512
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/14;day;other;u0n6wb4;7;45.44;9.09;896
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/18;day;other;u0nd8uk;11;45.46;9.18;3456
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/07;night;other;u0n6qzf;23;45.44;9.09;384
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0nd2g6;7;45.41;9.18;2048
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;night;other;u0n6wb0;19;45.44;9.09;1920
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/29;day;other;u0nd8uk;13;45.46;9.18;13440
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0n6wb1;15;45.44;9.09;2048
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0n6xb3;11;45.44;9.13;1408
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0n6rr1;7;45.43;9.11;2688
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/29;night;other;u0n6qzf;20;45.44;9.09;6016
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0n6wb1;16;45.44;9.09;1664
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0n6x1p;12;45.45;9.11;128
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/14;day;other;u0n6wb4;7;45.44;9.09;896
idfa_d_13a41e0d-928b-4b2b-af37-cfda5516713a;2019/01/31;day;other;u0n6qzf;9;45.44;9.09;7680
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/15;night;other;u0n6wb4;21;45.44;9.09;8213
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/25;day;other;u21tmby;16;45.93;13.62;11
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/29;night;other;u0n6qzf;20;45.44;9.09;43261
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/03;day;other;sr5z3fu;9;40.67;16.61;873
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/11;day;other;u205p8x;7;45.53;11.59;15
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/15;night;other;u0n6qzf;21;45.44;9.09;2660
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/15;night;other;u0n6qzf;20;45.44;9.09;1788
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/20;day;other;u21se3u;6;45.8;13.51;50349
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/11;day;other;u21se3u;11;45.8;13.51;32686
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/10;day;other;u205p8x;5;45.53;11.59;88260
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/15;night;other;u0n6wb4;19;45.44;9.09;3894
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/07;night;other;u21se3u;18;45.8;13.51;1
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/19;night;other;u21se6m;18;45.8;13.51;11
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/03;day;other;sr5z3cu;9;40.66;16.61;912
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/11;day;other;u21sekw;11;45.82;13.51;1363
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/26;night;other;u21se3u;21;45.8;13.51;40036
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/25;day;other;u21tjem;12;45.9;13.61;1981
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/25;day;other;u21tqtb;12;45.96;13.65;874
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/19;night;other;u21se6t;18;45.81;13.51;21
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/15;night;other;u0n6qzf;0;45.44;9.09;8995
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/15;day;other;u20dyhz;14;45.51;12.23;1535
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/15;night;other;u0n6wb4;20;45.44;9.09;1750
idfa_a_af7c55f7-af89-4f85-a914-3acda069ff10;2019/01/25;day;other;u21tmbt;12;45.93;13.62;795
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0t9;11;45.38;9.16;1172
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0n6w8r;18;45.44;9.09;69
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;night;other;u0nd2xu;18;45.44;9.17;59
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;day;other;u0n6qzf;11;45.44;9.09;1612
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;night;other;u0n6w8r;23;45.44;9.09;44159
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;night;other;u0n6qzf;19;45.44;9.09;2741
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;night;other;u0nd9kc;17;45.47;9.2;80
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;day;other;u0n6qzf;16;45.44;9.09;46
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;night;other;u0n6rrk;21;45.44;9.11;10
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/17;day;other;u0nd0t9;12;45.38;9.16;998
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/17;day;other;u0nd0t9;11;45.38;9.16;919
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0nd2r8;20;45.44;9.15;2688
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;day;other;u0n6qzf;16;45.44;9.09;2154
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6qz3;16;45.44;9.09;661
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/30;day;other;u0n6w8r;6;45.44;9.09;88
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0n6rpr;18;45.44;9.11;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0nd80n;20;45.44;9.15;906
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/02;night;other;u0n6w8r;17;45.44;9.09;90
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0nd0q4;16;45.39;9.16;27
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;night;other;u0nd2r2;20;45.44;9.15;828
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/13;night;other;u0n6w8r;17;45.44;9.09;92
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qy8;15;45.43;9.09;2026
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/01;night;other;u0n6qzf;0;45.44;9.09;1060
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;night;other;u0nd0t9;17;45.38;9.16;818
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0n6w8r;16;45.44;9.09;110
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;day;other;u0nd0t9;6;45.38;9.16;526
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;day;other;u0ndccg;7;45.49;9.22;11167
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;night;other;u0nd80n;18;45.44;9.15;689
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0t8;6;45.38;9.16;91
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/09;day;other;u0nd0t9;15;45.38;9.16;3162
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;night;other;u0n6w8r;21;45.44;9.09;61
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;night;other;u0n6qzf;17;45.44;9.09;869
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;day;other;u0n6qz1;16;45.43;9.09;1390
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;day;other;u0nd0t9;11;45.38;9.16;14032
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;day;other;u0n6qzf;16;45.44;9.09;1616
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0nd2rd;21;45.44;9.16;60
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0nd2r8;21;45.44;9.15;1455
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;day;other;u0ndcc6;7;45.49;9.22;79
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;day;other;u0nd0t9;13;45.38;9.16;2174
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;day;other;u0nd0t9;12;45.38;9.16;12
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0nd80q;18;45.44;9.15;29
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/02;night;other;u0nd80j;18;45.44;9.15;674
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/13;night;other;u0n6x02;17;45.44;9.1;146
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6qh6;16;45.42;9.06;968
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/20;night;other;u0n6w8r;22;45.44;9.09;44020
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/16;day;other;u0nd0t9;10;45.38;9.16;1310
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;day;other;u0nd0t8;12;45.38;9.16;42
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;day;other;u0nd0t8;7;45.38;9.16;583
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;night;other;u0nd80n;20;45.44;9.15;874
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6wb5;11;45.44;9.09;70
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/20;day;other;u0n6wb4;11;45.44;9.09;77
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qz2;15;45.44;9.09;27
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0n6w8q;18;45.44;9.08;969
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;day;other;u0n6xe6;6;45.46;9.12;621
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;night;other;u0nd80n;19;45.44;9.15;203
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0n6qyc;13;45.43;9.09;26
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0nd80j;15;45.44;9.15;1943
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0nd80n;14;45.44;9.15;1168
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;day;other;u0nd0t9;15;45.38;9.16;2722
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;day;other;u0nd0mr;6;45.38;9.16;10
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;night;other;u0nd80j;19;45.44;9.15;122
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;day;other;u0n6wb5;11;45.44;9.09;59
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/13;day;other;u0n6wcn;11;45.45;9.09;1735
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/13;day;other;u0n6qzf;12;45.44;9.09;1050
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6wb4;13;45.44;9.09;682
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;night;other;u0n6w8r;23;45.44;9.09;82
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0t9;12;45.38;9.16;478
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;night;other;u0nd0tb;17;45.38;9.16;1436
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0nd80n;20;45.44;9.15;802
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;night;other;u0n6xe6;17;45.46;9.12;172
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0nd80n;16;45.44;9.15;13
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;day;other;u0nd0t8;15;45.38;9.16;395
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0nd80j;19;45.44;9.15;459
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;day;other;u0n6qzf;12;45.44;9.09;819
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/02;night;other;u0n6w8q;18;45.44;9.08;59297
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6qwh;16;45.43;9.08;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/31;day;other;u0nd0t8;13;45.38;9.16;315
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/31;day;other;u0nd0t8;7;45.38;9.16;834
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0nd2px;18;45.44;9.15;1323
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;night;other;u0nd80n;19;45.44;9.15;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/12;day;other;u0n6qzf;16;45.44;9.09;13
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/17;night;other;u0n6w8r;17;45.44;9.09;46716
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;day;other;u0n6wb4;15;45.44;9.09;28
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/02;night;other;u0nd80q;18;45.44;9.15;205
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/02;night;other;u0nd80q;18;45.44;9.15;133
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;night;other;u0n6qz3;18;45.44;9.09;554
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6x9k;14;45.45;9.12;3203
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qzf;13;45.44;9.09;53
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/31;night;other;u0n6w8r;17;45.44;9.09;47488
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/31;night;other;u0nd0t8;18;45.38;9.16;48
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/30;day;other;u0nd0t8;7;45.38;9.16;618
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/30;day;other;u0nd0t8;12;45.38;9.16;177
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;day;other;u0nd0t8;12;45.38;9.16;241
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;day;other;u0nd0t9;12;45.38;9.16;951
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/16;night;other;u0nd80j;20;45.44;9.15;887
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;night;other;u0nd80q;18;45.44;9.15;117
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;night;other;u0n6rrk;18;45.44;9.11;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0n6qwq;17;45.43;9.08;29
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;night;other;u0n6w8r;18;45.44;9.09;1388
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/16;night;other;u0nd80n;18;45.44;9.15;956
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;day;other;u0n6qht;11;45.42;9.06;57
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0n6qyf;13;45.43;9.09;35
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0n6qzf;12;45.44;9.09;1508
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0n6wb4;12;45.44;9.09;442
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;day;other;u0n6x02;15;45.44;9.1;81
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/16;day;other;u0nd0t9;7;45.38;9.16;12
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;day;other;u0nd9qx;7;45.48;9.21;9
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;day;other;u0n6x6f;6;45.46;9.11;1616
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;night;other;u0nd80n;19;45.44;9.15;122
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0nd80n;15;45.44;9.15;471
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0n6qwr;16;45.43;9.09;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;day;other;u0nd0t9;7;45.38;9.16;7008
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0nd2r8;20;45.44;9.15;771
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/09;day;other;u0nd0t8;7;45.38;9.16;1086
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0nd2px;18;45.44;9.15;690
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0nd80n;19;45.44;9.15;16
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0n6rrk;18;45.44;9.11;13
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0nd80j;20;45.44;9.15;2654
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;day;other;u0n6qz3;15;45.44;9.09;62
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0n6w8r;20;45.44;9.09;55
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0nd0mf;16;45.38;9.16;32
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6x90;15;45.45;9.12;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/31;day;other;u0nd0t9;6;45.38;9.16;2225
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;day;other;u0nd0t8;6;45.38;9.16;40
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0nd80j;21;45.44;9.15;1117
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;night;other;u0n6qzf;18;45.44;9.09;35
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0nd80p;21;45.44;9.15;10
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0n6xbe;21;45.44;9.13;38
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0nd2r8;20;45.44;9.15;951
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0nd80j;20;45.44;9.15;2582
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6wb4;13;45.44;9.09;1456
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/31;day;other;u0nd0t9;7;45.38;9.16;11268
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qwh;11;45.43;9.08;4
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/14;day;other;u0nd0t8;7;45.38;9.16;85
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;night;other;u0nd80j;21;45.44;9.15;579
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0n6w8r;0;45.44;9.09;22595
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;day;other;u0nd0t9;16;45.38;9.16;2426
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0nd80n;19;45.44;9.15;1689
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0nd814;18;45.45;9.14;39
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0nd80j;18;45.44;9.15;2024
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/02;night;other;u0nd80j;19;45.44;9.15;2406
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0nd0r2;16;45.39;9.15;735
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6wb4;11;45.44;9.09;2335
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qzg;12;45.44;9.09;650
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/30;day;other;u0nd0t9;6;45.38;9.16;1807
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0nd2px;19;45.44;9.15;2426
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0nd2px;20;45.44;9.15;367
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0t8;7;45.38;9.16;1000
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;day;other;u0n6x02;16;45.44;9.1;762
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0n6qz3;17;45.44;9.09;409
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;day;other;u0ndcce;11;45.49;9.22;2077
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;night;other;u0nd80q;21;45.44;9.15;86
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;night;other;u0nd9x7;17;45.48;9.21;2
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/01;day;other;u0n6qzf;14;45.44;9.09;1519
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/16;day;other;u0nd0t9;6;45.38;9.16;1239
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/09;day;other;u0nd0t8;15;45.38;9.16;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;night;other;u0nd9hs;17;45.46;9.19;6
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;night;other;u0n6w8x;18;45.44;9.09;1627
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;day;other;u0n6qm3;16;45.43;9.07;129
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0nd80q;18;45.44;9.15;71
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;day;other;u0nd0t9;7;45.38;9.16;909
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0nd80n;20;45.44;9.15;1075
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6qz3;16;45.44;9.09;3632
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6wf5;11;45.45;9.09;847
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/01;day;other;u0n6wb4;13;45.44;9.09;3595
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/01;night;other;u0n6w8r;0;45.44;9.09;94
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;day;other;u0n6qwq;10;45.43;9.08;142
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;night;other;u0n6w8r;21;45.44;9.09;26
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0nd80j;13;45.44;9.15;379
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;night;other;u0nd80n;18;45.44;9.15;21
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;day;other;u0n6qzf;15;45.44;9.09;27
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0n6w8r;21;45.44;9.09;27
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0nd2r2;20;45.44;9.15;10
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6wf5;10;45.45;9.09;153
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/02;night;other;u0nd80n;19;45.44;9.15;874
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0nd80j;20;45.44;9.15;50
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;day;other;u0nd0t9;7;45.38;9.16;690
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/02;night;other;u0nd80j;20;45.44;9.15;242
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0nd0t8;6;45.38;9.16;79
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0nd0t9;11;45.38;9.16;2368
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qzd;10;45.44;9.09;25
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;night;other;u0n6qz1;17;45.43;9.09;1202
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/30;day;other;u0nd0qj;6;45.39;9.16;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/16;day;other;u0nd0tc;7;45.38;9.16;873
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0t8;16;45.38;9.16;117
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0t9;11;45.38;9.16;1114
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;day;other;u0ndcc2;7;45.49;9.22;154
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;day;other;u0n6qhs;11;45.42;9.06;40
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0n6qy2;16;45.43;9.09;161
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0nd80q;13;45.44;9.15;124
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;night;other;u0n6wb4;19;45.44;9.09;140
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0nd0t9;9;45.38;9.16;4
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0n6w8r;17;45.44;9.09;2594
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/31;night;other;u0nd0t9;18;45.38;9.16;56
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/20;day;other;u0n6x02;10;45.44;9.1;679
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0t9;11;45.38;9.16;112
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;day;other;u0nd0t8;11;45.38;9.16;1038
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;night;other;u0n6w8r;17;45.44;9.09;33
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0nd2r2;19;45.44;9.15;2291
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;day;other;u0nd9hs;6;45.46;9.19;4
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/12;night;other;u0n6w8r;21;45.44;9.09;47775
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/12;day;other;u0n6wbv;15;45.44;9.09;32
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;day;other;u0nd0t9;7;45.38;9.16;1155
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;night;other;u0n6w8r;17;45.44;9.09;45
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;day;other;u0nd0t8;12;45.38;9.16;374
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/23;day;other;u0nd0t9;7;45.38;9.16;1938
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0n6w8r;17;45.44;9.09;3633
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0nd80q;20;45.44;9.15;29
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0nd0tb;11;45.38;9.16;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;night;other;u0n6qz1;18;45.43;9.09;19
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6wb4;12;45.44;9.09;61
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qzf;14;45.44;9.09;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0nd2px;18;45.44;9.15;35
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0nd2r2;21;45.44;9.15;1026
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0nd80j;18;45.44;9.15;1188
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;night;other;u0n6qy9;17;45.43;9.09;200
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/23;day;other;u0nd0t9;9;45.38;9.16;993
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/13;night;other;u0n6w8q;18;45.44;9.08;53334
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6wb4;15;45.44;9.09;1020
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qwh;10;45.43;9.08;59
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;night;other;u0nd80n;21;45.44;9.15;1596
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;night;other;u0n6rxv;18;45.44;9.13;29
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0n6w8x;18;45.44;9.09;68
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0n6w8r;18;45.44;9.09;733
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/17;night;other;u0nd0q3;17;45.39;9.15;16
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0n6qy2;16;45.43;9.09;234
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0n6wb4;11;45.44;9.09;299
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/23;day;other;u0nd0q4;16;45.39;9.16;28
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;day;other;u0n6w8q;10;45.44;9.08;2943
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;night;other;u0n6xby;21;45.44;9.14;28
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0nd0t9;6;45.38;9.16;22
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;night;other;u0n6w8r;17;45.44;9.09;4231
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;night;other;u0n6qz1;18;45.43;9.09;712
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;day;other;u0ndccd;7;45.49;9.22;582
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;night;other;u0n6wch;21;45.45;9.09;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/02;night;other;u0n6w8q;21;45.44;9.08;2319
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0nd80n;19;45.44;9.15;1520
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qht;10;45.42;9.06;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/16;day;other;u0nd0t9;7;45.38;9.16;845
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/09;day;other;u0n6w8p;6;45.44;9.09;23
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0n6w8r;3;45.44;9.09;3850
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0nd80q;21;45.44;9.15;38
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0nd80n;19;45.44;9.15;1169
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0n6rp3;18;45.44;9.1;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/30;night;other;u0n6w8r;21;45.44;9.09;32
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/30;day;other;u0nd0q3;16;45.39;9.15;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/28;day;other;u0nd0t9;6;45.38;9.16;1826
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0nd2px;20;45.44;9.15;1395
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/16;day;other;u0nd0t9;13;45.38;9.16;9467
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0nd2r8;19;45.44;9.15;1646
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;day;other;u0nd0t9;9;45.38;9.16;3373
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0nd80j;14;45.44;9.15;1937
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0nd80n;15;45.44;9.15;443
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;night;other;u0n6w8r;21;45.44;9.09;47317
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;night;other;u0n6w8q;17;45.44;9.08;2971
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;day;other;u0n6qzd;15;45.44;9.09;84
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0nd80n;19;45.44;9.15;293
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;day;other;u0nd0t9;6;45.38;9.16;2038
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;night;other;u0nd80j;21;45.44;9.15;30
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;day;other;u0n6w8r;11;45.44;9.09;70276
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/12;day;other;u0n6qmt;14;45.43;9.07;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/17;day;other;u0nd0t9;12;45.38;9.16;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;night;other;u0n6wb4;18;45.44;9.09;2027
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;day;other;u0n6qzf;11;45.44;9.09;270
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0nd2r8;18;45.44;9.15;2963
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/28;day;other;u0nd0t8;7;45.38;9.16;420
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/28;day;other;u0nd0t8;8;45.38;9.16;739
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;day;other;u0n6qwh;16;45.43;9.08;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;night;other;u0nd80j;20;45.44;9.15;721
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;night;other;u0n6xby;23;45.44;9.14;26
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/16;day;other;u0nd0t8;7;45.38;9.16;1485
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0nd2r2;20;45.44;9.15;1563
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;night;other;u0n6rx9;21;45.44;9.12;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;day;other;u0n6w8r;7;45.44;9.09;10533
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;day;other;u0nd9kc;6;45.47;9.2;6
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;day;other;u0nd0t8;7;45.38;9.16;1270
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;day;other;u0nd0t8;7;45.38;9.16;193
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;day;other;u0nd0t9;15;45.38;9.16;176
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0nd80n;16;45.44;9.15;16
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;day;other;u0nd0t8;7;45.38;9.16;273
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;day;other;u0nd0q4;16;45.39;9.16;26
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;day;other;u0nd0t9;12;45.38;9.16;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0nd0t9;12;45.38;9.16;819
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/02;night;other;u0nd80n;19;45.44;9.15;1906
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/31;day;other;u0nd0t9;12;45.38;9.16;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;day;other;u0n6qtd;6;45.43;9.08;761
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;night;other;u0nd80n;19;45.44;9.15;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;day;other;u0nd9x7;7;45.48;9.21;3
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;day;other;u0nd0t9;15;45.38;9.16;2803
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;night;other;u0nd80j;18;45.44;9.15;1057
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/07;night;other;u0nd80j;21;45.44;9.15;14
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0nd2r8;18;45.44;9.15;78
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6wbv;11;45.44;9.09;28
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;day;other;u0nd0t8;7;45.38;9.16;73
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;day;other;u0nd0tc;12;45.38;9.16;2109
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;day;other;u0nd0t8;6;45.38;9.16;1377
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/16;day;other;u0nd0t9;12;45.38;9.16;2770
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0n6rp3;18;45.44;9.1;193
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/09;day;other;u0n6qy8;6;45.43;9.09;27
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;night;other;u0n6w8r;21;45.44;9.09;30843
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0n6qzf;17;45.44;9.09;1018
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0nd2r2;19;45.44;9.15;1484
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0n6qy6;16;45.43;9.09;812
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0nd80q;21;45.44;9.15;28
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;day;other;u0n6qz9;15;45.44;9.09;55
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;day;other;u0nd0t8;7;45.38;9.16;694
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0nd80q;21;45.44;9.15;96
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/28;day;other;u0n6qtb;16;45.43;9.08;59
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/28;night;other;u0n6w8r;17;45.44;9.09;360
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;day;other;u0nd0t9;7;45.38;9.16;12
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;night;other;u0n6rjq;19;45.43;9.11;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0nd80q;18;45.44;9.15;99
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0nd0t8;12;45.38;9.16;130
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qzf;13;45.44;9.09;1836
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;night;other;u0n6w8r;21;45.44;9.09;44464
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/01;day;other;u0n6qzf;14;45.44;9.09;963
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/01;day;other;u0n6wcw;15;45.45;9.09;19
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;day;other;u0n6qz3;16;45.44;9.09;2912
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/13;day;other;u0n6qz0;16;45.43;9.09;2046
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/06;day;other;u0n6wbz;9;45.44;9.1;732
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/31;day;other;u0nd0t9;12;45.38;9.16;2432
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;night;other;u0nd80j;18;45.44;9.15;8055
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0qh;6;45.39;9.16;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;night;other;u0nd2xe;18;45.44;9.17;27
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;day;other;u0nd0tb;15;45.38;9.16;959
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;day;other;u0nd0t9;11;45.38;9.16;3359
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/05;day;other;u0n6qzc;11;45.44;9.09;13
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;night;other;u0n6rr1;18;45.43;9.11;55
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/23;day;other;u0n6qm3;16;45.43;9.07;1465
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;day;other;u0nd0t9;11;45.38;9.16;2640
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;night;other;u0nd80n;20;45.44;9.15;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;night;other;u0n6wbv;21;45.44;9.09;49
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;night;other;u0n6rxd;21;45.44;9.12;4
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/14;day;other;u0nd0t9;11;45.38;9.16;2476
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0t9;7;45.38;9.16;2922
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;night;other;u0n6w8r;17;45.44;9.09;193
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;night;other;u0n6wb4;18;45.44;9.09;28
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0n6w8r;18;45.44;9.09;51
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/01;day;other;u0n6qzc;12;45.44;9.09;31
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/11;night;other;u0n6qyf;18;45.43;9.09;9
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;day;other;u0ndccd;11;45.49;9.22;1303
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0nd80j;19;45.44;9.15;1770
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/12;day;other;u0n6rpd;15;45.44;9.1;52
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0nd80n;19;45.44;9.15;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;night;other;u0nd80j;18;45.44;9.15;25
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0n6qgt;16;45.42;9.09;622
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6qzf;12;45.44;9.09;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/28;day;other;u0nd0t8;7;45.38;9.16;382
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/28;day;other;u0n6r8w;6;45.4;9.13;10
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/28;day;other;u0nd0t8;13;45.38;9.16;101
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;day;other;u0nd0t8;16;45.38;9.16;282
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0t9;8;45.38;9.16;363
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;night;other;u0nd8v1;17;45.47;9.18;23
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/12;day;other;u0n6qzf;11;45.44;9.09;6786
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/17;day;other;u0nd0t9;7;45.38;9.16;3277
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;night;other;u0nd80j;20;45.44;9.15;2163
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;night;other;u0nd2r2;19;45.44;9.15;2540
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/04;day;other;u0n6qzf;13;45.44;9.09;2593
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;day;other;u0n6qm3;16;45.43;9.07;1320
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/13;day;other;u0n6wb4;11;45.44;9.09;664
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/13;day;other;u0n6wb5;16;45.44;9.09;31
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/13;night;other;u0n6w8r;18;45.44;9.09;20
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;night;other;u0n6w8p;22;45.44;9.09;133
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;night;other;u0nd80j;19;45.44;9.15;4295
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/18;night;other;u0n6qzf;17;45.44;9.09;1822
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/21;night;other;u0nd80n;18;45.44;9.15;35
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/12;day;other;u0n6wc3;15;45.45;9.09;1472
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/23;day;other;u0nd0t8;7;45.38;9.16;1302
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0nd80j;19;45.44;9.15;637
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/20;day;other;u0n6qzf;11;45.44;9.09;1221
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/26;day;other;u0n6x02;11;45.44;9.1;624
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/14;day;other;u0nd0t9;12;45.38;9.16;2381
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;night;other;u0n6w8r;21;45.44;9.09;213
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;night;other;u0nd80q;21;45.44;9.15;39
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;day;other;u0nd0t8;12;45.38;9.16;124
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/09;day;other;u0nd0t8;7;45.38;9.16;1488
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/15;day;other;u0n6xe9;6;45.46;9.12;33
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/12;day;other;u0n6qwx;14;45.43;9.09;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/12;day;other;u0n6qhs;14;45.42;9.06;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/25;day;other;u0n6qm6;16;45.43;9.07;1038
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/14;day;other;u0nd0t8;7;45.38;9.16;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/14;day;other;u0nd0t9;7;45.38;9.16;699
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/28;day;other;u0nd0t9;9;45.38;9.16;11
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/28;day;other;u0nd0t9;9;45.38;9.16;4749
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;night;other;u0n6w8r;21;45.44;9.09;32962
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/08;night;other;u0nd2xf;21;45.44;9.17;40
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/10;day;other;u0nd0t9;12;45.38;9.16;1548
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/29;night;other;u0n6w8r;17;45.44;9.09;106
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;night;other;u0n6qz1;17;45.43;9.09;1528
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;day;other;u0n6qzf;12;45.44;9.09;2233
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/22;night;other;u0n6w8r;18;45.44;9.09;1
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/19;day;other;u0n6wb4;11;45.44;9.09;64
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/24;night;other;u0nd80j;18;45.44;9.15;722
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/03;day;other;u0n6qzf;11;45.44;9.09;426
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/13;day;other;u0n6qzf;14;45.44;9.09;2503
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/01;day;other;u0n6w8r;12;45.44;9.09;86
idfa_a_8a4e8c61-a613-47e4-a013-e99642de6e1d;2019/01/01;day;other;u0n6qzf;12;45.44;9.09;2102
idfa_d_cc4beca3-4e00-4a1b-b7af-bed7b6cf51ae;2019/01/11;day;other;u0n6qzg;7;45.44;9.09;3584
idfa_d_cc4beca3-4e00-4a1b-b7af-bed7b6cf51ae;2019/01/07;day;other;u0n6v9r;9;45.49;9.04;768
idfa_d_cc4beca3-4e00-4a1b-b7af-bed7b6cf51ae;2019/01/06;night;other;u0n6qzg;21;45.44;9.09;2688
idfa_d_cc4beca3-4e00-4a1b-b7af-bed7b6cf51ae;2019/01/24;day;other;u0nd8hy;11;45.47;9.15;896
idfa_d_cc4beca3-4e00-4a1b-b7af-bed7b6cf51ae;2019/01/07;day;other;u0n6v96;10;45.49;9.03;1024
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/22;night;other;u0nd95t;21;45.46;9.19;640
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/22;night;other;u0nd8vm;21;45.47;9.18;640
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/08;day;other;u0nd83p;9;45.45;9.16;15616
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/07;day;other;u0nd83p;11;45.45;9.16;5248
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/09;day;other;u0nd83p;13;45.45;9.16;12544
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/09;night;other;u0n6rr2;17;45.44;9.11;640
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/21;day;other;u0nd83p;9;45.45;9.16;12160
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/25;night;other;u0n6qzg;20;45.44;9.09;1792
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/30;night;other;u0n6x15;20;45.45;9.1;896
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/19;night;other;src41gm;19;43.96;12.74;7936
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/30;night;other;u0n6wb5;19;45.44;9.09;768
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/01;night;other;u0n6wb5;19;45.44;9.09;3456
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/22;night;other;u0n6xuj;21;45.46;9.14;640
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/09;night;other;u0nd88e;17;45.44;9.17;1152
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/09;night;other;u0n6qk9;17;45.42;9.07;2560
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/07;day;other;u0n5qd4;6;45.58;8.73;1024
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/30;night;other;u0n6wc6;19;45.45;9.09;768
idfa_d_ba46b8c0-a230-4077-93d4-a0d757a7763c;2019/01/04;day;other;u0nd8r4;13;45.48;9.16;8960
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd21j;13;45.4;9.15;3730
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;day;other;u0nd21r;11;45.4;9.15;4190
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/04;night;other;u0n6wbr;19;45.44;9.1;1
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/02;day;other;u0n7jyw;16;45.56;9.05;108
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/20;night;other;u0nd239;20;45.4;9.15;639
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd20v;10;45.4;9.15;509
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd20v;11;45.4;9.15;353
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/06;night;other;u0n7nn8;19;45.56;9.05;77
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd21j;10;45.4;9.15;1146
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/23;night;other;u0n6qzg;22;45.44;9.09;1201
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/04;night;other;u0n6qzg;18;45.44;9.09;50
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd20v;8;45.4;9.15;52
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd20v;9;45.4;9.15;53
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/12;day;other;u0n6qmx;12;45.43;9.07;6680
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0n6qzg;7;45.44;9.09;1279
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/21;day;other;u0nd20v;16;45.4;9.15;937
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd21j;8;45.4;9.15;716
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/21;day;other;u0nd20z;11;45.4;9.15;2642
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;night;other;u0n6wb5;20;45.44;9.09;37
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/06;day;other;u0n7jyy;16;45.57;9.05;1732
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/06;day;other;u0n7nn9;15;45.56;9.05;147
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/11;night;other;u0n6x68;20;45.45;9.11;672
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/11;day;other;u0nd21r;8;45.4;9.15;2
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd21j;9;45.4;9.15;115
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd232;11;45.4;9.15;30
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/05;night;other;u0n6qz4;17;45.43;9.09;11
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;day;other;u0n6wch;16;45.45;9.09;3249
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;day;other;u0n6qwq;16;45.43;9.08;823
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd20v;11;45.4;9.15;1253
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;night;other;u0n6wbw;19;45.44;9.09;17
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/09;night;other;u0nd85y;17;45.46;9.15;245
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/09;night;other;u0nd85y;17;45.46;9.15;14
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/21;day;other;u0nd21j;10;45.4;9.15;778
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;night;other;u0n6xut;21;45.46;9.14;53
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/23;day;other;u0nd6t4;16;45.42;9.25;619
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/11;night;other;u0n6wb5;2;45.44;9.09;1
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/11;night;other;u0n6qzb;20;45.44;9.09;220
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd20v;9;45.4;9.15;211
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/18;day;other;u0nd232;11;45.4;9.15;2282
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/12;night;other;u0n6qte;18;45.43;9.08;3
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/23;night;other;u0n6wc1;17;45.45;9.09;220
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;day;other;u0nd238;12;45.4;9.15;171
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd232;12;45.4;9.15;455
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/12;day;other;u0n6qzg;14;45.44;9.09;843
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/12;night;other;u0n6qtg;18;45.43;9.08;610
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/03;day;other;u0n6wch;16;45.45;9.09;6573
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;day;other;u0nd20v;8;45.4;9.15;6784
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/15;day;other;u0nd20v;12;45.4;9.15;14260
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/13;day;other;u0n6qw6;14;45.43;9.08;577
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/06;day;other;u0n7n25;15;45.53;9.07;708
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/06;day;other;u0n7jyw;16;45.56;9.05;711
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;night;other;u0n6qz4;17;45.43;9.09;41
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/16;day;other;u0nd20y;15;45.4;9.15;4914
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;day;other;u0nd239;11;45.4;9.15;1668
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd21j;10;45.4;9.15;197
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/11;day;other;u0nd20v;12;45.4;9.15;11969
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/11;night;other;u0n6r63;17;45.41;9.11;763
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd21j;8;45.4;9.15;277
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/21;day;other;u0nd21n;10;45.4;9.15;111
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/05;night;other;u0n6qz3;17;45.44;9.09;318
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/05;night;other;u0n6qz1;19;45.43;9.09;10
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/03;day;other;u0n6wb4;15;45.44;9.09;2457
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/02;day;other;u0nd2x8;11;45.44;9.16;774
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/02;night;other;u0ndehh;20;45.46;9.28;141
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd21h;9;45.4;9.15;1385
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;night;other;u0n6wbu;17;45.44;9.09;825
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/17;night;other;u0n6wbu;17;45.44;9.09;2
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;night;other;u0n6x6g;18;45.46;9.11;2
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/19;day;other;u0n6wb5;16;45.44;9.09;1154
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/06;day;other;u0n7nn3;15;45.56;9.05;297
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/26;day;other;sqgu0dm;12;38.68;16.55;386
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd20v;10;45.4;9.15;399
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/21;day;other;u0nd21j;10;45.4;9.15;846
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;night;other;u0n6wb5;18;45.44;9.09;848
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/12;night;other;u0n6qwh;17;45.43;9.08;1
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/12;night;other;u0n6wb5;17;45.44;9.09;889
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/23;day;other;u0nd239;11;45.4;9.15;1766
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/09;day;other;u0nd232;11;45.4;9.15;1137
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0n6qzg;5;45.44;9.09;701
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/04;night;other;u0nd8f7;20;45.45;9.18;4226
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/27;day;other;sqgu09x;9;38.68;16.56;1472
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;night;other;u0n6qzg;19;45.44;9.09;1852
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/16;day;other;u0nd21r;7;45.4;9.15;43
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;day;other;u0nd20y;11;45.4;9.15;779
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/12;night;other;u0n6wbu;17;45.44;9.09;28
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;night;other;u0n7jyw;20;45.56;9.05;7
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/02;night;other;u0n6qy8;20;45.43;9.09;3
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/06;night;other;u0n7jyy;17;45.57;9.05;2819
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/27;day;other;sqggbtw;11;38.66;16.55;776
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/27;day;other;sqgu03q;9;38.68;16.54;1671
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;night;other;u0n6qzg;19;45.44;9.09;80
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/16;night;other;u0n6qzg;17;45.44;9.09;277
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/09;day;other;u0nd20v;8;45.4;9.15;11750
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/15;day;other;u0nd239;12;45.4;9.15;206
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd239;11;45.4;9.15;1692
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd21j;10;45.4;9.15;803
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd21q;12;45.4;9.15;1
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/05;day;other;u0n6qzu;16;45.44;9.09;2965
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/26;night;other;sqgu0dd;18;38.69;16.55;2
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0n6wb5;7;45.44;9.09;1455
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;day;other;u0nd20v;10;45.4;9.15;1526
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd20v;9;45.4;9.15;1363
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;night;other;u0n7nn9;21;45.56;9.05;92
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/20;night;other;u0nd232;19;45.4;9.15;1524
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/20;day;other;u0n6wb5;7;45.44;9.09;5516
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/14;day;other;u0n6rb3;16;45.4;9.13;247
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/16;day;other;u0nd20v;16;45.4;9.15;912
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd20v;11;45.4;9.15;680
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd20v;9;45.4;9.15;344
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;night;other;u0n7nn3;20;45.56;9.05;2
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/02;day;other;u0n7jyy;16;45.57;9.05;825
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/06;night;other;u0n7jyw;18;45.56;9.05;2654
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/20;night;other;u0nd0wz;17;45.39;9.17;660
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/18;day;other;u0nd20v;9;45.4;9.15;6536
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;night;other;u0n7jyt;20;45.56;9.05;1657
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/04;night;other;u0nd8dz;20;45.46;9.17;273
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/24;day;other;sqgt6k9;9;38.92;16.27;11
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/23;day;other;u0nd21r;8;45.4;9.15;30
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/02;day;other;u0n7jyx;16;45.56;9.05;102
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/11;day;other;u0nd20v;8;45.4;9.15;9825
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/11;night;other;u0nd9pc;20;45.48;9.19;2
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/21;day;other;u0nd20y;16;45.4;9.15;13
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;night;other;u0n7nn3;21;45.56;9.05;457
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/20;night;other;u0nd0z8;17;45.39;9.17;1
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/16;day;other;u0nd20v;7;45.4;9.15;11
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;day;other;u0nd20v;12;45.4;9.15;5967
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd20v;9;45.4;9.15;477
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;night;other;u0n6xuw;21;45.46;9.14;980
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/27;day;other;sqgezjp;11;38.66;16.49;1034
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/11;day;other;u0nd20j;11;45.4;9.15;3031
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/15;day;other;u0nd21r;12;45.4;9.15;105
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;night;other;u0n6x4w;18;45.45;9.11;2
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/19;day;other;u0n6qw7;16;45.43;9.08;1
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/23;day;other;u0nd0qg;16;45.39;9.16;1095
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/23;day;other;u0nd6ny;16;45.43;9.24;90
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/04;night;other;u0nd8f3;19;45.45;9.18;1
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd20t;12;45.4;9.15;10
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/21;day;other;u0nd20v;12;45.4;9.15;2102
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/18;day;other;u0nd20v;12;45.4;9.15;11167
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/05;night;other;u0n6qz3;18;45.44;9.09;3095
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd20v;12;45.4;9.15;14540
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd21j;10;45.4;9.15;786
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/12;day;other;u0n6wch;15;45.45;9.09;594
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/12;night;other;u0n6qzg;21;45.44;9.09;1784
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/22;night;other;u0n7nn8;20;45.56;9.05;1
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/20;night;other;u0n6qzg;21;45.44;9.09;32952
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/30;day;other;sqgv144;6;38.86;16.57;1920
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/20;night;other;u0nd0z2;18;45.39;9.17;1020
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/13;day;other;u0n6qy8;13;45.43;9.09;2
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/11;night;other;u0nd8ed;21;45.46;9.17;500
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd20v;13;45.4;9.15;154
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;night;other;u0n6qzg;18;45.44;9.09;1291
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd20v;9;45.4;9.15;26
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/21;day;other;u0nd0sn;8;45.37;9.17;36
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/23;day;other;u0nd20v;8;45.4;9.15;8152
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/02;day;other;u0n6wb5;13;45.44;9.09;1186
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/25;day;other;sqgu0dw;5;38.69;16.55;57262
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/06;day;other;u0n6qte;12;45.43;9.08;77
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/20;night;other;u0nd0z3;18;45.39;9.18;2334
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/30;day;other;u0n6qzg;12;45.44;9.09;2826
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/26;night;other;sqgu03n;17;38.68;16.54;22
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/26;day;other;sqgu01m;11;38.68;16.53;44
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/20;night;other;u0nd232;20;45.4;9.15;479
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/08;day;other;u0nd20v;11;45.4;9.15;60
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/23;night;other;u0n6qzg;22;45.44;9.09;223
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/26;day;other;sqgu0dt;10;38.69;16.55;1485
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/14;day;other;u0n6r8u;16;45.4;9.12;691
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;night;other;u0nd8ed;19;45.46;9.17;5854
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/07;day;other;u0nd21j;8;45.4;9.15;1261
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/05;night;other;u0n6qz1;18;45.43;9.09;1136
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/19;day;other;u0n6wch;14;45.45;9.09;3
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/04;night;other;u0nd8en;19;45.46;9.17;1
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/06;day;other;u0n7nn8;15;45.56;9.05;950
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;day;other;u0nd20v;10;45.4;9.15;742
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;day;other;u0nd20y;10;45.4;9.15;716
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/21;day;other;u0nd20v;8;45.4;9.15;6442
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/05;day;other;u0n6wch;14;45.45;9.09;1509
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/23;day;other;u0nd232;8;45.4;9.15;33
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/26;day;other;sqgu0dw;16;38.69;16.55;1168
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/26;night;other;sqgu01m;17;38.68;16.53;443
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/20;night;other;u0nd238;19;45.4;9.15;1806
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/10;day;other;u0nd20v;14;45.4;9.15;4259
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/15;day;other;u0nd232;11;45.4;9.15;1943
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/12;day;other;u0n6wbu;15;45.44;9.09;54
idfa_a_a8484551-f78f-4598-82ff-52b1ba62bf98;2019/01/02;day;other;u0n7jyw;16;45.56;9.05;13
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;night;other;u0nd88z;19;45.44;9.17;128
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/02;day;other;u0nd8zh;8;45.48;9.18;1920
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/02;night;other;u0nd9sm;20;45.46;9.21;4736
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/15;night;other;u0nd0z3;19;45.39;9.18;1024
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/15;night;other;u0nd0zd;18;45.39;9.18;1664
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;night;other;u0n6qzg;17;45.44;9.09;2304
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/02;night;other;u0nd8zj;19;45.48;9.18;128
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;night;other;u0nd9sm;20;45.46;9.21;2176
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;night;other;u0nd8hy;19;45.47;9.15;128
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;day;other;u0n6x0e;16;45.44;9.1;1664
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/28;day;other;u0nd9h9;7;45.46;9.19;640
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;day;other;u0nd96j;14;45.45;9.2;256
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/01;night;other;u0nd9sm;21;45.46;9.21;1792
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;day;other;u0nd9d0;16;45.45;9.21;1024
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;night;other;u0nd8hz;19;45.47;9.15;128
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/02;day;other;u0nd9kh;8;45.46;9.2;1408
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/15;night;other;u0nd0z6;18;45.39;9.18;896
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;night;other;u0nd0z1;18;45.39;9.18;1152
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/01;night;other;u0nd0yu;17;45.39;9.18;2176
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/15;night;other;u0nd0z7;19;45.39;9.18;512
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;night;other;u0nd83k;19;45.45;9.16;640
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/02;night;other;u0nd9sm;21;45.46;9.21;36480
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;day;other;u0nd99b;15;45.45;9.21;1152
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/15;night;other;u0nd0yc;18;45.39;9.18;256
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;day;other;u0n6qvy;16;45.43;9.09;1024
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;night;other;u0nd0t1;17;45.38;9.16;1408
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/01;night;other;u0nd21z;18;45.41;9.15;384
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/02;night;other;u0ndb8e;19;45.49;9.17;640
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;day;other;u0nd9d1;15;45.45;9.21;896
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;day;other;u0nd9s0;14;45.46;9.21;128
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;day;other;u0nd93z;16;45.45;9.21;640
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;day;other;u0nd930;16;45.45;9.2;1792
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;day;other;u0nd9e8;14;45.46;9.21;256
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;night;other;u0nd0z6;17;45.39;9.18;1536
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;day;other;u0nd937;16;45.45;9.2;128
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/28;day;other;u0ndb30;8;45.49;9.15;1152
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/24;night;other;u0nd911;20;45.45;9.19;1536
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/02;day;other;u0ndb8y;9;45.49;9.17;256
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/14;day;other;u0nd93z;16;45.45;9.21;128
idfa_d_161de9f1-b55e-4f05-ae7d-6e9b97078094;2019/01/01;night;other;u0nd0z2;17;45.39;9.17;3200
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/31;day;other;u0n6qzf;15;45.44;9.09;19712
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/31;day;other;u0n6qzf;8;45.44;9.09;896
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/31;day;other;u0n6wbu;11;45.44;9.09;1408
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/27;night;other;u0n6qzf;22;45.44;9.09;39040
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/29;day;other;u0n6x10;7;45.45;9.1;640
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/26;night;other;u0n6qzf;21;45.44;9.09;896
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/29;night;other;u0n6qzf;19;45.44;9.09;10240
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/30;day;other;u0n6wb4;6;45.44;9.09;128
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/28;night;other;u0n6wb4;21;45.44;9.09;8960
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/29;day;other;u0ndcg5;12;45.5;9.22;2688
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/29;day;other;u0n6wb4;6;45.44;9.09;3456
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/30;night;other;u0n6qzf;22;45.44;9.09;1536
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/31;day;other;u0n6wb4;8;45.44;9.09;4224
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/30;night;other;u0n6qzf;21;45.44;9.09;6784
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/31;day;other;u0n6qzf;7;45.44;9.09;1024
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/29;night;other;u0n6qzf;20;45.44;9.09;6912
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/29;day;other;u0n6x6u;7;45.46;9.11;1664
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/31;day;other;u0n6qtg;13;45.43;9.08;1024
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/31;day;other;u0n6wb4;8;45.44;9.09;128
idfa_d_3c88fb21-da5f-4d9c-9aa4-689b62a42264;2019/01/31;day;other;u0n6qzf;10;45.44;9.09;3328
idfa_d_b21408de-0594-4d29-a3b5-7a7225a36063;2019/01/11;day;other;u0n6w6k;8;45.45;9.07;17920
idfa_d_b21408de-0594-4d29-a3b5-7a7225a36063;2019/01/11;day;other;u0n6wcb;8;45.45;9.09;1152
idfa_d_b21408de-0594-4d29-a3b5-7a7225a36063;2019/01/10;night;other;u0n6qze;22;45.44;9.09;3840
idfa_d_b21408de-0594-4d29-a3b5-7a7225a36063;2019/01/11;night;other;u0n6qze;2;45.44;9.09;20224
idfa_d_b21408de-0594-4d29-a3b5-7a7225a36063;2019/01/11;day;other;u0n6qjt;13;45.43;9.06;1664
idfa_d_b21408de-0594-4d29-a3b5-7a7225a36063;2019/01/11;day;other;u0n6qze;14;45.44;9.09;1664
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;day;other;u0n6rpd;12;45.44;9.1;1152
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;day;other;u0n6rpr;15;45.44;9.11;896
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6qzg;13;45.44;9.09;1792
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;day;other;u0n6rpd;14;45.44;9.1;4352
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;day;other;u0n6qwm;16;45.43;9.08;640
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;day;other;u0n6qzg;16;45.44;9.09;1408
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;day;other;u0n6rpd;14;45.44;9.1;768
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;night;other;u0n6qzg;17;45.44;9.09;7168
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;day;other;u0n6rpf;13;45.44;9.1;640
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;night;other;u0n6qzg;18;45.44;9.09;19840
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;day;other;u0n6x02;16;45.44;9.1;896
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6qxr;14;45.44;9.09;768
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6qz3;12;45.44;9.09;1536
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;night;other;u0n6qzf;21;45.44;9.09;768
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6qz3;12;45.44;9.09;1536
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/30;day;other;u0n6qzf;13;45.44;9.09;1664
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;day;other;u0n6rpd;13;45.44;9.1;1792
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;day;other;u0n6rpy;15;45.44;9.11;896
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6wbw;15;45.44;9.09;1152
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;night;other;u0n6qzg;21;45.44;9.09;7424
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6qzg;15;45.44;9.09;1792
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/28;night;other;u0n6qws;17;45.43;9.08;1792
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/30;day;other;u0n6qzg;14;45.44;9.09;9984
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/30;night;other;u0n6qzg;23;45.44;9.09;45568
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/30;day;other;u0n6qzg;13;45.44;9.09;1408
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6qzu;13;45.44;9.09;896
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;night;other;u0n6qzg;0;45.44;9.09;40064
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;night;other;u0n6qzg;20;45.44;9.09;1920
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6rpw;11;45.44;9.11;768
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6wbt;15;45.44;9.09;768
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6wbf;14;45.44;9.09;1280
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6qzf;16;45.44;9.09;1408
idfa_d_6774f145-af39-4d70-b9b8-d4e6512666b0;2019/01/29;day;other;u0n6x0k;11;45.44;9.1;2560
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;night;other;u0n6qzf;18;45.44;9.09;988
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;night;other;u21tqwd;22;45.96;13.65;2459
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3fw;12;40.66;16.61;14
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/13;day;other;u21se3u;16;45.8;13.51;12840
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/16;night;other;u0nd0nm;18;45.39;9.15;692
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;night;other;sr7bj6b;20;40.8;16.75;1237
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;night;other;u21tqwe;3;45.96;13.65;482
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;day;other;u0n6wb4;7;45.44;9.09;177
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/04;day;other;u21sec6;10;45.8;13.53;83
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;night;other;u21tqm9;17;45.95;13.64;28
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/14;day;other;u20dyhz;14;45.51;12.23;3
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/14;night;other;u0pfr74;17;45.41;11.22;10
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/12;night;other;u21se6k;19;45.8;13.51;49
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;night;other;sr7bj1w;19;40.79;16.75;12
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;night;other;sr7bj1y;21;40.79;16.75;1233
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;night;other;u21se3u;18;45.8;13.51;866
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/16;night;other;u0n6wr6;21;45.48;9.07;638
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/07;night;other;u21se3u;19;45.8;13.51;141
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/13;night;other;u21se3u;19;45.8;13.51;53
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/09;night;other;spvwqdb;18;44.71;8.02;920
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/16;day;other;u0ndeq3;11;45.47;9.29;54
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/10;day;other;u206bpp;12;45.52;11.61;2640
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/12;night;other;u21se6m;18;45.8;13.51;1211
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u206pz6;13;45.39;11.95;508
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;night;other;u21tqwe;18;45.96;13.65;987
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u206pz3;12;45.39;11.94;1082
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/09;day;other;spvw443;14;44.66;7.82;11226
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;night;other;u0n6wr6;23;45.48;9.07;9936
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/05;day;other;u21se6e;11;45.81;13.51;7
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/28;day;other;u206m23;14;45.4;11.83;1438
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/28;night;other;u0nd0ps;17;45.39;9.15;613
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/28;night;other;u0n6qzf;17;45.44;9.09;1322
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u206pz6;12;45.39;11.95;794
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;night;other;u21tqmz;17;45.96;13.64;3578
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3gm;11;40.67;16.61;259
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;day;other;u21qj6d;14;46.07;13.24;650
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;day;other;u21mnnn;13;45.91;13.28;720
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;night;other;u21se3u;17;45.8;13.51;4551
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/06;night;other;u21se4b;18;45.81;13.49;1530
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/27;day;other;u21se6f;13;45.81;13.51;3341
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/17;night;other;u0n6qzf;18;45.44;9.09;1615
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/17;day;other;u0n6qzg;12;45.44;9.09;2559
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/04;day;other;u21sec6;16;45.8;13.53;699
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u206pz3;12;45.39;11.94;776
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/04;day;other;u21seep;11;45.81;13.52;1782
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3gp;12;40.67;16.61;939
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/08;day;other;u206pz6;13;45.39;11.95;1155
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;night;other;u0n6wb4;19;45.44;9.09;1
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;day;other;u21tjsn;13;45.9;13.61;787
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;night;other;sr7bj6b;20;40.8;16.75;1830
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/14;day;other;u21sdgj;7;45.81;13.49;640
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/07;day;other;u21se6e;6;45.81;13.51;1
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/22;night;other;u21se3u;18;45.8;13.51;47570
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;day;other;u0n6qzf;11;45.44;9.09;1709
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/07;day;other;u21seky;7;45.82;13.51;1521
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3cm;13;40.66;16.61;17432
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/07;day;other;u21sefv;8;45.81;13.53;1076
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;night;other;u21se4d;19;45.81;13.49;1327
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/16;night;other;u0n6wbp;18;45.44;9.1;1391
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/16;day;other;u0ndewx;13;45.48;9.3;5358
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;night;other;u0n6qzf;2;45.44;9.09;753
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/07;day;other;u21seef;9;45.81;13.52;1287
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/04;day;other;u21sep8;10;45.83;13.49;618
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;night;other;u21tqjh;17;45.95;13.63;27
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/03;day;other;sr7ddg4;11;41.24;16.3;1348
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/10;day;other;u205pb6;7;45.53;11.59;2297
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/07;day;other;u21qj4f;6;46.07;13.23;11
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;day;other;u0n6wb4;10;45.44;9.09;3
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/12;day;other;u21se3u;9;45.8;13.51;30
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u206py2;13;45.39;11.94;177
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;night;other;sr7bj4z;20;40.8;16.75;1729
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/31;day;other;u20g22v;16;45.58;12.32;979
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/30;night;other;u0n6qzf;18;45.44;9.09;2297
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/30;night;other;spypx2v;17;44.92;8.76;1518
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/22;day;other;u21se6s;9;45.81;13.51;1219
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/17;day;other;u0n6wb4;13;45.44;9.09;14393
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;night;other;u0n6qzf;2;45.44;9.09;38
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u205pb6;9;45.53;11.59;4787
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u21tqhu;16;45.95;13.63;11
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/08;night;other;u205p6c;20;45.54;11.57;16
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/09;day;other;spvw502;13;44.65;7.87;1934
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;day;other;u0n6wb4;10;45.44;9.09;687
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/31;day;other;u0n6qwy;14;45.43;9.08;630
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/26;night;other;u21se3u;21;45.8;13.51;61803
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/09;day;other;spvw503;12;44.65;7.87;3536
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/09;day;other;u0pdmj7;10;45.43;10.77;893
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;night;other;u21tqtb;17;45.96;13.65;182
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;day;other;u0nd20v;11;45.4;9.15;40
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;day;other;u0n6r8g;11;45.4;9.12;1
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;night;other;u0nd20v;17;45.4;9.15;763
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;day;other;u21tqw0;4;45.96;13.65;107
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;day;other;u21se3u;16;45.8;13.51;1385
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/14;day;other;u21qj69;6;46.07;13.24;68
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/14;day;other;u21qj6d;6;46.07;13.24;121
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/16;day;other;u0ndeq3;15;45.47;9.29;5537
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;day;other;u0nd20v;15;45.4;9.15;9750
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/17;night;other;u0n6qzc;19;45.44;9.09;85
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u205pb6;8;45.53;11.59;2275
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;night;other;u21tqjr;17;45.95;13.63;4
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u21se6e;5;45.81;13.51;1
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;night;other;u21qj4g;20;46.07;13.23;818
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/03;day;other;sr7ddfg;11;41.24;16.3;75
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3gh;11;40.67;16.61;799
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3ft;12;40.66;16.61;55
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;night;other;u21sf74;18;45.85;13.46;1329
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;night;other;u0n6qzc;2;45.44;9.09;71
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/17;day;other;u0n6wb4;7;45.44;9.09;3409
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u206pz6;11;45.39;11.95;3068
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u205pb6;8;45.53;11.59;657
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/28;night;other;u21tq77;2;45.94;13.64;674
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;day;other;u0n6qzc;11;45.44;9.09;80
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3fp;12;40.66;16.61;1089
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;day;other;u0nd20v;12;45.4;9.15;7420
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;day;other;u0n6qzf;7;45.44;9.09;1088
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/12;night;other;u21sefz;17;45.81;13.53;2830
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/27;night;other;u21se3u;23;45.8;13.51;32390
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/08;day;other;u206py2;14;45.39;11.94;41
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/21;night;other;u0n6qyu;0;45.43;9.09;39
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u206pz6;13;45.39;11.95;238
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;night;other;u21musv;20;46.04;13.21;1274
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;night;other;u21se6e;19;45.81;13.51;2324
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;day;other;u21tqw3;4;45.96;13.65;29
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;day;other;u0n6qsn;11;45.42;9.08;1
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;day;other;u0pfpjd;15;45.38;11.21;2133
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/05;day;other;u21se3u;11;45.8;13.51;14915
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/22;day;other;u21se3u;15;45.8;13.51;8250
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/04;day;other;u21sedx;10;45.81;13.52;93
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;night;other;u21tqwe;22;45.96;13.65;1120
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/06;night;other;u21sdfz;17;45.81;13.49;3504
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/16;night;other;u0ndeq6;17;45.47;9.29;1045
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;day;other;u0ngu94;12;45.67;9.69;1
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/07;day;other;u21qj68;6;46.07;13.24;37
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/21;night;other;u21se3u;17;45.8;13.51;1352
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/04;day;other;u21se9k;10;45.8;13.52;25
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/25;day;other;u21qhgk;13;46.07;13.22;637
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3fq;12;40.66;16.61;44
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;night;other;sr7bj1z;19;40.79;16.75;13
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3gh;11;40.67;16.61;151
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3fq;13;40.66;16.61;78
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/31;night;other;u0n6x8b;0;45.44;9.12;3
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/26;day;other;u21kxgq;14;45.81;13.36;993
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/15;night;other;u0n6wb5;19;45.44;9.09;27
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/03;day;other;sr9u7x4;14;42.97;13.87;16
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/24;day;other;u206pwr;15;45.39;11.94;615
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;night;other;sr5zu71;21;40.75;16.71;948
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/03;day;other;sr7ddfc;11;41.24;16.29;1
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/14;night;other;u0pfkuh;17;45.42;11.11;1
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/31;night;other;u0n6qzf;0;45.44;9.09;62
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/28;day;other;u21mvrw;6;46.05;13.25;803
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/28;day;other;u21ht43;13;45.8;12.88;909
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/28;day;other;u206qmg;15;45.43;11.88;1049
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/28;day;other;u21se4q;13;45.8;13.5;885
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/30;day;other;spvw4eq;14;44.67;7.85;973
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/27;day;other;u21sekw;14;45.82;13.51;219
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/09;night;other;spvw443;17;44.66;7.82;33
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;day;other;u0n6wb4;8;45.44;9.09;1406
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/05;night;other;u21se3u;17;45.8;13.51;52718
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/04;day;other;u21se3u;11;45.8;13.51;15572
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/11;day;other;u21se3e;11;45.8;13.51;729
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/18;day;other;u0ngen5;12;45.65;9.63;1
idfa_a_76032e0f-e1c9-474d-87a4-91517f7674d1;2019/01/02;day;other;sr5z3f8;10;40.66;16.6;90`
}
